import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImwebImage1 from '../../../../images/ImwebImage1.png';
import ImwebImage2 from '../../../../images/ImwebImage2.png';
import ImwebImage3 from '../../../../images/ImwebImage3.png';
import ImwebImage4 from '../../../../images/ImwebImage4.png';
import ImwebImage5 from '../../../../images/ImwebImage5.png';
import ImwebImage6 from '../../../../images/ImwebImage6.png';

const ImwebGuide = () => {
  const divRefs = useRef<any>([]);
  const [tapIndex, setTapIndex] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (tapIndex === 0 || tapIndex) {
      divRefs.current.forEach((el: any, index: number) => {
        if (tapIndex === index) {
          el.classList.add('selected');
        } else {
          el.classList.remove('selected');
        }
      });
    }
  }, [tapIndex]);

  const getContents = () => {
    return tapIndex === 0 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">01</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>사이트 목록 페이지</span>에 들어가
                <br />
                <span style={{ color: '#4758D3' }}>[디자인 모드]</span> 를 눌러주세요.
              </>
            ) : (
              <>
                Please go to the Site List page and click on
                <br /> <span style={{ color: '#4758D3' }}>[Design Mode]</span>.
              </>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <img width={800} src={ImwebImage1} alt="아임웹 가이드 이미지01" />
        </div>
      </div>
    ) : tapIndex === 1 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">02</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>메뉴 관리</span>에서
                <br />
                <span style={{ color: '#4758D3' }}>편집할 페이지</span>를 선택해주세요.
              </>
            ) : (
              <>
                Please select the page you want to edit
                <br /> in the <span style={{ color: '#4758D3' }}>Manage Menu</span>.
              </>
            )}
          </div>
        </div>
        <div>
          <img width={800} src={ImwebImage2} alt="아임웹 가이드 이미지02" />
        </div>
      </div>
    ) : tapIndex === 2 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">03</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>버튼을 넣고 싶은 위치</span>를 선택해
                <br />
                <span style={{ color: '#4758D3' }}>[코드]</span>를 눌러 블록을 추가합니다.
              </>
            ) : (
              <>
                Please select the position where you want to add
                <br /> the button and click on <span style={{ color: '#4758D3' }}>[Code]</span> to add a block.
              </>
            )}
          </div>
        </div>
        <div>
          <img width={800} src={ImwebImage3} alt="아임웹 가이드 이미지03" />
        </div>
      </div>
    ) : tapIndex === 3 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">04</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>마우스 오른쪽</span> 클릭해
                <br />
                <span style={{ color: '#4758D3' }}>코드 설정 화면</span>을 띄워주세요.
              </>
            ) : (
              <>
                Please right-click and open the <span style={{ color: '#4758D3' }}>Code Settings</span>
              </>
            )}
          </div>
        </div>
        <div>
          <img width={800} src={ImwebImage4} alt="아임웹 가이드 이미지04" />
        </div>
      </div>
    ) : tapIndex === 4 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">05</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>플리카</span>에서 내 웹사이트에 넣고 싶은
                <br />
                <span style={{ color: '#4758D3' }}>버튼 디자인</span>을 선택해
                <br />
                <span style={{ color: '#4758D3' }}>[자바스크립트]</span>버튼을 눌러 복사해주세요.
                <br />
                <span className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub__Explain2">
                  잠깐! 만약 Free 플랜을 이용 중이실 경우 하이퍼링크 버튼을 클릭해주세요.
                </span>
              </>
            ) : (
              <>
                Please select the <span style={{ color: '#4758D3' }}>button design</span> you want to use
                <br /> on your website from <span style={{ color: '#4758D3' }}>PlicAR</span> and copy the
                <br /> <span style={{ color: '#4758D3' }}>[JavaScript]</span> button by clicking it. <br />
                <span className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub__Explain2">
                  Wait! If you are using the Free plan, please click on the hyperlink button instead.
                </span>
              </>
            )}
          </div>
        </div>
        <div>
          <img width={800} src={ImwebImage5} alt="아임웹 가이드 이미지05" />
        </div>
      </div>
    ) : tapIndex === 5 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">06</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>코드 블록</span>에 복사한 코드를
                <br />
                <span style={{ color: '#4758D3' }}>붙여넣기</span> 하고 [게시하기]를 눌러주세요.
              </>
            ) : (
              <>
                Please <span style={{ color: '#4758D3' }}>paste the copied code</span> into the code block
                <br /> and click on [Publish].
              </>
            )}
          </div>
        </div>
        <div>
          <img width={800} src={ImwebImage6} alt="아임웹 가이드 이미지06" />
        </div>
      </div>
    ) : (
      ''
    );
  };

  return (
    <div className="DetailViewerGuide__Contents__Wrapper">
      <div className="DetailViewerGuide__Contents__Wrapper__Tap">
        <div
          ref={(el: any) => (divRefs.current[0] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item selected"
          onClick={() => setTapIndex(0)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">01</div>
          {`${t('Detail.디자인 모드')}`}
        </div>
        <div
          ref={(el: any) => (divRefs.current[1] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item"
          onClick={() => setTapIndex(1)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">02</div>
          {`${t('Detail.메뉴 관리')}`}
        </div>
        <div
          ref={(el: any) => (divRefs.current[2] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item"
          onClick={() => setTapIndex(2)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">03</div>
          {`${t('Detail.코드 블록')}`}
        </div>
        <div
          ref={(el: any) => (divRefs.current[3] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item"
          onClick={() => setTapIndex(3)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">04</div>
          {`${t('Detail.코드 설정')}`}
        </div>
        <div
          ref={(el: any) => (divRefs.current[4] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item"
          onClick={() => setTapIndex(4)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">05</div>
          {`${t('Detail.코드 복사')}`}
        </div>
        <div
          ref={(el: any) => (divRefs.current[5] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item"
          onClick={() => setTapIndex(5)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">06</div>
          {`${t('Detail.코드 붙여넣기')}`}
        </div>
      </div>
      {getContents()}
    </div>
  );
};

export default ImwebGuide;
