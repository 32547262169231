/** 
 @param {number} expire expire time unit is hr
*/
export const setCookie = (name: string, value: string, expire: number | 'never') => {
  let date;
  // set cookie never expire : https://www.geeksforgeeks.org/how-to-set-up-a-cookie-that-never-expires-in-javascript/
  if (expire === 'never') {
    date = new Date(2147483647 * 1000);
  } else {
    date = new Date();
    date.setHours(date.getHours() + expire);
  }
  document.cookie = `${name}=${value};expires=${date.toUTCString()}`;
};

export const getCookie = (keyString: string) => {
  const cookies = document.cookie;
  let cookieArray;
  if (cookies !== '') {
    // document.cookie 형식 = aaa=a; bbb=b; ccc=c;
    cookieArray = cookies.split('; ');
    for (const cookie of cookieArray) {
      const cookieKeyAndValue = cookie.split('=');
      const key = cookieKeyAndValue[0];
      const value = cookieKeyAndValue[1];
      if (key === keyString) {
        return value;
      }
    }
  }
};
