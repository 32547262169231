import React, { useEffect, useState } from 'react';
import { RDSButton } from '@reconlabs/reconlabs-fe-components';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { urlToBlob } from '../../../utils/utils';
import { useModelTransform } from '../../../hooks/react-query/useThreeDModel';
import { useTranslation } from 'react-i18next';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import DetailGIFImage from '../../../images/DetailGIFImage.png';
import DetailGIFImageEn from '../../../images/DetailGIFImageEn.png';

type Detail3dGIFFileProps = {
  disabled: boolean;
  gifUrl: string;
  gifUid: string;
  name: string;
  setRDSAlert: Function;
  viewerSwitch?: boolean;
};

const Detail3dGIFFile = (props: Detail3dGIFFileProps) => {
  /*----------------------------------------
                    Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const { gifUid, gifUrl } = props;
  const [gifDownloadUrl, setGifDownloadUrl] = useState<string>('');
  const { data } = useModelTransform(gifUid);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (data && data.result === 'success') {
      setGifDownloadUrl(data.url);
    }
  }, [data]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const gifDownload = async () => {
    const blob: any = await urlToBlob(gifDownloadUrl);
    const link = document.createElement('a');
    link.download = getFileName(props.name);
    link.href = window.URL.createObjectURL(blob);
    link.click();
    props.setRDSAlert({ type: 'success', message: t('Detail.다운로드가 완료되었습니다!') });
  };

  const getFileName = (name: string) => {
    return name.slice(0, name.lastIndexOf('.')) + '.gif';
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div className="Detail3DGIF">
      <Row>
        <Col xl={1}></Col>
        <Col className="Detail3DGIF__title" xl={10}>
          {`${t('Detail.GIF 이미지')}`}
        </Col>
        <Col xl={1}></Col>
      </Row>
      <Row className="Detail3DGIF__contents">
        <Col xl={1}></Col>
        <Col className="Detail3DGIF__contents__viewer" xl={5}>
          <div className="Detail3DGIF__contents__viewer__paper">
            {gifUrl ? (
              <img src={gifUrl} alt="GIF 이미지" draggable={false} />
            ) : (
              <div className="Detail3DGIF__contents__viewer__paper__waiting">
                {`${t('Detail.뷰어 생성 중입니다...')}`}
              </div>
            )}
          </div>
          <RDSButton
            className="Detail3DGIF__contents__viewer__button"
            size="medium"
            onClick={() => {
              amplitude.sendEvent(amplitudeEvents.detail.zero_utilization_detail_gif_download, {
                viewer_publish_states:
                  props.viewerSwitch === undefined ? 'unknown' : props.viewerSwitch ? 'publish' : 'unpublish',
              });
              gifDownload();
            }}
          >
            {`${t('Detail.GIF 다운로드')}`}
          </RDSButton>
        </Col>
        <Col xl={5}>
          <img src={window.locale === 'ko' ? DetailGIFImage : DetailGIFImageEn} alt="상세페이지 GIF 이미지" />
        </Col>
        <Col xl={1}></Col>
      </Row>
    </div>
  );
};

export default Detail3dGIFFile;
