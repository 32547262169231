import React from 'react';

const KeyboardArrowDownOutlined = ({
  width = 16,
  height = 16,
  color = '#646466',
  ...otherProps
}: {
  width?: number;
  height?: number;
  color?: string;
  [key: string]: any;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={'none'}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M7.41 8.59009L12 13.1701L16.59 8.59009L18 10.0001L12 16.0001L6 10.0001L7.41 8.59009Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default KeyboardArrowDownOutlined;
