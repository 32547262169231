import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CustomQRCode, RDSIconTextButton, RDSModal, UploadIcon } from '@reconlabs/reconlabs-fe-components';
import { validationFile } from '../../utils/utils';
import { DashboardContext } from '../context/DashboardContextProvider';
import { useLimits } from '../../hooks/react-query/useLimits';
import { checkModelSizeLimit, checkRemainingModelCount } from '../../utils/limit';
import RDSUpload from './fileupload/RDSUpload';
import OverViewCountModal from '../global/OverViewCountModal';
import ThreeDViewerImage from '../../images/ThreeDViewerImage.png';
/* @ts-ignore */
import Armchair from '../../images/Armchair.glb';
import ARImage from '../../images/ARImage.png';
import GIFImage from '../../images/GIFImage.png';
import GIFFile from '../../images/GIFFile.gif';
import CatalogImage from '../../images/CatalogImage.png';
import MainPageThreeDModelViewer from './MainPageThreeDModelViewer';
import amplitude from '../../utils/amplitude';
import amplitudeEvents from '../../constants/amplitudeEvents';

const MainPage = () => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  // const
  const { t } = useTranslation();
  const navigate = useNavigate();
  const exhibitionProductId = '7019313b8ad7287c849900062e6fa8283dcdefa3';
  const qrCodeUrl = `https://webapp.plicar.io:443/#/argateway/${exhibitionProductId}`;

  //context
  const { openUploadWindow, setOpenUploadWindow, setIsOverUploadModalOpen, setSpinner, setRemainingUploadCount } =
    useContext(DashboardContext);

  // internal variables
  const [dragOver, setDragOver] = useState<boolean>(false);
  const [sizeLimit, setSizeLimit] = useState<string>('');

  // boolean
  const [isOverViewCount, setIsOverViewCount] = useState<boolean>(false);
  const [isOverViewCountModalOpen, setIsOverViewCountModalOpen] = useState(false);
  const [hoverAR, setHoverAR] = useState<boolean>(false);
  const [hoverGIF, setHoverGIF] = useState<boolean>(false);
  const [open3DViewerModal, setOpen3DViewerModal] = useState<boolean>(false);

  // ref
  const dropInputRef = useRef<HTMLInputElement>(null);
  const hideInputRef = useRef<HTMLInputElement>(null);

  // API calls
  const { data: limitData, refetch: limitRefetch } = useLimits();
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    (async () => {
      const size = await checkModelSizeLimit();
      setSizeLimit(size);
    })();
  }, []);

  useEffect(() => {
    if (openUploadWindow) {
      hideInputRef.current?.click();
      setOpenUploadWindow(false);
    }
  }, [openUploadWindow]);

  useEffect(() => {
    if (limitData?.result === 'success') {
      setIsOverViewCount(limitData?.isOverViewCount);
    }
  }, [limitData]);

  const buttonClicked = async (event: any) => {
    limitRefetch();
    if (isOverViewCount) {
      event.preventDefault();
      setIsOverViewCountModalOpen(true);
    } else {
      hideInputRef.current?.click();
    }
  };

  const getViewerData = () => {
    return {
      enableVerticalPlacement: false,
      isPublished: false,
      modelGlbURL: Armchair,
      modelName: 'Armchair.glb',
      modelUsdzURL: Armchair,
      resizable: true,
      salesURL: null,
    };
  };

  const getFileSize = () => {
    let result;
    if (sizeLimit === 'inf') {
      result =
        window.locale === 'ko' ? (
          <div>&#8226; 업로드 가능 포맷 : glb</div>
        ) : (
          <div>&#8226; Format for uploads : glb</div>
        );
    } else {
      result =
        window.locale === 'ko' ? (
          <div>&#8226; 업로드 가능 포맷 : glb (최대 {sizeLimit})</div>
        ) : (
          <div>&#8226; Format for uploads : glb (Max {sizeLimit})</div>
        );
    }

    return result;
  };
  /*----------------------------------------
                  Default Template
  ----------------------------------------*/
  return (
    <>
      <OverViewCountModal
        isOverViewCountModalOpen={isOverViewCountModalOpen}
        setIsOverViewCountModalOpen={setIsOverViewCountModalOpen}
        closeHandler={() => hideInputRef.current?.click()}
      />
      <RDSModal
        open={open3DViewerModal}
        onClose={() => setOpen3DViewerModal(false)}
        title={'3D VIEWER'}
        supportingText={
          <div style={{ position: 'relative' }}>
            <MainPageThreeDModelViewer viewerData={JSON.stringify(getViewerData())}></MainPageThreeDModelViewer>
          </div>
        }
        customStyle={{
          '.RDSModal': {
            width: '66.71875%',
            maxWidth: '1000px',
          },
          '.RDSModal__container': {
            background: '#f6f6fa',
            padding: '28px 40px',
          },
        }}
      />
      <div className="Main container-fluid">
        <Row className="Main__Text">
          <Col className="Main__Text__Title" xl={12}>
            {`${t('Main.3D 모델 업로드만 하면 뷰어를 한 번에!')}`}
          </Col>
          <Col className="Main__Text__Sub" xl={12}>
            {`${t('Main.내 커머스 페이지에 활용해보세요.')}`}
          </Col>
        </Row>
        <Row>
          <Col xl={5}>
            <div className="Main__Upload">
              <div className={`${dragOver ? 'Main__Upload__Dragover' : 'Main__Upload__DragLeave'}`}></div>
              <RDSUpload.Input checkValidation={(file) => validationFile(file)}>
                {({ handleOnChange }) => (
                  <>
                    <input
                      ref={dropInputRef}
                      id="RDSUpload-input"
                      type="file"
                      multiple
                      title=" "
                      onChange={async (e) => {
                        // Amplitude: drop 이벤트 전송
                        amplitude.sendEvent(amplitudeEvents.main.zero_main_fileupload_dragdrop, {
                          type: 'drop',
                        });
                        setSpinner('loading-spinner');
                        const remainingModelCount = await checkRemainingModelCount();
                        setSpinner('');
                        const fileCount = e.target.files?.length || 0;
                        if (remainingModelCount - fileCount < 0) {
                          setRemainingUploadCount(remainingModelCount);
                          dropInputRef.current!.value = '';
                          setIsOverUploadModalOpen(true);
                          return;
                        }
                        // Amplitude: 파일 개수 전송
                        // amplitude.sendEvent(amplitudeEvents.allmodel.zero_allmodel_nohistory_fileupload_start, {
                        //   count_upload_files: e.target.files?.length ?? 0,
                        // });
                        handleOnChange(e);
                        dropInputRef.current!.value = '';
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        dropInputRef.current!.value = '';
                      }}
                      onDragOver={() => setDragOver(true)}
                      onDragLeave={() => setDragOver(false)}
                      onDragEnd={() => setDragOver(false)}
                      onDrop={() => setDragOver(false)}
                      style={{
                        position: 'absolute',
                        inset: '0',
                        opacity: '0',
                        zIndex: '5',
                      }}
                    ></input>
                    <input
                      ref={hideInputRef}
                      id="RDSUpload-hide-input"
                      type="file"
                      multiple
                      onChange={async (e) => {
                        setSpinner('loading-spinner');
                        const remainingModelCount = await checkRemainingModelCount();
                        setSpinner('');
                        const fileCount = e.target.files?.length || 0;
                        if (remainingModelCount - fileCount < 0) {
                          setRemainingUploadCount(remainingModelCount);
                          hideInputRef.current!.value = '';
                          setIsOverUploadModalOpen(true);
                          return;
                        }
                        handleOnChange(e);
                        // Amplitude: 파일 개수 전송
                        amplitude.sendEvent(amplitudeEvents.main.zero_main_fileupload_progress, {
                          count_upload_files: fileCount,
                        });
                        hideInputRef.current!.value = '';
                      }}
                      onClick={() => {
                        // Amplitude: 버튼 click 이벤트 전송
                        amplitude.sendEvent(amplitudeEvents.main.zero_main_fileupload_btn, {
                          type: 'click',
                        });
                        hideInputRef.current!.value = '';
                      }}
                      style={{ display: 'none' }}
                    ></input>
                  </>
                )}
              </RDSUpload.Input>
              <div className="Main__Upload__Img"></div>
              <div className="Main__Upload__Text">
                <RDSIconTextButton
                  customStyle={{
                    position: 'absolute',
                    width: '249px',
                    height: '50px',
                    marginTop: '-25px',
                    marginBottom: '26px',
                    pointerEvents: dragOver ? 'none' : 'auto',
                    zIndex: '10',
                  }}
                  icon={<UploadIcon />}
                  onClick={buttonClicked}
                  onDragOver={() => setDragOver(true)}
                >
                  {`${t('Main.3D 모델 업로드')}`}
                </RDSIconTextButton>
                <div className="Main__Upload__Text__Wrapper">
                  {getFileSize()}
                  <div>&#8226; {`${t('Main.한 번에 파일 최대 10개 업로드 가능')}`}</div>
                </div>
                <div className="Main__Upload__footer">Drag & drop to upload</div>
              </div>
            </div>
          </Col>
          <Col xl={1}></Col>
          <Col xl={6}>
            <div className="Main__Viewer">
              <Row>
                <Col xl={4}>
                  <div
                    className="Main__Viewer__Wrapper"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer, { type: 'click' });
                      setOpen3DViewerModal(true);
                    }}
                  >
                    <img src={ThreeDViewerImage} alt="3D 뷰어 이미지" draggable={false} />
                    <div className="Main__Viewer__Text">3D viewer</div>
                  </div>
                </Col>
                <Col xl={4}>
                  <div
                    className="Main__Viewer__Wrapper"
                    onMouseOver={() => {
                      amplitude.sendEvent(amplitudeEvents.main.zero_main_example_arviewer, { type: 'hover' });
                      setHoverAR(true);
                    }}
                    onMouseLeave={() => setHoverAR(false)}
                  >
                    <img src={ARImage} alt="AR 이미지" draggable={false} />
                    <div className="Main__Viewer__Text">AR</div>
                    {hoverAR && (
                      <div className="Main__Viewer__QRCode">
                        <CustomQRCode url={qrCodeUrl} width={'126px'} height={'126px'} />
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl={4}>
                  <div
                    className="Main__Viewer__GIFWrapper"
                    onMouseOver={() => {
                      amplitude.sendEvent(amplitudeEvents.main.zero_main_example_gif);
                      setHoverGIF(true);
                    }}
                    onMouseLeave={() => setHoverGIF(false)}
                  >
                    <img src={hoverGIF ? GIFFile : GIFImage} alt="GIF 이미지" draggable={false} />
                    <div className="Main__Viewer__Text">GIF</div>
                  </div>
                </Col>
              </Row>
              <Col xl={12}>
                <div className="Main__Viewer__Wrapper">
                  <img
                    className="Main__Viewer__Catalog"
                    src={CatalogImage}
                    alt="카탈로그 이미지"
                    draggable={false}
                    onClick={() => {
                      amplitude.sendEvent(amplitudeEvents.main.zero_main_catalog, {
                        type: 'click',
                      });
                      navigate('/dashboard/catalog');
                    }}
                  />
                  <div
                    className="Main__Viewer__Text"
                    style={{ cursor: 'pointer', marginBottom: '12px' }}
                    onClick={() => {
                      amplitude.sendEvent(amplitudeEvents.main.zero_main_catalog, {
                        type: 'click',
                      });
                      navigate('/dashboard/catalog');
                    }}
                  >
                    {`${t('Main.3D 카탈로그')}`}
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MainPage;
