import React, { useEffect, useState } from 'react';
import GuideImage from './GuideImage';
import GuideImage2 from './GuideImage2';
import GuideImage3 from './GuideImage3';
import GuideImageEn from './GuideImageEn';
import GuideImage2En from './GuideImage2En';
import GuideImage3En from './GuideImage3En';
import { getModelViewerScriptSource } from '../../../utils/createARScripts';
import { useTranslation } from 'react-i18next';
import {
  RDSPlicarZeroViewer,
  RDSDropdown,
  RDSOption,
  RDSIconTextButton,
  AlertErrorIcon,
  ModalMaker,
  XMarkIcon,
  RDSIconButton,
} from '@reconlabs/reconlabs-fe-components';

type CatalogModelViewerProps = {
  viewerData: ViewerData;
};

type ViewerData = {
  enableVerticalPlacement: boolean;
  isPublished: boolean;
  modelGlbURL: string;
  modelName: string;
  modelUsdzURL: string;
  resizable: boolean;
  salesURL: string | null;
};

const CatalogPreviewModelViewer = (props: CatalogModelViewerProps) => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const [lightingMode, setLightingMode] = useState<'default' | 'studio' | 'neutral' | 'outdoor' | 'indoor'>('default');
  const [measureMode, setMeasureMode] = useState<'none' | 'mm' | 'cm' | 'inch'>('none');
  const [openModal, setOpenModal] = useState<boolean>(false);
  /*----------------------------------------
                    Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (openModal) {
      const el = document.querySelector('#RDSPlicarViewer');
      el?.setAttribute('style', 'background: grey');
    } else {
      const el = document.querySelector('#RDSPlicarViewer');
      el?.setAttribute('style', 'background: #ffffff');
    }
  }, [openModal]);
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <ModalMaker
        isVisible={openModal}
        setIsVisible={setOpenModal}
        modalStyle={{ background: 'none', pointerEvents: 'auto' }}
        backdropStyle={{ background: 'none', pointerEvents: 'auto' }}
      >
        <div className="GuideModal__CloseButton">
          <RDSIconButton
            type="noBox"
            icon={<XMarkIcon />}
            customStyle={{ color: 'white' }}
            onClick={() => setOpenModal(false)}
          />
        </div>
        <div className="GuideModal">
          {window.locale === 'ko' ? (
            <>
              <GuideImage />
              <GuideImage2 />
              <GuideImage3 />
            </>
          ) : (
            <>
              <GuideImageEn />
              <GuideImage2En />
              <GuideImage3En />
            </>
          )}
        </div>
      </ModalMaker>
      <div className="CatalogModelViewer">
        <div className="CatalogModelViewer__Wrapper">
          <div className="CatalogModelViewer__Text">{`${t('Catalog.3D 환경을 설정해보세요!')}`}</div>
          <div className="CatalogModelViewer__Dropdown">
            <RDSDropdown
              width={'100%'}
              onChange={(value) => setLightingMode(value)}
              value={lightingMode}
              customStyles={{ width: '210px', height: '60px' }}
            >
              <React.Fragment key=".0">
                <RDSOption value="default">{`${t('Detail.기본 조명')}`}</RDSOption>
                <RDSOption value="studio">{`${t('Detail.스튜디오 조명')}`}</RDSOption>
                <RDSOption value="neutral">{`${t('Detail.무색조명')}`}</RDSOption>
                <RDSOption value="indoor">{`${t('Detail.실내조명')}`}</RDSOption>
                <RDSOption value="outdoor">{`${t('Detail.실외조명')}`}</RDSOption>
              </React.Fragment>
            </RDSDropdown>
          </div>
          <div className="CatalogModelViewer__Dropdown">
            <RDSDropdown
              width={'100%'}
              onChange={(value) => setMeasureMode(value)}
              value={measureMode}
              customStyles={{ width: '210px', height: '60px' }}
            >
              <React.Fragment key=".0">
                <RDSOption value="none">{`${t('Detail.치수 숨김')}`}</RDSOption>
                <RDSOption value="mm">{`${t('Detail.밀리미터')}`} (mm)</RDSOption>
                <RDSOption value="cm">{`${t('Detail.센치미터')}`} (cm)</RDSOption>
                <RDSOption value="inch">{`${t('Detail.인치')}`} (inch)</RDSOption>
              </React.Fragment>
            </RDSDropdown>
          </div>
          <div className="CatalogModelViewer__Button">
            <RDSIconTextButton
              icon={<AlertErrorIcon />}
              size={'small'}
              color={'primary-light'}
              customStyle={{ width: '198px', height: '50px' }}
              onClick={() => setOpenModal((prev) => !prev)}
            >
              {`${t('Catalog.3D viewer 확인 방법')}`}
            </RDSIconTextButton>
          </div>
        </div>
        <div className="CatalogModelViewer__Viewer">
          <RDSPlicarZeroViewer
            viewerUid={''}
            viewerData={JSON.stringify(props.viewerData)}
            modelViewerScriptSource={getModelViewerScriptSource()}
            lightingMode={lightingMode}
            measureMode={measureMode}
            modelViewerAttributes={{ 'touch-action': 'auto' }}
          />
        </div>
      </div>
    </>
  );
};

export default CatalogPreviewModelViewer;
