import { axiosInstance } from '../fetchInstance/customAxios';
import { getJWTHeaderFromLocalStorage } from '../fetchInstance/axios';

export const checkRemainingModelCount = async (): Promise<number> => {
  const res = await axiosInstance.get(`/user/limit`, {
    headers: getJWTHeaderFromLocalStorage(),
  });
  return res.data.remainingModelCount;
};

export const checkModelSizeLimit = async () => {
  const res = await axiosInstance.get(`/user/limit`, {
    headers: getJWTHeaderFromLocalStorage(),
  });
  return res.data.userPermission.upload_file_size;
};

export const checkOverViewCount = async (): Promise<{ isOverWarningViewCount: boolean; isOverViewCount: boolean }> => {
  const res = await axiosInstance.get(`/user/limit`, {
    headers: getJWTHeaderFromLocalStorage(),
  });
  return { isOverWarningViewCount: res.data.isOverWarningViewCount, isOverViewCount: res.data.isOverViewCount };
};
