import { RDSDropdown, RDSOption, RDSRadio } from '@reconlabs/reconlabs-fe-components';
import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import { useTranslation } from 'react-i18next';
import { AnalysisViewerFilterType } from '../../../constants/type';
import { getPeriodByMonthly, getPeriodDataByMonthly } from '../../../utils/analysisCalculator';
import { checkBillingInfo } from '../../../utils/payment';
import { UserContext } from '../../context/UserContextProvider';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getModelStat } from '../../../hooks/react-query/useThreeDModel';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

type DetailModelCountProps = {
  modelUid: string;
};

const DetailModelCount = ({ modelUid }: DetailModelCountProps) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [baseDate, setBaseDate] = useState<number>(0);
  const [period, setPeriod] = useState<string[]>();
  const [periodParams, setPeriodParams] = useState<any[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('');
  const [currentRadio, setCurrentRadio] = useState<AnalysisViewerFilterType>('all');
  const [chartData, setChartData] = useState<number[]>([]);
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    (async () => {
      const checkBillingInfoData = await checkBillingInfo();

      if (checkBillingInfoData) {
        const nextPaymentDate = checkBillingInfoData.nextPaymentDate.slice(8, 10);
        setBaseDate(Number(nextPaymentDate));
      } else {
        setBaseDate(1);
      }
    })();
  }, []);

  useEffect(() => {
    if (baseDate) {
      setPeriod(getPeriodByMonthly(baseDate, window.locale).res);
    }
  }, [baseDate]);

  useEffect(() => {
    if (period && period.length > 0) {
      setSelectedPeriod(period[0]);
    }
  }, [period]);

  useEffect(() => {
    if (baseDate && period && selectedPeriod) {
      setChartLabels(
        getPeriodDataByMonthly([], getPeriodByMonthly(baseDate).params[period.indexOf(selectedPeriod)]).labels,
      );
      setPeriodParams(
        getPeriodDataByMonthly([], getPeriodByMonthly(baseDate).params[period.indexOf(selectedPeriod)]).params,
      );
    }
  }, [baseDate, period, selectedPeriod]);

  useEffect(() => {
    if (chartLabels && chartLabels.length > 0 && periodParams && periodParams.length > 0) {
      getModalChartData();
    }
  }, [chartLabels, periodParams, currentRadio]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const getModalChartData = async () => {
    if (period) {
      const res: number[] = [];

      for (let i = 0; i < periodParams.length; i++) {
        const data = await getModelStat({
          viewer_type: currentRadio,
          period: periodParams[i],
          model_uid_arr: [modelUid],
          order: ['viewcount', 'asc'],
        });
        res.push(data.viewcounts[0].viewcount);
      }

      setChartData(res);
    }
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <Col xl={10} className="DetailModelCount">
      <div className="DetailModelCount__Title">{`${t('DetailModelCount.모델 조회수 데이터')}`}</div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="DetailModelCount__SubText">
          {`${t('Analysis.플랜')}`} : {user.user_tier} ({`${t('Analysis.매월')}`} {baseDate}
          {`${t('Analysis.일 시작')}`})
        </div>
        {period ? (
          <RDSDropdown
            size="small"
            customStyles={{
              fontFamily: 'Pretendard, sans-serif',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '12px',
              letterSpacing: '0.25px',
            }}
            onChange={(value) => setSelectedPeriod(value)}
            value={selectedPeriod}
          >
            <React.Fragment key=".0">
              {period.map((item: string) => {
                return (
                  <RDSOption key={item} value={item}>
                    {item}
                  </RDSOption>
                );
              })}
            </React.Fragment>
          </RDSDropdown>
        ) : (
          ''
        )}
      </div>
      <div className="DetailModelCount__Radio">
        <RDSRadio
          selected={currentRadio === 'all'}
          handleSelect={() => {
            setCurrentRadio('all');
          }}
          label={`${t('Analysis.전체 보기')}`}
          labelSize="md"
        />
        <RDSRadio
          selected={currentRadio === 'ar'}
          handleSelect={() => {
            setCurrentRadio('ar');
          }}
          label={`AR ${t('Analysis.뷰어')}`}
          labelSize="md"
        />
        <RDSRadio
          selected={currentRadio === '3d'}
          handleSelect={() => {
            setCurrentRadio('3d');
          }}
          label={`3D ${t('Analysis.뷰어')}`}
          labelSize="md"
        />
      </div>
      <div className="DetailModelCount__ChartWrapper">
        <Line
          data={{
            labels: chartLabels,
            datasets: [
              {
                label: t('DetailModelCount.조회수'),
                data: chartData,
                borderWidth: 1,
                fill: true,
                borderColor: '#F0919E',
                backgroundColor: '#F0919E',
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                left: 20,
                right: 20,
                top: 20,
                bottom: 20,
              },
            },
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      </div>
    </Col>
  );
};

export default DetailModelCount;
