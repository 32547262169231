import {
  RDSButton,
  RDSCheckbox,
  AlertErrorFilledIcon,
  RDSInputField,
  RDSModal,
  RDSToastAlertObject,
  RDSToast,
} from '@reconlabs/reconlabs-fe-components';
import React, { useState } from 'react';
import { loginZeroUserpool, sendVerifyEmail } from '../../../utils/login';
import { UserInput } from '../../../types/types';
import { useNavigate } from 'react-router-dom';
import { pressEnter } from '../../../utils/utils';
import { DEFAULT_LOGGEDIN_ROUTE } from '../../../constants/urls';
import { INPUT_LENGTH_LIMIT } from '../../../constants/values';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import { useTranslation } from 'react-i18next';
import { getLocale } from '../../../utils/localstorage';
import { Locale } from '../../../constants/type';

type LoginInputFormProps = {
  setOpenResetPasswordModal: Function;
  setErrorRegisterTypeOauthUser: Function;
};

const LoginInputForm = (props: LoginInputFormProps) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const [input, setInput] = useState<UserInput>({ email: '', password: '' });
  const [staySignedIn, setStaySignedIn] = useState<boolean>(false);
  const [isLoginFailed, setIsLoginFailed] = useState<boolean>(false);
  const [isEmailBlank, setIsEmailBlank] = useState<boolean>(false);
  const [isPasswordBlank, setIsPasswordBlank] = useState<boolean>(false);
  const [isLoginButtonLoading, setIsLoginButtonLoading] = useState(false);
  const [isEmailVerifyNoticeModalOpen, setIsEmailVerifyNoticeModalOpen] = useState(false);
  const [isResendEmailButtonLoading, setIsResendEmailButtonLoading] = useState(false);
  const [emailAlert, setEmailAlert] = useState<RDSToastAlertObject>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  /*----------------------------------------
                  Business Logic
   ----------------------------------------*/
  const onClickLogin = async (e: any) => {
    setIsLoginFailed(false);

    if (isLoginButtonLoading) {
      return;
    }

    const { email, password } = input;

    if (email.length === 0) {
      setIsEmailBlank(true);
      return;
    } else if (password.length === 0) {
      setIsEmailBlank(false);
      setIsPasswordBlank(true);
      return;
    }

    setIsEmailBlank(false);
    setIsPasswordBlank(false);
    setIsLoginButtonLoading(true);

    try {
      // login api
      // await loginZeroUserpool(email, password, staySignedIn);
      // 성공 시 navigate
      await loginZeroUserpool(email, password, staySignedIn, getLocale() as Locale);

      amplitude.sendEvent(amplitudeEvents.signin.zero_signin_email_success, {
        signin_remember: staySignedIn ? 'on' : 'off',
      });
      if (staySignedIn) {
        amplitude.sendEvent(amplitudeEvents.signin.zero_signin_remember);
      }

      navigate(DEFAULT_LOGGEDIN_ROUTE);
    } catch (e: any) {
      amplitude.sendEvent(amplitudeEvents.signin.zero_signin_email_failure);

      if (e.response.data.result === 'invalid_user_email_or_password') {
        setIsLoginFailed(true);
      } else if (e.response.data.result === 'email_not_verified') {
        setIsEmailVerifyNoticeModalOpen(true);
      } else if (e.response.data.result === 'register_type_kakao_user') {
        props.setErrorRegisterTypeOauthUser('kakao');
      } else if (e.response.data.result === 'register_type_naver_user') {
        props.setErrorRegisterTypeOauthUser('naver');
      } else if (e.response.data.result === 'register_type_google_user') {
        props.setErrorRegisterTypeOauthUser('google');
      } else {
        alert('네트워크 오류로 로그인이 실패하였습니다. 잠시 후 다시 이용해 주세요.');
      }
    }
    setIsLoginButtonLoading(false);
  };

  const onClickResendVerifyEmail = async () => {
    setIsResendEmailButtonLoading(true);
    try {
      await sendVerifyEmail(input.email, getLocale() as Locale);
      setEmailAlert({ type: 'success', message: t('Login.이메일이 재발송되었습니다') });
    } catch {
      setEmailAlert({ type: 'failure', message: '네트워크 오류로 실패하여습니다. 잠시후 다시 시도해주세요' });
    }
    setIsResendEmailButtonLoading(false);
  };

  const onChangeInput = (e: any, type: string) => {
    if (type === 'email' && e.target.value.length > INPUT_LENGTH_LIMIT) {
      return;
    }
    setInput((prevInput) => {
      return { ...prevInput, [type]: e.target.value };
    });
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div className="Login__inner__input">
      <RDSModal
        open={isEmailVerifyNoticeModalOpen}
        onClose={() => {
          setIsEmailVerifyNoticeModalOpen(false);
        }}
        title={`${t('Login.회원 가입을 완료해주세요.')}`}
        supportingText={
          <>
            <div>
              {`${t(
                'Login.가입하신 이메일로 인증 버튼이 발송되었습니다. 이메일로 이동하여 남은 회원 가입 절차를 완료해주시기 바랍니다.',
              )}`}
            </div>
            <div className="ResetPassword__resendEmail">
              {`${t('Login.인증 메일을 받지 못하셨나요?')}`}
              <RDSButton
                size="xsmall"
                isLoading={isResendEmailButtonLoading}
                type={'noBox'}
                onClick={onClickResendVerifyEmail}
              >
                {`${t('Login.인증메일 재발송')}`}
              </RDSButton>
            </div>
            <RDSToast.AlertArea timingMs={2000} openedAlert={emailAlert} />
          </>
        }
        buttonType="noBox"
        button1Label={t('MyPage.확인')}
        button1Fn={() => {
          setIsEmailVerifyNoticeModalOpen(false);
        }}
        enableCloseButton={false}
      ></RDSModal>
      <div className="Login__inner__input__email">
        <RDSInputField.Text
          isError={isLoginFailed || isEmailBlank}
          helperText={isEmailBlank ? <>{`${t('Login.이메일을 입력해주세요')}`}</> : ''}
          value={input.email}
          placeholder={t('Login.이메일')}
          handleInputChange={(e: any) => onChangeInput(e, 'email')}
        />
      </div>
      <RDSInputField.Password
        isError={isLoginFailed || isPasswordBlank}
        helperText={
          isLoginFailed ? (
            <div className="Login__inner__input__fail">
              <AlertErrorFilledIcon />
              {`${t('Login.이메일 또는 비밀번호가 잘못되었습니다.')}`}
            </div>
          ) : isPasswordBlank ? (
            <>{`${t('Login.패스워드를 입력해주세요')}`}</>
          ) : (
            ''
          )
        }
        value={input.password}
        placeholder={t('Login.비밀번호')}
        handleInputChange={(e: any) => onChangeInput(e, 'password')}
        onKeyDown={(e: any) => {
          pressEnter(e) && !isLoginButtonLoading ? onClickLogin(e) : null;
        }}
      />
      <div className="Login__inner__input__option">
        <div className="Login__inner__input__option__login">
          <RDSCheckbox.IconType
            label={<div className="Login__inner__input__option__login__text">{`${t('Login.로그인 상태 유지')}`}</div>}
            labelPosition="left"
            checked={staySignedIn}
            handleCheck={() => setStaySignedIn((prevState) => !prevState)}
          />
        </div>
        <div
          className="Login__inner__input__option__password"
          onClick={() => {
            amplitude.sendEvent(amplitudeEvents.signin.zero_signin_change_password);
            props.setOpenResetPasswordModal(true);
          }}
        >
          {`${t('Login.비밀번호 재설정')}`}
        </div>
      </div>
      <div>
        <RDSButton fullWidth onClick={onClickLogin} isLoading={isLoginButtonLoading}>
          {`${t('Login.로그인')}`}
        </RDSButton>
      </div>
    </div>
  );
};

export default LoginInputForm;
