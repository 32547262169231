import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelfImage1 from '../../../../images/SelfImage1.png';
import SelfImage2 from '../../../../images/SelfImage2.png';

const SelfGuide = () => {
  const divRefs = useRef<any>([]);
  const [tapIndex, setTapIndex] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (tapIndex === 0 || tapIndex) {
      divRefs.current.forEach((el: any, index: number) => {
        if (tapIndex === index) {
          el.classList.add('selected');
        } else {
          el.classList.remove('selected');
        }
      });
    }
  }, [tapIndex]);

  const getContents = () => {
    return tapIndex === 0 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">01</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>플리카</span>에서 내 웹사이트에 넣고 싶은
                <br />
                <span style={{ color: '#4758D3' }}>버튼 디자인</span>을 선택해
                <br />
                <span style={{ color: '#4758D3' }}>[자바스크립트]</span>버튼을 눌러 복사해주세요.
              </>
            ) : (
              <>
                Please select the <span style={{ color: '#4758D3' }}>button design</span> you want to use
                <br /> on your website from <span style={{ color: '#4758D3' }}>PlicAR</span> and copy the
                <br /> <span style={{ color: '#4758D3' }}>[JavaScript]</span> button by clicking it.
              </>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <img width={800} src={SelfImage1} alt="자체 개발 가이드 이미지01" />
        </div>
      </div>
    ) : tapIndex === 1 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">02</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>내 웹사이트</span>의 원하는 위치에 코드를
                <br />
                <span style={{ color: '#4758D3' }}>붙여넣기</span>해주세요.
              </>
            ) : (
              <>
                Please <span style={{ color: '#4758D3' }}>paste the code</span> into the desired location
                <br /> on my website.
              </>
            )}
          </div>
        </div>
        <div>
          <img width={800} src={SelfImage2} alt="자체 개발 가이드 이미지02" />
        </div>
      </div>
    ) : (
      ''
    );
  };

  return (
    <div className="DetailViewerGuide__Contents__Wrapper">
      <div className="DetailViewerGuide__Contents__Wrapper__Tap">
        <div
          ref={(el: any) => (divRefs.current[0] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item selected"
          onClick={() => setTapIndex(0)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">01</div>
          {`${t('Detail.코드 복사')}`}
        </div>
        <div
          ref={(el: any) => (divRefs.current[1] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item"
          onClick={() => setTapIndex(1)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">02</div>
          {`${t('Detail.코드 붙여넣기')}`}
        </div>
      </div>
      {getContents()}
    </div>
  );
};

export default SelfGuide;
