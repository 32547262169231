import { FileDownloadIcon, RDSIconTextButton } from '@reconlabs/reconlabs-fe-components';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NaverImage1 from '../../../../images/NaverImage1.png';
import NaverImage2 from '../../../../images/NaverImage2.png';
import NaverImage3 from '../../../../images/NaverImage3.png';
import NaverImage4 from '../../../../images/NaverImage4.png';
import NaverImage5 from '../../../../images/NaverImage5.png';
import NaverImage6 from '../../../../images/NaverImage6.png';
import { urlToBlob } from '../../../../utils/utils';
import btn_ar from '../../../../images/btn_ar.png';
import btn_buy from '../../../../images/btn_buy.png';
import banner from '../../../../images/banner.png';

const NaverGuide = () => {
  const divRefs = useRef<any>([]);
  const [tapIndex, setTapIndex] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (tapIndex === 0 || tapIndex) {
      divRefs.current.forEach((el: any, index: number) => {
        if (tapIndex === index) {
          el.classList.add('selected');
        } else {
          el.classList.remove('selected');
        }
      });
    }
  }, [tapIndex]);

  const download = async (url: string, name: string) => {
    const blob: any = await urlToBlob(url);
    const link = document.createElement('a');
    link.download = name;
    link.href = window.URL.createObjectURL(blob);
    link.click();
  };

  const getContents = () => {
    return tapIndex === 0 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">01</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>네이버 블로그</span>에 들어가
                <br />
                <span style={{ color: '#4758D3' }}>[글쓰기]</span> 클릭 후 제목을 입력해주세요.
              </>
            ) : (
              <>
                Please go to your <span style={{ color: '#4758D3' }}>Naver Blog</span>, click on{' '}
                <span style={{ color: '#4758D3' }}>[Write a Post]</span>,
                <br />
                and enter the title.
              </>
            )}
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <img width={800} src={NaverImage1} alt="네이버 가이드 이미지01" />
          <div
            className="DetailViewerGuide__Contents__Wrapper__Contents__Explain"
            style={{ position: 'absolute', top: '20px', right: '40px' }}
          >
            {window.locale === 'ko' ? (
              <>
                *네이버 스마트 스토어의 상세페이지에는 외부 링크
                <br />
                삽입이 불가능하여 현재는 블로그 작성을 통해 서비스
                <br />를 이용하실 수 있도록 안내해 드리고 있습니다.
              </>
            ) : (
              <>
                *Please note that external links cannot be
                <br />
                inserted on the product detail page of Naver
                <br /> Smart Store, so currently, we recommend
                <br /> using blog posts as an alternative way to
                <br /> promote your products with external links.
              </>
            )}
          </div>
        </div>
      </div>
    ) : tapIndex === 1 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">02</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>플리카에서 [GIF 다운로드]</span>를 눌러 파일을 받고
                <br />
                <span style={{ color: '#4758D3' }}>블로그에서 [사진]</span>을 클릭해 GIF 이미지를 업로드해주세요.
              </>
            ) : (
              <>
                Click on <span style={{ color: '#4758D3' }}>[Download GIF]</span> in PlicAR, then click on
                <br />
                <span style={{ color: '#4758D3' }}>[Photo]</span> in your blog and upload the GIF image.
              </>
            )}
          </div>
        </div>
        <div>
          <img width={800} src={NaverImage2} alt="네이버 가이드 이미지02" />
        </div>
      </div>
    ) : tapIndex === 2 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">03</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>[사진]</span>을 클릭해
                <br />
                <span style={{ color: '#4758D3' }}>버튼 이미지</span> 를 블로그에 삽입해주세요.
                <br />
                <span className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub__Explain">
                  AR 체험을 위한 버튼 이미지+판매 페이지로 이동할 버튼 이미지
                </span>
              </>
            ) : (
              <>
                Please click <span style={{ color: '#4758D3' }}>[Photo]</span> to insert the button image into
                <br />
                your blog.
                <br />
                <span className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub__Explain">
                  Button image for AR experience + Button image to go to sales page
                </span>
              </>
            )}
          </div>
        </div>
        <div>
          <div className="DetailViewerGuide__Contents__Wrapper__ButtonText">
            기본 버튼 이미지 제공 &gt;
            <RDSIconTextButton
              size="medium"
              color="grey_dark"
              icon={<FileDownloadIcon></FileDownloadIcon>}
              onClick={async () => {
                await download(btn_ar, 'ar 버튼 이미지');
                await download(btn_buy, '구매 버튼 이미지');
              }}
            >
              버튼 이미지 받기
            </RDSIconTextButton>
          </div>
          <img width={800} src={NaverImage3} style={{ marginTop: '20px' }} alt="네이버 가이드 이미지03" />
        </div>
      </div>
    ) : tapIndex === 3 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">04</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>플리카 AR 뷰어 [링크 복사]</span> 를 눌러
                <br />
                <span style={{ color: '#4758D3' }}>블로그에서 ‘AR로 상품보기'</span> 에 링크를 삽입하고
                <br />
                <span style={{ color: '#4758D3' }}>판매 페이지 링크</span>를 ‘구매하러 가기'에도 함께 삽입해보세요.
              </>
            ) : (
              <>
                Click the <span style={{ color: '#4758D3' }}>[Copy link]</span> button on the PlicAR and
                <br /> insert the link into "View in AR" on your blog post.
                <br /> Also, add the purchase page link into <span style={{ color: '#4758D3' }}>"Go to</span>
                <br /> <span style={{ color: '#4758D3' }}>purchase</span> button.
              </>
            )}
          </div>
        </div>
        <div>
          <img width={800} src={NaverImage4} alt="네이버 가이드 이미지04" />
        </div>
      </div>
    ) : tapIndex === 4 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">05</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>[발행]</span>을 누르고, 해당 블로그의
                <br />
                <span style={{ color: '#4758D3' }}>주소를 복사</span>해주세요.
              </>
            ) : (
              <>
                Please click <span style={{ color: '#4758D3' }}>[Publish]</span> and{' '}
                <span style={{ color: '#4758D3' }}>copy the URL</span> of the blog.
              </>
            )}
          </div>
        </div>
        <div>
          <img width={800} src={NaverImage5} alt="네이버 가이드 이미지05" />
        </div>
      </div>
    ) : (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">06</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>네이버 스마트 스토어의 [Smart Editor ONE]</span>으로 이동해
                <br />
                <span style={{ color: '#4758D3' }}>배너 이미지</span>를 삽입하고
                <br />
                <span style={{ color: '#4758D3' }}>하이퍼링크</span>에 복사한 블로그 주소를 붙여넣기 해주세요.
              </>
            ) : (
              <>
                Please go to <span style={{ color: '#4758D3' }}>[Smart Editor ONE]</span> in Naver Smart Store,
                <br /> insert the <span style={{ color: '#4758D3' }}>banner image</span>, and paste the copied blog
                <br /> <span style={{ color: '#4758D3' }}>URL into the hyperlink.</span>
              </>
            )}
          </div>
        </div>
        <div>
          <div className="DetailViewerGuide__Contents__Wrapper__ButtonText">
            기본 배너 이미지 제공 &gt;
            <RDSIconTextButton
              size="medium"
              color="grey_dark"
              icon={<FileDownloadIcon></FileDownloadIcon>}
              onClick={() => {
                download(banner, '배너 이미지');
              }}
            >
              배너 이미지 받기
            </RDSIconTextButton>
          </div>
          <img style={{ marginTop: '50px' }} width={800} src={NaverImage6} alt="네이버 가이드 이미지01" />
        </div>
      </div>
    );
  };

  return (
    <div className="DetailViewerGuide__Contents__Wrapper">
      <div className="DetailViewerGuide__Contents__Wrapper__Tap">
        <div
          ref={(el: any) => (divRefs.current[0] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item selected"
          onClick={() => setTapIndex(0)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">01</div>
          {`${t('Detail.블로그 글쓰기')}`}
        </div>
        <div
          ref={(el: any) => (divRefs.current[1] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item"
          onClick={() => setTapIndex(1)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">02</div>
          {`${t('Detail.GIF 업로드')}`}
        </div>
        <div
          ref={(el: any) => (divRefs.current[2] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item"
          onClick={() => setTapIndex(2)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">03</div>
          {`${t('Detail.버튼 이미지')}`}
        </div>
        <div
          ref={(el: any) => (divRefs.current[3] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item"
          onClick={() => setTapIndex(3)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">04</div>
          {`${t('Detail.링크 삽입')}`}
        </div>
        <div
          ref={(el: any) => (divRefs.current[4] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item"
          onClick={() => setTapIndex(4)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">05</div>
          {`${t('Detail.발행 후 복사')}`}
        </div>
        <div
          ref={(el: any) => (divRefs.current[5] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item"
          onClick={() => setTapIndex(5)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">06</div>
          {`${t('Detail.배너 삽입')}`}
        </div>
      </div>
      {getContents()}
    </div>
  );
};

export default NaverGuide;
