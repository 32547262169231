import { UploadState } from '../components/pages/fileupload/RDSUpload';
import { checkModelSizeLimit } from './limit';

/**
 * javascript Date를 받아서 사람이 보기좋은 string으로 변환합니다.
 * ex) 2021-11-16T03:42:21.000Z -> 2021.11.16
 * @param {Date} date  date: 2021-11-16T03:42:21.000Z
 * @param {String} seperator 연월일을 구분자입니다 default  "/"
 * @returns
 */
export const dateToString = (date: Date, seperator = '.') => {
  if (date === undefined || date === null) {
    return '';
  }
  const dateInstance = new Date(date);
  const YEAR = dateInstance.getFullYear(); // yyyy
  const MONTH =
    dateInstance.getMonth() + 1 < 10 ? '0' + String(dateInstance.getMonth() + 1) : dateInstance.getMonth() + 1; // mm
  const DATE = dateInstance.getDate() < 10 ? '0' + String(dateInstance.getDate()) : dateInstance.getDate(); // dd
  const HOUR = dateInstance.getHours() < 10 ? '0' + String(dateInstance.getHours()) : dateInstance.getHours(); // hh
  const MINUTE = dateInstance.getMinutes() < 10 ? '0' + String(dateInstance.getMinutes()) : dateInstance.getMinutes(); // mm

  return `${YEAR}${seperator}${MONTH}${seperator}${DATE} ${HOUR}:${MINUTE}`;
};

/**
 * Date 객체를 입력받아 현재로부터 Date 전날까지의 string과 남은일수를 return 합니다.
 * 브라우저 호환성을 위해 Date 객체를 생성할때 파라미터는 'yyyy-mm-ddThh:mm:ss' 형식이어야 합니다.
 * Chrome : yyyy-mm-dd hh:mm:ss safari: yyyy/mm/dd hh:mm:ss both: yyyy-mm-ddThh:mm:ss
 * @param date
 * @param lang
 * @returns period: string (현재 ~ date 전날) days: number (현재 ~ date 전날)
 */
export const dateToRemainingPeriod = (date: Date, lang = 'ko') => {
  const startDate = new Date();
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);
  const endDate = new Date(date.setDate(date.getDate() - 1));
  endDate.setHours(0);
  endDate.setMinutes(0);
  endDate.setSeconds(0);
  endDate.setMilliseconds(0);
  const startMonth = startDate.getMonth() + 1;
  const startDay = startDate.getDate();
  const endMonth = endDate.getMonth() + 1;
  const endDay = endDate.getDate();
  const days = (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000) + 1;
  return lang === 'ko'
    ? { period: `${startMonth}월 ${startDay}일~${endMonth}월 ${endDay}일`, days: days }
    : { period: `${startDay} ${getEngMonth(startMonth - 1)} - ${endDay} ${getEngMonth(endMonth - 1)}` };
};

/**
 * Date.getMonth() 의 반환값에 맞춰 영문 달력을 return 합니다.
 * @param month
 * @returns string
 */
export const getEngMonth = (month: number, abbreviation = false) => {
  return abbreviation
    ? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][month]
    : [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ][month];
};

/**
 * 1000단위로 구분자 ',' 을 추가하여 반환합니다.
 * @param number
 * @returns string with comma
 */
export const numberWithCommas = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const convertSizeUnit = (size: number) => {
  return Math.round(size / (1024 * 1024));
};

export const pressEnter = (e: any) => {
  return e.keyCode === 13;
};

export const validationFile = async (file: File) => {
  let result: boolean = true;
  let state: UploadState = 'waiting';
  let maxSize = await checkModelSizeLimit();
  maxSize = maxSize.split('MB')[0] * 1024 * 1024;

  if (file.name.split('.').pop() !== 'glb') {
    result = false;
    state = 'invalidFile';
  } else if (maxSize && file.size > maxSize) {
    result = false;
    state = 'canceled';
  } else if (file.size === 0) {
    result = false;
    state = 'invalidToast';
  }
  return { result: result, state: state };
};

export function uuidv4() {
  return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, (c: any) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

export const urlToBlob = (url: string) => {
  return new Promise((resolve, reject) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open('get', url);
      xhr.responseType = 'blob';
      xhr.onerror = () => {
        reject('error');
      };
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject('error : ' + xhr.statusText);
        }
      };
      xhr.send();
    } catch (err: any) {
      reject(err.message);
    }
  });
};

export const getResizedData = async (base64Data: string, fileFormat: string, aspectRatio: string, height: number) => {
  const canvasWidth = (height / Number(aspectRatio.split(':')[1])) * Number(aspectRatio.split(':')[0]);
  const dataFormat = fileFormat === 'jpg' ? 'image/jpeg' : 'image/png';
  return new Promise((resolve) => {
    const img = document.createElement('img');
    img.src = base64Data;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = canvasWidth;
      canvas.height = height;

      if (ctx) {
        ctx.drawImage(img, 0, 0, canvasWidth, height);
        if (fileFormat === 'jpg') {
          // destination-over : 나중에 그려진 도형이 기존 도형 아래로 그려집니다
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, canvasWidth, height);
        }
      }

      const dataURI = canvas.toDataURL(dataFormat);
      resolve(dataURI);
    };
  });
};

export const getQueryStringProps = (queryString: string, props: string, multiple?: boolean) => {
  return queryString.indexOf(props) >= 0
    ? multiple
      ? queryString
          .split('?')[1]
          .split('&')
          .filter((item: string) => item.indexOf(props) + 1)[0]
          .split('=')[1]
      : queryString
          .split('?')
          .filter((item: string) => item.indexOf(props) + 1)[0]
          .split('=')[1]
    : '';
};
