import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { RDSButton, RDSInputField, RDSModal, RDSToast } from '@reconlabs/reconlabs-fe-components';
import { loginResetPassword } from '../../../utils/login';
import { pressEnter } from '../../../utils/utils';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import { useTranslation } from 'react-i18next';

const ResetPasswordPage = () => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [isPwValid, setIsPwValid] = useState<boolean>(true);
  const [isConfirmPwValid, setIsConfirmPwValid] = useState<boolean>(true);
  // token verified === null => initial state (that didnt check valid user yet)
  const [tokenVerified, setTokenVerified] = useState<'verifying' | 'invalid' | 'verified'>('verifying');
  const [completeResetPw, setCompleteResetPw] = useState(false);
  const [toast, setToast] = useState<string>('loading');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      try {
        const res = await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/login/resetpassword?token=${token}`);
        setToast('');
        if (res.data.result === 'success') {
          console.log(res.data);
          setTokenVerified('verified');
          setEmail(res.data.data.user);
        }
      } catch (e) {
        console.log(e);
        setTokenVerified('invalid');
      }
    })();
  }, []);

  const onClickCompleteButton = async () => {
    setIsButtonLoading(true);
    try {
      await loginResetPassword(email, password, token as string);
      setCompleteResetPw(true);
    } catch {
      alert('비밀번호 변경요청이 실패하였습니다. 잠시 후, 다시 시도해 주십시오.');
    }
    setIsButtonLoading(false);
  };

  /*----------------------------------------
                  Life Cycle
     ----------------------------------------*/

  useEffect(() => {
    if (password.length > 0) {
      setIsPwValid(!!validatePassword(password));
    } else {
      setIsPwValid(true);
    }
  }, [password, password.length]);

  useEffect(() => {
    password.length > 0 && passwordConfirm.length > 0 && isConfirmPwValid && isPwValid
      ? setIsButtonDisabled(false)
      : setIsButtonDisabled(true);
  }, [isConfirmPwValid, isPwValid]);

  useEffect(() => {
    if (passwordConfirm.length > 0) {
      setIsConfirmPwValid(!!checkConfirmPassword(passwordConfirm));
    } else {
      setIsConfirmPwValid(true);
    }
  }, [password, passwordConfirm, passwordConfirm.length]);
  /*----------------------------------------
                  Business Logic
     ----------------------------------------*/
  const validatePassword = (pw: string) => {
    const pwRegexp = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    return pw.match(pwRegexp);
  };

  const checkConfirmPassword = (pw: string) => {
    return password === pw;
  };
  return (
    <div className="ResetPasswordPage_background">
      {tokenVerified === 'verifying' && (
        <RDSToast.Overlay
          type="loadingSpinner"
          toastOverlayId="loading"
          openedToastOverlay={toast}
          setOpenedToastOverlay={setToast}
        />
      )}
      {tokenVerified === 'invalid' && (
        <RDSModal
          open={true}
          onClose={() => {
            navigate('/login');
          }}
          title={`${t('Login.유효기간이 만료되었습니다. 다시 시도해주세요.')}`}
          supportingText={`${t(
            'Login.비밀번호 재설정 신청 후 시간이 오래 지났거나, 링크가 잘못된 경우 정상적으로 인증이 되지 않을 수 있습니다. 다시 한번 재설정 시도를 부탁드립니다.',
          )}`}
          buttonType="solid"
          button1Label={t('Login.메인 페이지로 이동')}
          button1Fn={() => {
            amplitude.sendEvent(amplitudeEvents.signin.zero_signin_change_password_failure);
            navigate('/login');
          }}
          enableCloseButton={false}
          enableCloseOnBackdropClick={false}
          enableBackdrop={false}
        ></RDSModal>
      )}
      {tokenVerified === 'verified' &&
        (!completeResetPw ? (
          <div className="ResetPasswordPage">
            <div className="ResetPasswordPage__header">{`${t('Login.비밀번호 재설정')}`}</div>
            <div className="ResetPasswordPage__inner">
              <div className="ResetPasswordPage__inner__header">{`${t(
                'Login.안전을 위해 비밀번호를 재설정해주세요.',
              )}`}</div>
              <div className="ResetPasswordPage__inner__input">
                <RDSInputField.Password
                  customStyles={{ marginTop: '16px' }}
                  title={`${t('Login.새 비밀번호')}`}
                  required
                  isError={!isPwValid}
                  helperText={!isPwValid ? t('Login.올바르지 않은 패스워드 형식입니다. 다시 확인해 주세요') : ''}
                  placeholder={t('Login.비밀번호(영문, 숫자, 특수문자를 조합한 8자)')}
                  value={password}
                  handleInputChange={(e: any) => setPassword(e.target.value)}
                />
                <RDSInputField.Password
                  customStyles={{ marginTop: '10px' }}
                  title={`${t('Login.새 비밀번호 확인')}`}
                  required
                  isError={!isConfirmPwValid}
                  helperText={!isConfirmPwValid ? t('Login.패스워드가 틀립니다. 다시 확인해 주세요') : ''}
                  placeholder={t('Login.비밀번호(영문, 숫자, 특수문자를 조합한 8자)')}
                  value={passwordConfirm}
                  onKeyDown={(e: any) => {
                    pressEnter(e) && !isButtonDisabled && !isButtonLoading ? onClickCompleteButton() : null;
                  }}
                  handleInputChange={(e: any) => {
                    setPasswordConfirm(e.target.value);
                  }}
                  onBlur={() => {
                    checkConfirmPassword(passwordConfirm) ? setIsConfirmPwValid(true) : setIsConfirmPwValid(false);
                  }}
                />
              </div>
              <RDSButton
                fullWidth
                disabled={isButtonDisabled}
                onClick={onClickCompleteButton}
                isLoading={isButtonLoading}
              >
                {`${t('Login.완료')}`}
              </RDSButton>
            </div>
          </div>
        ) : (
          <RDSModal
            open={true}
            onClose={() => {}}
            title={`${t('Login.비밀번호 변경이 완료되었습니다.')}`}
            supportingText={`${t(
              'Login.비밀번호 변경이 정상적으로 완료되었습니다. 로그인 페이지로 이동하여 로그인을 진행해주세요.',
            )}`}
            buttonType="solid"
            button1Label={t('Login.로그인 페이지로 이동')}
            button1Fn={() => {
              amplitude.sendEvent(amplitudeEvents.signin.zero_signin_change_password_success);
              navigate('/login');
            }}
            enableCloseButton={false}
            customWidth={'480px'}
            enableCloseOnBackdropClick={false}
          ></RDSModal>
        ))}
    </div>
  );
};

export default ResetPasswordPage;
