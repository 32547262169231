import { RDSButton } from '@reconlabs/reconlabs-fe-components';
import React, { useState } from 'react';
import { getLoggedInUser, restoreUser } from '../../../hooks/react-query/useZeroUser';
import { useTranslation } from 'react-i18next';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import amplitude from '../../../utils/amplitude';

const RestoreUser = ({ user, setUserInfo }: { user: any; setUserInfo: Function }) => {
  const { t } = useTranslation();
  const [isRestoreButtonLoading, setIsRestoreButtonLoading] = useState(false);

  const onClickRestore = async () => {
    setIsRestoreButtonLoading(true);
    try {
      await restoreUser();
    } catch (e: any) {
      if (e.response.data === 'not_deleted_user') {
        alert(t('MyPage.삭제되지 않은 유저입니다.'));
      }
      setIsRestoreButtonLoading(false);
    }

    amplitude.sendEvent(amplitudeEvents.mypage.zero_mypage_withdrawal_restoration, {
      type: 'restoration_' + user.register_type === 'idpw' ? 'email' : user.register_type,
    });
    setUserInfo(await getLoggedInUser());
    setIsRestoreButtonLoading(false);
  };
  return (
    <div className="RestoreUser">
      <div className="RestoreUser__text">
        {`${t('MyPage.회원 탈퇴하신지 얼마 안되셨군요!')}`}
        <p />
        {`${t('MyPage.다시 계정을 복구해 드릴까요?')}`}
      </div>
      <div>
        <RDSButton fullWidth={true} onClick={onClickRestore} isLoading={isRestoreButtonLoading}>
          {`${t('MyPage.계정복구')}`}
        </RDSButton>
      </div>
    </div>
  );
};

export default RestoreUser;
