import React from 'react';
import { CheckCircleOutlinedIcon } from '@reconlabs/reconlabs-fe-components';
import { convertSizeUnit } from '../../../../utils/utils';
import { UploadFile } from '../RDSUpload';
import { useTranslation } from 'react-i18next';

const UploadDoneFileBox = ({ uploadFile }: { uploadFile: UploadFile }) => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const { t } = useTranslation();
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div className="UploadDone">
      <div className="UploadDone__name">
        {uploadFile.file.name} ({convertSizeUnit(uploadFile.file.size)}MB)
      </div>
      <div className="UploadDone__state">
        <div>{`${t('global.완료')}`}</div>
        <CheckCircleOutlinedIcon />
      </div>
    </div>
  );
};

export default UploadDoneFileBox;
