import React, { useContext, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import {
  RDSDropdown,
  RDSLinkButton,
  RDSModal,
  RDSOption,
  RDSRadio,
  RDSSwitch,
  RDSTab,
  RDSTabs,
  RDSToast,
} from '@reconlabs/reconlabs-fe-components';
import AnalysisAllModels from './AnalysisAllModels';
import AnalysisMonthlyUsage from './AnalysisMonthlyUsage';
import AnalysisEachModel from './AnalysisEachModel';
import { PeriodFilterType, AnalysisViewerFilterType } from '../../../constants/type';
import { ANALYSIS_TAB_INFO_TEXT } from '../../../constants/values';
import { getModelStat, getTrafficStat, useModelsList } from '../../../hooks/react-query/useThreeDModel';
import DataTable from 'react-data-table-component';
import {
  getAnalysisAllModelLabels,
  getModelChartData,
  getPeriodArr,
  getPeriodByMonthly,
  getPeriodDataByDay,
  getPeriodDataByMonth,
  getPeriodDataByMonthly,
  getPeriodDataByWeek,
} from '../../../utils/analysisCalculator';
import { checkBillingInfo } from '../../../utils/payment';
import { UserContext } from '../../context/UserContextProvider';
import { useMaxViewCount, useViewCount } from '../../../hooks/react-query/useTraffic';
import { dateToString } from '../../../utils/utils';
import ModelCardSwitchModal from '../../ModelCardSwitchModal';
import { useUpdateModel } from '../../../hooks/react-query/useUpdateThreeDModel';
import { ThreeDModel } from '../../../types/modelTypes';
import { useNavigate } from 'react-router-dom';

const BAR_CHART_COLORS = [
  '#ACB6FC',
  '#F0919E',
  '#DDC65F',
  '#7391DD',
  '#8CB541',
  '#B77CD2',
  '#E9998D',
  '#6B8FC6',
  '#C6C85A',
  '#80DFD5',
];

const Analysis = () => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  // const
  const { t } = useTranslation();
  const navigate = useNavigate();

  // internal variables
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [currentRadio, setCurrentRadio] = useState<AnalysisViewerFilterType>('all');
  const [currentPeriod, setCurrentPeriod] = useState<PeriodFilterType>('day');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [period, setPeriod] = useState<string[]>();
  const [selectedPeriod, setSelectedPeriod] = useState<string>('');
  const [baseDate, setBaseDate] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(0);
  const [uidOrder, setUidOrder] = useState<string[]>([]);

  // grid selected model uid list
  const [modelIdList, setModelIdList] = useState<string[]>([]);

  // chart data
  const [arChartData, setArChartData] = useState<number[]>([]);
  const [threedChartData, setThreedChartData] = useState<number[]>([]);
  const [monthlyChartData, setMonthlyChartData] = useState<number[]>([]);
  const [modelChartData, setModelChartData] = useState<number[]>([]);
  const [modelChartLabels, setModelChartLabels] = useState<string[]>([]);
  const [monthlyLabels, setMonthlyLabels] = useState<string[]>([]);

  // context
  const { user } = useContext(UserContext);

  const [publishedCatalogModels, setPublishedCatalogModels] = useState<string[]>([]);
  const [savedCatalogModels, setSavedCatalogModels] = useState<string[]>([]);

  // switch clicked model
  const [selectedModel, setSelectedModel] = useState<ThreeDModel>();

  // boolean
  const [selectedViewerSwitch, setSelectedViewerSwitch] = useState<boolean>(false);
  const [openViewerSwitchModal, setOpenViewerSwitchModal] = useState<boolean>(false);
  const [openModelOffDenyModal, setOpenModelOffDenyModal] = useState<boolean>(false);

  // spinner
  const [spinner, setSpinner] = useState<string>('');

  // api calls
  const { models, count, paginationParam, setPaginationParam, isLoading } = useModelsList({ range: [0, 49] });
  const { data: viewCount } = useViewCount();
  const { maxViewCount } = useMaxViewCount();

  const {
    mutate: updateMutation,
    isLoading: isUpdateModelLoading,
    error: updateModelError,
  } = useUpdateModel(paginationParam);

  // DataTable Columns
  const [columns, setColumns] = useState<any[]>([]);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    (async () => {
      const checkBillingInfoData = await checkBillingInfo();

      if (checkBillingInfoData) {
        const nextPaymentDate = checkBillingInfoData.nextPaymentDate.slice(8, 10);
        setBaseDate(Number(nextPaymentDate));
      } else {
        setBaseDate(Number(user.registered_date?.slice(8, 10)));
      }
    })();
  }, []);

  useEffect(() => {
    if (count && count > 50) {
      setPaginationParam({ range: [0, Math.ceil(count / 50) * 50 - 1] });
    }
  }, [count]);

  useEffect(() => {
    if (viewCount && maxViewCount) {
      setPercentage(
        Math.floor(((viewCount?.current_view_count ? viewCount.current_view_count : 0) / maxViewCount) * 100),
      );
    }
  }, [viewCount, maxViewCount]);

  useEffect(() => {
    if (baseDate) {
      setPeriod(getPeriodByMonthly(baseDate, window.locale).res);
    }
  }, [baseDate]);

  useEffect(() => {
    if (period && period.length > 0) {
      setSelectedPeriod(period[0]);
    }
  }, [period]);

  useEffect(() => {
    if (currentTab === 0) {
      getAllModelsData();
    } else if (currentTab === 1) {
      getMonthlyData();
    } else {
      getEachModelData();
    }
  }, [currentTab, currentRadio, currentPeriod, selectedPeriod, modelIdList.length, order, isLoading]);

  useEffect(() => {
    if (isUpdateModelLoading) {
      setSpinner('loading-spinner');
    } else {
      setSpinner('');
    }
  }, [isUpdateModelLoading]);

  useEffect(() => {
    setColumns([
      {
        width: '160px',
        name: t('Analysis.표기'),
        cell: (row: any) => {
          const colorIndex = uidOrder.indexOf(row.uid);
          return (
            <div
              style={{
                width: '24px',
                height: '24px',
                borderRadius: '24px',
                backgroundColor: colorIndex >= 0 ? BAR_CHART_COLORS[colorIndex] : '',
              }}
            ></div>
          );
        },
      },
      {
        name: t('Analysis.모델명'),
        selector: (row: any) => row.model_name,
        cell: (row: any) => {
          return <div>{row.model_name}</div>;
        },
      },
      {
        width: '120px',
        name: t('Analysis.업데이트'),
        selector: (row: any) => row.modified_date,
        center: true,
        cell: (row: any) => {
          const date = row.modified_date.replaceAll('-', '/');
          const utcDate = new Date(date);
          utcDate.setTime(utcDate.getTime() + 9 * 60 * 60 * 1000);
          const koreaTime = dateToString(utcDate);
          return <>{koreaTime.slice(0, 10)}</>;
        },
      },
      {
        width: '160px',
        name: t('Analysis.뷰어공개'),
        selector: (row: any) => row.catalog_state,
        center: true,
        cell: (row: any) => {
          return (
            <RDSSwitch
              enableLabel
              isOn={row.model_published === 1}
              handleClick={() => onSwitchClicked(row.model_published === 1, row)}
            />
          );
        },
      },

      {
        width: '120px',
        name: t('Analysis.모델관리'),
        selector: (row: any) => row.uid,
        center: true,
        cell: (row: any) => {
          return (
            <RDSLinkButton
              size="xsmall"
              color="grey"
              label={`${t('Analysis.자세히 보기')}`}
              onClick={() => navigate(`/dashboard/model/detail?model_uid=${row.uid}`)}
            />
          );
        },
      },
    ]);
  }, [uidOrder, uidOrder.length]);

  useEffect(() => {
    const error: any = updateModelError;
    if (error && error.response.status === 400) {
      errorHandler(error);
      setOpenModelOffDenyModal(true);
    }
  }, [updateModelError]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const getAllModelsData = async () => {
    const res = await getTrafficStat({
      viewer_type: currentRadio,
      period: getPeriodArr(currentPeriod),
    });

    if (currentPeriod === 'day') {
      setArChartData(getPeriodDataByDay(res.viewcounts.all, 'ar'));
      setThreedChartData(getPeriodDataByDay(res.viewcounts.all, '3d'));
    } else if (currentPeriod === 'week') {
      setArChartData(getPeriodDataByWeek(res.viewcounts.all, 'ar'));
      setThreedChartData(getPeriodDataByWeek(res.viewcounts.all, '3d'));
    } else {
      setArChartData(getPeriodDataByMonth(res.viewcounts.all, 'ar'));
      setThreedChartData(getPeriodDataByMonth(res.viewcounts.all, '3d'));
    }
  };

  const getMonthlyData = async () => {
    if (period) {
      const res = await getTrafficStat({
        viewer_type: 'all',
        period: getPeriodByMonthly(baseDate).params[period.indexOf(selectedPeriod)],
      });

      setMonthlyChartData(
        getPeriodDataByMonthly(res.viewcounts.all, getPeriodByMonthly(baseDate).params[period.indexOf(selectedPeriod)])
          .data,
      );

      setMonthlyLabels(
        getPeriodDataByMonthly(res.viewcounts.all, getPeriodByMonthly(baseDate).params[period.indexOf(selectedPeriod)])
          .labels,
      );
    }
  };

  const getEachModelData = async () => {
    if (period) {
      const res = await getModelStat({
        viewer_type: currentRadio,
        period: getPeriodByMonthly(baseDate).params[period.indexOf(selectedPeriod)],
        model_uid_arr: modelIdList.length > 0 ? modelIdList : [],
        order: ['viewcount', order],
      });

      const modelChartData = getModelChartData(
        res.viewcounts.length > 10 ? res.viewcounts.slice(0, 10) : res.viewcounts,
        models,
      );

      setModelChartData(modelChartData.data);
      setModelChartLabels(modelChartData.labels);
      setUidOrder(modelChartData.uidOrder);
    }
  };
  /*----------------------------------------
                Event Handler
   ----------------------------------------*/
  const handleRowDisabled = (row: any) => {
    return modelIdList.length === 10 && modelIdList.indexOf(row.uid) === -1;
  };

  const onSelectedRowsChange = (e: any) => {
    const result: string[] = [];
    const selectedRows = e.selectedRows;
    let validation = false;
    selectedRows.forEach((row: any) => {
      result.push(row.uid);
    });
    if (selectedRows.length > modelIdList.length) {
      selectedRows.forEach((id: string) => {
        if (modelIdList.indexOf(id) === -1) {
          validation = true;
        } else {
          result.push(id);
        }
      });
    } else if (modelIdList.length > selectedRows.length) {
      modelIdList.forEach((id: string) => {
        if (selectedRows.indexOf(id) === -1) {
          validation = true;
        } else {
          result.push(id);
        }
      });
    }
    if (validation) {
      setModelIdList(result);
    }
  };

  const onSwitchClicked = (value: boolean, selectedModel: any) => {
    setSelectedViewerSwitch(value);
    setSelectedModel(selectedModel);

    if (localStorage.getItem('plicar-zero_closed_modal')) {
      handleSwitch(value);
    } else {
      setOpenViewerSwitchModal(true);
    }
  };

  const handleSwitch = async (value: boolean) => {
    const updateModel = {
      uid: selectedModel ? selectedModel.uid : '',
      model_name: selectedModel ? selectedModel.model_name : '',
      model_memo: selectedModel ? selectedModel.model_memo : '',
      model_sales_url: selectedModel ? selectedModel.model_sales_url : '',
      model_published: value ? 0 : 1,
    };
    updateMutation(updateModel);
  };

  const errorHandler = (e: any) => {
    const data = e.response.data.catalogs;
    const catalogs: string[] = data.catalogs;
    const publishedCatalogs: string[] = data.published_catalogs;
    const saved: string[] = [];
    const published: string[] = [];

    if (catalogs.length > 0) {
      catalogs.forEach((catalog: string) => {
        if (publishedCatalogs && publishedCatalogs.indexOf(catalog) >= 0) {
          published.push(catalog);
        } else {
          saved.push(catalog);
        }
      });
    }

    setPublishedCatalogModels(published);
    setSavedCatalogModels(saved);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <ModelCardSwitchModal
        openModal={openViewerSwitchModal}
        setOpenModal={setOpenViewerSwitchModal}
        viewerSwitch={selectedViewerSwitch}
        setViewerSwitch={setSelectedViewerSwitch}
        handleSwitch={handleSwitch}
      />
      <RDSModal
        title={`${t('Detail.AR, 3D 뷰어 공개를 중단할 수 없는 모델입니다')}`}
        enableCloseButton={false}
        button1Label={t('Detail.카탈로그 확인하기')}
        button1Fn={() => {
          navigate('/dashboard/catalog');
        }}
        button2Label={t('Detail.닫기')}
        button2Fn={() => {
          setOpenModelOffDenyModal(false);
        }}
        supportingText={
          <>
            {`${t(
              'Detail.모델이 카탈로그에 포함되어서 AR, 3D 뷰어 공개 중단이 불가능합니다. 중단을 원하시면 카탈로그에서 먼저 모델을 제거해주세요.',
            )}`}
          </>
        }
        linkText={
          <div style={{ maxHeight: '130px', overflowY: 'scroll' }}>
            {publishedCatalogModels.length > 0 && (
              <div>
                <div> · {`${t('Detail.발행되어 있는 카탈로그')}`}</div>
                {publishedCatalogModels.map((model, index) => {
                  return (
                    <div key={model + index} style={{ marginLeft: '20px' }}>
                      - {model}
                    </div>
                  );
                })}
              </div>
            )}
            {savedCatalogModels.length > 0 && (
              <div>
                <div> · {`${t('Detail.저장되어 있는 카탈로그')}`}</div>
                {savedCatalogModels.map((model, index) => {
                  return (
                    <div key={model + index} style={{ marginLeft: '20px' }}>
                      - {model}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        }
        open={openModelOffDenyModal}
        onClose={() => {
          setOpenModelOffDenyModal(false);
        }}
      />
      <RDSToast.Overlay
        type="loadingSpinner"
        toastOverlayId="loading-spinner"
        openedToastOverlay={spinner}
        setOpenedToastOverlay={setSpinner}
      />
      <div className="Analysis container-fluid">
        <Row>
          <Col xl={{ span: 10, offset: 1 }}>
            <RDSTabs value={currentTab} handleChange={(value: number) => setCurrentTab(value)}>
              <RDSTab label={`${t('Analysis.전체 모델')}`} customStyle={{ padding: '0 32px' }} />
              <RDSTab label={`${t('Analysis.월별 사용량')}`} customStyle={{ padding: '0 32px' }} />
              <RDSTab label={`${t('Analysis.모델별')}`} customStyle={{ padding: '0 32px' }} />
              <RDSTab borderline />
            </RDSTabs>
          </Col>
        </Row>
        <Row>
          <Col xl={{ span: 10, offset: 1 }}>
            <div className="Analysis__TabInfo">
              {`${t(ANALYSIS_TAB_INFO_TEXT[currentTab])}`}
              {currentTab === 2 && window.locale === 'en' && <br />}
              {currentTab === 2 && <>{`${t(ANALYSIS_TAB_INFO_TEXT[currentTab + 1])}`}</>}
            </div>
          </Col>
        </Row>
        <Row style={{ display: currentTab === 0 ? 'none' : '', marginTop: '20px' }}>
          <Col xl={{ span: 6, offset: 1 }} className="Analysis__Usage">
            <div className="Analysis__Usage__Mount" style={{ display: currentTab === 2 ? 'none' : '' }}>
              {`${t('Analysis.전체사용량')}`} : {percentage}%
            </div>
            <div className="Analysis__Usage__Plan">
              {`${t('Analysis.플랜')}`} : {user.user_tier} ({`${t('Analysis.매월')}`} {baseDate}
              {`${t('Analysis.일 시작')}`})
            </div>
          </Col>
          <Col xl={4} className="Analysis__Period">
            {period ? (
              <RDSDropdown
                size="small"
                customStyles={{
                  fontFamily: 'Pretendard, sans-serif',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '12px',
                  letterSpacing: '0.25px',
                }}
                onChange={(value) => setSelectedPeriod(value)}
                value={selectedPeriod}
              >
                <React.Fragment key=".0">
                  {period.map((item: string) => {
                    return (
                      <RDSOption key={item} value={item}>
                        {item}
                      </RDSOption>
                    );
                  })}
                </React.Fragment>
              </RDSDropdown>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <Row style={{ marginBottom: '6px', display: currentTab === 1 ? 'none' : '' }}>
          <Col xl={{ span: 5, offset: 1 }}>
            <div className="Analysis__Allmodels__Radio">
              <RDSRadio
                selected={currentRadio === 'all'}
                handleSelect={() => {
                  setCurrentRadio('all');
                }}
                label={`${t('Analysis.전체 보기')}`}
                labelSize="md"
              />
              <RDSRadio
                selected={currentRadio === 'ar'}
                handleSelect={() => {
                  setCurrentRadio('ar');
                }}
                label={`AR ${t('Analysis.뷰어')}`}
                labelSize="md"
              />
              <RDSRadio
                selected={currentRadio === '3d'}
                handleSelect={() => {
                  setCurrentRadio('3d');
                }}
                label={`3D ${t('Analysis.뷰어')}`}
                labelSize="md"
              />
            </div>
          </Col>
          <Col xl={5}>
            <div className="Analysis__Allmodels__Period">
              {currentTab === 2 ? (
                <RDSTabs
                  value={order}
                  type="box"
                  size="small"
                  handleChange={(value: 'asc' | 'desc') => setOrder(value)}
                >
                  <RDSTab value="asc" label={`${t('Analysis.오름차순')}`} />
                  <RDSTab value="desc" label={`${t('Analysis.내림차순')}`} />
                </RDSTabs>
              ) : (
                <RDSTabs
                  value={currentPeriod}
                  type="box"
                  size="small"
                  handleChange={(value: PeriodFilterType) => setCurrentPeriod(value)}
                >
                  <RDSTab value="day" label={`${t('Analysis.AllModels.일별')}`} />
                  <RDSTab value="week" label={`${t('Analysis.AllModels.주별')}`} />
                  <RDSTab value="month" label={`${t('Analysis.AllModels.월별')}`} />
                </RDSTabs>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={{ span: 10, offset: 1 }} style={{ marginTop: '24px' }}>
            <div className="Analysis__ChartWrapper">
              {currentTab === 0 && (
                <AnalysisAllModels
                  currentRadio={currentRadio}
                  labels={getAnalysisAllModelLabels(currentPeriod, window.locale)}
                  arData={arChartData}
                  threedData={threedChartData}
                />
              )}
              {currentTab === 1 && (
                <AnalysisMonthlyUsage
                  label={`${t('Analysis.누적 조회수')}`}
                  labels={monthlyLabels}
                  monthlyData={monthlyChartData}
                />
              )}
              {currentTab === 2 && (
                <AnalysisEachModel
                  colors={BAR_CHART_COLORS}
                  labels={modelChartLabels}
                  data={modelChartData}
                ></AnalysisEachModel>
              )}
            </div>
            {currentTab === 2 && uidOrder.length > 0 && (
              <div className="Analysis__ModelDataTable">
                <DataTable
                  data={models}
                  columns={columns}
                  selectableRows
                  selectableRowsNoSelectAll
                  onSelectedRowsChange={onSelectedRowsChange}
                  selectableRowDisabled={(row: any) => handleRowDisabled(row)}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Analysis;
