/** @jsxImportSource @emotion/react */
import { ModalMaker, XMarkIcon } from '@reconlabs/reconlabs-fe-components';
import React, { useState } from 'react';
import { getCookie, setCookie } from '../utils/cookie';
import { Viewer } from '@toast-ui/react-editor';
import { PopupType } from './layout/GlobalLayout';

const PopupNoticeModal = ({
  type,
  title,
  content,
  cookieName,
  headerImageSrc,
  width,
  height,
}: PopupNoticeModalProps) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  let shouldModalOpenAtFirst = true;
  const [shouldModalOpen, setShouldModalOpen] = useState<boolean>(true);
  /*----------------------------------------
                 Business Logic
   ----------------------------------------*/
  const onClickNotToSeeAgain = (exp: number | 'never') => {
    setCookie(cookieName, 'stop', exp);
    setShouldModalOpen(false);
  };

  const checkCookieAndSeePopupShouldOpen = () => {
    if (getCookie(cookieName) === 'stop') {
      return 'close';
    }
    return 'open';
  };

  if (checkCookieAndSeePopupShouldOpen() === 'close') {
    shouldModalOpenAtFirst = false;
  }

  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <ModalMaker isVisible={shouldModalOpenAtFirst && shouldModalOpen} setIsVisible={setShouldModalOpen}>
      <div
        css={{
          backgroundColor: 'white',
          width: width,
          height: height,
          overflowY: 'scroll',
        }}
      >
        {type === 'notice' ? (
          <PopupNoticeModal.Notice
            onClickNotToSeeAgain={onClickNotToSeeAgain}
            onClose={setShouldModalOpen}
            width={width}
            height={height}
            title={title}
            content={content}
          />
        ) : (
          <PopupNoticeModal.Emergency
            onClickNotToSeeAgain={onClickNotToSeeAgain}
            onClose={setShouldModalOpen}
            headerImageSrc={headerImageSrc}
            width={width}
            height={height}
            title={title}
            content={content}
          />
        )}
      </div>
    </ModalMaker>
  );
};

export default PopupNoticeModal;

type PopupNoticeModalProps = {
  type: PopupType;
  title: string;
  content: string;
  cookieName: string;
  headerImageSrc: string;
  width: number;
  height: number;
};

PopupNoticeModal.Notice = ({
  onClickNotToSeeAgain,
  onClose,
  width,
  height,
  title,
  content,
}: PopupNoticeContentProps) => {
  return (
    <div css={{ padding: width / 20 }}>
      <div
        css={{
          justifyContent: 'right',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          marginBottom: '15px',
          fontWeight: '500',
        }}
      >
        <div
          css={{ cursor: 'pointer' }}
          onClick={() => {
            onClickNotToSeeAgain('never');
          }}
        >
          다시보지 않을래요
        </div>
        <div
          onClick={() => {
            onClose(false);
          }}
          css={{ cursor: 'pointer' }}
        >
          <XMarkIcon css={{ color: 'lightgrey' }} />
        </div>
      </div>
      <div
        css={{
          border: '#0071FF 1px solid',
          borderStyle: 'dashed',
          padding: `${height / 20}px ${width / 20}px ${height / 20}px ${width / 20}px`,
        }}
      >
        <div>
          <div
            css={{
              fontFamily: 'Noto Sans KR',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '36px',
              lineHeight: '52px',
              textAlign: 'center',
              whiteSpace: 'pre-line',
              color: '#000000',
              '@media (max-width: 780px)': {
                fontSize: '23px',
                lineHeight: '30px',
              },
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
          <Viewer initialValue={content} />
          <p />
          <div css={{ fontWeight: '800' }}>문의가 필요하시면 채널톡을 이용해주세요</div>
        </div>
      </div>
    </div>
  );
};

PopupNoticeModal.Emergency = ({
  onClickNotToSeeAgain,
  onClose,
  width,
  height,
  headerImageSrc,
  title,
  content,
}: PopupNoticeContentProps) => {
  return (
    <div css={{ padding: width / 20 }}>
      <div
        css={{
          justifyContent: 'right',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          marginBottom: '15px',
          fontWeight: '500',
        }}
      >
        <div
          css={{ cursor: 'pointer' }}
          onClick={() => {
            onClickNotToSeeAgain(24);
          }}
        >
          오늘 다시보지 않을래요
        </div>
        <div
          onClick={() => {
            onClose(false);
          }}
          css={{ cursor: 'pointer' }}
        >
          <XMarkIcon css={{ color: 'lightgrey' }} />
        </div>
      </div>
      <div
        css={{
          border: '#0071FF 1px solid',
          borderStyle: 'dashed',
          padding: `${height / 20}px ${width / 20}px ${height / 20}px ${width / 20}px`,
        }}
      >
        <div
          css={{
            height: '75px',
            paddingBottom: '30px',
            '@media (max-width: 780px)': {
              height: '40px',
              paddingBottom: '10px',
            },
          }}
        >
          <div
            css={{
              margin: 'auto',
              padding: '10px 0 10px 0',
              background: ' #0071FF',
              width: '70px',
              height: '70px',
              '@media (max-width: 780px)': {
                width: '45px',
                height: '45px',
              },
            }}
          >
            <img
              css={{
                height: '50px',
                margin: 'auto',
                objectFit: 'contain',
                '@media (max-width: 780px)': {
                  marginTop: '5px',
                  width: '10px',
                  height: '35px',
                },
              }}
              src={headerImageSrc}
            />
          </div>
        </div>
        <div>
          <div
            css={{
              fontFamily: 'Noto Sans KR',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '36px',
              lineHeight: '52px',
              textAlign: 'center',
              whiteSpace: 'pre-line',
              color: '#000000',
              '@media (max-width: 780px)': {
                fontSize: '23px',
                lineHeight: '30px',
              },
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
          <Viewer initialValue={content} />
          <p />
          <div css={{ fontWeight: '800' }}>문의가 필요하시면 채널톡을 이용해주세요</div>
        </div>
      </div>
    </div>
  );
};

type PopupNoticeContentProps = {
  onClickNotToSeeAgain: Function;
  onClose: Function;
  title: string;
  content: string;
  headerImageSrc?: string;
  width: number;
  height: number;
};
