import {
  ExpandLessOutlinedIcon,
  RDSChip,
  RDSIconTextButton,
  CustomQRCode,
  FileDownloadIcon,
  LinkIcon,
} from '@reconlabs/reconlabs-fe-components';
import React, { useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import DetailPageARviewerImage from '../../../images/DetailPageARviewerImage.png';
import ZoomInOutImage from '../../../images/zoomInOutImage.png';
import DetailARviewerPositionImage from '../../../images/DetailARviewerPositionImage.png';
import { getARGatewayUrl } from '../../../utils/createARScripts';
import { ThreeDModel } from '../../../types/modelTypes';
import KeyboardArrowDownOutlined from '../../icons/KeyboardArrowDownOutlined';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from '../../../utils/clipboardUtils';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';

type DetailPageARviewerProps = {
  model: ThreeDModel;
  updateMutation: Function;
  setRDSAlert: Function;
};

const DetailPageARviewer = ({ model, updateMutation, setRDSAlert }: DetailPageARviewerProps) => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const [more, setMore] = useState<boolean>(false);
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const handleSizeSwitchChange = (value: boolean) => {
    updateMutation({
      uid: model ? model.uid : '',
      model_name: model ? model.model_name : '',
      model_memo: model ? model.model_memo : '',
      model_sales_url: model ? model.model_sales_url : '',
      model_published: model ? model.model_published : 0,
      model_resizable: value ? 1 : 0,
    });
  };

  const handleVerticalChange = (value: boolean) => {
    updateMutation({
      uid: model ? model.uid : '',
      model_name: model ? model.model_name : '',
      model_memo: model ? model.model_memo : '',
      model_sales_url: model ? model.model_sales_url : '',
      model_published: model ? model.model_published : 0,
      model_vertical_placeable: value ? 1 : 0,
    });
  };

  const downloadQRImage = () => {
    const qrCode = document.querySelector('.publish-qr-code') as HTMLCanvasElement;
    const link = document.createElement('a');
    link.download = `PlicAR_QRCode_${model.viewer_uid}.png`;
    link.href = qrCode.toDataURL();
    link.click();
    setRDSAlert({ type: 'success', message: t('Detail.다운로드가 완료되었습니다!') });
  };

  const handleCopyToClipboard = (value: string) => {
    copyToClipboard(value);
    setRDSAlert({ type: 'success', message: t('Detail.복사가 완료되었습니다!') });
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <Col xl={1}></Col>
      <Col xl={10} style={{ marginTop: '140px' }}>
        <div className="DetailPageARviewer">
          <div className="DetailPageARviewer__Title">{`${t('Detail.AR 뷰어')}`}</div>
          <div className="DetailPageARviewer__Contents">
            <img src={DetailPageARviewerImage} alt="AR 뷰어 이미지" width={190} height={190} draggable={false} />
            <div style={{ width: '300px' }}>
              <div className="DetailPageARviewer__Contents__Header">
                <div style={{ zIndex: 2, position: 'relative' }}>{`${t('Detail.AR 경험의 시작')}`}</div>
                <div className="DetailPageARviewer__Contents__Header__Underline"></div>
              </div>
              <div className="DetailPageARviewer__Contents__Sub">
                {`${t(
                  'Detail.고객의 현실 공간과 연결된 가상의 AR 화면에 3D 상품이 나타나 보다 입체적이고 현실감 가득하게 상품을 감상할 수 있습니다.',
                )}`}
              </div>
            </div>
          </div>
          <div className="DetailPageARviewer__Control">
            <div className="DetailPageARviewer__Control__Contents">
              <div className="DetailPageARviewer__Control__Images">
                <img src={ZoomInOutImage} alt="확대/축소 이미지" width={265} height={120} draggable={false} />
              </div>
              <div className="DetailPageARviewer__Control__Text">
                <div className="DetailPageARviewer__Control__Text__Title">{`${t('Detail.AR 화면 동작 설정하기')}`}</div>
                <div className="DetailPageARviewer__Control__Text__Sub">{`${t(
                  'Detail.AR 화면 조작 방법을 설정할 수 있어요',
                )}`}</div>
              </div>
              <div className="DetailPageARviewer__Control__Size">
                <div className="DetailPageARviewer__Control__Size__Title">{`${t('Detail.사이즈 조절')}`}</div>
                <div className="DetailPageARviewer__Control__Size__Sub">
                  <RDSChip
                    chipStyle="outline"
                    chipSize="small"
                    selected={model.model_resizable === 1}
                    customStyle={{ width: '90px', justifyContent: 'center' }}
                    handleClick={() => handleSizeSwitchChange(true)}
                  >
                    {`${t('Detail.켬')}`}
                  </RDSChip>
                  <RDSChip
                    chipStyle="outline"
                    chipSize="small"
                    selected={model.model_resizable === 0}
                    customStyle={{ width: '90px', justifyContent: 'center', marginLeft: '8px' }}
                    handleClick={() => handleSizeSwitchChange(false)}
                  >
                    {`${t('Detail.끄기')}`}
                  </RDSChip>
                </div>
              </div>
              <div className="DetailPageARviewer__Control__Move">
                <div className="DetailPageARviewer__Control__Move__Title">{`${t('Detail.이동 방향')}`}</div>
                <div className="DetailPageARviewer__Control__Move__Sub">
                  <RDSChip
                    chipStyle="outline"
                    chipSize="small"
                    selected={model.model_vertical_placeable === 0}
                    customStyle={{ width: '90px', justifyContent: 'center' }}
                    handleClick={() => handleVerticalChange(false)}
                  >
                    {`${t('Detail.바닥면 중심')}`}
                  </RDSChip>
                  <RDSChip
                    chipStyle="outline"
                    chipSize="small"
                    selected={model.model_vertical_placeable === 1}
                    customStyle={{ width: '90px', justifyContent: 'center', marginLeft: '8px' }}
                    handleClick={() => handleVerticalChange(true)}
                  >
                    {`${t('Detail.벽면 중심')}`}
                  </RDSChip>
                </div>
              </div>
            </div>
            <div className="DetailPageARviewer__Control__Separator"></div>
            <div className="DetailPageARviewer__Control__More">
              <div
                className={
                  more
                    ? 'DetailPageARviewer__Control__More__Contents active'
                    : 'DetailPageARviewer__Control__More__Contents'
                }
              >
                <div className="DetailPageARviewer__Control__More__Contents__Size">
                  <div className="DetailPageARviewer__Control__More__Contents__Size__Header">{`${t(
                    'Detail.사이즈 조절',
                  )}`}</div>
                  <img src={ZoomInOutImage} alt="확대/축소 이미지" width={265} height={120} draggable={false} />
                  <div className="DetailPageARviewer__Control__More__Contents__Size__Sub">
                    {`${t(
                      'Detail.AR 화면에서 사물의 크기를 축소/확대해볼 수 있는 기능입니다. 고정된 실물 사이즈(100%)로 확인을 원하실경우 스위치를 off로 설정해주세요.',
                    )}`}
                  </div>
                </div>
                <div className="DetailPageARviewer__Control__More__Contents__Size">
                  <div className="DetailPageARviewer__Control__More__Contents__Size__Header">{`${t(
                    'Detail.이동 방향',
                  )}`}</div>
                  <img
                    src={DetailARviewerPositionImage}
                    alt="확대/축소 이미지"
                    width={265}
                    height={120}
                    draggable={false}
                  />
                  <div className="DetailPageARviewer__Control__More__Contents__Size__Sub">
                    {`${t(
                      'Detail.AR 화면에서 사물을 바닥면(의자, 컵 등) 또는 벽면(거울, 액자 등)을 기준으로 이동시켜 볼 수 있는 기능입니다.',
                    )}`}
                  </div>
                </div>
              </div>
              <RDSIconTextButton
                type="noBox"
                size="xsmall"
                color="grey"
                icon={more ? <ExpandLessOutlinedIcon /> : <KeyboardArrowDownOutlined />}
                onClick={() => setMore((prev) => !prev)}
              >
                {`${t('Detail.AR 동작 설정이란?')}`}
              </RDSIconTextButton>
            </div>
          </div>
          <div className="DetailPageARviewer__Wrapper">
            <div className="DetailPageARviewer__Wrapper__QRCode">
              <div className="DetailPageARviewer__Wrapper__QRCode__Back">SCAN</div>
              <div className="DetailPageARviewer__Wrapper__QRCode__QR">
                <div className="DetailPageARviewer__Wrapper__QRCode__QR__Back">
                  <CustomQRCode width="182px" height="182px" url={getARGatewayUrl(model.viewer_uid)} />
                </div>
              </div>
              <div>
                <div className="DetailPageARviewer__Wrapper__QRCode__Header">
                  {`${t('Detail.기본 카메라 앱에서 QR코드를 비춘 후')}`}
                  <br />
                  {`${t('Detail.보여지는 링크를 눌러주세요.')}`}
                </div>
                <div className="DetailPageARviewer__Wrapper__QRCode__Sub">
                  {`${t('Detail.AR기능은 현재 아래의 기기에서만 정상 작동 합니다.')}`} <br />
                  iPhone: {`${t('Detail.iOS 12+ 이상')}`} <br />
                  Android: {`${t('Detail.8.0+ 이상')}`} ({`${t('Detail.AR Core 1.9 지원기기')}`})
                </div>
                <div className="DetailPageARviewer__Wrapper__Buttons">
                  <RDSIconTextButton
                    icon={<FileDownloadIcon />}
                    type="solid"
                    size="xsmall"
                    color="grey"
                    customStyle={{ width: '50%' }}
                    onClick={() => {
                      downloadQRImage();
                      amplitude.sendEvent(amplitudeEvents.detail.zero_utilization_detail_arviewer_export_qrcode);
                    }}
                  >
                    {`${t('Detail.QR 코드')}`}
                  </RDSIconTextButton>
                  <RDSIconTextButton
                    icon={<LinkIcon />}
                    type="outline"
                    size="xsmall"
                    color="grey"
                    customStyle={{ width: '50%' }}
                    onClick={() => {
                      handleCopyToClipboard(getARGatewayUrl(model.viewer_uid));
                      amplitude.sendEvent(amplitudeEvents.detail.zero_utilization_detail_arviewer_export_link);
                    }}
                  >
                    {`${t('Detail.링크 복사')}`}
                  </RDSIconTextButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default DetailPageARviewer;
