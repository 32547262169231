import React, { useEffect, useMemo, useRef, useState } from 'react';
import { copyToClipboard } from '../../../utils/clipboardUtils';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  RDSIconTextButton,
  CodeIcon,
  RDSModal,
  RDSLinkButton,
  RDSButton,
  RDSChip,
  useThrottle,
  LinkIcon,
  RDSCheckbox,
  ExpandLessOutlinedIcon,
  RDSDropdown,
  RDSOption,
  FileDownloadIcon,
  RDSSlider,
} from '@reconlabs/reconlabs-fe-components';
import { useModelViewer } from '../../../hooks/react-query/useThreeDModel';
import { useTranslation } from 'react-i18next';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import { STATIC_FILES_URL } from '../../../constants/urls';
import {
  getModelViewerScriptSource,
  getPublicModelViewerIframeScript,
  getPublicModelViewerIframeScriptWithProps,
} from '../../../utils/createARScripts';
import DetailExportImage from '../../../images/DetailExportImage.png';
import Detail3DViewerMoreImage from '../../../images/Detail3DViewerMoreImage.png';
import Detail3DViewerMoreImage2 from '../../../images/Detail3DViewerMoreImage2.png';
import KeyboardArrowDownOutlined from '../../icons/KeyboardArrowDownOutlined';
import { getResizedData } from '../../../utils/utils';
import DetailCaptureGiftImage from '../../../images/DetailCaptureGiftImage.png';
import DetailCaptureJpgImage from '../../../images/DetailCaptureJpgImage.png';
import DetailCapturePngImage from '../../../images/DetailCapturePngImage.png';

type Detail3dViewerProps = {
  viewerUid: string;
  getModelViewerScript: (viewerData: string) => string;
  getPublicModelViewerScript: (viewerUid: string) => string;
  getPublicModelViewerScriptWithProps: (
    viewerUid: string,
    environmentImage?: any,
    measure?: string,
    exposure?: string,
    shadowIntensity?: string,
    shadowSoftness?: string,
  ) => string;
  getModelViewerScriptSource: string;
  disabled: boolean;
  setRDSAlert: Function;
  modelName: string;
};

type ModelViewerSize = {
  x: number;
  y: number;
  z: number;
  toString: Function;
};

const IframeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 4H5C3.89 4 3 4.9 3 6V18C3 19.1 3.89 20 5 20H19C20.1 20 21 19.1 21 18V6C21 4.9 20.11 4 19 4ZM19 18H5V8H19V18Z"
      fill="black"
      fillOpacity="0.38"
    />
  </svg>
);

const hdrImage = {
  first: `${STATIC_FILES_URL}/images/hdr/plicar-studio.hdr`,
  second: `${STATIC_FILES_URL}/images/hdr/plicar-studio2.hdr`,
  studio: `${STATIC_FILES_URL}/images/hdr/hdr-studio.hdr`,
  neutral: `${STATIC_FILES_URL}/images/hdr/hdr-neutral.hdr`,
  outdoor: `${STATIC_FILES_URL}/images/hdr/hdr-outdoor.hdr`,
  indoor: `${STATIC_FILES_URL}/images/hdr/hdr-indoor.hdr`,
};

const Detail3dViewer = (props: Detail3dViewerProps) => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const uid = props.viewerUid;
  const { t } = useTranslation();
  const [lightingMode, setLightingMode] = useState<'default' | 'studio' | 'neutral' | 'outdoor' | 'indoor' | 'hdr'>(
    'default',
  );
  const [applyViewerSetting, setApplyViewerSetting] = useState<boolean>(true);
  const [measureMode, setMeasureMode] = useState<'none' | 'mm' | 'cm' | 'inch'>('none');
  const [openArViewerModal, setOpenArViewerModal] = useState<boolean>(false);
  const [aspectRatio, setAspectRatio] = useState<string>('16:9');
  const [size, setSize] = useState<ModelViewerSize>();
  const [viewerData, setViewerData] = useState<any>();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [pcHeight, setPcHeight] = useState<number>(0);
  const { data, isSuccess } = useModelViewer(uid);
  const [more, setMore] = useState<boolean>(false);
  const [fileFormat, setFileFormat] = useState<'png' | 'jpg'>('png');
  const [exposure, setExposure] = useState<number>(1);
  const [shadowIntensity, setShadowIntensity] = useState<number>(1);
  const [shadowSoftness, setShadowSoftness] = useState<number>(0.5);

  const environmentImage = useMemo(() => {
    switch (lightingMode) {
      case 'default':
        return 'neutral';
      case 'indoor':
        return hdrImage.indoor;
      case 'neutral':
        return hdrImage.neutral;
      case 'outdoor':
        return hdrImage.outdoor;
      case 'studio':
        return hdrImage.studio;
      case 'hdr':
        return hdrImage.second;
      default:
        return 'neutral';
    }
  }, [lightingMode]);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (isSuccess) {
      setViewerData(data.data);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (document.querySelector('.plicarzero-viewer') === null) {
      const RDSPlicarViewerScript = document.createElement('script');
      RDSPlicarViewerScript.classList.add('plicarzero-viewer');
      RDSPlicarViewerScript.type = 'module';
      RDSPlicarViewerScript.src = getModelViewerScriptSource(); // TODO API Endpoint 변경 및 안정화시 plicar-viewer.min.js 로 변경
      document.head.appendChild(RDSPlicarViewerScript);
    }
  }, []);

  // measure indicator 세팅
  useEffect(() => {
    const modelViewer = document.querySelector('#Detail__PlicarViewer');
    if (viewerData && modelViewer !== null) {
      modelViewer.addEventListener('load', () => {
        //@ts-ignore
        const center = modelViewer.getBoundingBoxCenter();

        // const center = modelViewer.getCameraTarget();
        //@ts-ignore
        const size = modelViewer.getDimensions();
        setSize(size);

        const x2 = size.x / 2;
        const y2 = size.y / 2;
        const z2 = size.z / 2;

        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot+X-Y+Z',
          position: `${center.x + x2} ${center.y - y2} ${center.z + z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim+X-Y',
          position: `${center.x + x2} ${center.y - y2} ${center.z}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot+X-Y-Z',
          position: `${center.x + x2} ${center.y - y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim+X-Z',
          position: `${center.x + x2} ${center.y} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot+X+Y-Z',
          position: `${center.x + x2} ${center.y + y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim+Y-Z',
          position: `${center.x} ${center.y + y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot-X+Y-Z',
          position: `${center.x - x2} ${center.y + y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim-X-Z',
          position: `${center.x - x2} ${center.y} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot-X-Y-Z',
          position: `${center.x - x2} ${center.y - y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim-X-Y',
          position: `${center.x - x2} ${center.y - y2} ${center.z}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot-X-Y+Z',
          position: `${center.x - x2} ${center.y - y2} ${center.z + z2}`,
        });
        // update svg
        function drawLine(
          svgLine: SVGLineElement,
          dotHotspot1: any,
          dotHotspot2: any,
          dimensionHotspot: { facingCamera: any } | undefined,
        ) {
          if (svgLine == null) {
            return;
          }
          if (dotHotspot1 && dotHotspot1) {
            svgLine.setAttribute('x1', dotHotspot1.screenPosition.x);
            svgLine.setAttribute('y1', dotHotspot1.screenPosition.y);
            svgLine.setAttribute('x2', dotHotspot2.screenPosition.x);
            svgLine.setAttribute('y2', dotHotspot2.screenPosition.y);

            // use provided optional hotspot to tie visibility of this svg line to
            if (dimensionHotspot && !dimensionHotspot.facingCamera) {
              svgLine.classList.add('RDSPlicarViewer__hide');
            } else {
              svgLine.classList.remove('RDSPlicarViewer__hide');
            }
          }
        }

        const lines = modelViewer.querySelectorAll('line');

        // use requestAnimationFrame to update with renderer
        const startSVGRenderLoop = () => {
          drawLine(
            lines[0],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X-Y+Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X-Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim+X-Y'),
          );
          drawLine(
            lines[1],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X-Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X+Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim+X-Z'),
          );
          // @ts-ignore
          drawLine(
            lines[2],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X+Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X+Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim+Y-Z'),
          ); // always visible
          drawLine(
            lines[3],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X+Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X-Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim-X-Z'),
          );
          drawLine(
            lines[4],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X-Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X-Y+Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim-X-Y'),
          );
          requestAnimationFrame(startSVGRenderLoop);
        };

        startSVGRenderLoop();
      });
    }
  }, [viewerData]);

  const setViewerHeight = () => {
    if (wrapperRef.current) {
      setPcHeight(wrapperRef.current.clientHeight);
    }
  };

  const throttleOnScroll = useThrottle(setViewerHeight, 300);

  useEffect(() => {
    if (viewerData) {
      setViewerHeight();
      window.addEventListener('resize', throttleOnScroll);
    }
    return () => {
      window.removeEventListener('resize', throttleOnScroll);
    };
  }, [viewerData]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const convertMeter = (meter: number | any, resultLength: 'mm' | 'cm' | 'inch' | 'none') => {
    if (isNaN(meter) || typeof meter !== 'number') {
      return 0;
    }

    if (resultLength === 'cm') {
      return meter * 100;
    } else if (resultLength === 'inch') {
      return meter * 39.3701;
    } else if (resultLength === 'mm') {
      return meter * 1000;
    } else {
      return 0;
    }
  };
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const onClickJavaScriptButton = () => {
    if (applyViewerSetting) {
      copyToClipboard(
        props.getPublicModelViewerScriptWithProps(
          uid,
          environmentImage,
          measureMode !== 'none' ? measureMode : '',
          exposure.toString(),
          shadowIntensity.toString(),
          shadowSoftness.toString(),
        ),
      );
    } else {
      copyToClipboard(props.getPublicModelViewerScript(uid));
    }

    props.setRDSAlert({ type: 'success', message: t('Detail.복사가 완료되었습니다!') });
  };

  const onClickIframeButton = () => {
    if (applyViewerSetting) {
      copyToClipboard(
        getPublicModelViewerIframeScriptWithProps(
          props.viewerUid,
          lightingMode,
          measureMode !== 'none' ? measureMode : '',
          exposure.toString(),
          shadowIntensity.toString(),
          shadowSoftness.toString(),
        ),
      );
    } else {
      copyToClipboard(getPublicModelViewerIframeScript(props.viewerUid));
    }

    props.setRDSAlert({ type: 'success', message: t('Detail.복사가 완료되었습니다!') });
  };

  const onClickViewerLinkButton = () => {
    if (applyViewerSetting) {
      copyToClipboard(
        `${process.env.REACT_APP_PLICAR_ZERO_PROTOCOL}://${process.env.REACT_APP_PLICAR_ZERO_DOMAIN}${
          process.env.REACT_APP_PLICAR_ZERO_PORT ? `:${process.env.REACT_APP_PLICAR_ZERO_PORT}` : ''
        }/viewer?viewer_uid=${props.viewerUid}&lightingMode=${lightingMode}${
          measureMode !== 'none' ? `&measure=${measureMode}` : ''
        }${exposure ? `&exposure=${exposure}` : ''}${shadowIntensity ? `&shadowIntensity=${shadowIntensity}` : ''}${
          shadowSoftness ? `&shadowSoftness=${shadowSoftness}` : ''
        }`,
      );
    } else {
      copyToClipboard(
        `${process.env.REACT_APP_PLICAR_ZERO_PROTOCOL}://${process.env.REACT_APP_PLICAR_ZERO_DOMAIN}${
          process.env.REACT_APP_PLICAR_ZERO_PORT ? `:${process.env.REACT_APP_PLICAR_ZERO_PORT}` : ''
        }/viewer?viewer_uid=${props.viewerUid}`,
      );
    }

    props.setRDSAlert({ type: 'success', message: t('Detail.복사가 완료되었습니다!') });
  };

  const exportImage = async () => {
    const plicarViewerElement = document.querySelector('#Detail__PlicarViewer');
    // @ts-ignore
    const base64Data = plicarViewerElement?.toDataURL();
    const resizedBase64Data = await getResizedData(base64Data, fileFormat, aspectRatio, 500);
    if (resizedBase64Data && typeof resizedBase64Data === 'string') {
      const base64Response = await fetch(resizedBase64Data);
      const blob = await base64Response.blob();
      const link = document.createElement('a');
      link.download = getFileName(props.modelName);
      link.href = window.URL.createObjectURL(blob);
      link.click();
    }
  };

  const getFileName = (name: string) => {
    const dataFormat = fileFormat === 'jpg' ? '.jpg' : '.png';
    return name.slice(0, name.lastIndexOf('.')) + dataFormat;
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <RDSModal
        title={`${t('Detail.3D 뷰어 삽입 방법')}`}
        enableCloseButton={false}
        supportingText={
          <>
            1. {`${t('Detail.‘자바스크립트’ 버튼을 눌러 코드를 복사합니다.')}`} <br />
            2. {`${t('Detail.3D 뷰어 사용을 원하는 판매 페이지에서 html 편집모드에 붙여넣기를 해주세요.')}`}
            <div className="Detail3DViewer__modal">
              <RDSLinkButton
                label={t('Detail.자세히 보기')}
                size="medium"
                color="information"
                anchorHref="https://guide.plicar.io/faq/faqdb/3dviewer"
                anchorTarget="_blank"
                onClick={() => amplitude.sendEvent(amplitudeEvents.detail.zero_detail_3dviewer_guide_more)}
              />
              <RDSButton type={'noBox'} onClick={() => setOpenArViewerModal(false)} size={'medium'} color={'grey'}>
                {`${t('global.닫기')}`}
              </RDSButton>
            </div>
          </>
        }
        customStyle={{
          ['.RDSModal__container__bottom']: {
            display: 'none',
          },
        }}
        open={openArViewerModal}
        onClose={() => setOpenArViewerModal(false)}
      />
      <div className="Detail3DViewer">
        <Row>
          <Col xl={1}></Col>
          <Col className="Detail3DViewer__title" xl={10}>
            3D VIEWER
          </Col>
          <Col xl={1}></Col>
        </Row>
        <Row className="Detail3DViewer__contents">
          <Col xl={1}></Col>
          <Col className="Detail3DViewer__contents__viewer" xl={10}>
            {viewerData ? (
              <div
                ref={wrapperRef}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  paddingBottom: '40%',
                  position: 'relative',
                  transition: 'all 150ms linear',
                  borderRadius: '4px',
                  zIndex: 1,
                  aspectRatio: '1.7777777',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left:
                      aspectRatio === '16:9'
                        ? '0'
                        : aspectRatio === '1:1'
                        ? `calc((100% - ${pcHeight}px) / 2)`
                        : `calc((100% - ${(pcHeight / 16) * 9}px) / 2)`,
                    zIndex: 1,
                    width: aspectRatio === '16:9' ? '100%' : aspectRatio === '9:16' ? (pcHeight / 16) * 9 : pcHeight,
                    transition: 'all 150ms linear',
                    borderRadius: '4px',
                    overflow: 'hidden',
                    height: '100%',
                    maxHeight: '100%',
                  }}
                >
                  {/* @ts-ignore */}
                  <plicarzero-viewer
                    id="Detail__PlicarViewer"
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '8px',
                      backgroundColor: '#FFFFFF',
                    }}
                    viewer-uid={''}
                    viewer-data={JSON.stringify(viewerData)}
                    exposure={exposure}
                    environment-image={environmentImage}
                    shadow-intensity={shadowIntensity}
                    shadow-softness={shadowSoftness}
                    camera-controls
                    camera-orbit="25deg 75deg 105%"
                    max-camera-orbit="Infinity 170deg 120%"
                    min-camera-orbit="-Infinity 10deg auto"
                  >
                    {measureMode && (
                      <>
                        <button
                          slot="hotspot-dot+X-Y+Z"
                          className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                          data-position="1 -1 1"
                          data-normal="1 0 0"
                        ></button>
                        <button
                          slot="hotspot-dim+X-Y"
                          className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                          data-position="1 -1 0"
                          data-normal="1 0 0"
                        >
                          {size ? convertMeter(size.z, measureMode).toFixed(0) + ' ' + measureMode : ''}
                        </button>
                        <button
                          slot="hotspot-dot+X-Y-Z"
                          className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                          data-position="1 -1 -1"
                          data-normal="1 0 0"
                        ></button>
                        <button
                          slot="hotspot-dim+X-Z"
                          className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                          data-position="1 0 -1"
                          data-normal="1 0 0"
                        >
                          {size ? convertMeter(size.y, measureMode).toFixed(0) + ' ' + measureMode : ''}
                        </button>
                        <button
                          slot="hotspot-dot+X+Y-Z"
                          className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                          data-position="1 1 -1"
                          data-normal="0 1 0"
                        ></button>
                        <button
                          slot="hotspot-dim+Y-Z"
                          className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                          data-position="0 -1 -1"
                          data-normal="0 1 0"
                        >
                          {size ? convertMeter(size.x, measureMode).toFixed(0) + ' ' + measureMode : ''}
                        </button>
                        <button
                          slot="hotspot-dot-X+Y-Z"
                          className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                          data-position="-1 1 -1"
                          data-normal="0 1 0"
                        ></button>
                        <button
                          slot="hotspot-dim-X-Z"
                          className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                          data-position="-1 0 -1"
                          data-normal="-1 0 0"
                        >
                          {size ? convertMeter(size.y, measureMode).toFixed(0) + ' ' + measureMode : ''}
                        </button>
                        <button
                          slot="hotspot-dot-X-Y-Z"
                          className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                          data-position="-1 -1 -1"
                          data-normal="-1 0 0"
                        ></button>
                        <button
                          slot="hotspot-dim-X-Y"
                          className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                          data-position="-1 -1 0"
                          data-normal="-1 0 0"
                        >
                          {size ? convertMeter(size.z, measureMode).toFixed(0) + ' ' + measureMode : ''}
                        </button>
                        <button
                          slot="hotspot-dot-X-Y+Z"
                          className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                          data-position="-1 -1 1"
                          data-normal="-1 0 0"
                        ></button>
                        <svg
                          id="lines"
                          xmlns="http://www.w3.org/2000/svg"
                          className="RDSPlicarViewer__dimensionLineContainer"
                        >
                          <line
                            className={` ${
                              measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                            }`}
                          ></line>
                          <line
                            className={` ${
                              measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                            }`}
                          ></line>
                          <line
                            className={` ${
                              measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                            }`}
                          ></line>
                          <line
                            className={` ${
                              measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                            }`}
                          ></line>
                          <line
                            className={` ${
                              measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                            }`}
                          ></line>
                        </svg>
                      </>
                    )}
                    {/* @ts-ignore */}
                  </plicarzero-viewer>
                </div>
              </div>
            ) : (
              <div className="Detail3DViewer__contents__viewer__waiting">{`${t('Detail.뷰어 생성 중입니다...')}`}</div>
            )}
          </Col>
          <Col xl={1}></Col>
        </Row>
        <Row>
          <Col xl={1}></Col>
          <Col xl={10}>
            <div className="Detail3DViewer__Control">
              <div className="Detail3DViewer__Control__Title">{`${t('Detail.3D 뷰어 설정')}`}</div>
              <div className="Detail3DViewer__Control__Detail">
                <div
                  className={
                    window.locale === 'ko'
                      ? 'Detail3DViewer__Control__Detail__Text'
                      : 'Detail3DViewer__Control__Detail__TextEn'
                  }
                >
                  {`${t('Main.조명 설정')}`}
                  <RDSChip
                    chipStyle="outline"
                    onClick={() => {
                      // amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                      //   lighting: 'default',
                      // });
                      setLightingMode('default');
                    }}
                    selected={lightingMode === 'default'}
                  >
                    {`${t('Detail.기본 조명')}`}
                  </RDSChip>
                  <RDSChip
                    customStyle={
                      window.locale === 'ko'
                        ? { width: '98px', justifyContent: 'center' }
                        : { width: '104px', justifyContent: 'center' }
                    }
                    chipStyle="outline"
                    onClick={() => {
                      // amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                      //   lighting: 'studio',
                      // });
                      setLightingMode('studio');
                    }}
                    selected={lightingMode === 'studio'}
                  >
                    {`${t('Detail.스튜디오 조명')}`}
                  </RDSChip>
                  <RDSChip
                    customStyle={
                      window.locale === 'ko'
                        ? { width: '98px', justifyContent: 'center' }
                        : { width: '104px', justifyContent: 'center' }
                    }
                    chipStyle="outline"
                    onClick={() => {
                      // amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                      //   lighting: 'neutral',
                      // });
                      setLightingMode('neutral');
                    }}
                    selected={lightingMode === 'neutral'}
                  >
                    {`${t('Detail.무색조명')}`}
                  </RDSChip>
                  <RDSChip
                    customStyle={
                      window.locale === 'ko'
                        ? { width: '98px', justifyContent: 'center' }
                        : { width: '104px', justifyContent: 'center' }
                    }
                    chipStyle="outline"
                    onClick={() => {
                      // amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                      //   lighting: 'indoor',
                      // });
                      setLightingMode('indoor');
                    }}
                    selected={lightingMode === 'indoor'}
                  >
                    {`${t('Detail.실내조명')}`}
                  </RDSChip>
                  <RDSChip
                    customStyle={
                      window.locale === 'ko'
                        ? { width: '98px', justifyContent: 'center' }
                        : { width: '109px', justifyContent: 'center' }
                    }
                    chipStyle="outline"
                    onClick={() => {
                      // amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                      //   lighting: 'outdoor',
                      // });
                      setLightingMode('outdoor');
                    }}
                    selected={lightingMode === 'outdoor'}
                  >
                    {`${t('Detail.실외조명')}`}
                  </RDSChip>
                </div>
                <div className="Detail3DViewer__Control__Detail__Text">
                  <div className="Detail3DViewer__Control__Detail__Text__Sub" style={{ marginLeft: '20px' }}>
                    {`${t('Detail.상세 설정')}`}
                  </div>
                  <div style={{ width: '85%', display: 'flex' }}>
                    <div style={{ width: '33%' }}>
                      <div className="Detail3DViewer__Control__Detail__Text__Sub">{`${t('Detail.노출')}`}</div>
                      <RDSSlider.Continuous
                        value={exposure}
                        handleValueChange={(e: any) => setExposure(e)}
                        min={0}
                        max={2}
                        step={0.01}
                        enableButtons
                      />
                    </div>
                    <div style={{ width: '33%' }}>
                      <div className="Detail3DViewer__Control__Detail__Text__Sub">{`${t('Detail.그림자 강도')}`}</div>
                      <RDSSlider.Continuous
                        value={shadowIntensity}
                        handleValueChange={(e: any) => setShadowIntensity(e)}
                        min={0}
                        max={2}
                        step={0.01}
                        enableButtons
                      />
                    </div>
                    <div style={{ width: '33%' }}>
                      <div className="Detail3DViewer__Control__Detail__Text__Sub">{`${t('Detail.그림자 경계')}`}</div>
                      <RDSSlider.Continuous
                        value={shadowSoftness}
                        handleValueChange={(e: any) => setShadowSoftness(e)}
                        min={0}
                        max={1}
                        step={0.01}
                        enableButtons
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={
                    window.locale === 'ko'
                      ? 'Detail3DViewer__Control__Detail__Text'
                      : 'Detail3DViewer__Control__Detail__TextEn'
                  }
                >
                  {`${t('Main.치수 정보')}`}
                  <RDSChip
                    chipStyle="outline"
                    onClick={() => {
                      // amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                      //   dimension: 'none',
                      // });
                      setMeasureMode('none');
                    }}
                    selected={measureMode === 'none'}
                  >
                    {`${t('Detail.치수 숨김')}`}
                  </RDSChip>
                  <RDSChip
                    chipStyle="outline"
                    onClick={() => {
                      // amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                      //   dimension: 'mm',
                      // });
                      setMeasureMode('mm');
                    }}
                    selected={measureMode === 'mm'}
                  >
                    {`${t('Detail.밀리미터')} (mm)`}
                  </RDSChip>
                  <RDSChip
                    customStyle={
                      window.locale === 'ko'
                        ? { width: '98px', justifyContent: 'center' }
                        : { width: '117px', justifyContent: 'center' }
                    }
                    chipStyle="outline"
                    onClick={() => {
                      // amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                      //   dimension: 'cm',
                      // });
                      setMeasureMode('cm');
                    }}
                    selected={measureMode === 'cm'}
                  >
                    {`${t('Detail.센치미터')} (cm)`}
                  </RDSChip>
                  <RDSChip
                    customStyle={{ width: '98px', justifyContent: 'center' }}
                    chipStyle="outline"
                    onClick={() => {
                      // amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                      //   dimension: 'inch',
                      // });
                      setMeasureMode('inch');
                    }}
                    selected={measureMode === 'inch'}
                  >
                    {`${t('Detail.인치')} (inch)`}
                  </RDSChip>
                </div>
                <div
                  className={
                    window.locale === 'ko'
                      ? 'Detail3DViewer__Control__Detail__Text'
                      : 'Detail3DViewer__Control__Detail__TextEn'
                  }
                >
                  {`${t('Main.뷰어 비율')}`}
                  <RDSChip
                    customStyle={{ width: '70px', justifyContent: 'center' }}
                    chipStyle="outline"
                    onClick={() => {
                      // amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                      //   ratio: '16:9',
                      // });
                      setAspectRatio('16:9');
                    }}
                    selected={aspectRatio === '16:9'}
                  >
                    16:9
                  </RDSChip>
                  <RDSChip
                    customStyle={{ width: '98px', justifyContent: 'center' }}
                    chipStyle="outline"
                    onClick={() => {
                      // amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                      //   ratio: '9:16',
                      // });
                      setAspectRatio('9:16');
                    }}
                    selected={aspectRatio === '9:16'}
                  >
                    9:16
                  </RDSChip>
                  <RDSChip
                    customStyle={{ width: '98px', justifyContent: 'center' }}
                    chipStyle="outline"
                    onClick={() => {
                      // amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                      //   ratio: '1:1',
                      // });
                      setAspectRatio('1:1');
                    }}
                    selected={aspectRatio === '1:1'}
                  >
                    1:1
                  </RDSChip>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={1}></Col>
          <Col xl={1}></Col>
          <Col xl={10}>
            <div className="Detail3DViewer__Capture">
              <div className="Detail3DViewer__Capture__Background"></div>
              <div className="Detail3DViewer__Capture__Text">
                <div className="Detail3DViewer__Capture__Text__Title">{`${t('Detail.이미지 캡처하기')}`}</div>
                <div className="Detail3DViewer__Capture__Text__Sub">{`${t(
                  'Detail.3D 뷰어 설정값 그대로 이미지를 활용해보세요',
                )}`}</div>
              </div>
              <div className="Detail3DViewer__Capture__Images">
                <img src={DetailCaptureJpgImage} alt="jpg 이미지" width={48} height={48} draggable={false} />
                <img src={DetailCaptureGiftImage} alt="gift 이미지" width={136} height={136} draggable={false} />
                <img src={DetailCapturePngImage} alt="png 이미지" width={48} height={48} draggable={false} />
              </div>
              <div className="Detail3DViewer__Capture__Buttons">
                <RDSDropdown
                  size="small"
                  customStyles={{
                    fontFamily: 'Pretendard, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '12px',
                    letterSpacing: '0.25px',
                    width: '193px',
                  }}
                  onChange={(value) => setFileFormat(value)}
                  value={fileFormat}
                >
                  <React.Fragment key=".0">
                    <RDSOption value="png">png</RDSOption>
                    <RDSOption value="jpg">jpg</RDSOption>
                  </React.Fragment>
                </RDSDropdown>
                <RDSIconTextButton
                  icon={<FileDownloadIcon />}
                  size="xsmall"
                  fullWidth
                  onClick={() => {
                    exportImage();
                    amplitude.sendEvent(amplitudeEvents.detail.zero_utilization_detail_3dviewer_capture_download);
                  }}
                  customStyle={{ zIndex: 1 }}
                >
                  {`${t('Detail.다운로드')}`}
                </RDSIconTextButton>
              </div>
            </div>
          </Col>
          <Col xl={1}></Col>
          <Col xl={1}></Col>
          <Col xl={10}>
            <div className="Detail3DViewer__Export">
              <div className="Detail3DViewer__Export__Contents">
                <img
                  src={DetailExportImage}
                  alt="모델 내보내기 이미지"
                  width={265}
                  height={154}
                  draggable={false}
                  style={{ borderRadius: '8px 0 0 0' }}
                />
                <div className="Detail3DViewer__Export__Contents__Text">
                  <div className="Detail3DViewer__Export__Contents__Text__Title">
                    {`${t('Detail.3D 뷰어 내보내기')}`}
                  </div>
                  <div className="Detail3DViewer__Export__Contents__Text__Sub">
                    {`${t('Detail.웹 사이트에 3D 뷰어를 화면 그대로 가져가세요')}`}
                  </div>
                  <RDSCheckbox.IconType
                    checked={applyViewerSetting}
                    handleCheck={() => {
                      setApplyViewerSetting((prev) => !prev);
                    }}
                    label={`${t('Detail.뷰어 설정 적용하기')}`}
                    customStyle={{ justifyContent: 'flex-end', marginTop: '12px' }}
                  />
                </div>
                <div className="Detail3DViewer__Export__Contents__Buttons">
                  <RDSIconTextButton
                    size="xsmall"
                    type="outline"
                    color="grey"
                    icon={<CodeIcon />}
                    customStyle={{ width: '192px' }}
                    onClick={() => {
                      onClickJavaScriptButton();
                      amplitude.sendEvent(amplitudeEvents.detail.zero_utilization_detail_3dviewer_export_javascript);
                    }}
                  >
                    {`${t('ModelOverview.자바스크립트')}`}
                  </RDSIconTextButton>
                  <RDSIconTextButton
                    size="xsmall"
                    type="outline"
                    color="grey"
                    icon={<IframeIcon />}
                    customStyle={{ width: '192px' }}
                    onClick={() => {
                      onClickIframeButton();
                      amplitude.sendEvent(amplitudeEvents.detail.zero_utilization_detail_3dviewer_export_iframe);
                    }}
                  >
                    iframe
                  </RDSIconTextButton>
                  <RDSIconTextButton
                    size="xsmall"
                    type="outline"
                    color="grey"
                    icon={<LinkIcon />}
                    customStyle={{ width: '192px' }}
                    onClick={() => {
                      onClickViewerLinkButton();
                      amplitude.sendEvent(amplitudeEvents.detail.zero_utilization_detail_3dviewer_export_viewerpage);
                    }}
                  >
                    {`${t('ModelOverview.뷰어전용 링크')}`}
                  </RDSIconTextButton>
                </div>
              </div>
              <div className="Detail3DViewer__Export__Contents__Separator"></div>
              <div className="Detail3DViewer__Export__Contents__More">
                <div
                  className={
                    more
                      ? 'Detail3DViewer__Export__Contents__More__Contents active'
                      : 'Detail3DViewer__Export__Contents__More__Contents'
                  }
                >
                  <div className="Detail3DViewer__Export__Contents__More__Contents__Images">
                    <img width={325} height={220} src={Detail3DViewerMoreImage} alt="더보기 컨텐츠 이미지" />
                    <img
                      style={{ marginRight: '25px' }}
                      width={277}
                      height={153}
                      src={Detail3DViewerMoreImage2}
                      alt="더보기 컨텐츠 이미지"
                    />
                  </div>
                  <div className="Detail3DViewer__Export__Contents__More__Contents__Text">
                    <div style={{ display: 'flex', gap: '45px' }}>
                      <div>
                        <CodeIcon />
                        <span className="Detail3DViewer__Export__Contents__More__Contents__Text__Title">
                          {`${t('Detail.자바스크립트')}`}
                        </span>
                        <br />
                        {`${t('Detail.내 웹사이트에 3D 뷰어를 삽입해 하나의')}`}
                        <br />
                        {`${t('Detail.홈페이지로 만들 수 있습니다.')}`}
                      </div>
                      <div>
                        <IframeIcon />
                        <span className="Detail3DViewer__Export__Contents__More__Contents__Text__Title">iframe</span>
                        <br />
                        {`${t('Detail.3D뷰어가 포함된 다른 웹페이지를 삽입해')}`}
                        <br />
                        {`${t('Detail.미리보기를 할 수 있습니다.')}`}
                      </div>
                    </div>
                    <div
                      className="Detail3DViewer__Export__Contents__More__Contents__Text__Ex"
                      style={{ marginTop: '34px' }}
                    >
                      *
                      {`${t(
                        'Detail.웹사이트 제작 솔루션에 따라 이용 가능한 버튼이 다르니 아래 가이드 확인 후 이용해주세요.',
                      )}`}
                    </div>
                    <div style={{ marginTop: '70px' }}>
                      <LinkIcon />
                      <span className="Detail3DViewer__Export__Contents__More__Contents__Text__Title">
                        {`${t('Detail.뷰어 전용 링크')}`}
                      </span>
                      <br />
                      {`${t('Detail.3D뷰어만 한 눈에 확인해볼 수 있는 다이렉트 링크를 제공합니다.')}`}
                    </div>
                  </div>
                </div>
                <RDSIconTextButton
                  type="noBox"
                  size="xsmall"
                  color="grey"
                  icon={more ? <ExpandLessOutlinedIcon /> : <KeyboardArrowDownOutlined />}
                  onClick={() => setMore((prev) => !prev)}
                >
                  {`${t('Detail.3D 뷰어를 활용하는 방법')}`}
                </RDSIconTextButton>
              </div>
            </div>
          </Col>
          <Col xl={1}></Col>
        </Row>
      </div>
    </>
  );
};

export default Detail3dViewer;
