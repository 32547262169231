import { Tier, BillingPurpose } from './type';

export const JWT_ACCESS_TOKEN_LOCALSTORAGE_KEY = 'plicarzero.access_token';
export const JWT_REFRESH_TOKEN_LOCALSTORAGE_KEY = 'plicarzero.refresh_token';
export const INPUT_LENGTH_LIMIT = 41;

export const TIER: Record<string, Tier> = { Free: 'Free', Basic: 'Basic', Plus: 'Plus', Enterprise: 'Enterprise' };

export const BILLING_PURPOSE: Record<string, BillingPurpose> = { Billing: 'billing', ChangeCard: 'changeCard' };

export const ANALYSIS_TAB_INFO_TEXT = [
  'Analysis.시간에 따른 조회 수 증가 형태로 제공합니다.',
  'Analysis.플랜 정기 구독 주기를 기준으로 전체 사용량 대비 누적 조회 수를 확인합니다.',
  'Analysis.플랜 정기 구독 주기를 기준으로 모델별 사용량을 확인할 수 있으며, ',
  'Analysis.상세한 데이터 확인은 아래 모델 리스트에서 확인해주세요.',
];
