import React, { useState } from 'react';
import { RDSIconTextButton, CodeIcon, LinkIcon, ExpandLessOutlinedIcon } from '@reconlabs/reconlabs-fe-components';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { buttonValueTypes, getARDirectButtonScript, getARGatewayButtonScript } from '../../../utils/createARScripts';
import { plicarButtonImgUrl } from '../../../constants/urls';
import ARLinkInfo from '../../../images/ARLinkInfo.png';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import Cafe24Image from '../../../images/arviewer/Cafe24Image.png';
import ImwebImage from '../../../images/arviewer/ImwebImage.png';
import WordpressImage from '../../../images/arviewer/WordpressImage.png';
import ShowpifyImage from '../../../images/arviewer/ShowpifyImage.png';
import CodeImage from '../../../images/arviewer/CodeImage.png';
import NaverImage from '../../../images/arviewer/NaverImage.png';
import KeyboardArrowDownOutlined from '../../icons/KeyboardArrowDownOutlined';

type DetailPageLinksProps = {
  viewerUid: string;
  disabled: boolean;
  setRDSAlert: Function;
};

const DetailPageLinks = (props: DetailPageLinksProps) => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const [selectedButtonType, setSelectedButtonType] = useState<buttonValueTypes>('primary');
  const [more, setMore] = useState<boolean>(false);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const copyToClipboard = (value: string): Promise<void> => {
    return navigator.clipboard.writeText(value);
  };
  /*----------------------------------------
                Event Handler
   ----------------------------------------*/
  const onCopyJavaScriptButtonClicked = () => {
    const script = getARDirectButtonScript(props.viewerUid, selectedButtonType);
    copyToClipboard(script);
    props.setRDSAlert({ type: 'success', message: t('Detail.복사가 완료되었습니다!') });
  };

  const onCopyHyperLinkButtonClicked = () => {
    const link = getARGatewayButtonScript(props.viewerUid, selectedButtonType);
    copyToClipboard(link);
    props.setRDSAlert({ type: 'success', message: t('Detail.복사가 완료되었습니다!') });
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <Col xl={12} className="DetailPageLinks__Header">
        <img src={ARLinkInfo} width={415} height={188} alt="AR 이미지" draggable={false} />
        <div className="DetailPageLinks__Header__TextWrapper">
          <div className="DetailPageLinks__Header__TextWrapper__Title">{`${t('Detail.AR 이커머스')}`}</div>
          <div className="DetailPageLinks__Header__TextWrapper__Title__Back"></div>
          <div className="DetailPageLinks__Header__TextWrapper__Text">
            {`${t('Detail.AR뷰어 버튼을 내 판매 페이지에 삽입해')}`}
            <br />
            {`${t('Detail.판매 상품을 고객의 공간에서 생동감있게 보여줄 수 있습니다.')}`}
          </div>
        </div>
      </Col>
      <Col xl={1}></Col>
      <Col xl={10}>
        <div className="DetailPageLinks">
          <div className="DetailPageLinks__Wrapper">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'center' }}>
              <button
                type="button"
                className={`DetailPageLinks__Radio ${
                  props.disabled === true ? 'DetailPageLinks__Radio__disabled' : ''
                }`}
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.detail.zero_detail_arviewer_arlinkbtn_btntype, {
                    type: 'btn1',
                  });
                  setSelectedButtonType('primary');
                }}
                value="primary"
              >
                <div
                  className={`DetailPageLinks__Radio__radio ${
                    selectedButtonType === 'primary' ? 'DetailPageLinks__Radio__radio__selected' : ''
                  }`}
                >
                  <div
                    className={`DetailPageLinks__Radio__radio__check ${
                      selectedButtonType !== 'primary' ? 'DetailPageLinks__Radio__hidden' : ''
                    }`}
                  />
                </div>
                <div className="DetailPageLinks__Radio__label">
                  <img
                    width={200}
                    height={48}
                    src={window.locale === 'ko' ? plicarButtonImgUrl['primary'] : plicarButtonImgUrl['primaryEn']}
                  />
                </div>
              </button>
              <button
                type="button"
                className={`DetailPageLinks__Radio ${
                  props.disabled === true ? 'DetailPageLinks__Radio__disabled' : ''
                }`}
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.detail.zero_detail_arviewer_arlinkbtn_btntype, {
                    type: 'btn2',
                  });
                  setSelectedButtonType('secondary');
                }}
                value="secondary"
              >
                <div
                  className={`DetailPageLinks__Radio__radio ${
                    selectedButtonType === 'secondary' ? 'DetailPageLinks__Radio__radio__selected' : ''
                  }`}
                >
                  <div
                    className={`DetailPageLinks__Radio__radio__check ${
                      selectedButtonType !== 'secondary' ? 'DetailPageLinks__Radio__hidden' : ''
                    }`}
                  />
                </div>
                <div className="DetailPageLinks__Radio__label">
                  <img
                    width={200}
                    height={48}
                    src={window.locale === 'ko' ? plicarButtonImgUrl['secondary'] : plicarButtonImgUrl['secondaryEn']}
                  />
                </div>
              </button>
              <button
                type="button"
                className={`DetailPageLinks__Radio ${
                  props.disabled === true ? 'DetailPageLinks__Radio__disabled' : ''
                }`}
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.detail.zero_detail_arviewer_arlinkbtn_btntype, {
                    type: 'btn3',
                  });
                  setSelectedButtonType('tertiary');
                }}
                value="tertiary"
              >
                <div
                  className={`DetailPageLinks__Radio__radio ${
                    selectedButtonType === 'tertiary' ? 'DetailPageLinks__Radio__radio__selected' : ''
                  }`}
                >
                  <div
                    className={`DetailPageLinks__Radio__radio__check ${
                      selectedButtonType !== 'tertiary' ? 'DetailPageLinks__Radio__hidden' : ''
                    }`}
                  />
                </div>
                <div className="DetailPageLinks__Radio__label">
                  <img
                    width={200}
                    height={48}
                    src={window.locale === 'ko' ? plicarButtonImgUrl['tertiary'] : plicarButtonImgUrl['tertiaryEn']}
                  />
                </div>
              </button>
            </div>
            <div className="DetailPageLinks__Wrapper__Text">
              <div className="DetailPageLinks__Wrapper__Text__Title">{`${t('Detail.AR뷰어 버튼 내보내기')}`}</div>
              <div className="DetailPageLinks__Wrapper__Text__Sub">
                {`${t('Detail.내 판매 페이지에 삽입할 디자인을 골라 내보내기 해보세요!')}`}
              </div>
              <div className="DetailPageLinks__Wrapper__Buttons">
                <RDSIconTextButton
                  icon={<CodeIcon />}
                  type="outline"
                  size="xsmall"
                  color="grey"
                  onClick={() => {
                    amplitude.sendEvent(
                      amplitudeEvents.detail.zero_utilization_detail_arviewer_arlinkbtn_export_javascript,
                    );
                    onCopyJavaScriptButtonClicked();
                  }}
                  customStyle={{ width: '100%' }}
                  disabled={props.disabled}
                  data-event-name="model_detail_button1_direct_copy"
                >
                  {`${t('Detail.자바스크립트')}`}
                </RDSIconTextButton>
                <RDSIconTextButton
                  icon={<LinkIcon />}
                  type="outline"
                  size="xsmall"
                  color="grey"
                  onClick={() => {
                    amplitude.sendEvent(
                      amplitudeEvents.detail.zero_utilization_detail_arviewer_arlinkbtn_export_hyperlink,
                    );
                    onCopyHyperLinkButtonClicked();
                  }}
                  customStyle={{ width: '100%' }}
                  disabled={props.disabled}
                  data-event-name="model_detail_button1_link_copy"
                >
                  {`${t('Detail.하이퍼링크')}`}
                </RDSIconTextButton>
              </div>
            </div>
          </div>
          <div className={more ? 'DetailPageLinks__More__Contents active' : 'DetailPageLinks__More__Contents'}>
            <div className="DetailPageLinks__More__Contents__Text">
              <div className="DetailPageLinks__More__Contents__Text__Title">{`${t('Detail.이용 방법')}`}</div>
              <div
                className={
                  window.locale === 'ko'
                    ? 'DetailPageLinks__More__Contents__Text__Sub'
                    : 'DetailPageLinks__More__Contents__Text__Sub DetailPageLinks__More__Contents__Text__Sub__En'
                }
              >
                1. {`${t('Detail.판매 페이지에 넣고 싶은 버튼을 선택합니다.')}`}
                <br />
                2. {`${t('Detail.삽입 가능한 코드나 링크를 복사해 페이지에 붙여넣기 해주세요.')}`}
              </div>
            </div>
            <div className="DetailPageLinks__More__Contents__Icons">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
                {`${t('Detail.자바스크립트')}`} &gt;
                <img
                  className="DetailPageLinks__More__Contents__Icons__Shadow"
                  width={32}
                  height={32}
                  src={Cafe24Image}
                  alt="카페24 이미지"
                  draggable={false}
                />
                <img width={32} height={32} src={ImwebImage} alt="아이엠웹 이미지" draggable={false} />
                <img
                  className="DetailPageLinks__More__Contents__Icons__Shadow"
                  width={32}
                  height={32}
                  src={WordpressImage}
                  alt="Wordpress 이미지"
                  draggable={false}
                />
                <img
                  className="DetailPageLinks__More__Contents__Icons__Shadow"
                  width={32}
                  height={32}
                  src={ShowpifyImage}
                  alt="쇼피파이 이미지"
                  draggable={false}
                />
                <img
                  className="DetailPageLinks__More__Contents__Icons__Shadow"
                  width={32}
                  height={32}
                  src={CodeImage}
                  alt="코드 이미지"
                  draggable={false}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '20px' }}>
                {`${t('Detail.하이퍼링크')}`} &gt;
                <img
                  style={{ marginLeft: '20px' }}
                  width={32}
                  height={32}
                  src={NaverImage}
                  alt="네이버 이미지"
                  draggable={false}
                />
              </div>
            </div>
          </div>
          <div className="DetailPageLinks__More">
            <div className="DetailPageLinks__More__Separator"></div>
            <RDSIconTextButton
              type="noBox"
              size="xsmall"
              color="grey"
              icon={more ? <ExpandLessOutlinedIcon /> : <KeyboardArrowDownOutlined />}
              onClick={() => setMore((prev) => !prev)}
            >
              {`${t('Detail.간단 설명')}`}
            </RDSIconTextButton>
          </div>
        </div>
      </Col>
      <Col xl={1}></Col>
    </>
  );
};

export default DetailPageLinks;
