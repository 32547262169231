import axios from 'axios';
import srp from 'secure-remote-password/client';
import { baseUrl, getJWTHeaderFromLocalStorage } from '../fetchInstance/axios';
import { Locale, LoginType, Oauth, OauthKr, SurveyType } from '../constants/type';
import { PLICAR_ZERO_URL } from '../constants/urls';
import { getRefreshToken, setAccessToken, setToken } from './localstorage';

const config = { baseURL: baseUrl };
const axiosInstance = axios.create(config);

export const loginZeroUserpool = async (email: string, password: string, keep_login: boolean, locale: Locale) => {
  // client ephemeral 생성
  const clientEphemeral = srp.generateEphemeral();
  const res = await axiosInstance.get(`/login/initiate`, {
    params: {
      user_email: email,
      locale,
    },
  });
  // 서버의 Ephmeral,salt를 가져와서 clientSession을 계산한다.
  const serverEphemeral = res.data.data.server_ephemeral;
  const salt = res.data.data.salt;
  const privateKey = srp.derivePrivateKey(salt, email, password);
  const clientSession = srp.deriveSession(clientEphemeral.secret, serverEphemeral, salt, email, privateKey);

  // 올바른 패스워드와 ephemeral로 구성된 proof를 서버에 던져 암호가 맞는지 계산한다.
  const res2 = await axiosInstance.get(`/login`, {
    params: {
      user_email: email,
      client_ephemeral: clientEphemeral.public,
      proof: clientSession.proof,
      keep_login,
    },
  });
  const token = res2.data.token;
  setToken(token);
};

/**
 * @returns boolean 회원가입 성공여부를 반환합니다
 * */
export const signUp = async (
  email: string,
  password: string,
  params: { marketing_agreed: boolean; user_tag?: [] },
  locale: Locale,
) => {
  // 유저 회원가입 정보와 Salt와 Verifier를 생성해서 서버로 보낸다
  const salt = srp.generateSalt();
  const privateKey = srp.derivePrivateKey(salt, email, password);
  const verifier = srp.deriveVerifier(privateKey);
  const res = await axiosInstance.post(`/user`, {
    user_email: email,
    salt,
    verifier,
    ...params,
    locale,
    //추가로 회원가입 정보 등등 추가
  });
  return res.data;
};

export const loginOauth = async (code: string, Oauth: Oauth) => {
  const res = await axiosInstance.get(`/login/${Oauth}`, {
    params: { code },
  });
  const token = res.data.token;
  setToken(token);
};

// 로그인 화면에서 비밀번호 변경
export const loginResetPassword = async (email: string, password: string, token: string) => {
  const salt = srp.generateSalt();
  const privateKey = srp.derivePrivateKey(salt, email, password);
  const verifier = srp.deriveVerifier(privateKey);

  const res = await axiosInstance.put(`/login/resetpassword`, {
    token,
    verifier,
    salt,
  });
};

// 마이페이지에서 유저가 비밀번호 변경
export const userResetPassword = async (email: string, new_password: string, old_password: string) => {
  // client ephemeral 생성
  const old_clientEphemeral = srp.generateEphemeral();
  const res = await axiosInstance.get(`/user/resetpassword/initiate`, {
    headers: getJWTHeaderFromLocalStorage(),
  });
  // 서버의 Ephmeral,salt를 가져와서 clientSession을 계산한다.
  const serverEphemeral = res.data.data.server_ephemeral;
  const old_salt = res.data.data.salt;
  const old_privateKey = srp.derivePrivateKey(old_salt, email, old_password);
  const old_clientSession = srp.deriveSession(
    old_clientEphemeral.secret,
    serverEphemeral,
    old_salt,
    email,
    old_privateKey,
  );
  // 새로운 비밀번호의 salt, verifier생성
  const new_salt = srp.generateSalt();
  const new_privateKey = srp.derivePrivateKey(new_salt, email, new_password);
  const new_verifier = srp.deriveVerifier(new_privateKey);
  // 기존 비밀번호의 Proof와 새로운 비밀번호의 salt, verifier를 보낸다
  await axiosInstance.put(
    `/user/resetpassword`,
    {
      client_ephemeral: old_clientEphemeral.public,
      proof: old_clientSession.proof,
      verifier: new_verifier,
      salt: new_salt,
    },
    {
      headers: getJWTHeaderFromLocalStorage(),
    },
  );
};

export const oAuthLogin = (oauth: OauthKr) => {
  const atag = document.createElement('a');
  const redirect_uri = `${PLICAR_ZERO_URL}/signedin`;
  let client_id;
  let scope;
  switch (oauth) {
    case '카카오':
      localStorage.setItem('oauth', 'kakao');
      client_id = process.env.REACT_APP_KAKAO_CLIENT_ID;
      atag.href = `https://kauth.kakao.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code`;
      break;
    case '구글':
      localStorage.setItem('oauth', 'google');
      client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
      scope = 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';
      atag.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${client_id}&scope=${scope}&redirect_uri=${redirect_uri}&response_type=code`;
      break;
    case '네이버':
      localStorage.setItem('oauth', 'naver');
      client_id = process.env.REACT_APP_NAVER_CLIENT_ID;
      scope = '';
      atag.href = `https://nid.naver.com/oauth2.0/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&state=${scope}`;
      break;
    default:
      break;
  }
  atag.click();
};

export const findUserByEmail = async (
  user_email: string,
): Promise<{ user_exist: boolean; register_type: LoginType; user_email: string }> => {
  const res = await axiosInstance.get(`/login/user`, { params: { user_email } });
  return res.data.data;
};

export const sendVerifyEmail = async (user_email: string, locale: Locale) => {
  const res = await axiosInstance.post(`/user/verify/email`, { user_email, locale });
};

export const updateCurrentOauthUserAsConfirmed = async (params: { marketing_agreed: boolean; user_tag?: string[] }) => {
  // the token must contain 'user_status = pending' (유저가 아직 회원가입 절차를 거치지 않은상태)
  const res = await axiosInstance.post(`/user/oauth`, params, {
    headers: getJWTHeaderFromLocalStorage(),
  });
  const token = res.data.token;
  setToken(token);
};

export const verifyEmailAndSetToken = async (email_token: string) => {
  const res = await axiosInstance.get(`/user/verify?token=${email_token}`);
  const token = res.data.token;
  setToken(token);
};

export const refreshToken = async (refresh_token: string) => {
  const res = await axiosInstance.post(`/token`, { refresh_token });
  const access_token = res.data.token;
  setAccessToken(access_token);
  return access_token;
};

export const revokeToken = async () => {
  const res = await axiosInstance.post(
    `/token/revoke`,
    {},
    {
      headers: getJWTHeaderFromLocalStorage(),
    },
  );
};

export const sendEmailResetPassword = async (user_email: string, locale: Locale) => {
  const res = await axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/login/resetpassword`, { user_email, locale });
  return res.data;
};
