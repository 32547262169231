import React, { useEffect, useState } from 'react';
import { RDSButton, RDSCheckbox } from '@reconlabs/reconlabs-fe-components';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import { useTranslation } from 'react-i18next';

type AgreementCheckboxAreaProps = {
  setViewType: Function;
  setSignUpParams: any;
};

type AgreementType = {
  idx: number;
  content: string;
  required: boolean;
  link?: string;
};

const AgreementCheckboxArea = (props: AgreementCheckboxAreaProps) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const agreementlist: AgreementType[] = [
    {
      idx: 1,
      content: t('Login.[필수] 만 14세 이상입니다'),
      required: true,
    },
    {
      idx: 2,
      content: t('Login.[필수] 이용 약관'),
      required: true,
      link:
        window.locale === 'ko'
          ? 'https://reconlabs.notion.site/106b051b77c144199b28217f849d8b66'
          : 'https://reconlabs.notion.site/Terms-and-Conditions-of-Use-429b00950a82481fa21e32a42ca70321',
    },
    {
      idx: 3,
      content: t('Login.[필수] 개인정보 처리방침 동의'),
      required: true,
      link:
        window.locale === 'ko'
          ? 'https://reconlabs.notion.site/d1b81cbce44148c3bcacba381445c8e1'
          : 'https://reconlabs.notion.site/Privacy-Policy-eae10460417346729f2cbf41125e96c0',
    },
    {
      idx: 4,
      content: t('Login.[필수] 저작물 정책 및 저작물 이용 동의'),
      required: true,
      link:
        window.locale === 'ko'
          ? 'https://reconlabs.notion.site/0f7df0cb2d7942ad822f6f88e254bbfd'
          : 'https://reconlabs.notion.site/CONSENT-TO-COPYRIGHT-POLICY-AND-INDEMNIFICATION-0a4ad0fa7f1e4247923d430914f5a120',
    },
    { idx: 5, content: t('Login.[선택] 뉴스레터 및 마케팅 활용 동의'), required: false },
  ];
  const requiredAgreementsList = agreementlist.filter((agreement) => agreement.required === true);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [checkedBoxIdx, setCheckedBoxIdx] = useState<any>([]);
  const [list, setList] = useState<AgreementType[]>([]);
  const [disabledNextButton, setDisabledNextButton] = useState<boolean>(true);
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll);
    setCheckedBoxIdx(list.map((li) => li.idx));
    //전체선택시 next버튼 활성화
    setDisabledNextButton(false);
    if (isCheckAll) {
      setCheckedBoxIdx([]);
      // 전체선택 해제시, next 버튼도 비활성화
      setDisabledNextButton(true);
    }
  };

  const handleChange = (idx: number) => {
    if (isCheckAll) {
      setIsCheckAll(false);
    }
    const ischecked = checkedBoxIdx.includes(idx);
    let newCheckedBoxIdx;
    newCheckedBoxIdx = [...checkedBoxIdx, idx];
    setCheckedBoxIdx(newCheckedBoxIdx);
    if (ischecked) {
      newCheckedBoxIdx = checkedBoxIdx.filter((item: any) => item !== idx);
      setCheckedBoxIdx(newCheckedBoxIdx);
    }
    // required 동의항목을 순회하면서 체크된거에 포함안된게 있으면 로직 끝내고 아니면 버튼 활성화
    for (const rquiredAgreement of requiredAgreementsList) {
      if (!newCheckedBoxIdx.includes(rquiredAgreement.idx)) {
        setDisabledNextButton(true);
        return;
      }
    }
    setDisabledNextButton(false);
  };

  const onClickNext = () => {
    amplitude.sendEvent(amplitudeEvents.signup.zero_signup_process1_next);

    const checkedOptionalCheckBox = list.filter(
      // 선택가능 한 것이면서 체크되어있는 것만 가져오기
      (agreement) => agreement.required === false && checkedBoxIdx.includes(agreement.idx),
    );
    const checkedContents = checkedOptionalCheckBox.map((checkbox) => checkbox.content);
    // 원래 선택 체크박스의 내용들 전부 저장하려했으나, 선택 채크박스가 하나로 고정이므로 아래와같이 params저장
    props.setSignUpParams({ marketing_agreed: checkedContents.length === 0 ? false : true });
    props.setViewType('userTag');
  };
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    setList(agreementlist);
  }, []);
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <div className="Checkbox">
        <RDSCheckbox.IconType label={t('Login.전체 약관 동의')} handleCheck={handleSelectAll} checked={isCheckAll} />
        <div className="Checkbox__separator"></div>
        {list.map(({ idx, content, link, required }) => {
          return (
            <div key={idx} className="Checkbox__wrapper">
              <RDSCheckbox.IconType
                handleCheck={() => {
                  handleChange(idx);
                }}
                required={required}
                checked={checkedBoxIdx.includes(idx)}
                label={content}
              />
              {required && idx !== 1 && (
                <div className="Checkbox__wrapper__show">
                  <a href={link} target={'_blank'}>
                    {`${t('Login.보기')}`}
                  </a>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="Agreement__inner__body__button">
        <RDSButton fullWidth onClick={onClickNext} disabled={disabledNextButton}>
          {`${t('MyPage.다음')}`}
        </RDSButton>
      </div>
    </>
  );
};

export default AgreementCheckboxArea;
