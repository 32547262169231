import { useQuery } from 'react-query';
import { getJWTHeaderFromLocalStorage } from '../../fetchInstance/axios';
import { axiosInstance } from '../../fetchInstance/customAxios';
import { queryKeys } from './constants';

async function getModelViewCount(): Promise<any> {
  const { data } = await axiosInstance.get('/viewcount', {
    headers: getJWTHeaderFromLocalStorage(),
  });

  return data;
}

export const getMaxViewCount = async () => {
  const res = await axiosInstance.get(`/user/limit`, {
    headers: getJWTHeaderFromLocalStorage(),
  });
  return res.data.userPermission.max_view_count;
};

export const getUserInfo = async (user_uid: string) => {
  const res = await axiosInstance.get(`/user?user_uid=${user_uid}`, {
    headers: getJWTHeaderFromLocalStorage(),
  });
  return res.data;
};

export function useViewCount() {
  const { data } = useQuery(queryKeys.viewCount, () => getModelViewCount());
  return { data };
}

export const useMaxViewCount = () => {
  const { data: maxViewCount, isSuccess: maxViewSuccess } = useQuery([queryKeys.traffic, 'maxViewCount'], () =>
    getMaxViewCount(),
  );
  return { maxViewCount, maxViewSuccess };
};

export const useTrafficUser = (user_uid: string | undefined) => {
  const { data: userInfo, isSuccess: userInfoSuccess } = useQuery(
    [queryKeys.traffic, 'userInfo'],
    () => getUserInfo(user_uid!),
    {
      enabled: user_uid !== undefined,
    },
  );

  return { userInfo, userInfoSuccess };
};
