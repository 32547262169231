import React from 'react';
import { Chart as ChartJS, CategoryScale, PointElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, PointElement, BarElement, Title, Tooltip, Legend);

type AnalysisEachModelProps = {
  colors: string[];
  labels: string[];
  data: number[];
};

const AnalysisEachModel = ({ colors, data, labels }: AnalysisEachModelProps) => {
  return (
    <Bar
      options={{
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 20,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          // legend: {
          //   position: 'top' as const,
          // },
          // title: {
          //   display: true,
          //   text: 'Chart.js Line Chart',
          // },
        },
      }}
      data={{
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: colors,
          },
        ],
      }}
    />
  );
};

export default AnalysisEachModel;
