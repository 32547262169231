import { RDSToast } from '@reconlabs/reconlabs-fe-components';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Oauth } from '../../../constants/type';
import { DEFAULT_LOGGEDIN_ROUTE } from '../../../constants/urls';
import { UserContext } from '../../context/UserContextProvider';
import { checkOverViewCount } from '../../../utils/limit';
import { loginOauth } from '../../../utils/login';

const Signedin = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const [toast, setToast] = useState<string>('');
  const Oauth = localStorage.getItem('oauth');
  const { setIsOverViewCountModalOpen, setIsOverWarningViewCountModalOpen } = useContext(UserContext);

  useEffect(() => {
    (async function () {
      try {
        await loginOauth(code!, Oauth as Oauth);
        const { isOverViewCount, isOverWarningViewCount } = await checkOverViewCount();
        if (isOverViewCount) setIsOverViewCountModalOpen(true);
        else if (isOverWarningViewCount) setIsOverWarningViewCountModalOpen(true);
        navigate(DEFAULT_LOGGEDIN_ROUTE);
      } catch {
        alert('해당 로그인 이메일을 찾을 수 없습니다. 다른 로그인을 이용해주세요');
        navigate('/login');
        throw new Error();
      }
      setToast('');
    })();
  }, []);
  return (
    <div>
      <RDSToast.Overlay
        type="loadingSpinner"
        toastOverlayId="loading"
        openedToastOverlay={'loading'}
        setOpenedToastOverlay={setToast}
      />
    </div>
  );
};

export default Signedin;
