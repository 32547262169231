import React, { useContext, useEffect, useRef, useState } from 'react';
import { UploadFile, UploadState, RDSUploadContext } from '../RDSUpload';
import RDSUpload from '../RDSUpload';
import {
  RDSButton,
  UploadIcon,
  WarningOutlinedIcon,
  RefreshIcon,
  ReplayIcon,
  RDSToast,
  RDSToastAlertObject,
} from '@reconlabs/reconlabs-fe-components';
import { validationFile } from '../../../../utils/utils';
import { useTranslation } from 'react-i18next';
import amplitude from '../../../../utils/amplitude';
import amplitudeEvents from '../../../../constants/amplitudeEvents';
import { checkModelSizeLimit, checkOverViewCount } from '../../../../utils/limit';
import OverViewCountModal from '../../../global/OverViewCountModal';
import { useLimits } from '../../../../hooks/react-query/useLimits';

const UploadFailedFileBox = ({ uploadFile }: { uploadFile: UploadFile }) => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const [uploadMaxSize, setUploadMaxSize] = useState('20MB');
  // const
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const state: UploadState = uploadFile.state!;
  const text =
    state === 'canceled'
      ? t(`global.용량 초과.1`) + uploadMaxSize + t(`global.용량 초과.2`)
      : state === 'unknownFail'
      ? t('global.네트워크를 확인해주세요.')
      : '';

  // context
  const { uploadFiles } = useContext(RDSUploadContext);

  // internal variables
  const [alert, setAlert] = useState<RDSToastAlertObject>();
  const [isOverViewCountModalOpen, setIsOverViewCountModalOpen] = useState(false);
  const [isOverViewCount, setIsOverViewCount] = useState<boolean>(false);

  // API calls
  const { data: limitData, refetch: limitRefetch } = useLimits();
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    (async () => {
      setUploadMaxSize(await checkModelSizeLimit());
    })();
  }, []);

  useEffect(() => {
    if (limitData?.result === 'success') {
      setIsOverViewCount(limitData?.isOverViewCount);
    }
  }, [limitData]);

  useEffect(() => {
    if (
      uploadFile.state === 'canceled' &&
      uploadFiles.filter((file) => file.id !== uploadFile.id && file.state === 'canceled').length > 0
    ) {
      setAlert({
        type: 'failure',
        message: (
          <>
            {`${t('global.업로드에 실패했습니다.')}`} <br />
            {`${t('global.오류가 반복된다면 고객센터에 문의해주세요.')}`}
          </>
        ),
      });
    }
  }, [uploadFile]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const getIcon = () => {
    const result = state === 'unknownFail' ? <RefreshIcon /> : state === 'overPlan' ? <ReplayIcon /> : <UploadIcon />;
    return result;
  };

  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <RDSToast.AlertArea openedAlert={alert} />
      <div className="Separator">
        <div data-id={uploadFile.id} className={state === 'overPlan' ? 'UploadFailed plan' : 'UploadFailed'}>
          <div>
            <div className="UploadFailed__name">{uploadFile.file.name}</div>
            <div
              className={state === 'overPlan' ? 'UploadFailed__cause UploadFailed__cause__plan' : 'UploadFailed__cause'}
            >
              <WarningOutlinedIcon width="20" height="20" />
              {text}
            </div>
          </div>
          <RDSButton
            size="medium"
            type="outline"
            color="grey"
            customStyle={{ width: '40px', height: '40px' }}
            onClick={async (event: any) => {
              limitRefetch();

              if (state === 'canceled') {
                amplitude.sendEvent(amplitudeEvents.bottomsheet.zero_allmodel_bottomsheet_list_error_capacity_retry);
              }

              if (isOverViewCount) {
                event.preventDefault();
                setIsOverViewCountModalOpen(true);
              } else {
                inputRef.current?.click();
              }
            }}
          >
            {getIcon()}
          </RDSButton>
          <RDSUpload.Input checkValidation={(file) => validationFile(file)}>
            {({ handleOnChange }) => (
              <input
                ref={inputRef}
                id="RDSUpload-input"
                type={'file'}
                multiple
                onChange={handleOnChange}
                onClick={() => (inputRef.current!.value = '')}
                style={{
                  display: 'none',
                }}
              ></input>
            )}
          </RDSUpload.Input>
          <OverViewCountModal
            isOverViewCountModalOpen={isOverViewCountModalOpen}
            setIsOverViewCountModalOpen={setIsOverViewCountModalOpen}
            closeHandler={() => inputRef.current?.click()}
          />
        </div>
      </div>
    </>
  );
};

export default UploadFailedFileBox;
