import React, { useState, useEffect } from 'react';
import AgreementCheckboxArea from './AgreementCheckboxArea';
import UserTag from './UserTag';
import Signup from './Singup';
import { useTranslation } from 'react-i18next';
import { JWT_ACCESS_TOKEN_LOCALSTORAGE_KEY, JWT_REFRESH_TOKEN_LOCALSTORAGE_KEY } from '../../../utils/localstorage';
import { Link } from 'react-router-dom';

const SignUpFlow = () => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const [viewType, setViewType] = useState<string>('checkbox');
  const [element, setElement] = useState<JSX.Element>(<></>);
  const [signUpParams, setSignUpParams] = useState();
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (viewType && viewType.length > 0) {
      const el =
        viewType === 'checkbox' ? (
          <AgreementCheckboxArea setViewType={setViewType} setSignUpParams={setSignUpParams} />
        ) : viewType === 'userTag' ? (
          <UserTag setViewType={setViewType} signUpParams={signUpParams} setSignUpParams={setSignUpParams} />
        ) : (
          <Signup setViewType={setViewType} signUpParams={signUpParams} setSignUpParams={setSignUpParams} />
        );

      setElement(el);
    }
  }, [viewType, viewType.length]);
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div className="Agreement">
      <div className="Agreement__inner">
        <div className="Agreement__inner__header">{`${t('Login.회원가입')}`}</div>
        <div className="Agreement__inner__body">{element}</div>
        <div className="Agreement__inner__footer">
          {`${t('Login.이미 가입하셨나요?')}`}
          <Link
            className="Agreement__inner__footer__login"
            to={'/login'}
            onClick={() => {
              localStorage.removeItem(JWT_ACCESS_TOKEN_LOCALSTORAGE_KEY);
              localStorage.removeItem(JWT_REFRESH_TOKEN_LOCALSTORAGE_KEY);
            }}
          >
            {`${t('Login.로그인')}`}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUpFlow;
