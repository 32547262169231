import React, { createContext, memo, useCallback, useEffect, useState } from 'react';
import { Tier } from '../../constants/type';
import { LoginType, Oauth } from '../../constants/type';
import amplitude from '../../utils/amplitude';

export const UserContext = createContext<UserContextType>({
  user: {},
  setUserInfo: () => {},
  newModels: false,
  setNewModels: () => {},
  modelDeleted: false,
  setModelDeleted: () => {},
  isOverViewCountModalOpen: false,
  setIsOverViewCountModalOpen: (isOpen: boolean) => {},
  isOverWarningViewCountModalOpen: false,
  setIsOverWarningViewCountModalOpen: (isOpen: boolean) => {},
});

const UserContextProvider = (props: any) => {
  const [user, setUser] = useState<User>({});
  const [newModels, setNewModels] = useState<boolean>(false);
  const [modelDeleted, setModelDeleted] = useState<boolean>(false);
  const [isOverViewCountModalOpen, setIsOverViewCountModalOpen] = useState(false);
  const [isOverWarningViewCountModalOpen, setIsOverWarningViewCountModalOpen] = useState(false);

  useEffect(() => {
    //check auth state (prevent logout when redirect)
  }, []);

  const setUserInfo = useCallback((newState: User) => {
    // console.log(newState);
    setUser(newState);
    amplitude.setUserInfo('user_email', newState.user_email!);
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUserInfo,
        newModels,
        setNewModels,
        modelDeleted,
        setModelDeleted,
        isOverViewCountModalOpen,
        setIsOverViewCountModalOpen,
        isOverWarningViewCountModalOpen,
        setIsOverWarningViewCountModalOpen,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default memo(UserContextProvider);

export type User = {
  idx?: number;
  uid?: string;
  user_email?: string;
  user_status?: 'pending' | 'verifying' | 'confirmed';
  user_tier?: Tier;
  delete_flag?: boolean;
  [key: string]: unknown;
  last_login_type?: Oauth;
  organization_idx?: number;
  register_type?: LoginType;
  registered_date?: string;
  user_name?: string;
  reference_date?: string;
};
type UserContextType = {
  user: User;
  setUserInfo: (newUserState: User) => void;
  newModels: boolean;
  setNewModels: (newModelState: boolean) => void;
  modelDeleted: boolean;
  setModelDeleted: (newDeleteState: boolean) => void;
  isOverViewCountModalOpen: boolean;
  setIsOverViewCountModalOpen: (newModalState: boolean) => void;
  isOverWarningViewCountModalOpen: boolean;
  setIsOverWarningViewCountModalOpen: (newModalState: boolean) => void;
};
