import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  AlertErrorIcon,
  CreditCardOutlinedIcon,
  RDSButton,
  RDSLinkButton,
  RDSSnackbar,
} from '@reconlabs/reconlabs-fe-components';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/utils/useAuth';
import { getBillingCardInfo, loadPayment, changeBillingCard } from '../../../utils/payment';
import { BillingPurpose } from '../../../constants/type';
import { BILLING_PURPOSE } from '../../../constants/values';
import { TAB } from './Payment';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';

const PaymentInfo = ({ handleLoader }: { handleLoader: Function }) => {
  const [cardNumber, setCardNumber] = useState('');
  const [openedSnackbar, setOpenedSnackbar] = useState('');
  const [changeCardResult, setChangeCardResult] = useState('');
  const { user } = useAuth();
  const { status } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setCardInfo();
  }, []);

  //카드 정보 세팅
  const setCardInfo = async () => {
    handleLoader('start');
    let cardNumber = await getBillingCardInfo();
    cardNumber && setCardNumber(cardNumber.match(/.{1,4}/g).join(' \u00A0'));
    handleLoader('end');
  };

  //변경할 결제 카드 검증 후 처리(success/fail url로 리다이렉트)
  useEffect(() => {
    if ((searchParams.get('purpose') as BillingPurpose) === BILLING_PURPOSE.ChangeCard) {
      setChangeCardResult(status as string);
      if (status === 'success') {
        (async () => {
          const authKey = searchParams.get('authKey') as string;
          await changeBillingCard(authKey);
          setCardInfo();
          navigate(`/dashboard/payment?tab=${TAB.paymentInfo}`);
        })();
      }
      setOpenedSnackbar('changeCardSnackbar');
    }
  }, [status]);

  //채널톡 오픈
  const handleAsk = () => {
    amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_management_contact);
    (window as any).ChannelIO('openChat');
  };

  return (
    <div className="PaymentInfo">
      <div className="PaymentInfo__item">
        <CreditCardOutlinedIcon color="#CBD2FF" width="65px" height="65px" />
      </div>
      <div className="PaymentInfo__item">
        <div className="PaymentInfo__item__cardNum">{cardNumber}</div>
        <RDSButton
          color="white"
          children={t(`Payment.결제 수단 변경`)}
          onClick={() => {
            amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_management_card_change);
            loadPayment(
              user.uid || '',
              `${process.env.REACT_APP_PLICAR_ZERO_PROTOCOL}://${process.env.REACT_APP_PLICAR_ZERO_DOMAIN}:${process.env.REACT_APP_PLICAR_ZERO_PORT}/dashboard/payment`,
              `tab=${TAB.paymentInfo}&purpose=${BILLING_PURPOSE.ChangeCard}`,
            );
          }}
          customStyle={{ width: '200px', height: '50px' }}
        ></RDSButton>
      </div>
      <div className="PaymentInfo__item">
        <RDSLinkButton
          color="information"
          label={`${t('Payment.결제 관련 문의')}`}
          size="xsmall"
          navigateFn={handleAsk}
        />
      </div>
      <RDSSnackbar.CloseButton
        snackbarId="changeCardSnackbar"
        content={`카드 변경에 ${changeCardResult === 'success' ? '성공' : '실패'}헀습니다.`}
        icon={<AlertErrorIcon />}
        openedSnackbar={openedSnackbar}
        setOpenedSnackbar={setOpenedSnackbar}
      ></RDSSnackbar.CloseButton>
    </div>
  );
};

export default PaymentInfo;
