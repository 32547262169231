import { RDSButton, RDSCheckbox } from '@reconlabs/reconlabs-fe-components';
import React, { useState } from 'react';
import { deleteUser, getLoggedInUser, postSurvey } from '../../../hooks/react-query/useZeroUser';
import { useTranslation } from 'react-i18next';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import { surveyList } from '../../../constants/surveyList';
import { SurveyType } from '../../../constants/type';
import amplitude from '../../../utils/amplitude';
import useAuth from '../../../hooks/utils/useAuth';

const Survey = ({ setModalNextStep, onClickCancel }: { setModalNextStep: Function; onClickCancel: Function }) => {
  const { t } = useTranslation();
  const [checkedBoxIdx, setCheckedBoxIdx] = useState<any>([]);
  const [afterCallChecked, setAfterCallChecked] = useState(false);
  const [isWithdrawalButtonLoading, setIsWithdrawalButtonLoading] = useState(false);
  const { user, setUserInfo } = useAuth();

  const handleChangeCheckBoxList = (idx: number) => {
    const checked = checkedBoxIdx.includes(idx);
    let newCheckedBoxIdx;
    newCheckedBoxIdx = [...checkedBoxIdx, idx];
    setCheckedBoxIdx(newCheckedBoxIdx);
    if (checked) {
      newCheckedBoxIdx = checkedBoxIdx.filter((item: any) => item !== idx);
      setCheckedBoxIdx(newCheckedBoxIdx);
    }
  };

  const handleChangeAfterCallCheckBox = () => {
    setAfterCallChecked(!afterCallChecked);
  };

  const sendAmplitudeInterviewParticipation = () => {
    if (afterCallChecked) {
      amplitude.sendEvent(amplitudeEvents.mypage.zero_mypage_withdrawal_survey_interview_participation, {
        user_id: user.uid,
        user_email: user.user_email,
        signup_date: user.registered_date,
        withdrawal_date: new Date(),
      });
    }
  };

  const sendAmplitudeSurvey = () => {
    const checklistProps = checkedBoxIdx.map((idx: number) => {
      switch (idx) {
        case 1:
          return 'system_error';
        case 2:
          return 'price_policy';
        case 3:
          return 'delete_data';
        case 4:
          return 'low_frequency';
        case 5:
          return 'lack_of_funtion';
        case 6:
          return 'inconvenience';
        default:
          return '에러';
      }
    });

    amplitude.sendEvent(amplitudeEvents.mypage.zero_mypage_withdrawal_survey_checklist, {
      survey_checklist: checklistProps,
    });
  };

  const onClickWithdrawal = async () => {
    setIsWithdrawalButtonLoading(true);
    try {
      // await postSurvey(checkedSurveys);
      await deleteUser();
      // Amplitude 전송
      sendAmplitudeInterviewParticipation();
      sendAmplitudeSurvey();
    } catch (e: any) {
      e.response.data.result === 'not_found'
        ? alert('유저를 찾을 수 없습니다. 삭제된 유저이거나 잘못된 접근입니다.')
        : alert('네트워크 에러로 유저 삭제가 실패하였습니다. 잠시 후 다시 이용해주세요.');
      // if falied to delete user, finish the logic not to post survey and go nextStep
      setIsWithdrawalButtonLoading(false);
      return;
    }
    try {
      const checkedSurveys: SurveyType[] = [
        { survey_id: 'before_unregister', survey_response: JSON.stringify([...checkedBoxIdx]) },
        { survey_id: 'after_interview', survey_response: afterCallChecked },
      ];
      await postSurvey(checkedSurveys);
    } catch (e) {
      console.log('post survey error');
      // even if post Survey failed, need to go next step
    }
    setUserInfo(await getLoggedInUser());
    setModalNextStep();
  };
  return (
    <div className="notice">
      <div className="notice__title">{`${t('MyPage.플리카 서비스 경험 평가')}`}</div>
      <div className="notice__survey__text">
        {`${t('MyPage.계정을 탈퇴하시려는 이유를 말씀해주세요.')}`}
        <p />
        {`${t('MyPage.빠른 제품 개선에 소중한 자료로 활용하여 더 나은 서비스로 성장하겠습니다!')}`}
      </div>
      <div className="notice__survey__multipleSelect">*{`${t('MyPage.중복 선택 가능')}`}</div>
      <div className="notice__survey__container">
        {surveyList.map(({ idx, content }) => {
          return (
            <RDSCheckbox.IconType
              key={idx + content}
              handleCheck={() => {
                handleChangeCheckBoxList(idx);
              }}
              checked={checkedBoxIdx.includes(idx)}
              label={t(`MyPage.${content}`)}
            />
          );
        })}
      </div>
      <div className="notice__survey__afterCall">
        <RDSCheckbox.IconType
          handleCheck={() => {
            handleChangeAfterCallCheckBox();
          }}
          checked={afterCallChecked}
          label={t('MyPage.추후 사용자 인터뷰 요청 시 참여 의사가 있습니다. (선택 사항)')}
        />
      </div>
      <RDSButton
        size="large"
        color="warning"
        fullWidth
        onClick={() => {
          amplitude.sendEvent(amplitudeEvents.mypage.zero_mypage_withdrawal_survey_next);
          onClickWithdrawal();
        }}
        isLoading={isWithdrawalButtonLoading}
      >
        {`${t('MyPage.회원 탈퇴')}`}
      </RDSButton>
      <div className="notice__cancelButton">
        <RDSButton
          type="noBox"
          color="grey"
          fullWidth
          onClick={() => {
            amplitude.sendEvent(amplitudeEvents.mypage.zero_mypage_withdrawal_survey_cancel);
            onClickCancel();
          }}
        >
          {`${t('MyPage.취소')}`}
        </RDSButton>
      </div>
    </div>
  );
};

export default Survey;
