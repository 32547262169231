import React, { useEffect, useState } from 'react';
import {
  XMarkIcon,
  RDSButton,
  RDSInputField,
  RDSToast,
  RDSToastAlertObject,
  ModalMaker,
} from '@reconlabs/reconlabs-fe-components';
import axios from 'axios';
import { pressEnter } from '../../../utils/utils';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import { useTranslation } from 'react-i18next';
import { sendEmailResetPassword } from '../../../utils/login';
import { getLocale } from '../../../utils/localstorage';

type ResetPasswordModalProps = {
  isVisible: boolean;
  setOpenResetPasswordModal: Function;
  setToast: Function;
};

const ResetPasswordModal = (props: ResetPasswordModalProps) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [isLoginButtonLoading, setIsLoginButtonLoading] = useState(false);
  const [isResendEmailButtonLoading, setIsResendEmailButtonLoading] = useState(false);
  const [errorText, setErrorText] = useState<string>('');
  const [isInputFiledDisabled, setIsInputFiledDisabled] = useState(false);
  const [alert, setAlert] = useState<RDSToastAlertObject | undefined>();
  /*----------------------------------------
                Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    emailSent ? setIsInputFiledDisabled(true) : setIsInputFiledDisabled(false);
  }, [emailSent]);

  // 모달 껐을때 초기화
  useEffect(() => {
    setEmail('');
    setEmailSent(false);
    setErrorText('');
    setIsInputFiledDisabled(false);
    setAlert(undefined);
  }, [props.isVisible]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const validateEmail = (email: string) => {
    const emailRegexp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return email.match(emailRegexp);
  };
  const sendEmail = async () => {
    if (!validateEmail(email)) {
      setErrorText(t('Login.올바른 이메일을 입력해주세요.'));
      return;
    }
    setIsLoginButtonLoading(true);
    try {
      await sendEmailResetPassword(email, getLocale());
      // 이메일 전송 API 요청 응답 200 이후 호출`
      setEmailSent(true);
    } catch (e: any) {
      switch (e.response.data.result) {
        case 'user_not_found':
          setErrorText(t('Login.입력하신 이메일을 찾을 수 없습니다.'));
          break;
        case 'invalid_user_status':
          setErrorText(t('Login.회원가입이 완료되지 않은 이메일입니다.'));
          break;
        case 'invalid_user_register_type':
          setErrorText(t('Login.소셜 계정은 비밀번호 변경이 불가능합니다.'));
          break;
        default:
          break;
      }
    }
    setIsLoginButtonLoading(false);
  };

  const reSendEmail = async () => {
    setIsResendEmailButtonLoading(true);
    try {
      await sendEmailResetPassword(email, getLocale());
      setAlert({ type: 'success', message: t('Login.인증메일이 재발송되었습니다.') });
    } catch (e: any) {
      setAlert({ type: 'failure', message: '네트워크 오류로 실패하였습니다. 잠시후 다시 이용해주세요' });
    }
    setIsResendEmailButtonLoading(false);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <ModalMaker
      isVisible={props.isVisible}
      setIsVisible={(bool: boolean) => {
        // 중도 이탈 시
        if (emailSent === false && window.location.pathname === '/login') {
          amplitude.sendEvent(amplitudeEvents.signin.zero_signin_change_password_failure);
        }
        props.setOpenResetPasswordModal(bool);
      }}
    >
      <div className={'ResetPassword'}>
        <div className="ResetPassword__header">
          {`${t('Login.비밀번호 재설정')}`}
          <button
            onClick={() => {
              if (emailSent === false && window.location.pathname === '/login') {
                amplitude.sendEvent(amplitudeEvents.signin.zero_signin_change_password_failure);
              }
              props.setOpenResetPasswordModal(false);
            }}
          >
            <XMarkIcon />
          </button>
        </div>
        <RDSInputField.Text
          type={'email'}
          placeholder="sample@plicar.io"
          customStyles={{ marginTop: '24px' }}
          isError={errorText ? true : false}
          helperText={errorText}
          value={email}
          disabled={isInputFiledDisabled}
          handleInputChange={(e: any) => setEmail(e.target.value)}
          onKeyDown={(e: any) => {
            pressEnter(e) && email.length !== 0 && !isLoginButtonLoading ? sendEmail() : null;
          }}
        />

        {emailSent ? (
          <>
            <div className="ResetPassword__text">
              {`${t('Login.입력하신 이메일로 비밀번호 재설정 링크가 전송되었습니다. 이메일을 확인해주세요.')}`}
            </div>
            <div className="ResetPassword__resendEmail">
              {`${t('Login.인증 메일을 받지 못하셨나요?')}`}
              <RDSButton size="xsmall" isLoading={isResendEmailButtonLoading} type={'noBox'} onClick={reSendEmail}>
                {`${t('Login.인증메일 재발송')}`}
              </RDSButton>
            </div>
            <RDSToast.AlertArea timingMs={2000} openedAlert={alert} />
          </>
        ) : (
          <div className="ResetPassword__sendButton">
            <RDSButton fullWidth disabled={email.length === 0} onClick={sendEmail} isLoading={isLoginButtonLoading}>
              {`${t('Login.전송')}`}
            </RDSButton>
          </div>
        )}
      </div>
    </ModalMaker>
  );
};

export default ResetPasswordModal;
