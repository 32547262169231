export const queryKeys = {
  user: 'user',
  model: 'model',
  modelTransform: 'modelTransform',
  modelsList: 'modelsList',
  modelViewer: 'modelViewer',
  viewCount: 'viewCount',
  catalog: 'catalog',
  catalogList: 'catalogList',
  survey: 'serviceEval',
  traffic: 'traffic',
  userLimits: 'limits',
};
