import React from 'react';
import { PLICAR_COLORS } from '@reconlabs/reconlabs-fe-components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { AnalysisViewerFilterType } from '../../../constants/type';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

type AnalysisAllModelsProps = {
  currentRadio: AnalysisViewerFilterType;
  labels: string[];
  arData?: number[];
  threedData?: number[];
};

const AnalysisAllModels = ({ currentRadio, labels, arData, threedData }: AnalysisAllModelsProps) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  return (
    <Line
      data={{
        labels: labels,
        datasets:
          currentRadio === 'all'
            ? [
                {
                  label: 'AR Viewer',
                  data: arData,
                  borderWidth: 1,
                  borderColor: PLICAR_COLORS.data.no1,
                  backgroundColor: PLICAR_COLORS.data.no1,
                },
                {
                  label: '3D Viewer',
                  data: threedData,
                  borderWidth: 1,
                  borderColor: PLICAR_COLORS.data.no2,
                  backgroundColor: PLICAR_COLORS.data.no2,
                },
              ]
            : currentRadio === 'ar'
            ? [
                {
                  label: 'AR Viewer',
                  data: arData,
                  borderWidth: 1,
                  borderColor: PLICAR_COLORS.data.no1,
                  backgroundColor: PLICAR_COLORS.data.no1,
                },
              ]
            : [
                {
                  label: '3D Viewer',
                  data: threedData,
                  borderWidth: 1,
                  borderColor: PLICAR_COLORS.data.no2,
                  backgroundColor: PLICAR_COLORS.data.no2,
                },
              ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 20,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      }}
    />
  );
};

export default AnalysisAllModels;
