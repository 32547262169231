import { useContext, useState } from 'react';
import DashboardNavigator from './DashboardNavigator';
import { Outlet, useNavigate } from 'react-router-dom';
import { ModalMaker, RDSModal, RDSToast, useRDSMediaQuery } from '@reconlabs/reconlabs-fe-components';
import { useTranslation } from 'react-i18next';
import { TIER } from '../constants/values';
import useAuth from '../hooks/utils/useAuth';
import DashboardContextProvider, { DashboardContext } from './context/DashboardContextProvider';
import { UserContext } from './context/UserContextProvider';
import amplitude from '../utils/amplitude';
import amplitudeEvents from '../constants/amplitudeEvents';
import MenuIcon from '../images/MobileMenubarImage.png';

const Dashboard = () => {
  return (
    <DashboardContextProvider>
      <DashboardMain />
    </DashboardContextProvider>
  );
};

const DashboardMain = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const isMobile = useRDSMediaQuery(['true', ''], [767]);
  const { spinner, setSpinner, isOverUploadModalOpen, setIsOverUploadModalOpen, remainingUploadCount } =
    useContext(DashboardContext);
  const { isOverWarningViewCountModalOpen, setIsOverWarningViewCountModalOpen } = useContext(UserContext);
  const [openMobileNavigator, setOpenMobileNavigator] = useState<boolean>(false);
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <div className="Dashboard">
        <ModalMaker
          isVisible={openMobileNavigator}
          setIsVisible={setOpenMobileNavigator}
          modalStyle={{ top: 0, left: 0, transform: 'none', borderRadius: 0 }}
        >
          <DashboardNavigator />
        </ModalMaker>
        <div className="Dashboard__MobileNavigator">
          <div style={{ width: '24px', height: '16px' }}>
            <button onClick={() => setOpenMobileNavigator(true)}>
              <img src={MenuIcon} alt="모바일 메뉴 아이콘" />
            </button>
          </div>
        </div>
        {!isMobile && <DashboardNavigator />}
        <div className="Dashboard__contents">
          <div>
            <Outlet />
          </div>
        </div>
        <RDSModal
          open={isOverUploadModalOpen}
          onClose={() => setIsOverUploadModalOpen(false)}
          title={`${t(`Payment.플랜 사용량 초과.${user.user_tier}`)}`}
          supportingText={
            <div>
              <div>{`${t(`Payment.플랜 사용량 초과 모달 문구.업로드 수.1`)}`}</div>
              <div>{`${t(`Payment.플랜 사용량 초과 모달 문구.업로드 수.2`)}`}</div>
              <br />
              <div>{`${t(`Payment.플랜 사용량 초과 모달 문구.업로드 수.3`)}${remainingUploadCount}${t(
                'Payment.개',
              )}`}</div>
            </div>
          }
          buttonType="noBox"
          button1Label={user.user_tier === TIER.Free ? t('Payment.플랜 구독하기') : t('Payment.문의하기')}
          button1Fn={
            user.user_tier === TIER.Free
              ? () => {
                  setIsOverUploadModalOpen(false);
                  navigate('/dashboard/payment');
                }
              : () => {
                  setIsOverUploadModalOpen(false);
                  (window as any).ChannelIO('openChat');
                }
          }
          button2Label={t('global.닫기')}
          button2Fn={() => setIsOverUploadModalOpen(false)}
          customWidth={350}
        ></RDSModal>
        <RDSModal
          open={isOverWarningViewCountModalOpen}
          onClose={() => setIsOverWarningViewCountModalOpen(false)}
          title={`${t(`Payment.플랜 사용량 초과.${user.user_tier}`)}`}
          supportingText={
            <div>
              <div>{`${t(`Payment.플랜 사용량 초과 모달 문구.경고 조회 수.${user.user_tier}.1`)}`}</div>
              <div>{`${t(`Payment.플랜 사용량 초과 모달 문구.경고 조회 수.${user.user_tier}.2`)}`}</div>
            </div>
          }
          buttonType="noBox"
          button1Label={user.user_tier === TIER.Free ? t('Payment.플랜 구독하기') : t('Payment.문의하기')}
          button1Fn={() => {
            user.user_tier === TIER.Free
              ? amplitude.sendEvent(amplitudeEvents.payment.zero_payment_prewarning_free_modal_plans)
              : user.user_tier === TIER.Basic
              ? amplitude.sendEvent(amplitudeEvents.payment.zero_payment_prewarning_basic_modal_plans)
              : amplitude.sendEvent(amplitudeEvents.payment.zero_payment_prewarning_plus_modal_plans);
            setIsOverWarningViewCountModalOpen(false);
            user.user_tier === TIER.Free ? navigate('/dashboard/payment') : (window as any).ChannelIO('openChat');
          }}
          button2Label={t('global.닫기')}
          button2Fn={() => {
            user.user_tier === TIER.Free
              ? amplitude.sendEvent(amplitudeEvents.payment.zero_payment_prewarning_free_modal_close)
              : user.user_tier === TIER.Basic
              ? amplitude.sendEvent(amplitudeEvents.payment.zero_payment_prewarning_basic_modal_close)
              : amplitude.sendEvent(amplitudeEvents.payment.zero_payment_prewarning_plus_modal_close);
            setIsOverWarningViewCountModalOpen(false);
          }}
        ></RDSModal>
        <RDSToast.Overlay
          type="loadingSpinner"
          toastOverlayId="loading-spinner"
          openedToastOverlay={spinner}
          setOpenedToastOverlay={setSpinner}
        />
      </div>
    </>
  );
};

export default Dashboard;
