import React, { useState } from 'react';
import {
  RDSButton,
  ThreeDIcon,
  PinDropIcon,
  AttractionsIcon,
  SelfImprovementIcon,
  ShoppingCartIcon,
  WebIcon,
  PrintIcon,
  BookOnlineOutlinedIcon,
  RDSInputField,
  RDSToast,
} from '@reconlabs/reconlabs-fe-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SelectableIconButton from './SelectableIconButton';
import { updateCurrentOauthUserAsConfirmed } from '../../../utils/login';
import { DEFAULT_LOGGEDIN_ROUTE } from '../../../constants/urls';
import { SurveyType } from '../../../constants/type';
import { postSurvey } from '../../../hooks/react-query/useZeroUser';
import amplitude, { changeSignupTag } from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import { useTranslation } from 'react-i18next';

type UserTagProps = {
  signUpParams: any;
  setViewType: Function;
  setSignUpParams: Function;
};
const UserTag = (props: UserTagProps) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const [toast, setToast] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [inputTextValue, setInputTextValue] = useState('');
  const [selectedTags, setSelectedTags] = useState<any>({});
  const [isLoginButtonLoading, setIsLoginButtonLoading] = useState(false);
  const navigate = useNavigate();
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const onClickNext = async () => {
    setIsLoginButtonLoading(true);
    if (isLoginButtonLoading) {
      return;
    }
    setToast('loading');
    const oauth = searchParams.get('login_type');
    let user_tag = [];
    for (const [tag, selected] of Object.entries(selectedTags)) {
      if (selected) {
        user_tag.push(tag);
      }
    }

    const tagsForAmplitude = user_tag.map((tag: string) => changeSignupTag(tag));
    amplitude.sendEvent(amplitudeEvents.signup.zero_signup_process_survey_tag, {
      tag: tagsForAmplitude,
    });
    if (inputTextValue.length > 0) {
      amplitude.sendEvent(amplitudeEvents.signup.zero_signup_process_survey_etc, {
        etc_tag: inputTextValue,
      });
    }

    const updatedSignupParams = { ...props.signUpParams, user_tag, user_tag_text: inputTextValue };
    props.setSignUpParams(updatedSignupParams);
    if (oauth === 'oauth') {
      try {
        await updateCurrentOauthUserAsConfirmed(updatedSignupParams);
        try {
          const surveys: SurveyType[] = [
            { survey_id: 'first_register', survey_response: JSON.stringify(updatedSignupParams.user_tag) },
            { survey_id: 'first_register_text', survey_response: updatedSignupParams.user_tag_text },
          ];
          await postSurvey(surveys);
        } catch (e) {
          console.log('post survey error');
        }
        setToast('');
        navigate(DEFAULT_LOGGEDIN_ROUTE);
      } catch (e: any) {
        if (e.response.data.result === 'existing_user') {
          alert('이미 가입된 유저입니다.');
          navigate(DEFAULT_LOGGEDIN_ROUTE);
          return;
        }
        alert('회원가입에 실패하였습니다.');
        navigate('/login');
      }
    } else {
      setToast('');
      console.log('navigate to signup');
      props.setViewType('signup');
    }
    setIsLoginButtonLoading(false);
  };
  const handleInputChange = (e: any) => {
    setInputTextValue(e.target.value);
  };
  const onClickTag = (tag: string, selected: boolean) => {
    selectedTags[tag] = selected;
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div className="Usertag">
      <RDSToast.Overlay
        type="loadingSpinner"
        toastOverlayId="loading"
        openedToastOverlay={toast}
        setOpenedToastOverlay={setToast}
      />
      <div className="Usertag__header">{`${t('Login.플리카(PlicAR)를 어디에 활용하고자 하시나요?')}`}</div>
      <div className="Usertag__body">
        <div className="Usertag__body__icons">
          <SelectableIconButton
            type="outline"
            onClick={(selected: boolean) => {
              onClickTag('모델 뷰어(3D)', selected);
            }}
            icon={<ThreeDIcon />}
          >
            {`${t('Login.모델 뷰어(3D)')}`}
          </SelectableIconButton>
          <SelectableIconButton
            type="outline"
            onClick={(selected: boolean) => {
              onClickTag('증강 현실(AR)', selected);
            }}
            icon={<PinDropIcon />}
          >
            {`${t('Login.증강 현실(AR)')}`}
          </SelectableIconButton>
          <SelectableIconButton
            type="outline"
            onClick={(selected: boolean) => {
              onClickTag('광고', selected);
            }}
            icon={<AttractionsIcon />}
          >
            {`${t('Login.광고')}`}
          </SelectableIconButton>
          <SelectableIconButton
            type="outline"
            onClick={(selected: boolean) => {
              onClickTag('가상 현실(VR)', selected);
            }}
            icon={<SelfImprovementIcon />}
          >
            {`${t('Login.가상 현실(VR)')}`}
          </SelectableIconButton>
          <SelectableIconButton
            type="outline"
            onClick={(selected: boolean) => {
              onClickTag('e-커머스', selected);
            }}
            icon={<ShoppingCartIcon />}
          >
            {`${t('Login.e-커머스')}`}
          </SelectableIconButton>
          <SelectableIconButton
            type="outline"
            onClick={(selected: boolean) => {
              onClickTag('웹페이지', selected);
            }}
            icon={<WebIcon />}
          >
            {`${t('Login.웹페이지')}`}
          </SelectableIconButton>
          <SelectableIconButton
            type="outline"
            onClick={(selected: boolean) => {
              onClickTag('인쇄 홍보물', selected);
            }}
            icon={<PrintIcon />}
          >
            {`${t('Login.인쇄 홍보물')}`}
          </SelectableIconButton>
          <SelectableIconButton
            type="outline"
            onClick={(selected: boolean) => {
              onClickTag('디지털 카탈로그', selected);
            }}
            icon={<BookOnlineOutlinedIcon />}
          >
            {`${t('Login.디지털 카탈로그')}`}
          </SelectableIconButton>
        </div>
        <div className="Usertag__body__input">
          <RDSInputField.Text
            title={`${t('Login.다른 활용 용도가 있으시면 알려주세요.')}`}
            handleInputChange={handleInputChange}
            value={inputTextValue}
            placeholder={t('Login.ex) 서비스 소개서 제작, SNS 콘텐츠 제작 등')}
          />
        </div>
      </div>
      <div className="Usertag__skip">
        <button
          onClick={() => {
            amplitude.sendEvent(amplitudeEvents.signup.zero_signup_process_survey_skip);
            onClickNext();
          }}
        >
          {`${t('Login.건너뛰기')}`}
        </button>
      </div>
      <div className="Agreement__inner__body__button">
        <RDSButton
          fullWidth
          onClick={() => {
            amplitude.sendEvent(amplitudeEvents.signup.zero_signup_process2_next);
            onClickNext();
          }}
          isLoading={isLoginButtonLoading}
        >
          {`${t('MyPage.다음')}`}
        </RDSButton>
      </div>
    </div>
  );
};

export default UserTag;
