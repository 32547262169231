import { RDSButton, RDSCheckbox } from '@reconlabs/reconlabs-fe-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import amplitude from '../../../utils/amplitude';

const WithdrawalNotice = ({
  setModalNextStep,
  onClickCancel,
}: {
  setModalNextStep: Function;
  onClickCancel: Function;
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [disabledNextButton, setDisabledNextButton] = useState<boolean>(true);

  const handleClickCheckBox = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    setDisabledNextButton(!checked);
  }, [checked]);
  return (
    <div className="notice">
      <div className="notice__title">{`${t('MyPage.플리카 탈퇴 전 확인해주세요.')}`}</div>
      <div className="notice__contents">
        &#183; {`${t('MyPage.회원 탈퇴하시면 이용 중인 3D 카탈로그가 폐쇄됩니다.')}`}
        <p />
        &#183; {`${t('MyPage.모든 데이터는 복구가 불가능합니다.')}`}
        <p />
        &#183; {`${t('MyPage.정기 결제 중인 경우 다음 결제일 부터 자동 결제가 중단됩니다.')}`}
        <p />
        &#183; {`${t('MyPage.탈퇴 후 24시간 이내에 계정 복구가 가능합니다.')}`}
      </div>
      <RDSCheckbox.IconType
        customStyle={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: '500', marginBottom: '12px' }}
        handleCheck={handleClickCheckBox}
        checked={checked}
        label={t('MyPage.안내 사항을 모두 확인하였으며, 이에 동의합니다.')}
      />

      <RDSButton
        color="grey"
        fullWidth
        onClick={() => {
          amplitude.sendEvent(amplitudeEvents.mypage.zero_mypage_withdrawal_guide_next);
          setModalNextStep();
        }}
        disabled={disabledNextButton}
      >
        {`${t('MyPage.다음')}`}
      </RDSButton>
      <div className="notice__cancelButton">
        <RDSButton
          type="noBox"
          fullWidth
          onClick={() => {
            amplitude.sendEvent(amplitudeEvents.mypage.zero_mypage_withdrawal_guide_cancel);
            onClickCancel();
          }}
        >
          {`${t('MyPage.취소')}`}
        </RDSButton>
      </div>
    </div>
  );
};

export default WithdrawalNotice;
