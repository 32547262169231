import { ModalMaker } from '@reconlabs/reconlabs-fe-components';
import React, { useEffect, useState } from 'react';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import amplitude from '../../../utils/amplitude';
import Survey from './Survey';
import WithdrawalComplete from './WithdrawalComplete';
import WithdrawalNotice from './WithdrawalNotice';

const WithDrawalModal = ({ isVisible, onClickCancel }: { isVisible: boolean; onClickCancel: Function }) => {
  const [modalOrder, setModalOrder] = useState(1);

  const setModalNextStep = () => {
    setModalOrder(modalOrder + 1);
  };

  useEffect(() => {
    isVisible === false ? setModalOrder(1) : null;
  }, [isVisible]);

  const getAmplitudeEventName = (modalOrder: number) => {
    switch (modalOrder) {
      case 1:
        return amplitudeEvents.mypage.zero_mypage_withdrawal_guide_cancel;
      case 2:
        return amplitudeEvents.mypage.zero_mypage_withdrawal_survey_cancel;
      case 3:
        return amplitudeEvents.mypage.zero_mypage_withdrawal_success;
      default:
        return 'zero_mypage_withdrawal 모달 부분에서 에러 나는 중';
    }
  };

  return (
    <ModalMaker
      isVisible={isVisible}
      setIsVisible={() => {
        amplitude.sendEvent(getAmplitudeEventName(modalOrder));
        onClickCancel();
      }}
    >
      <div>
        {modalOrder === 1 && <WithdrawalNotice setModalNextStep={setModalNextStep} onClickCancel={onClickCancel} />}
        {modalOrder === 2 && <Survey setModalNextStep={setModalNextStep} onClickCancel={onClickCancel} />}
        {modalOrder === 3 && <WithdrawalComplete onClickCancel={onClickCancel} />}
      </div>
    </ModalMaker>
  );
};

export default WithDrawalModal;
