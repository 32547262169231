import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getModelViewer, useModelsList } from '../../../hooks/react-query/useThreeDModel';
import CatalogEditItem from './CatalogEditItem';
import { copyToClipboard } from '../../../utils/clipboardUtils';
import { useTranslation } from 'react-i18next';
import {
  CatalogPutParams,
  CatalogSaveItems,
  useCatalog,
  usePublishCatalog,
  useSaveCatalog,
} from '../../../hooks/react-query/useCatalog';
import {
  RDSToast,
  RDSIconButton,
  ChevronBackIcon,
  RDSIconTextButton,
  RDSButton,
  RDSInputField,
  RDSModal,
  RDSCard,
  useRDSCheckboxNumberType,
  OpenModelIcon,
  AddIcon,
  DragIndicatorIcon,
  SaveIcon,
  PublishIcon,
  RDSNotificationTooltip,
  AlertErrorIcon,
  RDSCapsuleButton,
  RDSDropdown,
  RDSOption,
  RDSToastAlertObject,
  RDSSwitch,
} from '@reconlabs/reconlabs-fe-components';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import { updateModel } from '../../../hooks/react-query/useUpdateThreeDModel';

export type CatalogItem = {
  description: string | null;
  model_uid: string;
  name: string;
  // 카탈로그 아이템의 판매 링크
  sales_url: string | null;
  // 모델의 원본 판매 링크
  model_sales_url: string;
  viewer_data: ViewerData;
  viewer_uid?: string;
};

type ViewerData = {
  enableVerticalPlacement: boolean;
  isPublished: boolean;
  modelGlbURL: string;
  modelName: string;
  modelUsdzURL: string;
  resizable: boolean;
  salesURL: string;
};

const CatalogDetail = () => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  let includeModelsCount = 0;

  // const
  const { t } = useTranslation();
  const location = useLocation();
  const catalog_uid = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const navigate = useNavigate();
  const isChanged = useRef(false);

  // boolean
  const [openModelsModal, setOpenModelsModal] = useState<boolean>(false);
  const [openLinkSyncModal, setOpenLinkSyncModal] = useState<boolean>(false);
  const [openPublishCompleteModal, setOpenPublishCompleteModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openChangeOrderModal, setOpenChangeOrderModal] = useState<boolean>(false);
  const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);
  const [openNotPublishedModal, setOpenNotPublishedModal] = useState<boolean>(false);
  const [isEqualCatalogItems, setIsEqualCatalogItems] = useState<boolean>(true);
  const [isEqualCatalogName, setIsEqualCatalogName] = useState<boolean>(true);
  const [isEqualCatalogCompany, setIsEqualCatalogCompany] = useState<boolean>(true);
  const [isEqualPublishLocale, setIsEqualPublishLocale] = useState<boolean>(true);
  const [isEqualShowLink, setIsEqualShowLink] = useState<boolean>(true);
  const [dragOver, setDragOver] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [showSalesButton, setShowSalesButton] = useState<boolean>(true);

  // rds function
  const { checkedArr, setCheckedArr, getCheckedNum, handleCheckClick } = useRDSCheckboxNumberType();

  // spinner
  const [spinner, setSpinner] = useState<string>('');

  // internal state
  const [catalogName, setCatalogName] = useState<string>('');
  const [edittedCatalogName, setEdittedCatalogName] = useState<string>(catalogName);
  const [companyName, setCompanyName] = useState<string>('');
  const [edittedCompanyName, setEdittedCompanyName] = useState<string>(companyName);
  const [catalogItems, setCatalogItems] = useState<CatalogItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<CatalogItem[]>([]);
  const [publishUrl, setPublishUrl] = useState<string>('');
  const [deleteType, setDeleteType] = useState<'all' | 'select'>('select');
  const [droppedModelUid, setDroppedModelUid] = useState<string>('');
  const [publishLocale, setPublishLocale] = useState<string>(window.locale);
  const [notPublishedModels, setNotPublishedModels] = useState<any[]>([]);
  const [copyToast, setCopyToast] = useState<RDSToastAlertObject>();

  // apis
  const { data: catalogData, isSuccess, refetch: catalogDataRefetch } = useCatalog(catalog_uid);
  const { models, setPaginationParam, count, isSuccess: modelListIsSuccess } = useModelsList({ range: [0, 49] });
  const { mutateAsync: saveCatalogMutate } = useSaveCatalog();
  const { mutateAsync: publishCatalogMutate } = usePublishCatalog();
  /*----------------------------------------
                    Life Cycle
   ----------------------------------------*/
  // 예외적으로 해당 페이지만 상단에 고정된 header 를 가져서 스크롤 제어를 위해 해당 페이지에서만 wrapper div 스타일을 조정합니다.
  useEffect(() => {
    const wrapperEl = document.querySelector('div#root > div');
    wrapperEl?.setAttribute('style', 'height: 100%; overflow: hidden;');
    return () => {
      wrapperEl?.setAttribute('style', 'height: 100%;');
    };
  }, []);

  useEffect(() => {
    if (modelListIsSuccess && count > 50) {
      setPaginationParam({ range: [0, count - 1] });
    }
  }, [modelListIsSuccess]);

  useEffect(() => {
    const popstate = () => {
      history.pushState(null, '', location.pathname);
      setOpenSaveModal(true);
    };

    if (isChanged.current) {
      (() => {
        history.pushState(null, '', location.pathname);
        window.addEventListener('popstate', popstate);
      })();
    }

    return () => {
      window.removeEventListener('popstate', popstate);
    };
  }, [isChanged.current]);

  useEffect(() => {
    if (isSuccess) {
      setSpinner('');
      setCatalogName(catalogData.catalog_name);
      setCatalogItems(JSON.parse(JSON.stringify(catalogData.catalog_items)));
      setCompanyName(catalogData.catalog_metadata?.company_name);
      setPublishLocale(catalogData.catalog_lang);
      setShowSalesButton(catalogData.catalog_show_sales_url == 1);

      /*
       * catalogItems 는 reference 참조를 해서 useEffect 를 타지만 원시타입 state 들은
       * useEffect 에서 갱신된 데이터로 equal state 를 업데이트 해주지 못함
       */
      setIsEqualCatalogName(catalogName === catalogData.catalog_name);
      setIsEqualPublishLocale(publishLocale === catalogData.catalog_lang);
      setIsEqualShowLink(showSalesButton === (catalogData.catalog_show_sales_url == 1));
      comparisonCompanyName();
    } else {
      setSpinner('loading-spinner');
    }
  }, [catalogData]);

  useEffect(() => {
    if (isSuccess) {
      setIsEqualPublishLocale(publishLocale === catalogData.catalog_lang);
    }
  }, [publishLocale]);

  useEffect(() => {
    if (isSuccess) {
      if (showSalesButton) {
        setIsEqualShowLink(catalogData.catalog_show_sales_url == 1);
      } else {
        setIsEqualShowLink(catalogData.catalog_show_sales_url == 0);
      }
    }
  }, [showSalesButton]);

  useEffect(() => {
    if (isSuccess) {
      setIsEqualCatalogItems(JSON.stringify(catalogData.catalog_items) === JSON.stringify(catalogItems));
    }
  }, [catalogItems]);

  useEffect(() => {
    if (isSuccess) {
      setIsEqualCatalogName(catalogName === catalogData.catalog_name);
    }
  }, [catalogName]);

  useEffect(() => {
    if (isSuccess) {
      comparisonCompanyName();
    }
  }, [companyName]);
  /*----------------------------------------
                  Business Logic
   ----------------------------------------*/
  const addModels = async (arr: number[]) => {
    const catalogItemList: CatalogItem[] = [...catalogItems];
    setOpenModelsModal(false);
    setSpinner('loading-spinner');

    for (let i = 0; i < arr.length; i++) {
      const selectedModel = models[arr[i]];
      const viewerData = await getModelViewer({ viewer_uid: selectedModel.viewer_uid });
      const selectedCatalogItemData: CatalogItem = {
        description: '',
        model_uid: selectedModel.uid,
        name: selectedModel.model_name,
        viewer_data: viewerData.data,
        sales_url: selectedModel.model_sales_url,
        model_sales_url: selectedModel.model_sales_url,
      };
      catalogItemList.push(selectedCatalogItemData);
    }

    if (!(catalogItems.length === catalogItemList.length)) {
      isChanged.current = true;
    }

    setCatalogItems(catalogItemList);
    setCheckedArr([]);
    setSpinner('');
  };

  const deleteAllItems = () => {
    setOpenDeleteModal(false);
    setCatalogItems([]);
  };

  const deleteSelectedItems = () => {
    setOpenDeleteModal(false);
    setCatalogItems((prevItems: CatalogItem[]) => {
      let newItems: CatalogItem[] = [];
      prevItems.forEach((prevItem) => {
        if (selectedItems.indexOf(prevItem) === -1) {
          newItems.push(prevItem);
        }
      });

      return newItems;
    });
  };

  const saveCatalog = async (tempSave: boolean = false) => {
    setSpinner('loading-spinner');
    const saveItems: CatalogSaveItems[] = [];

    catalogItems.forEach((item) => {
      saveItems.push({
        model_uid: item.model_uid,
        item_name: item.name,
        item_description: item.description ? item.description : '',
        item_sales_url: item.sales_url ? item.sales_url : '',
      });
    });

    const catalogPutData: CatalogPutParams = {
      catalog_uid,
      catalog_memo: '',
      catalog_metadata: {
        company_name: companyName,
      },
      catalog_name: catalogName,
      catalog_items: saveItems,
      catalog_lang: publishLocale,
      catalog_show_sales_url: showSalesButton,
    };

    try {
      await saveCatalogMutate(catalogPutData);
      setSpinner('');
      setIsSaved(true);
      catalogDataRefetch();
      if (tempSave) {
        navigate('/dashboard/catalog');
      }
      return { result: 'success' };
    } catch (e: any) {
      setSpinner('');
      const notPublished = e.response.data.invalidModels.notPublished;
      setNotPublishedModels(notPublished);
      setOpenNotPublishedModal(true);
      return { result: 'failed' };
    }
  };

  const publishCatalog = async () => {
    setSpinner('loading-spinner');

    if (!(isEqualCatalogItems && isEqualCatalogName && isEqualCatalogCompany && isEqualPublishLocale)) {
      const res = await saveCatalog();
      if (res.result === 'failed') {
        return;
      }
    }

    const result = await publishCatalogMutate(catalog_uid);
    if (result.status === 200) {
      setPublishUrl(`${process.env.REACT_APP_CATALOG_PUBLISH_DOMAIN}/${result.data.data.catalog_published_uid}`);
      setOpenPublishCompleteModal(true);
      setIsPublished(true);
    }

    setSpinner('');
  };

  const copyPublishUrl = () => {
    copyToClipboard(publishUrl);
  };

  const comparisonCompanyName = () => {
    const originCompanyName = catalogData.catalog_metadata?.company_name;
    // undefined 과 '' 을 같은 상태로 간주함
    if (!companyName && !originCompanyName) {
      setIsEqualCatalogCompany(true);
    } else {
      setIsEqualCatalogCompany(companyName === catalogData.catalog_metadata?.company_name);
    }
  };

  const getCatalogItems = (droppedModelUid: string, uid: string) => {
    if (droppedModelUid !== uid) {
      setCatalogItems((prevItems) => {
        const newItems: CatalogItem[] = [];
        prevItems.forEach((prevItem) => {
          if (prevItem.model_uid !== droppedModelUid && prevItem.model_uid !== uid) {
            newItems.push(prevItem);
          } else if (prevItem.model_uid === droppedModelUid) {
            newItems.push(prevItems.find((newItem) => newItem.model_uid === uid)!);
          } else if (prevItem.model_uid === uid) {
            newItems.push(prevItems.find((newItem) => newItem.model_uid === droppedModelUid)!);
          }
        });

        return newItems;
      });
    }
  };

  const getThumbnailUrl = (modelUid: string) => {
    return models.find((model) => model.uid === modelUid)?.latest_transforms.png.url;
  };

  const navigateBack = () => {
    if (!(isEqualCatalogItems && isEqualCatalogName && isEqualCatalogCompany && isEqualPublishLocale)) {
      setOpenSaveModal(true);
    } else {
      navigate('/dashboard/catalog');
    }
  };

  const getNotPublishedModels = () => {
    return (
      <div>
        {notPublishedModels.map((model) => {
          return <div key={model.uid}>{model.model_name}</div>;
        })}
      </div>
    );
  };

  const tempSave = async () => {
    setOpenSaveModal(false);
    await saveCatalog(true);
  };

  const overWriteLink = async () => {
    setOpenLinkSyncModal(false);
    setSpinner('loading-spinner');

    const updateModelUrlItems: CatalogItem[] = catalogItems.filter(
      (item: CatalogItem) => item.sales_url !== item.model_sales_url,
    );
    // 모델 sales url 업데이트 api 요청
    updateModelUrlItems.forEach((item: CatalogItem) => {
      updateModel({
        uid: item.model_uid,
        model_name: item.viewer_data.modelName,
        model_memo: '',
        model_sales_url: item.sales_url ? item.sales_url : '',
        model_published: item.viewer_data.isPublished ? 1 : 0,
      });
    });

    setSpinner('');
    await publishCatalog();
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <RDSToast.Overlay
        type="loadingSpinner"
        toastOverlayId="loading-spinner"
        openedToastOverlay={spinner}
        setOpenedToastOverlay={setSpinner}
      />
      <RDSToast.AlertArea openedAlert={copyToast} customStyle={{ top: 'auto', bottom: 16 }} />
      <RDSModal
        customStyle={{
          '.RDSModal': {
            width: '776px',
          },
        }}
        open={openModelsModal}
        onClose={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_import_modal_close);
          setOpenModelsModal(false);
        }}
        enableCloseButton={false}
        buttonType="noBox"
        title={`${t('Catalog.불러오기')}`}
        supportingText={
          <>
            <div>{`${t('Catalog.카탈로그에 삽입하고자 하는 3D 파일을 순서대로 클릭해 주세요.')}`}</div>
            <div className="ModelsWrapper">
              {models &&
                models.length > 0 &&
                models.map((model, index) => {
                  let isRender: boolean = true;
                  catalogItems.forEach((item) => {
                    if (item.model_uid === model.uid) {
                      isRender = false;
                      includeModelsCount++;
                    }
                  });
                  return isRender ? (
                    <RDSCard.CheckBox
                      key={model.uid}
                      title={model.model_name}
                      supportingText={model.modified_date}
                      checkedNum={getCheckedNum(index)}
                      handleCheck={() => handleCheckClick(index)}
                      imageSrc={model.latest_transforms.png.url}
                      customStyles={{
                        ['div.RDSCard__checkbox__dim__btns']: {
                          display: 'none',
                        },
                      }}
                    />
                  ) : (
                    <React.Fragment key={model.uid}></React.Fragment>
                  );
                })}
            </div>
            {includeModelsCount === models.length && (
              <div className="NoModels">
                {`${t('Catalog.불러올 모델이 없습니다.')}`} <br />
                {`${t('Catalog.3D 모델을 먼저 업로드해주세요!')}`}
              </div>
            )}
          </>
        }
        button1Label={
          includeModelsCount === models.length ? (
            ''
          ) : (
            <div data-test-id="load-model-btn">{`${t('Catalog.불러오기2')}`}</div>
          )
        }
        button1Fn={() => {
          // amplitude -------
          if (checkedArr.length > 0) {
            amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_import_modal_execute, {
              quantity: checkedArr.length,
            });
          }
          // -----------------
          addModels(checkedArr);
        }}
        button2Label={t('Catalog.닫기')}
        button2Fn={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_import_modal_close);
          setOpenModelsModal(false);
        }}
      />
      <RDSModal
        open={openPublishCompleteModal}
        onClose={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_publish_modal_run_close);
          setOpenPublishCompleteModal(false);
        }}
        enableCloseButton={false}
        title={`${t('Catalog.3D 카탈로그 발행 완료')}`}
        supportingText={
          <>
            {`${t('Catalog.3D 카탈로그 발행이 완료되었습니다.')}`} <br />
            {`${t('Catalog.아래 카탈로그 URL로 3D/AR 뷰어를 간편하게 공유해보세요!')}`}
          </>
        }
        linkText={publishUrl}
        buttonType="noBox"
        button1Label={t('Catalog.복사하기')}
        button1Fn={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_publish_modal_run_linkcopy);
          copyPublishUrl();
          setOpenPublishCompleteModal(false);
          setCopyToast({ type: 'success', message: t('Catalog.복사가 완료되었습니다!') });
        }}
        button2Label={t('Catalog.닫기')}
        button2Fn={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_publish_modal_run_close);
          setOpenPublishCompleteModal(false);
        }}
      />
      <RDSModal
        open={openLinkSyncModal}
        onClose={() => setOpenLinkSyncModal(false)}
        enableCloseButton={false}
        title={`${t('Catalog.판매 링크를 덮어쓸까요?')}`}
        supportingText={
          <>
            {`${t(
              'Catalog.카탈로그에 입력된 일부 판매 페이지 링크가 이전에 모델 관리 페이지에 입력된 주소와 다릅니다. 모두 같은 링크로 덮을까요?',
            )}`}
          </>
        }
        button1Label={`${t('Catalog.덮어쓰기')}`}
        button1Fn={() => overWriteLink()}
        button2Label={`${t('Catalog.별도로 관리할게요')}`}
        button2Fn={() => {
          publishCatalog();
          setOpenLinkSyncModal(false);
        }}
      />
      <RDSModal
        open={openDeleteModal}
        onClose={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_model_delete_modal_cancel);
          setOpenDeleteModal(false);
        }}
        enableCloseButton={false}
        title={`${t('Catalog.3D 모델을 삭제하시겠습니까?')}`}
        supportingText={`${t('Catalog.카탈로그에서 삭제한 3D 모델은 전체모델에서 다시 불러올 수 있습니다.')}`}
        button1Color="warning"
        button1Label={t('Catalog.삭제')}
        button1Fn={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_model_delete_modal_execute);
          deleteType === 'select' ? deleteSelectedItems() : deleteAllItems();
        }}
        button2Label={t('Catalog.취소')}
        button2Fn={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_model_delete_modal_cancel);
          setOpenDeleteModal(false);
        }}
      />
      <RDSModal
        open={openSaveModal}
        onClose={() => setOpenSaveModal(false)}
        enableCloseButton={false}
        title={`${t('Catalog.편집된 내용을 임시저장 하시겠습니까?')}`}
        supportingText={`${t('Catalog.저장하지 않고 종료할 경우 편집된 내용이 삭제됩니다.')}`}
        button1Label={t('Catalog.임시 저장하기')}
        button1Fn={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_pageout_modal_temporary_storage);
          tempSave();
        }}
        button2Label={t('Catalog.저장하지 않고 종료')}
        button2Fn={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_pageout_modal_nosave);
          setOpenSaveModal(false);
          navigate('/dashboard/catalog');
        }}
      />
      <RDSModal
        open={openNotPublishedModal}
        onClose={() => setOpenNotPublishedModal(false)}
        enableCloseButton={false}
        title={'발행되지 않은 모델'}
        supportingText={`아래 모델들이 발행되지 않았으므로 저장에 실패하였습니다.`}
        linkText={getNotPublishedModels()}
        button1Label={t('MyPage.확인')}
        button1Fn={() => setOpenNotPublishedModal(false)}
      />
      <RDSModal
        open={openNotPublishedModal}
        onClose={() => setOpenNotPublishedModal(false)}
        enableCloseButton={false}
        title={'발행되지 않은 모델'}
        supportingText={`아래 모델들이 발행되지 않았으므로 저장에 실패하였습니다.`}
        linkText={getNotPublishedModels()}
        button1Label={t('MyPage.확인')}
        button1Fn={() => setOpenNotPublishedModal(false)}
      />
      <RDSModal
        open={openChangeOrderModal}
        onClose={() => setOpenChangeOrderModal(false)}
        enableCloseButton={false}
        title={`${t('Catalog.순서 변경')}`}
        supportingText={
          <div>
            <div>{`${t('Catalog.순서를 간편하게 변경하세요.')}`}</div>
            <div
              style={{
                marginTop: '12px',
                borderTop: '1px solid rgba(150, 150, 153, 0.4)',
              }}
            >
              {catalogItems &&
                catalogItems.length >= 2 &&
                catalogItems.map((catalogItem) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 20px',
                        borderBottom: '1px solid rgba(150, 150, 153, 0.4)',
                      }}
                      key={catalogItem.model_uid}
                      data-uid={catalogItem.model_uid}
                      draggable
                      onDragOver={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onDrop={(e: any) => setDroppedModelUid(e.target.getAttribute('data-uid'))}
                      onDragEnd={(e: any) => {
                        amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_sequence_control);
                        getCatalogItems(droppedModelUid, e.target.getAttribute('data-uid'));
                      }}
                    >
                      <DragIndicatorIcon
                        style={{ pointerEvents: dragOver ? 'none' : 'auto' }}
                        onDragOver={() => setDragOver(true)}
                      />
                      <div
                        style={{ marginLeft: '10px', pointerEvents: dragOver ? 'none' : 'auto' }}
                        onDragOver={() => setDragOver(true)}
                      >
                        {catalogItem.name}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        }
      />
      <div className="CatalogDetailHeader">
        <div className="CatalogDetailHeader__Left">
          <RDSIconButton
            data-test-id="back-icon"
            type="noBox"
            size="small"
            icon={<ChevronBackIcon />}
            customStyle={{ marginLeft: '30px' }}
            onClick={() => navigateBack()}
          />
          <RDSButton
            type="solid"
            color="white"
            size="small"
            customStyle={{ marginLeft: '46px', width: '88px' }}
            disabled={catalogItems.length < 2}
            onClick={() => {
              amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_sequence);
              setOpenChangeOrderModal(true);
            }}
          >
            {`${t('Catalog.순서 변경')}`}
          </RDSButton>
          <RDSButton
            type="solid"
            color="white"
            size="small"
            customStyle={{ marginLeft: '16px', width: '108px' }}
            onClick={() => {
              amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_import);
              setOpenModelsModal(true);
            }}
          >
            {`${t('Catalog.모델 불러오기')}`}
          </RDSButton>
        </div>
        <div className="CatalogDetailHeader__Right">
          <RDSButton
            type="noBox"
            size="small"
            disabled={
              catalogItems.length === 0 ||
              !(
                isEqualCatalogItems &&
                isEqualCatalogName &&
                isEqualCatalogCompany &&
                isEqualPublishLocale &&
                isEqualShowLink
              )
            }
            onClick={() => {
              amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_preview);
              window.open(
                `${process.env.REACT_APP_PLICAR_ZERO_PROTOCOL}://${process.env.REACT_APP_PLICAR_ZERO_DOMAIN}:${process.env.REACT_APP_PLICAR_ZERO_PORT}/dashboard/catalog/preview/${catalog_uid}?locale=${publishLocale}`,
                '_blank',
              );
            }}
          >
            {`${t('Catalog.미리보기')}`}
          </RDSButton>
          {isSaved && !isPublished ? (
            <RDSNotificationTooltip
              id="catalog_viewer_tooltip"
              serviceName="plicar-zero"
              position="bottom"
              enableClose
              content={
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <AlertErrorIcon />
                  <div style={{ marginLeft: '8px' }}>
                    {`${t('Catalog.카탈로그 저장 완료!')}`}
                    <br />
                    {`${t('Catalog.‘발행하기’를 눌러 카탈로그를 업데이트 해주세요.')}`}
                  </div>
                </div>
              }
              customStyle={{ width: '330px', maxWidth: '330px' }}
            >
              <RDSIconTextButton
                type="outline"
                size="small"
                icon={<SaveIcon />}
                customStyle={{ marginLeft: '16px', width: '192px' }}
                disabled={
                  isEqualCatalogItems &&
                  isEqualCatalogName &&
                  isEqualCatalogCompany &&
                  isEqualPublishLocale &&
                  isEqualShowLink
                }
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_save);
                  saveCatalog();
                }}
              >
                {`${t('Catalog.저장하기')}`}
              </RDSIconTextButton>
            </RDSNotificationTooltip>
          ) : (
            <RDSIconTextButton
              type="outline"
              size="small"
              icon={<SaveIcon />}
              customStyle={{ marginLeft: '16px', width: '192px' }}
              disabled={
                isEqualCatalogItems &&
                isEqualCatalogName &&
                isEqualCatalogCompany &&
                isEqualPublishLocale &&
                isEqualShowLink
              }
              onClick={() => {
                amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_save);
                saveCatalog();
              }}
            >
              {`${t('Catalog.저장하기')}`}
            </RDSIconTextButton>
          )}

          {catalogItems && catalogItems.length === 0 ? (
            <RDSNotificationTooltip
              id="catalog_publish_disabled_tooltip"
              serviceName="plicar-zero"
              position="bottom"
              content={
                <>
                  <div>{`${t('Catalog.3D 모델을 불러와 카탈로그를 완성해주세요.')}`}</div>
                </>
              }
            >
              <RDSIconTextButton
                type="solid"
                size="small"
                icon={<PublishIcon />}
                customStyle={{ marginLeft: '16px', width: '192px' }}
                disabled={catalogItems.length === 0}
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_publish);
                  if (
                    catalogItems.filter(
                      (item: CatalogItem) =>
                        !!item.sales_url !== !!item.model_sales_url && item.sales_url !== item.model_sales_url,
                    ).length > 0
                  ) {
                    setOpenLinkSyncModal(true);
                  } else {
                    publishCatalog();
                  }
                }}
              >
                {`${t('Catalog.발행하기')}`}
              </RDSIconTextButton>
            </RDSNotificationTooltip>
          ) : (
            <RDSIconTextButton
              type="solid"
              size="small"
              icon={<PublishIcon />}
              customStyle={{ marginLeft: '16px', width: '192px' }}
              disabled={catalogItems.length === 0}
              onClick={() => {
                amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_publish);
                if (
                  catalogItems.filter(
                    (item: CatalogItem) =>
                      !!item.sales_url !== !!item.model_sales_url && item.sales_url !== item.model_sales_url,
                  ).length > 0
                ) {
                  setOpenLinkSyncModal(true);
                } else {
                  publishCatalog();
                }
              }}
            >
              {`${t('Catalog.발행하기')}`}
            </RDSIconTextButton>
          )}
        </div>
      </div>
      <div id="CatalogDetail" className="CatalogDetail">
        <RDSCapsuleButton scrollWrapperId="CatalogDetail" />
        <div>
          {catalogItems.length > 0 ? (
            <>
              <div className="CatalogDetail__Options">
                <div className="CatalogDetail__Options__Sales">
                  <div className="CatalogDetail__Options__Sales__Title">
                    {`${t('Catalog.판매 페이지 버튼 연결')}`}
                    <RDSSwitch isOn={showSalesButton} handleClick={() => setShowSalesButton((prev) => !prev)} />
                  </div>
                  <div className="CatalogDetail__Options__Sales__Sub">
                    {`${t('Catalog.온라인 판매 중인 상품 판매 페이지로 링크를 연결시켜')}`}
                    <br />
                    {`${t('Catalog.빠른 구매를 유도할 수 있습니다.')}`}
                  </div>
                </div>
                <div className="CatalogDetail__Options__i18n">
                  <div className="CatalogDetail__Options__i18n__Title">
                    {`${t('Catalog.발행 언어')}`}
                    <RDSDropdown
                      size="small"
                      customStyles={{
                        width: '192px',
                      }}
                      onChange={(value) => {
                        amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_language, {
                          type: value === 'ko' ? 'korean' : 'english',
                        });
                        setPublishLocale(value);
                      }}
                      value={publishLocale}
                    >
                      <React.Fragment key=".0">
                        <RDSOption value="ko">한국(한국어)</RDSOption>
                        <RDSOption value="en">USA(English)</RDSOption>
                      </React.Fragment>
                    </RDSDropdown>
                  </div>
                  <div className="CatalogDetail__Options__i18n__Subtext">
                    {`${t('Catalog.발행 언어에 따라 AR 버튼, 3D 뷰어 사용 가이드의 언어가 변경됩니다.')}`}{' '}
                    {`${t('Catalog.직접 작성하신 내용의 언어는 변경되지 않습니다')}`}
                  </div>
                </div>
              </div>

              <div className="CatalogDetail__Wrapper">
                <RDSInputField.Underline
                  displayValue={catalogName}
                  inputValue={edittedCatalogName}
                  maxLength={30}
                  handleInputChange={(e) => setEdittedCatalogName(e)}
                  handleOnSave={() => {
                    amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_title);
                    setCatalogName(edittedCatalogName);
                    isChanged.current = true;
                  }}
                  handleReset={() => setEdittedCatalogName('')}
                />
                <RDSInputField.Underline
                  displayValue={companyName ? companyName : `${t('기업명(연락처)')}`}
                  inputValue={edittedCompanyName}
                  maxLength={20}
                  handleInputChange={(e) => setEdittedCompanyName(e)}
                  handleOnSave={() => {
                    amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_source);
                    setCompanyName(edittedCompanyName);
                    isChanged.current = true;
                  }}
                  handleReset={() => setEdittedCompanyName('')}
                />
              </div>
              <div className="CatalogDetail__Buttons">
                <RDSButton
                  type="outline"
                  color="grey"
                  size="small"
                  onClick={() => {
                    amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_delete_selection);
                    setDeleteType('select');
                    setOpenDeleteModal(true);
                  }}
                >
                  {`${t('Catalog.선택 삭제2')}`}
                </RDSButton>
                <RDSButton
                  type="noBox"
                  color="grey"
                  size="small"
                  customStyle={{ marginLeft: '16px' }}
                  onClick={() => {
                    amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_delete_all);
                    setDeleteType('all');
                    setOpenDeleteModal(true);
                  }}
                >
                  {`${t('Catalog.전체 삭제')}`}
                </RDSButton>
              </div>
              <div className="CatalogDetail__Contents">
                <div className="CatalogDetail__Contents__Wrapper">
                  {catalogItems.length > 0 &&
                    catalogItems.map((item: CatalogItem, index) => {
                      return (
                        <CatalogEditItem
                          key={item.model_uid}
                          showSalesButton={showSalesButton}
                          index={index}
                          item={item}
                          setSelectedItems={setSelectedItems}
                          setCatalogItems={setCatalogItems}
                          deleteSelectedItems={deleteSelectedItems}
                          thumbnailUrl={getThumbnailUrl(item.model_uid)}
                          setIsChanged={() => {
                            isChanged.current = true;
                          }}
                        />
                      );
                    })}
                  <div className="CatalogDetail__Contents__Wrapper__AddModels">
                    <RDSIconTextButton
                      icon={<AddIcon />}
                      type="noBox"
                      onClick={() => {
                        amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_addmodel);
                        setOpenModelsModal(true);
                      }}
                    >
                      {`${t('Catalog.3D 모델 불러오기')}`}
                    </RDSIconTextButton>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="CatalogDetail__Wrapper">
                <RDSInputField.Underline
                  displayValue={catalogName}
                  inputValue={edittedCatalogName}
                  maxLength={30}
                  handleInputChange={(e) => setEdittedCatalogName(e)}
                  handleOnSave={() => {
                    amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_title);
                    setCatalogName(edittedCatalogName);
                  }}
                  handleReset={() => setEdittedCatalogName('')}
                />
                <RDSInputField.Underline
                  displayValue={companyName ? companyName : `${t('기업명(연락처)')}`}
                  inputValue={edittedCompanyName}
                  maxLength={20}
                  handleInputChange={(e) => setEdittedCompanyName(e)}
                  handleOnSave={() => {
                    amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_source);
                    setCompanyName(edittedCompanyName);
                  }}
                  handleReset={() => setEdittedCompanyName('')}
                />
              </div>
              <div className="CatalogDetail__Wrapper">
                <div className="CatalogDetail__Wrapper__NoData">
                  <RDSIconTextButton
                    type="noBox"
                    icon={<OpenModelIcon />}
                    size="large"
                    onClick={() => {
                      amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_nohistory_import);
                      setOpenModelsModal(true);
                    }}
                  >
                    {`${t('Catalog.3D 모델 불러오기')}`}
                  </RDSIconTextButton>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CatalogDetail;
