import * as amplitude from '@amplitude/analytics-browser';

export type AmplitudeEvent = {
  name: string;
  props?: object | any;
};

class AmplitudeLoader {
  private _userId: string;
  private _userInfo: object;
  private _startTimeInMilliseconds: number | null;

  constructor() {
    this._userId = '';
    this._userInfo = {};
    this._startTimeInMilliseconds = null;
  }

  get userId() {
    return this._userId;
  }

  get userInfo() {
    return this._userInfo;
  }

  get startTimeInMilliseconds() {
    return this._startTimeInMilliseconds;
  }

  initialize(amplitudeKey: string, userId?: string, options?: amplitude.Types.BrowserOptions) {
    amplitude.init(amplitudeKey, userId, options);
    if (userId) this._userId = userId;
  }

  /**
   * If your app has its own login system that you want to track users with, you can call setUserId at any time.
   * 
   * https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/#custom-user-id
   * @param userId custom으로 세팅하고 싶은 user id
   */
  setUserId(userId: string) {
    amplitude.setUserId(userId);
    this._userId = userId;
  }

  /**
   * "Set a user property"
   * User Properties에 원하는 key, value를 넣어서 사용자 정보를 저장할 수 있습니다.
   * 
   * https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/#set-a-user-property
   * @param key 
   * @param info 
   */
  setUserInfo(key: string, info: string) {
    const userInfo = new amplitude.Identify();
    userInfo.set(key, info);
    amplitude.identify(userInfo);

    this._userInfo = { ...this.userInfo, key: info };
  }

  /**
   * 동시에 여러 이벤트 track이 발생하면, amplitude가 이벤트를 쌓아서 보냅니다.
   * @param eventName aka event_type
   * @param eventProps aka event_properties
   */
  sendEvent(eventName: string, eventProps?: object) {
    if (eventProps) {
      amplitude.track(eventName, eventProps);
    } else {
      amplitude.track(eventName);
    }
  }

  async sendAsyncEvent(eventName: string, eventProps?: object) {
    if (eventProps) {
      const result = await amplitude.track(eventName, eventProps).promise;
      return result;
    } else {
      const result = await amplitude.track(eventName).promise;
      return result;
    }
  }

  setStartTime(timeInMilliseconds: number) {
    this._startTimeInMilliseconds = timeInMilliseconds;
  }

  getDuration(timeInMilliseconds: number) {
    if (this._startTimeInMilliseconds !== null) {
      return timeInMilliseconds - this._startTimeInMilliseconds;
    }
  }

  reset() {
    console.log("Amplitude reset")
    amplitude.reset();
  }
}

export default new AmplitudeLoader();

export const changeSignupTag = (tag: string) => {
  switch (tag) {
    case '모델 뷰어(3D)':
      return '3d_viewer';
    case '증강 현실(AR)':
      return 'ar';
    case '광고':
      return 'advertisement';
    case '가상 현실(VR)':
      return 'vr';
    case 'e-커머스':
      return 'ecommerce';
    case '웹페이지':
      return 'website';
    case '인쇄 홍보물':
      return 'printed_catalog';
    case '디지털 카탈로그':
      return 'digital_catalog';
    default:
      return tag;
  }
};

export const changeCatalogListFilter = (filter: string) => {
  switch (filter) {
    case 'default':
      return 'default';
    case 'saved':
      return 'temporary';
    case 'published':
      return 'published';
    case 'not_published':
      return 'halt';
    default:
      return 'default';
  }
};

export const getAmplitudePageViewEventName = (pathName: string) => {
  // 피그마 데이터 로깅 페이지에 명시된 페이지 뷰 이벤트만 기록합니다.
  switch (pathName) {
    case '/dashboard/upload':
      return 'zero_allmodel_nohistory';
    case '/dashboard/models':
      return 'zero_allmodel_list';
    case '/dashboard/upload/result':
      return 'zero_uploaded';
    case '/dashboard/model/detail':
      return 'zero_management';
    case '/dashboard/mypage':
      return 'zero_mypage';
    case '/login':
      return 'zero_signin';
    case '/agreement':
      return 'zero_signup';
    case '/dashboard/catalog':
      return 'zero_catalog_list';
    default:
      if (/^\/dashboard\/catalog\/.+$/.test(pathName)) {
        return 'zero_catalog_editor';
      }
      return 'error';
  }
};
