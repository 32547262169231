import React, { ReactNode, useContext, useState } from 'react';
import { multipartUploadApi } from '../../../apis/upload';
import { FileObj, MultipartUploader, MultipartUploadOnEvent } from '../../../utils/MultipartUploader';
import { uuidv4 } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';

declare global {
  interface Window {
    i18n: Function;
    locale: string;
  }
}

const makeUploaderOnEvent = (setUploads: React.Dispatch<React.SetStateAction<UploadFile[]>>) => {
  const onEvent: MultipartUploadOnEvent = {
    onStart: (fileObj, uid) => {
      setUploads((prevUploadFiles) => {
        const updatedUploadFiles = prevUploadFiles.map((uploadfile) => {
          return uploadfile.id === fileObj.fileId
            ? ({ ...uploadfile, state: 'ongoing', uid } as UploadFile)
            : uploadfile;
        });
        return updatedUploadFiles;
      });
    },
    onUploadingPart: (fileObj, progress) => {
      setUploads((prevUploadFiles) => {
        const updatedUploadFiles = prevUploadFiles.map((uploadfile) => {
          return uploadfile.id === fileObj.fileId
            ? ({ ...uploadfile, progress, state: 'ongoing' } as UploadFile)
            : uploadfile;
        });
        return updatedUploadFiles;
      });
    },
    onCancel: (fileId) => {
      setUploads((prevUploadFiles) => {
        const updatedUploadFiles = prevUploadFiles.filter((uploadfile) => {
          return uploadfile.id !== fileId;
        });
        return updatedUploadFiles;
      });
    },
    onStop: (fileObj) => {
      setUploads((prevUploadFiles) => {
        const updatedUploadFiles = prevUploadFiles.map((uploadfile) => {
          return uploadfile.id === fileObj.fileId ? ({ ...uploadfile, state: 'stopped' } as UploadFile) : uploadfile;
        });
        return updatedUploadFiles;
      });
    },
    onComplete: (fileObj) => {
      // Amplitude: 모델 업로드 완료 시 완료 이벤트 전송
      amplitude.sendEvent(amplitudeEvents.bottomsheet.zero_allmodel_bottomsheet_list_success);

      setUploads((prevUploadFiles) => {
        const updatedUploadFiles = prevUploadFiles.map((uploadfile) => {
          return uploadfile.id === fileObj.fileId ? ({ ...uploadfile, state: 'complete' } as UploadFile) : uploadfile;
        });
        return updatedUploadFiles;
      });
    },
  };
  return onEvent;
};
// withCredentials: false

export type ControllerVideoMetaInfo = {
  movie_file_name: string; //"리콘랩스 데모 영상.mp4",
  movie_file_size: number; //211.4,
  movie_width: number; // 4096,
  movie_height: number; //2304,
  movie_create_date: Date; // "2022-02-15T15:27:24.000Z"
  movie_duration?: number; // 영상 길이는 DB에 저장하지 않음.
};

const Input = ({
  checkValidation,
  children,
}: {
  checkValidation: (
    file: File,
  ) => { result: boolean; state: UploadState } | Promise<{ result: boolean; state: UploadState }>;
  children: ({
    handleOnChange,
  }: {
    handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }) => JSX.Element;
}) => {
  const { uploadFiles, uploader } = useContext(RDSUploadContext);
  const setUploads = useContext(SetRDSUploadContext);
  // uploadFileToServer(uploadFiles, setUploads)
  const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length <= 10) {
      // TODO: 현재 업로드 가능 모델 count 를 가져와 해당 count 이상 file 업로드를 시도하는 경우 분기처리(state = overPlan)
      // fileObj = uploader 에 넣는용
      const fileObjs: FileObj[] = [];
      // newUploads는 progress 표시용
      let newUploads: UploadFile[] | Promise<UploadFile>[] = Array.from(files).map(async (file) => {
        const uuid = uuidv4();
        let uploadFile: UploadFile = { file, id: uuid };
        const checkResult = await checkValidation(file);
        if (checkResult.result) {
          uploadFile = { file, id: uuid, state: 'waiting' };
          fileObjs.push({ file, fileId: uuid });
        } else {
          if (checkResult.state === 'canceled') {
            amplitude.sendEvent(amplitudeEvents.bottomsheet.zero_allmodel_bottomsheet_list_error_capacity);
          }
          uploadFile = { file, id: uuid, state: checkResult.state };
        }
        return uploadFile;
      });
      newUploads = await Promise.all(newUploads);
      const updatedUploads = [...uploadFiles, ...newUploads];
      setUploads(updatedUploads); // 새로 추가된 영상들을 전역컨텍스트에 등록
      const onEvent = makeUploaderOnEvent(setUploads);
      uploader.setOnEvent(onEvent);
      // uploader에 files 추가
      uploader.addfilesQueue(fileObjs);

      //uploader가 돌고있으면 upload를 start하지 않는다
      if (uploader.state !== 'uploading' && uploader.state !== 'stopped' && uploader.state !== 'completing') {
        uploader.start();
      }
    } else if (files && files.length > 10) {
      const exceedState: UploadFile = {
        file: files[0],
        id: uuidv4,
        state: 'overCount',
      };
      setUploads([...uploadFiles, ...[exceedState]]);
    }

    // 업로드 프로세스가 종료된뒤 change 이벤트를 발생시킨 부모의 id로 바텀시트의 업로드 버튼인지 구분합니다.
    const eventTarget = event.target;
    const targetElId = eventTarget.getAttribute('id');

    // 바텀시트의 업로드 버튼을 통한 업로드 시도라면, 해당 아이템의 id를 찾아 제거합니다.
    if (targetElId === 'RDSUpload-input') {
      const removeTargetId = eventTarget.parentElement?.getAttribute('data-id');
      setUploads((prevUploads) => {
        const newUploads: UploadFile[] = [];
        prevUploads.forEach((item) => {
          if (item.id !== removeTargetId) {
            newUploads.push(item);
          }
        });
        return newUploads;
      });
    }
  };
  return (
    <>
      {typeof children === 'function'
        ? children({ handleOnChange }) // children is a render prop
        : children}
    </>
  );
};

const Progress = ({
  children,
}: {
  children: ({
    uploadFiles,
    abortUpload,
    stopUpload,
    restartUpload,
  }: {
    uploadFiles: UploadFile[];
    abortUpload: (uploadFile: UploadFile) => any;
    stopUpload: () => any;
    restartUpload: () => any;
  }) => JSX.Element;
}) => {
  const { uploadFiles, uploader } = useContext(RDSUploadContext);
  const abortUpload = async (uploadFile: UploadFile) => {
    console.log('aborting');
    await uploader.cancel(uploadFile.id);
  };
  const stopUpload = () => {
    console.log('stopping');
    uploader.stop();
  };
  const restartUpload = async () => {
    await uploader.restart();
  };

  return (
    <>
      {typeof children === 'function'
        ? children({ uploadFiles, abortUpload, stopUpload, restartUpload }) // children is a render prop
        : children}
    </>
  );
};
type RDSUploadContextType = { uploadFiles: UploadFile[]; uploader: MultipartUploader };
const uploader = new MultipartUploader(multipartUploadApi);

export const RDSUploadContext = React.createContext<RDSUploadContextType>({
  uploadFiles: [],
  uploader,
});

export const SetRDSUploadContext = React.createContext<React.Dispatch<React.SetStateAction<UploadFile[]>>>(() => {});
const RDSUpload = ({ children }: { children: JSX.Element | ReactNode }) => {
  const { i18n } = useTranslation();
  window.i18n = i18n.changeLanguage;
  window.locale = i18n.language;
  const currentUploadFiles = useContext(RDSUploadContext);
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>(currentUploadFiles.uploadFiles);
  return (
    <SetRDSUploadContext.Provider value={setUploadFiles}>
      <RDSUploadContext.Provider value={{ uploadFiles, uploader }}>{children}</RDSUploadContext.Provider>
    </SetRDSUploadContext.Provider>
  );
};
RDSUpload.Input = Input;
RDSUpload.Progress = Progress;

export type UploadState =
  | 'waiting' // 대기
  | 'ongoing' // 업로드중
  | 'complete' // 업로드 완료
  | 'viewerComplete' // 뷰어 생성 완료
  | 'invalidFile' // 지원하지 않는 파일 형식
  | 'invalidToast' // 파일의 크기가 0 이거나 이름만 glb 형식이며 실제 glb 파일이 아닌 경우 toast 처리
  | 'unknownFail' // 네트워크 에러
  | 'canceled' // 용량 초과
  | 'stopped'
  | 'overPlan' // 개수 할당량 초과
  | 'overCount'; // 개수 10개 초과
export type UploadFile = {
  id: any;
  state?: UploadState; // 'ongoing' | 'complete' | 'invalidFile' | 'unknownFail' | 'canceled' | 'stopped';
  progress?: number; // 0 ~ 100
  file: File;
  [key: string]: any;
};

export default RDSUpload;
