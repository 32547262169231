import React, { useEffect, useMemo, useRef, useState } from 'react';
import { STATIC_FILES_URL } from '../../constants/urls';
import { getModelViewerScriptSource } from '../../utils/createARScripts';
import { useThrottle, RDSChip } from '@reconlabs/reconlabs-fe-components';
import { useTranslation } from 'react-i18next';
import amplitude from '../../utils/amplitude';
import amplitudeEvents from '../../constants/amplitudeEvents';

type ModelViewerProps = {
  viewerData: string;
};

type ModelViewerSize = {
  x: number;
  y: number;
  z: number;
  toString: Function;
};

const hdrImage = {
  first: `${STATIC_FILES_URL}/images/hdr/plicar-studio.hdr`,
  second: `${STATIC_FILES_URL}/images/hdr/plicar-studio2.hdr`,
  studio: `${STATIC_FILES_URL}/images/hdr/hdr-studio.hdr`,
  neutral: `${STATIC_FILES_URL}/images/hdr/hdr-neutral.hdr`,
  outdoor: `${STATIC_FILES_URL}/images/hdr/hdr-outdoor.hdr`,
  indoor: `${STATIC_FILES_URL}/images/hdr/hdr-indoor.hdr`,
};

const MainPageThreeDModelViewer = (props: ModelViewerProps) => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const { t } = useTranslation();

  const [lightingMode, setLightingMode] = useState<'default' | 'studio' | 'neutral' | 'outdoor' | 'indoor' | 'hdr'>(
    'default',
  );
  const [measureMode, setMeasureMode] = useState<'none' | 'mm' | 'cm' | 'inch'>('none');
  const [size, setSize] = useState<ModelViewerSize>();
  const [aspectRatio, setAspectRatio] = useState<string>('16:9');
  const [pcHeight, setPcHeight] = useState<number>(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const environmentImage = useMemo(() => {
    switch (lightingMode) {
      case 'default':
        return 'neutral';
      case 'indoor':
        return hdrImage.indoor;
      case 'neutral':
        return hdrImage.neutral;
      case 'outdoor':
        return hdrImage.outdoor;
      case 'studio':
        return hdrImage.studio;
      case 'hdr':
        return hdrImage.second;
      default:
        return 'neutral';
    }
  }, [lightingMode]);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (document.querySelector('.plicarzero-viewer') === null) {
      const RDSPlicarViewerScript = document.createElement('script');
      RDSPlicarViewerScript.classList.add('plicarzero-viewer');
      RDSPlicarViewerScript.type = 'module';
      RDSPlicarViewerScript.src = getModelViewerScriptSource(); // TODO API Endpoint 변경 및 안정화시 plicar-viewer.min.js 로 변경
      document.head.appendChild(RDSPlicarViewerScript);
    }
  }, []);

  // measure indicator 세팅
  useEffect(() => {
    const modelViewer = document.querySelector('#Main__PlicarViewer');
    if (modelViewer !== null) {
      modelViewer.addEventListener('load', () => {
        //@ts-ignore
        const center = modelViewer.getBoundingBoxCenter();

        // const center = modelViewer.getCameraTarget();
        //@ts-ignore
        const size = modelViewer.getDimensions();
        setSize(size);

        const x2 = size.x / 2;
        const y2 = size.y / 2;
        const z2 = size.z / 2;

        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot+X-Y+Z',
          position: `${center.x + x2} ${center.y - y2} ${center.z + z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim+X-Y',
          position: `${center.x + x2} ${center.y - y2} ${center.z}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot+X-Y-Z',
          position: `${center.x + x2} ${center.y - y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim+X-Z',
          position: `${center.x + x2} ${center.y} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot+X+Y-Z',
          position: `${center.x + x2} ${center.y + y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim+Y-Z',
          position: `${center.x} ${center.y + y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot-X+Y-Z',
          position: `${center.x - x2} ${center.y + y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim-X-Z',
          position: `${center.x - x2} ${center.y} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot-X-Y-Z',
          position: `${center.x - x2} ${center.y - y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim-X-Y',
          position: `${center.x - x2} ${center.y - y2} ${center.z}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot-X-Y+Z',
          position: `${center.x - x2} ${center.y - y2} ${center.z + z2}`,
        });
        // update svg
        function drawLine(
          svgLine: SVGLineElement,
          dotHotspot1: any,
          dotHotspot2: any,
          dimensionHotspot: { facingCamera: any } | undefined,
        ) {
          if (svgLine == null) {
            return;
          }
          if (dotHotspot1 && dotHotspot1) {
            svgLine.setAttribute('x1', dotHotspot1.screenPosition.x);
            svgLine.setAttribute('y1', dotHotspot1.screenPosition.y);
            svgLine.setAttribute('x2', dotHotspot2.screenPosition.x);
            svgLine.setAttribute('y2', dotHotspot2.screenPosition.y);

            // use provided optional hotspot to tie visibility of this svg line to
            if (dimensionHotspot && !dimensionHotspot.facingCamera) {
              svgLine.classList.add('RDSPlicarViewer__hide');
            } else {
              svgLine.classList.remove('RDSPlicarViewer__hide');
            }
          }
        }

        const lines = modelViewer.querySelectorAll('line');

        // use requestAnimationFrame to update with renderer
        const startSVGRenderLoop = () => {
          drawLine(
            lines[0],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X-Y+Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X-Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim+X-Y'),
          );
          drawLine(
            lines[1],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X-Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X+Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim+X-Z'),
          );
          // @ts-ignore
          drawLine(
            lines[2],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X+Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X+Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim+Y-Z'),
          ); // always visible
          drawLine(
            lines[3],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X+Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X-Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim-X-Z'),
          );
          drawLine(
            lines[4],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X-Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X-Y+Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim-X-Y'),
          );
          requestAnimationFrame(startSVGRenderLoop);
        };

        startSVGRenderLoop();
      });
    }
  }, []);

  const setViewerHeight = () => {
    if (wrapperRef.current) {
      setPcHeight(wrapperRef.current.clientHeight);
    }
  };

  const throttleOnScroll = useThrottle(setViewerHeight, 300);

  useEffect(() => {
    setViewerHeight();
    window.addEventListener('resize', throttleOnScroll);
    return () => {
      window.removeEventListener('resize', throttleOnScroll);
    };
  }, []);

  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const convertMeter = (meter: number | any, resultLength: 'mm' | 'cm' | 'inch' | 'none') => {
    if (isNaN(meter) || typeof meter !== 'number') {
      return 0;
    }

    if (resultLength === 'cm') {
      return meter * 100;
    } else if (resultLength === 'inch') {
      return meter * 39.3701;
    } else if (resultLength === 'mm') {
      return meter * 1000;
    } else {
      return 0;
    }
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/

  return (
    <div style={{ pointerEvents: 'auto', marginTop: '16px' }}>
      <div onClick={(e) => e.stopPropagation()}>
        <div>
          <div
            ref={wrapperRef}
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              paddingBottom: '40%',
              position: 'relative',
              transition: 'all 150ms linear',
              borderRadius: '4px',
              zIndex: 1,
              aspectRatio: '1.7777777',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left:
                  aspectRatio === '16:9'
                    ? '0'
                    : aspectRatio === '1:1'
                    ? `calc((100% - ${pcHeight}px) / 2)`
                    : `calc((100% - ${(pcHeight / 16) * 9}px) / 2)`,
                zIndex: 1,
                width: aspectRatio === '16:9' ? '100%' : aspectRatio === '9:16' ? (pcHeight / 16) * 9 : pcHeight,
                transition: 'all 150ms linear',
                borderRadius: '4px',
                overflow: 'hidden',
                height: '100%',
                maxHeight: '100%',
              }}
            >
              {/* @ts-ignore */}
              <plicarzero-viewer
                id="Main__PlicarViewer"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '8px',
                  backgroundColor: '#FFFFFF',
                }}
                viewer-uid={''}
                viewer-data={props.viewerData}
                exposure="1"
                environment-image={environmentImage}
                camera-controls
                camera-orbit="25deg 75deg 105%"
                max-camera-orbit="Infinity 170deg 120%"
                min-camera-orbit="-Infinity 10deg auto"
              >
                {measureMode && (
                  <>
                    <button
                      slot="hotspot-dot+X-Y+Z"
                      className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="1 -1 1"
                      data-normal="1 0 0"
                    ></button>
                    <button
                      slot="hotspot-dim+X-Y"
                      className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="1 -1 0"
                      data-normal="1 0 0"
                    >
                      {size ? convertMeter(size.z, measureMode).toFixed(0) + ' ' + measureMode : ''}
                    </button>
                    <button
                      slot="hotspot-dot+X-Y-Z"
                      className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="1 -1 -1"
                      data-normal="1 0 0"
                    ></button>
                    <button
                      slot="hotspot-dim+X-Z"
                      className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="1 0 -1"
                      data-normal="1 0 0"
                    >
                      {size ? convertMeter(size.y, measureMode).toFixed(0) + ' ' + measureMode : ''}
                    </button>
                    <button
                      slot="hotspot-dot+X+Y-Z"
                      className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="1 1 -1"
                      data-normal="0 1 0"
                    ></button>
                    <button
                      slot="hotspot-dim+Y-Z"
                      className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="0 -1 -1"
                      data-normal="0 1 0"
                    >
                      {size ? convertMeter(size.x, measureMode).toFixed(0) + ' ' + measureMode : ''}
                    </button>
                    <button
                      slot="hotspot-dot-X+Y-Z"
                      className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="-1 1 -1"
                      data-normal="0 1 0"
                    ></button>
                    <button
                      slot="hotspot-dim-X-Z"
                      className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="-1 0 -1"
                      data-normal="-1 0 0"
                    >
                      {size ? convertMeter(size.y, measureMode).toFixed(0) + ' ' + measureMode : ''}
                    </button>
                    <button
                      slot="hotspot-dot-X-Y-Z"
                      className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="-1 -1 -1"
                      data-normal="-1 0 0"
                    ></button>
                    <button
                      slot="hotspot-dim-X-Y"
                      className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="-1 -1 0"
                      data-normal="-1 0 0"
                    >
                      {size ? convertMeter(size.z, measureMode).toFixed(0) + ' ' + measureMode : ''}
                    </button>
                    <button
                      slot="hotspot-dot-X-Y+Z"
                      className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="-1 -1 1"
                      data-normal="-1 0 0"
                    ></button>
                    <svg
                      id="lines"
                      xmlns="http://www.w3.org/2000/svg"
                      className="RDSPlicarViewer__dimensionLineContainer"
                    >
                      <line
                        className={` ${
                          measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                        }`}
                      ></line>
                      <line
                        className={` ${
                          measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                        }`}
                      ></line>
                      <line
                        className={` ${
                          measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                        }`}
                      ></line>
                      <line
                        className={` ${
                          measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                        }`}
                      ></line>
                      <line
                        className={` ${
                          measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                        }`}
                      ></line>
                    </svg>
                  </>
                )}
                {/* @ts-ignore */}
              </plicarzero-viewer>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: '16px',
            width: '100%',
            padding: '24px 32px',
            border: '4px solid #ffffff',
            borderRadius: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              fontFamily: 'Pretendard, sans-serif',
              fontWeight: '700',
              fontStyle: ' normal',
              fontSize: '16px',
              lineHeight: '30px',
              letterSpacing: '0.25px',
              color: '#000000DE',
            }}
          >
            <div style={window.locale === 'ko' ? { display: 'flex', gap: '24px' } : { display: 'flex', gap: '13px' }}>
              {`${t('Main.조명 설정')}`}
              <RDSChip
                chipStyle="outline"
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                    lighting: 'default',
                  });
                  setLightingMode('default');
                }}
                selected={lightingMode === 'default'}
              >
                {`${t('Detail.기본 조명')}`}
              </RDSChip>
              <RDSChip
                customStyle={
                  window.locale === 'ko'
                    ? { width: '98px', justifyContent: 'center' }
                    : { width: '104px', justifyContent: 'center' }
                }
                chipStyle="outline"
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                    lighting: 'studio',
                  });
                  setLightingMode('studio');
                }}
                selected={lightingMode === 'studio'}
              >
                {`${t('Detail.스튜디오 조명')}`}
              </RDSChip>
              <RDSChip
                customStyle={
                  window.locale === 'ko'
                    ? { width: '98px', justifyContent: 'center' }
                    : { width: '104px', justifyContent: 'center' }
                }
                chipStyle="outline"
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                    lighting: 'neutral',
                  });
                  setLightingMode('neutral');
                }}
                selected={lightingMode === 'neutral'}
              >
                {`${t('Detail.무색조명')}`}
              </RDSChip>
              <RDSChip
                customStyle={
                  window.locale === 'ko'
                    ? { width: '98px', justifyContent: 'center' }
                    : { width: '104px', justifyContent: 'center' }
                }
                chipStyle="outline"
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                    lighting: 'indoor',
                  });
                  setLightingMode('indoor');
                }}
                selected={lightingMode === 'indoor'}
              >
                {`${t('Detail.실내조명')}`}
              </RDSChip>
              <RDSChip
                customStyle={
                  window.locale === 'ko'
                    ? { width: '98px', justifyContent: 'center' }
                    : { width: '109px', justifyContent: 'center' }
                }
                chipStyle="outline"
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                    lighting: 'outdoor',
                  });
                  setLightingMode('outdoor');
                }}
                selected={lightingMode === 'outdoor'}
              >
                {`${t('Detail.실외조명')}`}
              </RDSChip>
            </div>
            <div style={{ display: 'flex', gap: '24px' }}>
              {`${t('Main.치수 정보')}`}
              <RDSChip
                chipStyle="outline"
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                    dimension: 'none',
                  });
                  setMeasureMode('none');
                }}
                selected={measureMode === 'none'}
              >
                {`${t('Detail.치수 숨김')}`}
              </RDSChip>
              <RDSChip
                chipStyle="outline"
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                    dimension: 'mm',
                  });
                  setMeasureMode('mm');
                }}
                selected={measureMode === 'mm'}
              >
                {`${t('Detail.밀리미터')} (mm)`}
              </RDSChip>
              <RDSChip
                customStyle={
                  window.locale === 'ko'
                    ? { width: '98px', justifyContent: 'center' }
                    : { width: '117px', justifyContent: 'center' }
                }
                chipStyle="outline"
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                    dimension: 'cm',
                  });
                  setMeasureMode('cm');
                }}
                selected={measureMode === 'cm'}
              >
                {`${t('Detail.센치미터')} (cm)`}
              </RDSChip>
              <RDSChip
                customStyle={{ width: '98px', justifyContent: 'center' }}
                chipStyle="outline"
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                    dimension: 'inch',
                  });
                  setMeasureMode('inch');
                }}
                selected={measureMode === 'inch'}
              >
                {`${t('Detail.인치')} (inch)`}
              </RDSChip>
            </div>
            <div style={{ display: 'flex', gap: '24px' }}>
              {`${t('Main.뷰어 비율')}`}
              <RDSChip
                customStyle={{ width: '70px', justifyContent: 'center' }}
                chipStyle="outline"
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                    ratio: '16:9',
                  });
                  setAspectRatio('16:9');
                }}
                selected={aspectRatio === '16:9'}
              >
                16:9
              </RDSChip>
              <RDSChip
                customStyle={{ width: '98px', justifyContent: 'center' }}
                chipStyle="outline"
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                    ratio: '9:16',
                  });
                  setAspectRatio('9:16');
                }}
                selected={aspectRatio === '9:16'}
              >
                9:16
              </RDSChip>
              <RDSChip
                customStyle={{ width: '98px', justifyContent: 'center' }}
                chipStyle="outline"
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.main.zero_main_example_3dviewer_settings, {
                    ratio: '1:1',
                  });
                  setAspectRatio('1:1');
                }}
                selected={aspectRatio === '1:1'}
              >
                1:1
              </RDSChip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPageThreeDModelViewer;
