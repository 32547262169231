import { createContext, memo, useState } from 'react';

export const DashboardContext = createContext({
  spinner: '',
  setSpinner: (id: string) => {},
  isOverUploadModalOpen: false,
  setIsOverUploadModalOpen: (isOpen: boolean) => {},
  remainingUploadCount: -1,
  setRemainingUploadCount: (count: number) => {},
  openUploadWindow: false,
  setOpenUploadWindow: (isOpen: boolean) => {},
});

const DashboardContextProvider = (props: any) => {
  const [spinner, setSpinner] = useState('');
  const [isOverUploadModalOpen, setIsOverUploadModalOpen] = useState(false);
  const [remainingUploadCount, setRemainingUploadCount] = useState(-1);
  const [openUploadWindow, setOpenUploadWindow] = useState<boolean>(false);

  return (
    <DashboardContext.Provider
      value={{
        spinner,
        setSpinner,
        isOverUploadModalOpen,
        setIsOverUploadModalOpen,
        remainingUploadCount,
        setRemainingUploadCount,
        openUploadWindow,
        setOpenUploadWindow,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

export default memo(DashboardContextProvider);
