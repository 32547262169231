import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getJWTHeaderFromLocalStorage } from '../../fetchInstance/axios';
import { axiosInstance } from '../../fetchInstance/customAxios';
import { PaginationParam } from '../../types/types';
import { queryKeys } from './constants';

export type CatalogPutParams = {
  catalog_uid: string;
  catalog_name: string;
  catalog_metadata: CatalogMetaData;
  catalog_memo: string;
  catalog_items: CatalogSaveItems[];
  catalog_lang: string;
  catalog_show_sales_url: boolean;
};

type CatalogMetaData = {
  company_name: string;
};

export type CatalogSaveItems = {
  model_uid: string;
  item_name: string;
  item_description: string;
  item_sales_url: string;
};

async function getCatalogList({ paginationParam }: { paginationParam: PaginationParam }): Promise<any> {
  const { data } = await axiosInstance.get('/catalog/list', {
    params: {
      range: JSON.stringify(paginationParam.range),
      searchStrs: paginationParam.searchStrs ? JSON.stringify(paginationParam.searchStrs) : '',
      catalog_state: paginationParam.catalogState,
      sortColumn: paginationParam.sortColumn ? paginationParam.sortColumn : null,
      sortOrder: paginationParam.sortOrder ? paginationParam.sortOrder : null,
    },
    headers: getJWTHeaderFromLocalStorage(),
  });
  return data.catalogs;
}

async function getCatalog(uid: string): Promise<any> {
  const { data } = await axiosInstance.get('/catalog', {
    params: {
      catalog_uid: uid,
    },
    headers: getJWTHeaderFromLocalStorage(),
  });

  return data;
}

export async function createCatalog(title: string, locale: string): Promise<any> {
  let result;

  try {
    result = await axiosInstance.post(
      '/catalog',
      {
        catalog_name: title,
        catalog_lang: locale,
      },
      { headers: getJWTHeaderFromLocalStorage() },
    );
  } catch (e) {
    throw new Error('카탈로그 생성 실패');
  }

  return result;
}

async function deleteCatalog(uid: string): Promise<any> {
  const result = await axiosInstance.delete('/catalog', {
    params: {
      catalog_uid: uid,
    },
    headers: getJWTHeaderFromLocalStorage(),
  });

  return result;
}

async function putCatalog(catalogPutData: CatalogPutParams): Promise<any> {
  const result = await axiosInstance.put(
    'catalog',
    {
      catalog_uid: catalogPutData.catalog_uid,
      catalog_name: catalogPutData.catalog_name,
      catalog_metadata: catalogPutData.catalog_metadata,
      catalog_memo: catalogPutData.catalog_memo,
      catalog_items: catalogPutData.catalog_items,
      catalog_lang: catalogPutData.catalog_lang,
      catalog_show_sales_url: catalogPutData.catalog_show_sales_url,
    },
    {
      headers: getJWTHeaderFromLocalStorage(),
    },
  );

  return result;
}

async function publishCatalog(catalog_uid: string): Promise<any> {
  const result = await axiosInstance.put(
    '/catalog/publish',
    {
      catalog_uid,
    },
    {
      headers: getJWTHeaderFromLocalStorage(),
    },
  );

  return result;
}

async function stopCatalogPublish(catalog_uid: string): Promise<any> {
  const result = await axiosInstance.delete('/catalog/publish', {
    params: {
      catalog_uid,
    },
    headers: getJWTHeaderFromLocalStorage(),
  });

  return result;
}

export function useCatalogList(defaultPagination: PaginationParam) {
  const [paginationParam, setPaginationParam] = useState<PaginationParam>(defaultPagination);
  const { data, refetch, isSuccess, ...restQueryReturns } = useQuery([queryKeys.catalogList, paginationParam], () =>
    getCatalogList({ paginationParam }),
  );
  return { data, refetch, isSuccess, paginationParam, setPaginationParam, restQueryReturns };
}

export function useCatalog(uid: string) {
  const { data, isSuccess, refetch, ...restQueryReturns } = useQuery([queryKeys.catalog, uid], () => getCatalog(uid));
  return { data, isSuccess, refetch, restQueryReturns };
}

export function useDeleteCatalog() {
  const { mutateAsync } = useMutation((uid: string) => deleteCatalog(uid), {
    onError: () => {
      console.error('catalog delete error');
    },
    onSuccess: (result: any) => {
      console.log('delete success : ', result);
    },
  });

  return { mutateAsync };
}

export function useSaveCatalog() {
  const { mutateAsync } = useMutation((catalogPutData: CatalogPutParams) => putCatalog(catalogPutData), {
    onError: () => {
      console.error('catalog save error');
    },
    onSuccess: (result: any) => {
      console.log('save success : ', result);
    },
  });

  return { mutateAsync };
}

export function usePublishCatalog() {
  const { mutateAsync } = useMutation((catalog_uid: string) => publishCatalog(catalog_uid), {
    onError: () => {
      console.error('catalog publish error');
    },
    onSuccess: (result: any) => {
      console.log('publish success : ', result);
    },
  });

  return { mutateAsync };
}

export function useStopCatalogPublish() {
  const { mutateAsync } = useMutation((catalog_uid: string) => stopCatalogPublish(catalog_uid), {
    onError: () => {
      console.error('catalog publish stop error');
    },
    onSuccess: (result: any) => {
      console.log('publish stop success : ', result);
    },
  });

  return { mutateAsync };
}
