import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { useNavigate } from 'react-router-dom';
import RDSUpload from '../fileupload/RDSUpload';
import { validationFile } from '../../../utils/utils';
import { checkRemainingModelCount } from '../../../utils/limit';
import { DashboardContext } from '../../context/DashboardContextProvider';
import { useLimits } from '../../../hooks/react-query/useLimits';
import { useTranslation } from 'react-i18next';
import OverViewCountModal from '../../global/OverViewCountModal';
import PlicarIntroductionFunctionImage from '../../../images/PlicarIntroductionFunctionImage.png';
import PlicarIntroductionButtonImage from '../../../images/PlicarIntroductionButtonImage.png';
import PlicarIntroductionCommerceImage from '../../../images/PlicarIntroductionCommerceImage.png';
import PlicarIntroductionCatalogImage from '../../../images/PlicarIntroductionCatalogImage.png';
import PlicarIntroductionArtImage from '../../../images/PlicarIntroductionArtImage.png';
import PlicarIntroductionDataAnalysisImage from '../../../images/PlicarIntroductionDataAnalysisImage.png';
import PlicarIntroductionFunctionMobileImage from '../../../images/PlicarIntroductionFunctionMobileImage.png';
import PlicarIntroductionButtonMobileImage from '../../../images/PlicarIntroductionButtonMobileImage.png';
import PlicarIntroductionDataAnalysisMobileImage from '../../../images/PlicarIntroductionDataAnalysisMobileImage.png';
import PlicarIntroductionFunctionImageEn from '../../../images/PlicarIntroductionFunctionImageEn.png';
import PlicarIntroductionButtonImageEn from '../../../images/PlicarIntroductionButtonImageEn.png';
import PlicarIntroductionDataAnalysisImageEn from '../../../images/PlicarIntroductionDataAnalysisImageEn.png';
import {
  RDSIconTextButton,
  RDSButton,
  RDSTab,
  RDSTabs,
  UploadIcon,
  RDSLinkButton,
  useRDSMediaQuery,
} from '@reconlabs/reconlabs-fe-components';

const PlicarIntroduction = () => {
  /*----------------------------------------
                    Data
   ----------------------------------------*/
  const navigate = useNavigate();
  const hideInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [selectedTap, setSelectedTap] = useState<string>('modelExist');
  const [isOverViewCount, setIsOverViewCount] = useState<boolean>(false);
  const [isOverViewCountModalOpen, setIsOverViewCountModalOpen] = useState(false);
  const { setIsOverUploadModalOpen, setSpinner, setRemainingUploadCount } = useContext(DashboardContext);
  const isMobile = useRDSMediaQuery(['true', ''], [767]);

  // API calls
  const { data: limitData, refetch: limitRefetch } = useLimits();
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (limitData?.result === 'success') {
      setIsOverViewCount(limitData?.isOverViewCount);
    }
  }, [limitData]);
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const uploadButtonClicked = async (event: any) => {
    limitRefetch();
    if (isOverViewCount) {
      event.preventDefault();
      setIsOverViewCountModalOpen(true);
    } else {
      hideInputRef.current?.click();
    }
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <OverViewCountModal
        isOverViewCountModalOpen={isOverViewCountModalOpen}
        setIsOverViewCountModalOpen={setIsOverViewCountModalOpen}
        closeHandler={() => hideInputRef.current?.click()}
      />
      <div className="PlicarIntro container-fluid">
        <Row className="g-0">
          <Col xl={11}>
            <header>
              {`${t('PlicarIntroduction.3D')}`}
              <span style={{ margin: '0 3px' }}>
                <svg width={6} height={6} style={{ display: 'inline' }}>
                  <circle cx="3" cy="3" r="3" fill="#60ECFF" />
                </svg>
              </span>
              {`${t('PlicarIntroduction.커머스의 시작은 플리카와 함께')}`}
            </header>
          </Col>
        </Row>
        <div className="PlicarIntro__body">
          <div className="PlicarIntro__3d">
            <div className="PlicarIntro__3d__title">3D Experience</div>
            <div className="PlicarIntro__3d__sub">{`${t('PlicarIntroduction.3D 콘텐츠로 판매율을 높여보세요!')}`}</div>
            <div className="PlicarIntro__3d__tabs">
              <RDSTabs value={selectedTap} handleChange={(value: string) => setSelectedTap(value)}>
                <RDSTab
                  label={`${t('PlicarIntroduction.모델이 있는경우')}`}
                  value={'modelExist'}
                  width={isMobile ? 156 : 278}
                ></RDSTab>
                <RDSTab
                  label={`${t('PlicarIntroduction.모델이 없는경우')}`}
                  value={'modelNotExist'}
                  width={isMobile ? 156 : 278}
                ></RDSTab>
              </RDSTabs>
              <div className="PlicarIntro__3d__tabs__text">
                {selectedTap === 'modelExist' ? (
                  <>
                    <div>
                      {window.locale === 'ko' ? (
                        <>
                          <span className="PlicarIntro__highlight">3D 모델(.gIb)</span>을 업로드 해주세요.
                        </>
                      ) : (
                        <>
                          Please upload <span className="PlicarIntro__highlight">3D model (.glb)</span>
                        </>
                      )}
                    </div>
                    <div>
                      {window.locale === 'ko' ? (
                        <>
                          <span className="PlicarIntro__highlight">AR 뷰어, 3D 뷰어, GIF 파일</span>로 변환해드려요.
                        </>
                      ) : (
                        <>
                          Convert to <span className="PlicarIntro__highlight">AR viewer, 3D viewer, GIF file.</span>
                        </>
                      )}
                    </div>
                    <div>
                      {window.locale === 'ko' ? (
                        <>
                          <span className="PlicarIntro__highlight">판매 페이지</span>에 바로 연결시킬 수도 있고,
                        </>
                      ) : (
                        <>
                          Can also be directly linked to <span className="PlicarIntro__highlight">the sales page,</span>
                        </>
                      )}
                    </div>
                    <div>
                      {window.locale === 'ko' ? (
                        <>
                          <span className="PlicarIntro__highlight">3D 카탈로그</span>를 만들어 내 상품만 따로 모아볼 수
                          있습니다.
                        </>
                      ) : (
                        <>
                          Create <span className="PlicarIntro__highlight">a 3D catalog</span> to view your 3D models.
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      {window.locale === 'ko' ? (
                        <>
                          오프라인 상품을 플리카에 <span className="PlicarIntro__highlight">발송</span>만 해주세요.
                        </>
                      ) : (
                        <>
                          <span className="PlicarIntro__highlight">Only send</span> the product to PlicAR.
                        </>
                      )}
                    </div>
                    <div>
                      {window.locale === 'ko' ? (
                        <>
                          <span className="PlicarIntro__highlight">플리카 3D팀</span>이 대신 상품을 촬영하고
                        </>
                      ) : (
                        <>
                          <span className="PlicarIntro__highlight">Our 3D team</span> will take care of the product
                          photography,
                        </>
                      )}
                    </div>
                    <div>
                      {window.locale === 'ko' ? (
                        <>
                          <span className="PlicarIntro__highlight">플리카 AI</span>가 고퀄리티의 3D 모델을 만들어
                          드립니다.
                        </>
                      ) : (
                        <>
                          <span className="PlicarIntro__highlight">PlicAR AI</span> will create high-quality 3D models
                          for you.
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="PlicarIntro__Function">
            <div
              className={
                window.locale === 'ko'
                  ? 'PlicarIntro__Function__title'
                  : 'PlicarIntro__Function__title PlicarIntro__Function__titleEn'
              }
            >
              <div>{`${t('PlicarIntroduction.플리카 기능 소개')}`}</div>
              <div
                className={
                  window.locale === 'ko'
                    ? 'PlicarIntro__Function__title__background'
                    : 'PlicarIntro__Function__title__background PlicarIntro__Function__titleEn__background'
                }
              ></div>
            </div>
            <div className="PlicarIntro__Function__Wrapper">
              <div className="PlicarIntro__Function__Wrapper__Text__sm">{`${t(
                'PlicarIntroduction.3D 모델을 업로드 하면',
              )}`}</div>
              <div className="PlicarIntro__Function__Wrapper__Text__lg">
                {window.locale === 'ko' ? (
                  <>
                    생동감 있는 <span className="PlicarIntro__highlight">3D 콘텐츠</span>로 감상할 수 있습니다.
                  </>
                ) : (
                  <>
                    And experience dynamic <span className="PlicarIntro__highlight">3D content!</span>
                  </>
                )}
              </div>
              <img
                style={{ margin: '0 auto' }}
                width={841}
                height={411}
                src={
                  window.locale === 'ko'
                    ? isMobile
                      ? PlicarIntroductionFunctionMobileImage
                      : PlicarIntroductionFunctionImage
                    : isMobile
                    ? PlicarIntroductionFunctionMobileImage
                    : PlicarIntroductionFunctionImageEn
                }
                alt="플리카 기능소개 이미지"
              />
            </div>
          </div>
          <Row className="PlicarIntro__Button g-0">
            <Col xl={12}>
              <img
                src={
                  window.locale === 'ko'
                    ? isMobile
                      ? PlicarIntroductionButtonMobileImage
                      : PlicarIntroductionButtonImage
                    : isMobile
                    ? PlicarIntroductionButtonMobileImage
                    : PlicarIntroductionButtonImageEn
                }
                style={{ maxWidth: '100%' }}
              />
            </Col>
          </Row>
          <div className="PlicarIntro__Commerce">
            <div className="PlicarIntro__Commerce__Title">
              3D
              <span style={{ margin: '0 3px' }}>
                <svg width={10} height={10} style={{ display: 'inline' }}>
                  <circle cx="5" cy="5" r="5" fill="#6073FF" />
                </svg>
              </span>
              Commerce
            </div>
            <div className="PlicarIntro__Commerce__Sub">
              {`${t(
                'PlicarIntroduction.상품의 3D 모델을 회전, 확대/축소 등 다양한 방식으로 소비자가 살펴볼 수 있으며,',
              )}`}
              <br />
              {`${t('PlicarIntroduction.보다 입체적으로 제품의 색상, 크기, 디자인 등을 선택하고 주문할 수 있습니다.')}`}
            </div>
            <div className="PlicarIntro__Commerce__Images">
              <div className="PlicarIntro__Commerce__ImageWrapper">
                <div className="PlicarIntro__Commerce__Image">
                  <img width={244} height={244} src={PlicarIntroductionCommerceImage} alt="E-COMMERCE 이미지" />
                </div>
                <div>{`${t(
                  'PlicarIntroduction.판매 페이지에 3D 콘텐츠를 넣어 온라인 상품을 고객의 공간에서 보여줍니다.',
                )}`}</div>
              </div>
              <div className="PlicarIntro__Commerce__ImageWrapper">
                <div className="PlicarIntro__Commerce__Image">
                  <img width={244} height={244} src={PlicarIntroductionCatalogImage} alt="E-COMMERCE 이미지" />
                </div>
                <div>{`${t(
                  'PlicarIntroduction.상품 라인업을 한 페이지 안에 모두 담아 판매 전략에 활용할 수 있습니다.',
                )}`}</div>
              </div>
              <div className="PlicarIntro__Commerce__ImageWrapper">
                <div className="PlicarIntro__Commerce__Image">
                  <img width={244} height={244} src={PlicarIntroductionArtImage} alt="E-COMMERCE 이미지" />
                </div>
                <div>{`${t(
                  'PlicarIntroduction.3D 화보, 디지털 쇼룸 등 내 상품을 더 돋보이도록 디자인을 제공해 드립니다.',
                )}`}</div>
              </div>
            </div>
          </div>
          <div className="PlicarIntro__DataAnalysis">
            <img
              src={
                window.locale === 'ko'
                  ? isMobile
                    ? PlicarIntroductionDataAnalysisMobileImage
                    : PlicarIntroductionDataAnalysisImage
                  : isMobile
                  ? PlicarIntroductionDataAnalysisMobileImage
                  : PlicarIntroductionDataAnalysisImageEn
              }
              alt="데이터 분석 이미지"
            />
          </div>
          <div className="PlicarIntro__ButtonsArea">
            <div className="PlicarIntro__ButtonsArea__Text">{`${t(
              'PlicarIntroduction.3D 커머스의 시작, 플리카',
            )}`}</div>
            <div style={{ marginTop: '30px' }}>
              <RDSIconTextButton
                icon={<UploadIcon />}
                customStyle={{ width: isMobile ? '236px' : '304px', height: '64px' }}
                onClick={uploadButtonClicked}
              >
                {`${t('PlicarIntroduction.3D 모델 업로드')}`}
              </RDSIconTextButton>
            </div>
            <div style={{ marginTop: '16px' }}>
              <RDSButton
                type="outline"
                customStyle={{ width: isMobile ? '236px' : '304px', height: '64px' }}
                onClick={() => {
                  navigate('/dashboard/service/plicar#3dmodelrequest');
                  (window as any).ChannelIO('openChat');
                }}
              >
                {`${t('PlicarIntroduction.3D 모델 생성 의뢰하기')}`}
              </RDSButton>
            </div>
          </div>
        </div>
        <div className="PlicarIntro__Guide">
          <RDSLinkButton
            label={`${t('PlicarIntroduction.가이드 자세히 보기')}`}
            color="grey"
            navigateFn={() => window.open('https://plicar-user-guide.super.site/')}
          />
        </div>
        <footer>
          <div>Copyright 2023.RECON Labs Inc. All rights reserved.</div>
          <div className="separator"></div>
          <div className="PlicarIntro__Footer__buttons">
            <button onClick={() => window.open('https://www.reconlabs.ai/')}>{`${t(
              'PlicarIntroduction.회사소개',
            )}`}</button>
            <button
              onClick={() =>
                window.open(
                  'https://blog.naver.com/PostList.naver?blogId=reconlabs&from=postList&categoryNo=16&parentCategoryNo=16',
                )
              }
            >
              {`${t('PlicarIntroduction.최근소식')}`}
            </button>
            <button onClick={() => window.open('https://www.wanted.co.kr/company/21567')}>{`${t(
              'PlicarIntroduction.채용 정보',
            )}`}</button>
          </div>
          <div className="separator"></div>
          <div className="PlicarIntro__Footer__buttons">
            <button onClick={() => window.open('https://reconlabs.notion.site/106b051b77c144199b28217f849d8b66')}>
              {`${t('PlicarIntroduction.이용 약관')}`}
            </button>
            <button onClick={() => window.open('https://reconlabs.notion.site/0f7df0cb2d7942ad822f6f88e254bbfd')}>
              {`${t('PlicarIntroduction.저작권 정책')}`}
            </button>
            <button onClick={() => window.open('https://reconlabs.notion.site/d1b81cbce44148c3bcacba381445c8e1')}>
              {`${t('PlicarIntroduction.개인정보 처리방침')}`}
            </button>
          </div>
        </footer>
        <RDSUpload.Input checkValidation={(file) => validationFile(file)}>
          {({ handleOnChange }) => (
            <>
              <input
                ref={hideInputRef}
                id="Intro-RDSUpload-hide-input"
                type="file"
                multiple
                onChange={async (e) => {
                  setSpinner('loading-spinner');
                  const remainingModelCount = await checkRemainingModelCount();
                  setSpinner('');
                  const fileCount = e.target.files?.length || 0;
                  if (remainingModelCount - fileCount < 0) {
                    setRemainingUploadCount(remainingModelCount);
                    hideInputRef.current!.value = '';
                    setIsOverUploadModalOpen(true);
                    return;
                  }
                  handleOnChange(e);
                  hideInputRef.current!.value = '';
                }}
                onClick={() => {
                  hideInputRef.current!.value = '';
                }}
                style={{ display: 'none' }}
              ></input>
            </>
          )}
        </RDSUpload.Input>
      </div>
    </>
  );
};

export default PlicarIntroduction;
