import {
  ChevronForwardIcon,
  RDSButton,
  RDSInputField,
  RDSToast,
  RDSToastAlertObject,
  RDSDropdown,
  RDSOption,
  RDSCheckbox,
} from '@reconlabs/reconlabs-fe-components';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { useTranslation } from 'react-i18next';
import { getMarketingAgreed, putMarketingAgreed } from '../../../hooks/react-query/useZeroUser';
import useAuth from '../../../hooks/utils/useAuth';
import useLocale from '../../../hooks/utils/useLocale';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import amplitude from '../../../utils/amplitude';
import { userResetPassword } from '../../../utils/login';
import useDebounce from '../../../utils/useDebounce';
import { pressEnter } from '../../../utils/utils';
import RestoreUser from './RestoreUser';
import WithDrawalModal from './WithDrawalModal';

const Mypage = () => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const { user, setUserInfo } = useAuth();
  const [withDrawilModalOpen, setWithDrawilModalOpen] = useState(false);
  const [marketingAgreed, setMarketingAgreeed] = useState(false);
  const { locale, setLocale } = useLocale();
  const [alert, setAlert] = useState<RDSToastAlertObject>();
  const debouncedMarketingAgreedState = useDebounce(marketingAgreed, 300);
  /*----------------------------------------
                Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    (async () => {
      const { marketing_agreed } = await getMarketingAgreed();
      setMarketingAgreeed(marketing_agreed);
    })();
  }, []);
  // 마케팅 수신동의 체크박스 연속적으로 누를시 백엔드 요청이 너무 많은걸 대비해 1초후 통합해 반영
  useEffect(() => {
    putMarketingAgreed(debouncedMarketingAgreedState);
  }, [debouncedMarketingAgreedState]);

  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const changeLocale = (locale: string) => {
    const alertObject: RDSToastAlertObject = {
      type: 'success',
      message: locale === 'ko' ? '한국어 설정 완료' : 'English setup complete',
    };
    setLocale(locale);
    setAlert(alertObject);
  };

  const closeWithdrawalModal = () => {
    setWithDrawilModalOpen(false);
  };
  const openWithdrawalModal = () => {
    setWithDrawilModalOpen(true);
  };
  const onChangeMarketingAgree = async (check: boolean) => {
    setMarketingAgreeed(check);
  };

  return (
    <div className="MyPage container-fluid">
      <WithDrawalModal isVisible={withDrawilModalOpen} onClickCancel={closeWithdrawalModal} />
      <RDSToast.AlertArea openedAlert={alert} />
      <Row>
        <Col xl={4}></Col>
        <Col xl={4}>
          <div className="MyPage__title">{`${t('MyPage.언어 설정')}`}</div>
          <RDSDropdown
            size="small"
            customStyles={{
              fontFamily: 'Pretendard, sans-serif',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '12px',
              letterSpacing: '0.25px',
            }}
            onChange={(locale) => changeLocale(locale)}
            value={locale}
          >
            <React.Fragment key=".0">
              <RDSOption value="ko">한국(한국어)</RDSOption>
              <RDSOption value="en">USA(English)</RDSOption>
            </React.Fragment>
          </RDSDropdown>
          <div className="MyPage__i18n__divider"></div>
        </Col>
        <Col xl={4}></Col>
      </Row>
      <Row>
        <Col xl={4}></Col>
        <Col xl={4}>
          {/* idpw user */}
          {user.register_type && user.register_type === 'idpw' ? (
            // 회원탈퇴한 계정
            user.delete_flag ? (
              <>
                <div className="MyPage__title">{`${t('MyPage.마이 페이지')}`}</div>
                <div className="MyPage__deletedUser__email">
                  <RDSInputField.Text
                    title={t('MyPage.이메일')}
                    placeholder={t('MyPage.이메일')}
                    value={user.user_email}
                    handleInputChange={() => {}}
                    disabled={true}
                  />
                </div>
                <RestoreUser user={user} setUserInfo={setUserInfo} />
              </>
            ) : (
              <>
                <div className="MyPage__title">{`${t('MyPage.비밀번호 변경')}`}</div>
                <Mypage.ResetPasswordForm email={user.user_email as string} />
              </>
            )
          ) : // oauth user + 회원탈퇴한 계정
          user.delete_flag ? (
            <>
              <div className="MyPage__title">{`${t('MyPage.마이 페이지')}`}</div>
              <div className="MyPage__Oauth">
                <div className="MyPage__Oauth__title">{`${t('MyPage.소셜 로그인 사용 중')}`}</div>
                <OauthPlatformIcon registerType={user.register_type as Oauth} />
              </div>
              <RestoreUser user={user} setUserInfo={setUserInfo} />
            </>
          ) : (
            <>
              <div className="MyPage__title">{`${t('MyPage.마이 페이지')}`}</div>
              <Mypage.OauthAccount registerType={user.register_type as Oauth} />
            </>
          )}
          {!user.delete_flag ? (
            <>
              <Row>
                <div
                  className={
                    user.register_type === 'idpw'
                      ? 'MyPage__marketingAgree__container'
                      : 'MyPage__marketingAgree__container_oauth'
                  }
                >
                  <Mypage.MarketingAgreeCheckbox
                    marketingAgreed={marketingAgreed}
                    onChangeMarketingAgree={onChangeMarketingAgree}
                  />
                </div>
              </Row>
              <Row>
                <div className="MyPage__withdraw__container">
                  <div
                    className="MyPage__withdraw"
                    onClick={() => {
                      amplitude.sendEvent(amplitudeEvents.mypage.zero_mypage_withdrawal_email);
                      openWithdrawalModal();
                    }}
                  >
                    {`${t('MyPage.회원 탈퇴')}`} <ChevronForwardIcon />
                  </div>
                </div>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Col>
        <Col xl={4}></Col>
      </Row>
    </div>
  );
};

export default Mypage;

type Oauth = 'kakao' | 'naver' | 'google';
Mypage.OauthAccount = ({ registerType }: { registerType: Oauth }) => {
  const { t } = useTranslation();
  return (
    <div className="MyPage__Oauth">
      <div className="MyPage__Oauth__title">{`${t('MyPage.소셜 로그인 사용 중')}`}</div>
      <OauthPlatformIcon registerType={registerType} />
    </div>
  );
};

const OauthPlatformIcon = ({ registerType }: { registerType: Oauth }) => {
  const { t } = useTranslation();

  return (
    <div className="MyPage__Oauth__platform">
      {registerType === 'kakao' && (
        <div>
          <img id="kakao" src="/images/kakao.png" width={'40px'} alt="kakao" />
          <div className="MyPage__Oauth__platform__name">{`${t('MyPage.카카오')}`}</div>
        </div>
      )}
      {registerType === 'naver' && (
        <div>
          <img id="naver" src="/images/naver.png" width={'40px'} alt="naver" />
          <div className="MyPage__Oauth__platform__name">{`${t('MyPage.네이버')}`}</div>
        </div>
      )}

      {registerType === 'google' && (
        <div>
          <img id="google" src="/images/google.png" width={'40px'} alt="google" />
          <div className="MyPage__Oauth__platform__name">{`${t('MyPage.구글')}`}</div>
        </div>
      )}
    </div>
  );
};

type Input = {
  password: string;
  password_check: string;
  old_password: string;
};

Mypage.ResetPasswordForm = ({ email }: { email: string }) => {
  /*----------------------------------------
                       Data
     ----------------------------------------*/
  const { t } = useTranslation();
  const [input, setInput] = useState<Input>({ password: '', password_check: '', old_password: '' });
  const [signupButtonDisabled, setSignUpButtonDisabled] = useState(true);
  const [isPwValid, setIsPwValid] = useState<boolean>(true);
  const [isOldPwValid, setIsOldPwValid] = useState<boolean>(true);
  const [isConfirmPwValid, setIsConfirmPwValid] = useState<boolean>(true);
  const [isLoginButtonLoading, setIsLoginButtonLoading] = useState(false);
  const [alert, setAlert] = useState<RDSToastAlertObject>();
  /*----------------------------------------
                    Event Handler
     ----------------------------------------*/
  const onInputChanged = (e: any, type: string) => {
    setInput((prevInput) => {
      return {
        password: prevInput.password,
        password_check: prevInput.password_check,
        old_password: prevInput.old_password,
        [type]: e.target.value,
      };
    });
  };

  const onClickResetPassword = async () => {
    const { password, old_password } = input;
    setIsLoginButtonLoading(true);
    try {
      await userResetPassword(email, password, old_password);
      setAlert({ type: 'success', message: t('MyPage.비밀번호가 변경되었습니다.') });
      setIsOldPwValid(true);
      amplitude.sendEvent(amplitudeEvents.mypage.zero_mypage_email_pw_change_success);
    } catch (e: any) {
      if (e.response.data.result === 'invalid_user_email_or_password') {
        setIsOldPwValid(false);
        setAlert({ type: 'caution', message: t('MyPage.잘못된 비밀번호 입니다.') });
      } else {
        setAlert({
          type: 'caution',
          message: t('MyPage.네트워크 오류로 실패하였습니다. 잠시 후 다시 시도하여 주십시오.'),
        });
      }
      amplitude.sendEvent(amplitudeEvents.mypage.zero_mypage_email_pw_change_failure);
    }
    setIsLoginButtonLoading(false);
  };

  /*----------------------------------------
                  Life Cycle
     ----------------------------------------*/
  useEffect(() => {
    input.password.length > 0 &&
    input.password_check.length > 0 &&
    input.old_password.length > 0 &&
    isConfirmPwValid &&
    isPwValid
      ? setSignUpButtonDisabled(false)
      : setSignUpButtonDisabled(true);
  }, [input, isConfirmPwValid, isPwValid]);

  useEffect(() => {
    if (input.password.length > 0) {
      setIsPwValid(!!validatePassword(input.password));
    } else {
      setIsPwValid(true);
    }
  }, [input, input.password, input.password.length]);

  useEffect(() => {
    if (input.password_check.length > 0) {
      setIsConfirmPwValid(!!checkConfirmPassword(input.password_check));
    } else {
      setIsConfirmPwValid(true);
    }
  }, [input, input.password_check, input.password_check.length]);
  /*----------------------------------------
                  Business Logic
     ----------------------------------------*/
  const validatePassword = (pw: string) => {
    const pwRegexp = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    return pw.match(pwRegexp);
  };

  const checkConfirmPassword = (pw: string) => {
    return input.password === pw;
  };
  /*----------------------------------------
                  Default Template
     ----------------------------------------*/
  return (
    <div className="MyPage__resetPassword">
      <RDSToast.AlertArea openedAlert={alert} />
      <RDSInputField.Text
        title={t('MyPage.이메일')}
        placeholder={t('MyPage.이메일')}
        value={email}
        handleInputChange={() => {}}
        disabled={true}
      />
      <RDSInputField.Password
        customStyles={{ marginTop: '16px' }}
        title={t('MyPage.변경할 비밀번호')}
        required
        isError={!isPwValid}
        helperText={!isPwValid ? t('MyPage.올바르지 않은 패스워드 형식입니다. 다시 확인해 주세요') : ''}
        placeholder={t('MyPage.비밀번호(영문, 숫자, 특수문자를 조합한 8자)')}
        value={input.password}
        handleInputChange={(e: any) => onInputChanged(e, 'password')}
      />
      <RDSInputField.Password
        customStyles={{ marginTop: '10px' }}
        isError={!isConfirmPwValid}
        helperText={!isConfirmPwValid ? t('MyPage.패스워드가 틀립니다. 다시 확인해 주세요') : ''}
        placeholder={t('MyPage.비밀번호 확인(영문, 숫자, 특수문자를 조합한 8자)')}
        value={input.password_check}
        handleInputChange={(e: any) => onInputChanged(e, 'password_check')}
        onBlur={() => {
          checkConfirmPassword(input.password_check) ? setIsConfirmPwValid(true) : setIsConfirmPwValid(false);
        }}
      />
      <div className="MyPage__resetPassword__divider"></div>
      <RDSInputField.Password
        customStyles={{ marginTop: '16px' }}
        title={t('MyPage.기존 비밀번호')}
        required
        isError={!isOldPwValid}
        helperText={!isOldPwValid ? t('MyPage.비밀번호가 일치하지 않습니다') : ''}
        placeholder={t('MyPage.기존 비밀번호(영문, 숫자, 특수문자를 조합한 8자)')}
        value={input.old_password}
        handleInputChange={(e: any) => onInputChanged(e, 'old_password')}
        onKeyDown={(e: any) => {
          pressEnter(e) && !signupButtonDisabled && !isLoginButtonLoading ? onClickResetPassword() : null;
        }}
      />
      <div className="MyPage__resetPassword__button">
        <RDSButton
          fullWidth
          onClick={onClickResetPassword}
          disabled={signupButtonDisabled}
          isLoading={isLoginButtonLoading}
        >
          {`${t('MyPage.변경하기')}`}
        </RDSButton>
      </div>
    </div>
  );
};

Mypage.MarketingAgreeCheckbox = ({
  marketingAgreed,
  onChangeMarketingAgree,
}: {
  marketingAgreed: boolean;
  onChangeMarketingAgree: Function;
}) => {
  const { t } = useTranslation();
  const onClickCheckbox = () => {
    marketingAgreed ? onChangeMarketingAgree(false) : onChangeMarketingAgree(true);
  };

  return (
    <div className="MyPage__marketingAgree">
      <RDSCheckbox.IconType checked={marketingAgreed ? true : false} handleCheck={onClickCheckbox} />
      {`${t('MyPage.뉴스레터 및 마케팅 활용 동의')}`}
    </div>
  );
};
