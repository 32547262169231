import React, { useEffect, useState } from 'react';
import { findUserByEmail, sendVerifyEmail, signUp } from '../../../utils/login';
import {
  CheckOutlinedIcon,
  RDSButton,
  RDSInputField,
  RDSModal,
  RDSToast,
  RDSToastAlertObject,
} from '@reconlabs/reconlabs-fe-components';
import { useNavigate } from 'react-router-dom';
import { Locale, LoginType, OauthKr, SurveyType } from '../../../constants/type';
import { pressEnter } from '../../../utils/utils';
import useDebounce from '../../../utils/useDebounce';
import ResetPasswordModal from './ResetPasswordModal';
import { postSurvey } from '../../../hooks/react-query/useZeroUser';
import { INPUT_LENGTH_LIMIT } from '../../../constants/values';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import { useTranslation } from 'react-i18next';
import { getLocale } from '../../../utils/localstorage';

type SignUpInput = {
  email: string;
  password: string;
  password_check: string;
  name: string;
};

type SignupProps = {
  signUpParams: any;
  setViewType: Function;
  setSignUpParams: any;
};

const Singup = (props: SignupProps) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const [input, setInput] = useState<SignUpInput>({ email: '', password: '', password_check: '', name: '' });
  const [signupButtonDisabled, setSignUpButtonDisabled] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isPwValid, setIsPwValid] = useState<boolean>(true);
  const [isConfirmPwValid, setIsConfirmPwValid] = useState<boolean>(true);
  const [isLoginButtonLoading, setIsLoginButtonLoading] = useState(false);
  const [isEmailSentModalOpen, setIsEmailSentModalOpen] = useState(false);
  const [errorUserExist, setErrorUserExist] = useState<OauthKr | 'idpw' | boolean>(false);
  const [isResendEmailButtonLoading, setIsResendEmailButtonLoading] = useState(false);
  const [emailAlert, setEmailAlert] = useState<RDSToastAlertObject>();
  const debouncedEmail = useDebounce(input.email, 200);
  const [userEmailGetResult, setUserEmailGetResult] = useState<LoginType | boolean>(false);
  const [openPasswordChangeModal, setOpenResetPasswordModal] = useState<boolean>(false);
  const [toast, setToast] = useState<string>('');
  const navigate = useNavigate();
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const onInputChanged = (e: any, type: string) => {
    // 이메일과 이름은 글자수 제한
    if (type === 'email' && e.target.value.length > INPUT_LENGTH_LIMIT) {
      return;
    }
    if (type === 'name' && e.target.value.length > INPUT_LENGTH_LIMIT) {
      return;
    }
    setInput((prevInput) => {
      return {
        email: prevInput.email,
        password: prevInput.password,
        password_check: prevInput.password_check,
        name: prevInput.name,
        [type]: e.target.value,
      };
    });
  };

  const onClickSignup = async () => {
    if (isLoginButtonLoading) {
      return;
    }
    const { email, password, name } = input;
    // setIsLoginButtonLoading(true);
    try {
      const marketing_agreed = props.signUpParams.marketing_agreed;
      let params = { marketing_agreed, user_name: name };
      const res = await signUp(email, password, params, getLocale() as Locale);
      // survey post
      try {
        const surveys: SurveyType[] = [
          { survey_id: 'first_register', survey_response: JSON.stringify(props.signUpParams.user_tag) },
          { survey_id: 'first_register_text', survey_response: props.signUpParams.user_tag_text },
        ];
        await postSurvey(surveys, res.token);
      } catch (e) {
        console.log('post survey error');
      }

      amplitude.sendEvent(amplitudeEvents.signup.zero_signup_process_success);

      setIsEmailSentModalOpen(true);
      // navigate('/');
    } catch (e: any) {
      if (e.response.data.result === 'existing_plicar_user') {
        setErrorUserExist('idpw');
      } else if (e.response.data.result === 'existing_kakao_user') {
        setErrorUserExist('카카오');
      } else if (e.response.data.result === 'existing_naver_user') {
        setErrorUserExist('네이버');
      } else if (e.response.data.result === 'existing_google_user') {
        setErrorUserExist('구글');
      } else if (e.response.data.result === 'params_length_excess') {
        alert('이메일 혹은 이름의 글자수 제한(41)를 초과하였습니다.');
      } else {
        alert('네트워크 오류로 회원가입에 실패하였습니다.');
      }
    }
    setIsLoginButtonLoading(false);
  };

  const onClickResendVerifyEmail = async () => {
    setIsResendEmailButtonLoading(true);
    try {
      await sendVerifyEmail(input.email, getLocale() as Locale);
      setEmailAlert({ type: 'success', message: t('Login.이메일이 재발송되었습니다') });
    } catch {
      setEmailAlert({ type: 'failure', message: '네트워크 오류로 실패하여습니다. 잠시후 다시 시도해주세요' });
    }
    setIsResendEmailButtonLoading(false);
  };

  /*----------------------------------------
                  Analytics
   ----------------------------------------*/
  const sendAmplitudeLeave = async () => {
    amplitude.sendEvent(amplitudeEvents.signup.zero_signup_process_failure);
  };

  useEffect(() => {
    // 새로고침, 탭 닫기
    window.addEventListener('beforeunload', sendAmplitudeLeave);
    // 뒤로가기
    window.addEventListener('popstate', () => amplitude.sendEvent(amplitudeEvents.signup.zero_signup_process_failure));

    return () => {
      window.removeEventListener('beforeunload', sendAmplitudeLeave);
      window.removeEventListener('popstate', () =>
        amplitude.sendEvent(amplitudeEvents.signup.zero_signup_process_failure),
      );
    };
  }, []);

  /*----------------------------------------
                Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    input.email.length > 0 &&
    input.password.length > 0 &&
    input.password_check.length > 0 &&
    input.name.length > 0 &&
    isConfirmPwValid &&
    isPwValid &&
    isEmailValid
      ? setSignUpButtonDisabled(false)
      : setSignUpButtonDisabled(true);
  }, [input, isEmailValid, isConfirmPwValid, isPwValid]);

  useEffect(() => {
    if (input.email.length > 0) {
      setIsEmailValid(!!validateEmail(input.email));
    } else {
      setIsEmailValid(true);
    }
  }, [input, input.email, input.email.length]);

  useEffect(() => {
    if (input.password.length > 0) {
      setIsPwValid(!!validatePassword(input.password));
    } else {
      setIsPwValid(true);
    }
  }, [input, input.password, input.password.length]);

  useEffect(() => {
    if (input.password_check.length > 0) {
      setIsConfirmPwValid(!!checkConfirmPassword(input.password_check));
    } else {
      setIsConfirmPwValid(true);
    }
  }, [input, input.password_check, input.password_check.length]);

  // user email 존재 확인
  useEffect(() => {
    if (isEmailValid && input.email.length > 0) {
      (async function () {
        const { user_exist, register_type } = await findUserByEmail(debouncedEmail);
        setUserEmailGetResult(user_exist ? register_type : user_exist);
      })();
    }
  }, [debouncedEmail]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const validateEmail = (email: string) => {
    const emailRegexp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return email.match(emailRegexp);
  };

  const validatePassword = (pw: string) => {
    const pwRegexp = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    return pw.match(pwRegexp);
  };

  const checkConfirmPassword = (pw: string) => {
    return input.password === pw;
  };
  const handleCloseModal = () => {
    setIsEmailSentModalOpen(false);
    navigate('/login');
  };
  const makeEmailHelperText = () => {
    if (input.email.length === 0) {
      return '';
    }
    if (!isEmailValid) {
      return t('Login.올바르지 않은 이메일 형식입니다. 다시 확인해 주세요');
    } else {
      if (!userEmailGetResult) {
        return;
      }
      if (userEmailGetResult === 'idpw') {
        return window.locale === 'ko' ? (
          <div>
            이미 사용 중인 이메일입니다.
            <br />
            비밀번호를 찾으시려면{' '}
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: '700' }}
              onClick={() => {
                setOpenResetPasswordModal(true);
              }}
            >
              여기
            </span>
            를 눌러주세요.
          </div>
        ) : (
          <div>
            This email is already in use. Click{' '}
            <span
              style={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: '700' }}
              onClick={() => {
                setOpenResetPasswordModal(true);
              }}
            >
              here
            </span>{' '}
            to reset your
            <br />
            password.
          </div>
        );
      } else {
        return (
          <div>
            이미 {userEmailGetResult}계정으로 등록된 이메일 입니다.
            <br />
            로그인 페이지로 돌아가셔서 {userEmailGetResult}로그인을 이용해주세요
          </div>
        );
      }
    }
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <ResetPasswordModal
        isVisible={openPasswordChangeModal}
        setOpenResetPasswordModal={setOpenResetPasswordModal}
        setToast={setToast}
      />
      <div className="Signup__container">
        <RDSModal
          open={isEmailSentModalOpen}
          onClose={handleCloseModal}
          title={`${t('Login.인증 메일 발송')}`}
          supportingText={
            <>
              <div>
                {`${t(
                  'Login.가입하신 이메일로 인증 버튼이 발송되었습니다. 이메일로 이동하여 남은 회원 가입 절차를 완료해주시기 바랍니다.',
                )}`}
              </div>
              <div className="ResetPassword__resendEmail">
                {`${t('Login.인증 메일을 받지 못하셨나요?')}`}
                <RDSButton
                  size="xsmall"
                  isLoading={isResendEmailButtonLoading}
                  type={'noBox'}
                  onClick={onClickResendVerifyEmail}
                >
                  {`${t('Login.인증메일 재발송')}`}
                </RDSButton>
              </div>
              <RDSToast.AlertArea timingMs={2000} openedAlert={emailAlert} />
            </>
          }
          buttonType="noBox"
          button1Label={t('MyPage.확인')}
          button1Fn={handleCloseModal}
          enableCloseOnBackdropClick={false}
        ></RDSModal>
        <RDSModal
          open={errorUserExist ? true : false}
          onClose={() => {
            setErrorUserExist(false);
          }}
          title={`이미 ${errorUserExist !== 'idpw' ? errorUserExist + ' 계정으로' : ''} 가입된 이메일입니다.`}
          buttonType="noBox"
          button1Label={t('MyPage.확인')}
          button1Fn={() => {
            setErrorUserExist(false);
          }}
          enableCloseButton={false}
          customWidth={'400px'}
          // enableCloseOnBackdropClick={false}
        ></RDSModal>
        <RDSInputField.Text
          title={`${t('Login.이메일')}`}
          isError={(input.email.length > 0 && !isEmailValid) || userEmailGetResult ? true : false}
          helperText={makeEmailHelperText()}
          placeholder="sample@plicar.io"
          value={input.email}
          handleInputChange={(e: any) => onInputChanged(e, 'email')}
        />
        <RDSInputField.Password
          customStyles={{ marginTop: '16px' }}
          title={`${t('Login.비밀번호')}`}
          required
          isError={!isPwValid}
          helperText={!isPwValid ? t('Login.올바르지 않은 패스워드 형식입니다. 다시 확인해 주세요') : ''}
          placeholder={t('Login.비밀번호(영문, 숫자, 특수문자를 조합한 8자)')}
          value={input.password}
          handleInputChange={(e: any) => onInputChanged(e, 'password')}
        />
        <RDSInputField.Password
          customStyles={{ marginTop: '10px' }}
          isError={!isConfirmPwValid}
          helperText={!isConfirmPwValid ? t('Login.패스워드가 틀립니다. 다시 확인해 주세요') : ''}
          placeholder={t('Login.비밀번호(영문, 숫자, 특수문자를 조합한 8자)')}
          value={input.password_check}
          handleInputChange={(e: any) => onInputChanged(e, 'password_check')}
          onBlur={() => {
            checkConfirmPassword(input.password_check) ? setIsConfirmPwValid(true) : setIsConfirmPwValid(false);
          }}
          onKeyDown={(e: any) => {
            pressEnter(e) && !signupButtonDisabled && !isLoginButtonLoading ? onClickSignup() : null;
          }}
        />
        <RDSInputField.Text
          customStyles={{ marginTop: '16px' }}
          title={`${t('Login.이름')}`}
          placeholder={t('Login.홍길동')}
          value={input.name}
          onKeyDown={(e: any) => {
            pressEnter(e) && !signupButtonDisabled && !isLoginButtonLoading ? onClickSignup() : null;
          }}
          handleInputChange={(e: any) => onInputChanged(e, 'name')}
        />
        <div className="Agreement__inner__body__button">
          <RDSButton fullWidth onClick={onClickSignup} disabled={signupButtonDisabled} isLoading={isLoginButtonLoading}>
            {`${t('Login.회원가입')}`}
          </RDSButton>
        </div>
      </div>
    </>
  );
};

export default Singup;
