
/**
 * trailing slash를 포함하지 않습니다
 */
export const PATH = {
  PUBLIC: {
    agreement: 'agreement',
    login: 'login',
    env: 'env',
  },
}