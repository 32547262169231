import React, { useContext, useEffect, useState } from 'react';
import {
  RDSBottomSheet,
  RDSModal,
  RDSSnackbar,
  CheckCircleOutlinedIcon,
  RDSToast,
  RDSToastAlertObject,
} from '@reconlabs/reconlabs-fe-components';
import UploadProgress from './pages/fileupload/UploadProgress';
import { RDSUploadContext, SetRDSUploadContext, UploadFile } from './pages/fileupload/RDSUpload';
import { useLocation, useNavigate } from 'react-router-dom';
import websocketHandler from '../utils/websocketHandler';
import { useTranslation } from 'react-i18next';
import amplitude from '../utils/amplitude';
import amplitudeEvents from '../constants/amplitudeEvents';
import { UserContext } from './context/UserContextProvider';

const GlobalScopeComponents = () => {
  /*----------------------------------------
                    Data
   ----------------------------------------*/
  // const
  const { t } = useTranslation();
  let webSocket: any;
  const navigate = useNavigate();
  const location = useLocation();

  // context
  const { uploadFiles, uploader } = useContext(RDSUploadContext);
  const setUpload = useContext(SetRDSUploadContext);
  const { setNewModels } = useContext(UserContext);

  // snackbar
  const [openedSnackbar, setOpenedSnackbar] = useState<string>('');

  // toast
  const [invalidToast, setInvalidToast] = useState<RDSToastAlertObject>();

  // boolean
  const [openExceedModal, setOpenExceedModal] = useState<boolean>(false);
  const [openPlanModal, setOpenPlanModal] = useState<boolean>(false);
  const [openInvalidFileModal, setOpenInvalidFileModal] = useState<boolean>(false);
  const [openCloseBottomSheetModal, setOpenCloseBottomSheetModal] = useState<boolean>(false);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    handleSnackbar();
  }, [uploadFiles.filter((file) => file.state === 'viewerComplete').length]);

  useEffect(() => {
    handleBottomSheet(uploadFiles);
    handleValidationModal(uploadFiles);
  }, [uploadFiles, uploadFiles.length]);

  useEffect(() => {
    if (openBottomSheet) {
      const url = `${process.env.REACT_APP_WEBSOCKET_SERVER_DOMAIN}`;
      webSocket = websocketHandler(url, webSocket, messageHandler);
    }

    return () => {
      if (webSocket) {
        webSocket.close();
      }
    };
  }, [openBottomSheet]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const messageHandler = (data: any) => {
    return setUpload((prevState) => {
      const newState = [...prevState];
      const compldeteFile = newState.find((file) => file.uid === data.uid && data.type === 'gif_created');
      const invalidFile = newState.find((file) => file.uid === data.uid && data.type === 'invalid_file');
      if (compldeteFile) {
        compldeteFile.state = 'viewerComplete';
      } else if (invalidFile) {
        invalidFile.state = 'invalidToast';
      }
      return newState;
    });
  };

  const handleSnackbar = () => {
    if (
      uploader.state === 'await' &&
      uploadFiles.filter((file) => file.state === 'viewerComplete').length > 0 &&
      uploadFiles.filter((file) => file.state === 'complete').length === 0 &&
      uploadFiles.filter((file) => file.state === 'waiting').length === 0 &&
      uploadFiles.filter((file) => file.state === 'ongoing').length === 0
    ) {
      setOpenedSnackbar('upload-complete');

      if (location.pathname !== '/dashboard/models') {
        setNewModels(true);
      }
    }
  };

  const handleBottomSheet = (uploadFiles: UploadFile[]) => {
    if (
      uploadFiles &&
      uploadFiles.length > 0 &&
      (uploadFiles.filter((file) => file.state === 'waiting').length > 0 ||
        uploadFiles.filter((file) => file.state === 'ongoing').length > 0 ||
        uploadFiles.filter((file) => file.state === 'complete').length > 0 ||
        uploadFiles.filter((file) => file.state === 'canceled').length > 0)
    ) {
      setOpenBottomSheet(true);
    } else {
      setOpenBottomSheet(false);
    }
  };

  const handleValidationModal = (uploadFiles: UploadFile[]) => {
    const overPlan = () => {
      setOpenPlanModal(true);
      amplitude.sendEvent(amplitudeEvents.bottomsheet.zero_allmodel_bottomsheet_list_error_exceed_plan);
    };

    const overCount = () => {
      setOpenExceedModal(true);
      amplitude.sendEvent(amplitudeEvents.bottomsheet.zero_allmodel_bottomsheet_list_error_exceed_number);
    };

    const invalidFile = () => {
      setOpenInvalidFileModal(true);
      amplitude.sendEvent(amplitudeEvents.bottomsheet.zero_allmodel_bottomsheet_list_error_wrong_format);
    };

    const invalidToast = () => {
      setInvalidToast({ type: 'failure', message: t('global.올바른 파일 형식이 아닙니다.') });
      setUpload((prevState) => prevState.filter((file) => file.state !== 'invalidToast'));
    };

    checkValidationState(uploadFiles, 'overPlan')
      ? overPlan()
      : checkValidationState(uploadFiles, 'overCount')
      ? overCount()
      : checkValidationState(uploadFiles, 'invalidFile')
      ? invalidFile()
      : checkValidationState(uploadFiles, 'invalidToast')
      ? invalidToast()
      : '';
  };

  const checkValidationState = (uploadFiles: UploadFile[], validationState: string) => {
    return uploadFiles.filter((file) => file.state === validationState).length > 0;
  };

  const closeBottomSheet = () => {
    if (
      uploadFiles.filter((item) => item.state === 'ongoing' || item.state === 'waiting' || item.state === 'complete')
        .length > 0
    ) {
      setOpenCloseBottomSheetModal(true);
      amplitude.sendEvent(amplitudeEvents.bottomsheet.zero_allmodel_bottomsheet_close, { type: 'force_stop' });
    } else {
      amplitude.sendEvent(amplitudeEvents.bottomsheet.zero_allmodel_bottomsheet_close, { type: 'after_completed' });
      setUpload([]);
    }
  };

  const abrotAndClose = () => {
    uploader.abortAll();
    setUpload([]);
    setOpenCloseBottomSheetModal(false);
  };

  const navigateResultPage = () => {
    let queryString: string = '?';
    uploadFiles.map((file) => {
      if (file.state === 'viewerComplete') {
        if (queryString.split('?')[1].length > 0) {
          queryString += '&';
        }
        queryString += file.uid;
      }
    });
    navigate('/dashboard/upload/result' + queryString);
    setOpenedSnackbar('');
  };
  /*----------------------------------------
                Event Handler
   ----------------------------------------*/
  const invalidModalClose = () => {
    setOpenInvalidFileModal(false);
    setUpload((prevState) => {
      return prevState.filter((file) => file.state !== 'invalidFile');
    });
  };

  const exceedModalClose = () => {
    setOpenExceedModal(false);
    setUpload((prevState) => {
      return prevState.filter((file) => file.state !== 'overCount');
    });
  };

  const planModalClose = () => {
    setOpenPlanModal(false);
    setUpload((prevState) => {
      return prevState.filter((file) => file.state !== 'overPlan');
    });
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <RDSModal
        title={`${t('global.모든 진행을 중단할까요?')}`}
        enableCloseButton={false}
        button1Label={t('global.모두 중단')}
        button1Fn={abrotAndClose}
        button1Color="warning"
        button2Label={t('MyPage.취소')}
        button2Fn={() => {
          // Amplitude: 바텀시트 강제 종료 모달에서 취소를 선택한 경우
          amplitude.sendEvent(amplitudeEvents.bottomsheet.zero_allmodel_bottomsheet_close_modal_cancel);
          setOpenCloseBottomSheetModal(false);
        }}
        supportingText={
          <>
            {`${t('global.현재 업로드 중인 목록이 있습니다.')}`} <br />
            {`${t('global.중단하면 모든 진행이 멈추고 업로드 목록이 사라집니다.')}`}
          </>
        }
        open={openCloseBottomSheetModal}
        onClose={setOpenCloseBottomSheetModal}
      />
      <RDSSnackbar.LinkButton
        icon={<CheckCircleOutlinedIcon />}
        snackbarId="upload-complete"
        openedSnackbar={openedSnackbar}
        setOpenedSnackbar={setOpenedSnackbar}
        content={t('global.모든 파일의 업로드가 완료되었습니다.')}
        zIndex={200}
        navigateFn={() => {
          amplitude.sendEvent(amplitudeEvents.uploadcomplete.zero_uploaded_complete_link);
          navigateResultPage();
        }}
      />
      <RDSModal
        title={`${t('global.잘못된 파일 형식')}`}
        enableCloseButton={false}
        buttonType="noBox"
        button1Label={t('global.닫기')}
        button1Fn={invalidModalClose}
        button1Color="warning"
        supportingText={
          <>
            (.glb) {`${t('global.파일만 업로드만 가능합니다.')}`}
            <br /> {`${t('global.확인 후 다시 시도해주세요.')}`}
          </>
        }
        open={openInvalidFileModal}
        onClose={invalidModalClose}
      />
      <RDSModal
        title={`${t('global.업로드 개수 초과')}`}
        enableCloseButton={false}
        buttonType="noBox"
        button1Label={t('global.닫기')}
        button1Fn={exceedModalClose}
        button1Color="warning"
        supportingText={
          <>
            {`${t('global.파일 업로드 1회 당 최대 10개 씩만 사용 가능합니다.')}`} <br />
            {`${t('global.확인 후 다시 시도해주세요.')}`}
          </>
        }
        open={openExceedModal}
        onClose={exceedModalClose}
      />
      <RDSModal
        title={`${t('global.플랜 사용량 초과')}`}
        enableCloseButton={false}
        buttonType="noBox"
        button1Label={t('global.닫기')}
        button1Fn={planModalClose}
        button1Color="warning"
        supportingText={
          <>
            {`${t('global.업로드 가능한 개수를 초과했습니다.')}`} <br />
            {`${t('global.확인 후 다시 시도해주세요.')}`} <br />
            <br />
            {`${t('global.남은 사용량')}`} : n{window.locale === 'ko' && '개'}
          </>
        }
        open={openPlanModal}
        onClose={planModalClose}
      />
      <RDSToast.AlertArea openedAlert={invalidToast} customStyle={{ top: 'auto', bottom: 16 }} />
      <RDSBottomSheet
        zIndex={99}
        customStyle={{ width: '500px' }}
        visible={openBottomSheet}
        header={
          t('global.업로드 진행상황') +
          `(${uploadFiles.filter((item) => item.state === 'viewerComplete').length}/${uploadFiles.length})`
        }
        onCancelClick={() => {
          closeBottomSheet();
        }}
        onCollapse={(collapse: boolean) => {
          amplitude.sendEvent(amplitudeEvents.bottomsheet.zero_allmodel_bottomsheet_layer, {
            status: collapse === true ? 'unfold' : 'fold',
          });
        }}
      >
        <UploadProgress />
      </RDSBottomSheet>
    </>
  );
};

export default GlobalScopeComponents;
