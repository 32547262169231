import { useQuery } from 'react-query';
import { getJWTHeaderFromLocalStorage } from '../../fetchInstance/axios';
import { axiosInstance } from '../../fetchInstance/customAxios';
import { queryKeys } from './constants';

export const checkOverViewCount = async (): Promise<any> => {
  const { data } = await axiosInstance.get(`/user/limit`, {
    headers: getJWTHeaderFromLocalStorage(),
  });
  return data;
};

export function useLimits() {
  const { data, refetch } = useQuery([queryKeys.userLimits], () => checkOverViewCount());
  return { data, refetch };
}
