import { useEffect, useState } from 'react';
import {
  CheckOutlinedIcon,
  RDSLinkButton,
  RDSIconButton,
  ReceiptLongOutlinedIcon,
} from '@reconlabs/reconlabs-fe-components';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/utils/useAuth';
import { checkBillingInfo, getPaymentHistory } from '../../../utils/payment';
import DataTable from 'react-data-table-component';
import { TIER } from '../../../constants/values';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';

const PaymentHistory = ({ handleLoader }: { handleLoader: Function }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [nextPayday, setNextPayDay] = useState('-');
  const [datatableData, setDatatableData] = useState([]);

  //가격 세팅
  let amount;
  switch (user.user_tier) {
    case TIER.Free:
      amount = '무료';
      break;
    case TIER.Basic:
      amount = `49,000`;
      break;
    case TIER.Plus:
      amount = `149,000`;
      break;
    case TIER.Enterprise:
      amount = '-';
      break;
  }

  useEffect(() => {
    setBillingDate();
    handlePaymentHistory();
  }, [user.user_tier]);

  //다음 결제일 세팅
  const setBillingDate = async () => {
    handleLoader('start');
    const res = await checkBillingInfo();
    handleLoader('end');
    if (!res) return;
    setNextPayDay(dateToKRFormat(res.nextPaymentDate));
  };

  //결제 내역 조회
  const handlePaymentHistory = async () => {
    handleLoader('start');
    const res = await getPaymentHistory([0, 9]);
    handleLoader('end');
    res.payments = res.payments.map((paymentHistory: any) => {
      paymentHistory.payment_date = dateToKRFormat(paymentHistory.payment_date);
      paymentHistory.period_use = paymentHistory.period_use.map((date: string) => dateToKRFormat(date)).join(' ~ ');
      paymentHistory.amount = paymentHistory.amount.toLocaleString() + t(`Payment.원`);
      return paymentHistory;
    });
    setDatatableData(res.payments as any);
  };

  //영수증 팝업
  const linkRecepit = (recepitURL: string) => {
    amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_history_receipt);
    window.open(recepitURL, '_blank', 'height=800,width=500');
  };

  return (
    <div className="PaymentHistory">
      <div className="PaymentHistory__info">
        <div className="PaymentHistory__info__box">
          <div className="PaymentHistory__info__box__item">
            <div className="PaymentHistory__info__box__item__label">{user.user_tier}</div>
            <div className="PaymentHistory__info__box__item__detail">
              {user.user_tier === 'Free'
                ? window.locale === 'ko'
                  ? '무료'
                  : 'Free'
                : `${t('Payment.월')} ${amount}${t('Payment.원')}`}
            </div>
          </div>
          <div className="PaymentHistory__info__box__item">
            <div className="PaymentHistory__info__box__item__label">{`${t('Payment.다음 결제일')}`}</div>
            <div className="PaymentHistory__info__box__item__detail">{nextPayday}</div>
          </div>
          <div className="PaymentHistory__info__box__item">
            <div className="PaymentHistory__info__box__item__list">
              <CheckOutlinedIcon color="#72D7E4" />
              {`${t(`Payment.${user.user_tier} 기능.1`)}`}
            </div>
            <div className="PaymentHistory__info__box__item__list">
              <CheckOutlinedIcon color="#72D7E4" />
              {`${t(`Payment.${user.user_tier} 기능.2`)}`}
            </div>
            <div className="PaymentHistory__info__box__item__list">
              <CheckOutlinedIcon color="#72D7E4" />
              {`${t(`Payment.${user.user_tier} 기능.3`)}`}
            </div>
            <div className="PaymentHistory__info__box__item__list">
              <CheckOutlinedIcon color="#72D7E4" />
              {`${t(`Payment.${user.user_tier} 기능.4`)}`}
            </div>
            {(user.user_tier === 'Free' || user.user_tier === 'Enterprise') && (
              <div className="PaymentHistory__info__box__item__list">
                <CheckOutlinedIcon color="#72D7E4" />
                {`${t(`Payment.${user.user_tier} 기능.5`)}`}
              </div>
            )}
          </div>
        </div>
        <RDSLinkButton
          color="information"
          label={`${t('Payment.결제 관련 문의')}`}
          size="xsmall"
          navigateFn={() => {
            amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_history_contact);
            (window as any).ChannelIO('openChat');
          }}
        />
      </div>
      <div className="PaymentHistory__datatable">
        <div>
          <DataTable
            columns={[
              {
                name: `${t('Payment.결제일')}`,
                selector: (row: any) => row.payment_date,
                width: '160px',
                center: true,
              },
              { name: `${t('Payment.플랜')}`, selector: (row: any) => row.plan, width: '100px', center: true },
              {
                name: `${t('Payment.이용 기간')}`,
                selector: (row: any) => row.period_use,
                width: '270px',
                center: true,
              },
              {
                name: `${t('Payment.결제 수단')}`,
                selector: (row: any) => row.payment_method,
                width: '230px',
                center: true,
              },
              { name: `${t('Payment.결제 금액')}`, selector: (row: any) => row.amount, width: '140px', center: true },
              {
                name: `${t('Payment.영수증')}`,
                selector: (row: any) => row.receipt,
                width: '80px',
                center: true,
                cell: (row: any) => (
                  <RDSIconButton
                    icon={<ReceiptLongOutlinedIcon />}
                    color="primary-light"
                    size="xsmall"
                    onClick={() => linkRecepit(row.receipt)}
                  />
                ),
              },
            ]}
            data={datatableData}
            noDataComponent={<div></div>}
            fixedHeader={true}
            fixedHeaderScrollHeight="50vh"
          />
        </div>
      </div>
    </div>
  );
};

//dateString: YYYY-MM-DD HH:mm:ss
const dateToKRFormat = (dateString: string) => {
  const date = new Date(dateString.replace(' ', 'T') + '.000Z');
  return date.toISOString().split('T')[0].replace('-', `년 `).replace('-', `월 `) + '일';
};

export default PaymentHistory;
