import { baseUrl } from './axios';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { refreshToken } from '../utils/login';
import { getRefreshToken } from '../utils/localstorage';
const config: AxiosRequestConfig = { baseURL: baseUrl };

const axiosInstance = axios.create(config);
axiosInstance.prototype.retryCount = 0;

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error: AxiosError) => {
    if (axiosInstance.prototype.retryCount > 2) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        return;
      } else {
        return Promise.reject(error);
      }
    }
    axiosInstance.prototype.retryCount++;
    if (error.response?.status === 401 || error.response?.status === 403) {
      console.log(`${error.response.status} error, assume as token staled and get another idtoken with refresh token`);
      const refresh_token = getRefreshToken();
      if (!refresh_token) {
        console.log('refresh token not exist');
        handleInvalidUserSession();
        throw Promise.reject(error);
      }
      try {
        const access_token = await refreshToken(refresh_token);
        if (error.config == null) return Promise.reject(error); 
        const { url, params, data, method } = error.config;
        const res = await axiosInstance({
          method,
          data,
          url,
          params,
          headers: { Authorization: `Bearer ${access_token}` },
        });
        return res;
      } catch (error: any) {
        console.log('failed to get token by refresh token');
        handleInvalidUserSession();
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  },
);

const handleInvalidUserSession = () => {
  // invalid user는 로그인창이 아닌경우에 로그인창으로 보내고 alert띄우기
  if (window.location.pathname !== '/login' && window.location.pathname !== '/') {
    window.location.pathname = '/login';
    alert('세션이 만료되었습니다. 다시 로그인해주세요');
  }
};
export { axiosInstance };
