import { Locale } from '../constants/type';

export const JWT_ACCESS_TOKEN_LOCALSTORAGE_KEY = 'plicarzero.access_token';
export const JWT_REFRESH_TOKEN_LOCALSTORAGE_KEY = 'plicarzero.refresh_token';

export const getAccessToken = () => localStorage.getItem(JWT_ACCESS_TOKEN_LOCALSTORAGE_KEY);

export const getRefreshToken = () => localStorage.getItem(JWT_REFRESH_TOKEN_LOCALSTORAGE_KEY);

export const setAccessToken = (value: string) => localStorage.setItem(JWT_ACCESS_TOKEN_LOCALSTORAGE_KEY, value);

export const setRefreshToken = (value: string) => localStorage.setItem(JWT_REFRESH_TOKEN_LOCALSTORAGE_KEY, value);

export const setToken = (token: { access_token?: string; refresh_token?: string }) => {
  const { access_token, refresh_token } = token;
  access_token ? setAccessToken(access_token) : null;
  refresh_token ? setRefreshToken(refresh_token) : null;
};

export const getLocale = (): Locale => {
  let locale = localStorage.getItem('plicarzero-locale');
  if (locale === null) {
    locale = 'ko';
  } else if (locale !== 'ko' && locale !== 'en') {
    locale = 'ko';
  }
  return locale as Locale;
};
