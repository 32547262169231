import React, { useState } from 'react';
import { RDSIconButton, XMarkIcon, RDSModal } from '@reconlabs/reconlabs-fe-components';
import { convertSizeUnit } from '../../../../utils/utils';
import { UploadFile } from '../RDSUpload';
import { useTranslation } from 'react-i18next';
import amplitude from '../../../../utils/amplitude';
import amplitudeEvents from '../../../../constants/amplitudeEvents';

const UploadWaitingFileBox = ({ uploadFile, abortUpload }: { uploadFile: UploadFile; abortUpload: Function }) => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  /*----------------------------------------
                Bueiness Logic
   ----------------------------------------*/
  const deleteFile = async () => {
    await abortUpload(uploadFile);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <RDSModal
        open={openCancelModal}
        onClose={() => setOpenCancelModal(false)}
        buttonType="noBox"
        title={`${t('global.파일을 제거할까요?')}`}
        supportingText={`${t('global.제거하시면 해당 파일은 목록에서 사라집니다.')}`}
        button1Label={t('global.제거')}
        button1Fn={() => {
          amplitude.sendEvent(amplitudeEvents.bottomsheet.zero_allmodel_bottomsheet_list_waiting_delete, {
            button: 'delete',
          });
          deleteFile();
        }}
        button2Label={t('MyPage.취소')}
        button2Fn={() => {
          amplitude.sendEvent(amplitudeEvents.bottomsheet.zero_allmodel_bottomsheet_list_waiting_delete, {
            button: 'cancel',
          });
          setOpenCancelModal(false);
        }}
        enableCloseButton={false}
        customStyle={{
          ['.RDSButton__noBox__primary ']: {
            color: '#E94A51',
          },
        }}
      />
      <div className="UploadWaiting Separator">
        <div className="UploadWaiting__header">
          <div className="UploadWaiting__header__name">{uploadFile.file.name}</div>
          <div className="UploadWaiting__header__wrapper">
            <div className="UploadWaiting__header__wrapper__volume">({convertSizeUnit(uploadFile.file.size)}MB)</div>
            <RDSIconButton
              icon={<XMarkIcon />}
              size="small"
              color="grey"
              type="noBox"
              onClick={() => setOpenCancelModal(true)}
            />
          </div>
        </div>
        <div className={window.locale === 'ko' ? 'UploadWaiting__state' : 'UploadWaiting__state-en'}>
          <div
            className={window.locale === 'ko' ? 'UploadWaiting__state__text' : 'UploadWaiting__state__text-en'}
          >{`${t('global.대기중')}`}</div>
          <progress value={0} max={100}></progress>
        </div>
      </div>
    </>
  );
};

export default UploadWaitingFileBox;
