import { RDSToast } from '@reconlabs/reconlabs-fe-components';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/utils/useAuth';
import useLocale from '../../hooks/utils/useLocale';
import GlobalScopeComponents from '../GlobalScopeComponents';
import amplitude from '../../utils/amplitude';
import ChannelService from '../../utils/ChannelService';

const PrivateRoutesLayout = () => {
  /*----------------------------------------
                    Data
   ----------------------------------------*/
  const location = useLocation();
  const navigate = useNavigate();
  const [toast, setToast] = useState<string>('loading');
  const [loggedin, setLoggedin] = useState(false);
  const { user, checkUserWithToken } = useAuth();
  // 사용되지 않더라도 useLocale 을 호출해주어야 localStorage 에 저장된 언어로 최초 로그인시 적용 됩니다.
  const { locale, setLocale } = useLocale();
  checkUserWithToken();
  /*----------------------------------------
                  Analytics
   ----------------------------------------*/
  const setAmplitudeUserEmail = (uid: string) => {
    amplitude.setUserId(uid);
  };

  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    ChannelService.boot({
      pluginKey: process.env.REACT_APP_CHANNELTALK_PLUGIN_KEY,
      profile: {
        name: user.user_name ? user.user_name : '',
        email: user.user_email,
      },
    });
    if (user.uid) {
      setToast('');
      setAmplitudeUserEmail(user.uid);
      setLoggedin(true);
    }

    ChannelService.onHideMessenger(() => {
      if (location.hash.length > 0) {
        navigate(location.pathname);
      }
    });
  }, [user]);
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return loggedin ? (
    <>
      <GlobalScopeComponents /> <Outlet />
    </>
  ) : (
    <RDSToast.Overlay
      type="loadingSpinner"
      toastOverlayId="loading"
      openedToastOverlay={toast}
      setOpenedToastOverlay={setToast}
    />
  );
};

export default PrivateRoutesLayout;
