import { axiosInstance } from '../fetchInstance/customAxios';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { getJWTHeaderFromLocalStorage } from '../fetchInstance/axios';
import { setAccessToken } from './localstorage';
import { Tier } from '../constants/type';

const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;

//유저의 정기 결제 정보 반환
export const checkBillingInfo = async () => {
  const res = await axiosInstance.get(`/payment/billing`, { headers: getJWTHeaderFromLocalStorage() });
  return res.data
    ? {
        nextTier: res.data.nextTier,
        nextPaymentDate: res.data.nextPaymentDate,
        pause: res.data.pause === 1,
      }
    : null;
};

//다음 정기 결제일에 적용될 티어 변경(다운그레이드 / 유지)
export const changeNextTier = async (nextTier: Tier) => {
  const res = await axiosInstance.put(`/payment/downgrade`, { nextTier }, { headers: getJWTHeaderFromLocalStorage() });
  return res.data.nextTier;
};

//카드 검증 창 띄우기
export const loadPayment = async (userUid: string, redirectURL: string, queryString?: string) => {
  const tossPayments = await loadTossPayments(clientKey as string);
  tossPayments.requestBillingAuth('카드', {
    customerKey: userUid,
    successUrl: `${redirectURL}/success?${queryString}`,
    failUrl: `${redirectURL}/fail?${queryString}`,
  });
};

//자동 결제 등록 및 결제 진행
export const billing = async (authKey: string, tier: Tier) => {
  const res = await axiosInstance.post(
    `/payment/billing`,
    { authKey, tier },
    { headers: getJWTHeaderFromLocalStorage() },
  );
  setAccessToken(res.data.token);
  return res;
};

//결제 내역 조회
type getPaymentHistoryRes = {
  payments: {
    payment_date: string;
    plan: string;
    period_use: [string, string];
    payment_method: string;
    amount: number;
    receipt: string;
  }[];
  count: number;
  page: number;
  totalPage: number;
};
export const getPaymentHistory = async (range: number[]): Promise<getPaymentHistoryRes> => {
  const res = await axiosInstance.get(`/payment/list`, {
    params: { range: JSON.stringify(range) },
    headers: getJWTHeaderFromLocalStorage(),
  });
  return res.data;
};

//자동 결제 등록된 카드 정보 요청
export const getBillingCardInfo = async () => {
  const res = await axiosInstance.get(`/payment/billing/card`, { headers: getJWTHeaderFromLocalStorage() });
  return res.data.card_number;
};

//자동 결제 등록 카드 변경
export const changeBillingCard = async (authKey: string): Promise<string> => {
  const res = await axiosInstance.put(
    `/payment/billing/card`,
    { authKey },
    { headers: getJWTHeaderFromLocalStorage() },
  );
  return res.data.card_number;
};

//재결제 시도(일시 정지 해제)
export const repayment = async () => {
  const res = await axiosInstance.post(`/payment/billing/repayment`, {}, { headers: getJWTHeaderFromLocalStorage() });
  return res;
};

//유료 티어 -> 유료 티어 업그레이드 정보 요청
export const getUpgradeTierPaymentInfo = async (nextTier: Tier) => {
  const res = await axiosInstance.get('/payment/upgrade', {
    headers: getJWTHeaderFromLocalStorage(),
    params: { tier: nextTier },
  });

  return res.data;
};

//유료 티어 -> 유료 티어 업그레이드(차액 결제)
export const upgradeTier = async (nextTier: Tier) => {
  const res = await axiosInstance.post(
    '/payment/upgrade',
    { tier: nextTier },
    { headers: getJWTHeaderFromLocalStorage() },
  );
  setAccessToken(res.data.token);
  return res;
};
