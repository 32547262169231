import React, { useState } from 'react';
import Cafe24Image from '../../../images/Cafe24Image.png';
import Cafe24ImageEn from '../../../images/Cafe24ImageEn.png';
import ImwebImage from '../../../images/ImwebImage.png';
import ImwebImageEn from '../../../images/ImwebImageEn.png';
import WordImage from '../../../images/WordImage.png';
import WordImageEn from '../../../images/WordImageEn.png';
import ShowpifyImage from '../../../images/ShowpifyImage.png';
import ShowpifyImageEn from '../../../images/ShowpifyImageEn.png';
import CodeImage from '../../../images/CodeImage.png';
import CodeImageEn from '../../../images/CodeImageEn.png';
import NaverImage from '../../../images/NaverImage.png';
import NaverImageEn from '../../../images/NaverImageEn.png';
import { ExpandLessOutlinedIcon } from '@reconlabs/reconlabs-fe-components';
import KeyboardArrowDownOutlined from '../../icons/KeyboardArrowDownOutlined';
import NaverGuide from './guide/NaverGuide';
import Cafe24Guide from './guide/Cafe24Guide';
import ImwebGuide from './guide/ImwebGuide';
import WordGuide from './guide/WordGuide';
import ShowpifyGuide from './guide/ShowpifyGuide';
import SelfGuide from './guide/SelfGuide';
import { useTranslation } from 'react-i18next';

const DetailViewerGuide = () => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const [selectedButton, setSelectedButton] = useState<number>(0);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const getText = () => {
    return selectedButton === 1 ? (
      <>
        네이버 스마트 스토어는 네이버 블로그에 ‘하이퍼링크’ 삽입으로{' '}
        <span className="DetailViewerGuide__Contents__Explain__Highlight" style={{ marginLeft: '5px' }}>
          AR뷰어 버튼
        </span>
        을 지원합니다.
      </>
    ) : selectedButton === 2 ? (
      <>
        카페24는 ‘자바스크립트' 삽입으로
        <span className="DetailViewerGuide__Contents__Explain__Highlight" style={{ marginLeft: '5px' }}>
          3D뷰어 /AR뷰어 버튼
        </span>
        을 모두 지원합니다.
      </>
    ) : selectedButton === 3 ? (
      <div style={{ textAlign: 'center' }}>
        아임웹은 유료회원의 경우 ‘자바스크립트'로
        <span className="DetailViewerGuide__Contents__Explain__Highlight" style={{ marginLeft: '5px' }}>
          3D뷰어 /AR뷰어 버튼{' '}
        </span>
        삽입을 모두 지원하지만,
        <br />
        무료회원의 경우 ‘하이퍼링크'로
        <span className="DetailViewerGuide__Contents__Explain__Highlight" style={{ marginLeft: '5px' }}>
          AR뷰어 버튼{' '}
        </span>
        삽입을 지원합니다.
      </div>
    ) : selectedButton === 4 ? (
      <>
        워드프레스는 ‘자바스크립트' 코드 삽입으로
        <span className="DetailViewerGuide__Contents__Explain__Highlight" style={{ marginLeft: '5px' }}>
          3D뷰어 /AR뷰어 버튼
        </span>
        을 모두 지원합니다.
      </>
    ) : selectedButton === 5 ? (
      <>
        쇼피파이는 ‘자바스크립트' 코드 삽입으로
        <span className="DetailViewerGuide__Contents__Explain__Highlight" style={{ marginLeft: '5px' }}>
          3D뷰어 /AR뷰어 버튼
        </span>
        을 모두 지원합니다.
      </>
    ) : selectedButton === 6 ? (
      <>
        자체 개발 코드에 ‘자바스크립트'를 삽입하면
        <span className="DetailViewerGuide__Contents__Explain__Highlight" style={{ marginLeft: '5px' }}>
          3D뷰어 /AR뷰어 버튼
        </span>
        을 모두 보여줄 수 있습니다.
      </>
    ) : (
      ''
    );
  };

  const getContents = () => {
    return selectedButton === 1 ? (
      <NaverGuide />
    ) : selectedButton === 2 ? (
      <Cafe24Guide />
    ) : selectedButton === 3 ? (
      <ImwebGuide />
    ) : selectedButton === 4 ? (
      <WordGuide />
    ) : selectedButton === 5 ? (
      <ShowpifyGuide />
    ) : selectedButton === 6 ? (
      <SelfGuide />
    ) : (
      ''
    );
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div className="DetailViewerGuide">
      <div className="DetailViewerGuide__Title">{`${t('Detail.뷰어 삽입 방법')}`}</div>
      <div className="DetailViewerGuide__Sub">{`${t(
        'Detail.판매 사이트마다 다른 뷰어 삽입 방법을 자세히 알려드릴게요.',
      )}`}</div>
      <div className="DetailViewerGuide__Wrapper">
        <div className="DetailViewerGuide__Wrapper__Header">{`${t(
          'Detail.이용 중인 판매 사이트 제작 솔루션을 선택해주세요.',
        )}`}</div>
        <div className="DetailViewerGuide__Wrapper__Buttons">
          <button
            className={selectedButton === 1 ? 'GuideButton select' : 'GuideButton'}
            onClick={() => setSelectedButton(1)}
          >
            <img
              width={window.locale === 'ko' ? 166 : 170}
              height={48}
              src={window.locale === 'ko' ? NaverImage : NaverImageEn}
              alt="네이버 스마트 스토어 이미지"
            />
          </button>
          <button
            className={selectedButton === 2 ? 'GuideButton select' : 'GuideButton'}
            onClick={() => setSelectedButton(2)}
          >
            <img
              width={window.locale === 'ko' ? 104 : 105}
              height={24}
              src={window.locale === 'ko' ? Cafe24Image : Cafe24ImageEn}
              alt="카페24 이미지"
            />
          </button>
          <button
            className={selectedButton === 3 ? 'GuideButton select' : 'GuideButton'}
            onClick={() => setSelectedButton(3)}
          >
            <img
              width={window.locale === 'ko' ? 96 : 100}
              height={48}
              src={window.locale === 'ko' ? ImwebImage : ImwebImageEn}
              alt="아임웹 이미지"
            />
          </button>
          <button
            className={selectedButton === 4 ? 'GuideButton select' : 'GuideButton'}
            onClick={() => setSelectedButton(4)}
          >
            <img
              width={window.locale === 'ko' ? 117 : 127}
              height={48}
              src={window.locale === 'ko' ? WordImage : WordImageEn}
              alt="워드프레스 이미지"
            />
          </button>
          <button
            className={selectedButton === 5 ? 'GuideButton select' : 'GuideButton'}
            onClick={() => setSelectedButton(5)}
          >
            <img
              width={107}
              height={48}
              src={window.locale === 'ko' ? ShowpifyImage : ShowpifyImageEn}
              alt="쇼피파이 이미지"
            />
          </button>
          <button
            className={selectedButton === 6 ? 'GuideButton select' : 'GuideButton'}
            onClick={() => setSelectedButton(6)}
          >
            <img
              width={window.locale === 'ko' ? 110 : 194}
              height={48}
              src={window.locale === 'ko' ? CodeImage : CodeImageEn}
              alt="자체개발 이미지"
            />
          </button>
        </div>
        <div className="DetailViewerGuide__Wrapper__Arrow">
          {selectedButton ? (
            <ExpandLessOutlinedIcon
              width={48}
              height={48}
              color="#72D7E4"
              style={{ cursor: 'pointer' }}
              onClick={() => setSelectedButton(0)}
            />
          ) : (
            <KeyboardArrowDownOutlined
              width={48}
              height={48}
              color="#72D7E4"
              style={{ cursor: 'pointer' }}
              onClick={() => setSelectedButton(1)}
            />
          )}
        </div>
        {selectedButton ? (
          <div className="DetailViewerGuide__Contents">
            <div className="DetailViewerGuide__Contents__Explain">{getText()}</div>
            {getContents()}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default DetailViewerGuide;
