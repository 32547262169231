import React from 'react';

const EnvViewer = () => {
  const domain = process.env.REACT_APP_SERVER_DOMAIN;
  const isVisible = domain!.indexOf('qa') >= 0 || domain!.indexOf('stag') >= 0 || domain!.indexOf('dev') >= 0;
  return isVisible ? (
    <div style={{ padding: '20px' }}>
      env viewer page <br />
      <br />
      REACT_APP_SERVER_DOMAIN = {process.env.REACT_APP_SERVER_DOMAIN} <br />
      REACT_APP_KAKAO_CLIENT_ID = {process.env.REACT_APP_KAKAO_CLIENT_ID} <br />
      REACT_APP_GOOGLE_CLIENT_ID = {process.env.REACT_APP_GOOGLE_CLIENT_ID} <br />
      REACT_APP_GOOGLE_CLIENT_SCOPE = {process.env.REACT_APP_GOOGLE_CLIENT_SCOPE} <br />
      REACT_APP_NAVER_CLIENT_ID = {process.env.REACT_APP_NAVER_CLIENT_ID} <br />
      REACT_APP_ASSET_DOMAIN = {process.env.REACT_APP_ASSET_DOMAIN} <br />
      REACT_APP_PLICAR_ZERO_PROTOCOL = {process.env.REACT_APP_PLICAR_ZERO_PROTOCOL} <br />
      REACT_APP_PLICAR_ZERO_DOMAIN = {process.env.REACT_APP_PLICAR_ZERO_DOMAIN} <br />
      REACT_APP_PLICAR_ZERO_PORT = {process.env.REACT_APP_PLICAR_ZERO_PORT} <br />
      REACT_APP_WEBSOCKET_SERVER_DOMAIN = {process.env.REACT_APP_WEBSOCKET_SERVER_DOMAIN} <br />
    </div>
  ) : (
    <></>
  );
};

export default EnvViewer;
