import { useMutation, useQueryClient } from 'react-query';
import { getJWTHeaderFromLocalStorage } from '../../fetchInstance/axios';
import { UpdateModelInfo } from '../../types/modelTypes';
import { queryKeys } from './constants';
import { PaginationParam } from '../../types/types';
import { axiosInstance } from '../../fetchInstance/customAxios';
/**
 * API 문서
 * https://www.notion.so/reconlabs/model-put-97f02027a387499ab1b652d7d3fc3063
 *  */
export async function updateModel(model: UpdateModelInfo): Promise<any> {
  const result = await axiosInstance.put(
    `/model`,
    {
      model,
    },
    {
      headers: getJWTHeaderFromLocalStorage(),
    },
  );

  return result;
}

export function useUpdateModel(paginationParam: PaginationParam) {
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isLoading, error } = useMutation((modelInfo: UpdateModelInfo) => updateModel(modelInfo), {
    onError: () => {
      console.error('model update error');
    },
    onSuccess: (result: any) => {
      const model = result.data.model;
      const uid = model.uid;

      // model/list API 데이터 업데이트
      queryClient.setQueryData([queryKeys.modelsList, paginationParam], (oldData: any) => {
        const models = oldData.models;
        const findModel = models.find((model: any) => model.uid === uid);
        const index = models.indexOf(findModel);
        models[index] = { ...findModel, ...model }; // 기존 데이터에 갱신된 데이터를 덮어씁니다
        return oldData;
      });

      // model API 데이터 업데이트
      queryClient.setQueryData([queryKeys.model, uid], (oldData: any) => {
        return { ...oldData, ...model }; // 갱신된 데이터를 덮어씁니다
      });
    },
  });

  return { mutate, isUpdateSuccess: isSuccess, isLoading, error };
}

async function deleteModel(uid: string): Promise<any> {
  const result = await axiosInstance.delete(`/model`, {
    params: {
      model_uid: uid,
    },
    headers: getJWTHeaderFromLocalStorage(),
  });

  return result;
}

export function useDeleteModel() {
  const { mutateAsync } = useMutation((uid: string) => deleteModel(uid), {
    onError: () => {
      console.error('model delete error');
    },
    onSuccess: (result: any) => {
      console.log('delete success : ', result);
    },
  });

  return { mutateAsync };
}
