import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import NoDataImg from './NoDataImg';
import NoDataImgEn from './NoDataImgEn';
import NoModelsImg from './NoModelsImg';
import NoModelsImgEn from './NoModelsImgEn';
import DataTable from 'react-data-table-component';
import PaginationComponent from './PaginationComponent';
import { copyToClipboard } from '../../../utils/clipboardUtils';
import { useNavigate } from 'react-router-dom';
import { createCatalog } from '../../../hooks/react-query/useCatalog';
import { postSurvey, useSurvey } from '../../../hooks/react-query/useZeroUser';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContextProvider';
import { useModelsList } from '../../../hooks/react-query/useThreeDModel';
import CatalogAuthImg from '../../../images/CatalogAuthImg';
import {
  useCatalogList,
  useDeleteCatalog,
  usePublishCatalog,
  useStopCatalogPublish,
} from '../../../hooks/react-query/useCatalog';
import {
  RDSSearchBar,
  RDSDropdown,
  RDSOption,
  RDSIconTextButton,
  AddIcon,
  RDSSwitch,
  RDSIconButton,
  RDSLinkButton,
  RDSModalInput,
  RDSButton,
  RDSToastAlertObject,
  RDSToast,
  RDSModal,
  ThumbDownOutlinedIcon,
  ThumbUpOutlinedIcon,
  LaunchIcon,
  ThumbUpFilledIcon,
  ThumbDownFilledIcon,
  ReplyIcon,
} from '@reconlabs/reconlabs-fe-components';
import { dateToString } from '../../../utils/utils';
import amplitude, { changeCatalogListFilter } from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';

const CatalogList = () => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  // const
  const { t } = useTranslation();
  const navigate = useNavigate();

  // context
  const { user } = useContext(UserContext);

  // boolean
  const [openCreateCatalogModal, setOpenCreateCatalogModal] = useState<boolean>(false);
  const [openDeleteCatalogModal, setOpenDeleteCatalogModel] = useState<boolean>(false);
  const [openPublishModal, setOpenPublishModal] = useState<boolean>(false);
  const [openStopPublishModal, setOpenStopPublishModal] = useState<boolean>(false);
  const [openPublishCompleteModal, setOpenPublishCompleteModal] = useState<boolean>(false);
  const [dontShowAgain, setDontShowAgain] = useState<boolean>(false);

  // interval variables
  const [catalogIdList, setCatalogIdList] = useState<string[]>([]);
  const [status, setStatus] = useState<string>('default');
  const [recent, setRecent] = useState<string>('desc');
  const [switchClickedCatalogUid, setSwitchClickedCatalogUid] = useState<string>('');
  const [publishUrl, setPublishUrl] = useState<string>('');
  const [datatableRowCount, setDataTableRowCount] = useState<number>(20);
  const [surveyResponse, setSurveyResponse] = useState<string | undefined>();
  const [catalogCreateModalInput, setCatalogCreateModalInput] = useState<string | number>('');
  const [isFirst, setIsFirst] = useState<boolean>(true);

  // spinner
  const [spinner, setSpinner] = useState<string>('');

  // API
  const {
    data: catalogListData,
    refetch,
    paginationParam,
    setPaginationParam,
    isSuccess,
  } = useCatalogList({ range: [0, 19], sortColumn: 'modified_date', sortOrder: recent });
  const { data: surveyData } = useSurvey();
  const { mutateAsync: deleteCatalogMutation } = useDeleteCatalog();
  const { mutateAsync: publishCatalogMutate } = usePublishCatalog();
  const { mutateAsync: stopPublishCatalogMutate } = useStopCatalogPublish();
  const { count: modelCount } = useModelsList({ range: [0, 49] });

  // Alert
  const [deleteAlert, setDeleteAlert] = useState<RDSToastAlertObject>();

  // DataTable Columns
  const [columns] = useState<any[]>([
    {
      name: t('Catalog.카탈로그 제목'),
      selector: (row: any) => row.catalog_name,
      left: true,
      cell: (row: any) => {
        return (
          <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/dashboard/catalog/${row.uid}`)}>
            {row.catalog_name}
          </div>
        );
      },
    },
    {
      width: '160px',
      name: t('Catalog.상태'),
      selector: (row: any) => row.catalog_state,
      center: true,
      cell: (row: any) => {
        if (row.catalog_state === 'published') {
          return (
            <div className="CatalogPublished">
              {`${t('Catalog.발행 완료')}`}{' '}
              <RDSSwitch
                enableLabel
                isOn={row.catalog_state === 'published'}
                handleClick={() => {
                  amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_publish, { type: 'off' });
                  setSwitchClickedCatalogUid(row.uid);
                  setOpenStopPublishModal(true);
                }}
              />
            </div>
          );
        } else if (row.catalog_state === 'not_published') {
          return (
            <div className="CatalogStopPublish">
              {`${t('Catalog.발행 중단')}`}{' '}
              <RDSSwitch
                enableLabel
                isOn={row.catalog_state === 'published'}
                handleClick={() => {
                  amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_publish, {
                    type: 'on',
                  });

                  if (localStorage.getItem('plicar-zero_catalog_closed_modal')) {
                    publishCatalog(row.uid);
                  } else {
                    setSwitchClickedCatalogUid(row.uid);
                    setOpenPublishModal(true);
                  }
                }}
              />
            </div>
          );
        } else {
          return (
            <div className="CatalogTempSaved">
              {`${t('Catalog.임시 저장')}`}{' '}
              <RDSSwitch enableLabel isOn={row.catalog_state === 'published'} disabled handleClick={() => {}} />
            </div>
          );
        }
      },
    },
    {
      width: '160px',
      name: t('Catalog.최종 수정일(발행일)'),
      selector: (row: any) => row.modified_date,
      center: true,
      cell: (row: any) => {
        const date = row.modified_date.replaceAll('-', '/');
        const utcDate = new Date(date);
        utcDate.setTime(utcDate.getTime() + 9 * 60 * 60 * 1000);
        const koreaTime = dateToString(utcDate);
        return <>{koreaTime}</>;
      },
    },
    {
      width: '80px',
      name: t('Catalog.링크'),
      selector: (row: any) => row.uid,
      center: true,
      cell: (row: any) => {
        return (
          <RDSIconButton
            type="noBox"
            icon={<LaunchIcon />}
            disabled={!row.catalog_published}
            onClick={() => {
              amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_list_readonly);
              window.open(`${process.env.REACT_APP_CATALOG_PUBLISH_DOMAIN}/${row.catalog_published_uid}`);
            }}
          />
        );
      },
    },
  ]);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (localStorage.getItem('plicar-catalog-access')) {
      setIsFirst(false);
    }
  }, []);

  useEffect(() => {
    if (!isSuccess) {
      setSpinner('loading-spinner');
    } else {
      setSpinner('');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (recent && catalogListData) {
      const currentPage = catalogListData.page;
      const startIndex = datatableRowCount * (currentPage - 1);
      const endIndex = datatableRowCount * currentPage - 1;
      setPaginationParam({ range: [startIndex, endIndex], sortColumn: 'modified_date', sortOrder: recent });
    }
  }, [recent]);

  useEffect(() => {
    if (surveyData) {
      setSurveyResponse(surveyData.survey?.survey_response);
    }
  }, [surveyData]);

  useEffect(() => {
    let updatedPaginationParams;
    if (status === 'default') {
      updatedPaginationParams = { ...paginationParam };
      delete updatedPaginationParams.catalogState;
    } else {
      updatedPaginationParams = { ...paginationParam, catalogState: status };
    }
    setPaginationParam(updatedPaginationParams);
  }, [status]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const handlePreSelection = (row: any) => {
    return catalogIdList.indexOf(row.uid) >= 0;
  };

  const onSelectedRowsChange = (e: any) => {
    const result: string[] = [];
    const selectedRows = e.selectedRows;
    let validation = false;

    selectedRows.forEach((row: any) => {
      result.push(row.uid);
    });

    if (selectedRows.length > catalogIdList.length) {
      selectedRows.forEach((id: string) => {
        if (catalogIdList.indexOf(id) === -1) {
          validation = true;
        } else {
          result.push(id);
        }
      });
    } else if (catalogIdList.length > selectedRows.length) {
      catalogIdList.forEach((id: string) => {
        if (selectedRows.indexOf(id) === -1) {
          validation = true;
        } else {
          result.push(id);
        }
      });
    }

    if (validation) {
      setCatalogIdList(result);
    }
  };

  const create = async (title: string) => {
    setOpenCreateCatalogModal(false);
    setSpinner('loading-spinner');
    const result = await createCatalog(title, window.locale);
    setSpinner('');
    if (result.status === 200) {
      navigate(`/dashboard/catalog/${result.data.data.uid}`);
    } else {
      console.warn('카탈로그 생성 에러');
    }
  };

  const deleteCatalog = async () => {
    let deleteCount = 0;
    let existPublished = false;

    setOpenDeleteCatalogModel(false);
    setSpinner('loading-spinner');

    for (let i = 0; i < catalogIdList.length; i++) {
      if (catalogListData.data.find((row: any) => row.uid === catalogIdList[i]).catalog_state === 'published') {
        existPublished = true;
      } else {
        await deleteCatalogMutation(catalogIdList[i]);
        deleteCount++;
      }
    }

    setSpinner('');
    refetch();

    if (deleteCount) {
      setDeleteAlert({ type: 'success', message: `${deleteCount}${t('Catalog.개의 3D 카탈로그가 삭제되었습니다.')}` });
      amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_list_delete_modal_success, { quantity: deleteCount });
    }

    if (existPublished) {
      setDeleteAlert({ type: 'failure', message: t('Catalog.발행 중인 카탈로그는 삭제할 수 없습니다.') });
      amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_list_delete_modal_failure_publishing, {
        quantity: catalogIdList.length - deleteCount,
      });
    }
  };

  const copyPublishUrl = () => {
    copyToClipboard(publishUrl);
  };
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const onChangePage = (page: number) => {
    const startIndex = datatableRowCount * (page - 1);
    const endIndex = datatableRowCount * page - 1;
    setPaginationParam({ ...paginationParam, range: [startIndex, endIndex] });
  };

  const onChangeRowsPerPage = (rowCount: number, page: number) => {
    if (rowCount !== datatableRowCount) {
      setDataTableRowCount(rowCount);
      const startIndex = rowCount * (page - 1);
      const endIndex = rowCount * page - 1;
      setPaginationParam({ ...paginationParam, range: [startIndex, endIndex] });
    }
  };

  const publishCatalog = async (catalog_uid: string) => {
    if (dontShowAgain) {
      localStorage.setItem('plicar-zero_catalog_closed_modal', 'publish_modal');
    }

    setOpenPublishModal(false);
    setSpinner('loading-spinner');
    const result = await publishCatalogMutate(catalog_uid);
    setSpinner('');
    if (result.status === 200) {
      refetch();
      setPublishUrl(`${process.env.REACT_APP_CATALOG_PUBLISH_DOMAIN}/${result.data.data.catalog_published_uid}`);
      setOpenPublishCompleteModal(true);
    }
  };

  const catalogPublishStop = async (catalog_uid: string) => {
    setSpinner('loading-spinner');
    setOpenStopPublishModal(false);
    await stopPublishCatalogMutate(catalog_uid);
    setSpinner('');
    refetch();
  };

  const surveyLike = async () => {
    setSpinner('loading-spinner');
    setSurveyResponse('like');
    await postSurvey([{ survey_id: 'service_eval', survey_response: 'like' }]);
    setSpinner('');
  };

  const surveyDislike = async () => {
    setSpinner('loading-spinner');
    setSurveyResponse('dislike');
    await postSurvey([{ survey_id: 'service_eval', survey_response: 'dislike' }]);
    setSpinner('');
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return isFirst ? (
    <div className="FreeCatalog">
      <div className="FreeCatalog__Title">{`${t('Catalog.3D 카탈로그')}`}</div>
      <div className="FreeCatalog__Subtext">{`${t('Catalog.subtext')}`}</div>
      <CatalogAuthImg />
      <RDSButton
        fullWidth
        type="outline"
        customStyle={{ marginTop: '75px' }}
        onClick={() => window.open('https://catalog.app.plicar.io/M2SmOXxUgI81JBNHn7yWd')}
      >
        {`${t('Catalog.샘플 보기')}`}
      </RDSButton>
      <RDSIconTextButton
        icon={<AddIcon />}
        fullWidth
        customStyle={{ marginTop: '10px' }}
        onClick={() => {
          localStorage.setItem('plicar-catalog-access', 'true');
          setIsFirst(false);
          setOpenCreateCatalogModal(true);
        }}
      >
        {`${t('Catalog.3D 카탈로그 만들기')}`}
      </RDSIconTextButton>
    </div>
  ) : (
    <>
      <RDSToast.Overlay
        type="loadingSpinner"
        toastOverlayId="loading-spinner"
        openedToastOverlay={spinner}
        setOpenedToastOverlay={setSpinner}
      />
      <RDSModal
        open={openDeleteCatalogModal}
        onClose={() => setOpenDeleteCatalogModel(false)}
        enableCloseButton={false}
        title={`${t('Catalog.3D 카탈로그를 삭제하시겠습니까?')}`}
        supportingText={`${t(
          'Catalog.삭제된 카탈로그는 다시 복구할 수 없으며, 기존에 제공되던 웹 페이지 링크도 서비스가 중단됩니다.',
        )}`}
        button1Label={<div data-test-id="catalog-delete-btn">{`${t('Catalog.삭제')}`}</div>}
        button1Fn={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_list_delete_modal, {
            quantity: catalogIdList.length,
          });
          deleteCatalog();
        }}
        button1Color="warning"
        button2Label={t('Catalog.취소')}
        button2Fn={() => setOpenDeleteCatalogModel(false)}
      />
      <RDSToast.AlertArea openedAlert={deleteAlert}></RDSToast.AlertArea>
      <RDSModalInput
        open={openCreateCatalogModal}
        onClose={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_add_close);
          setOpenCreateCatalogModal(false);
        }}
        enableCloseButton={false}
        title={`${t('Catalog.카탈로그 제작')}`}
        inputValue={catalogCreateModalInput}
        handleInputChange={(value) => setCatalogCreateModalInput(value)}
        buttonType="solid"
        button1Label={t('Catalog.만들기')}
        button1Fn={(title: any) => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_add_create);
          create(title);
        }}
        button1Props={{
          disabled: catalogCreateModalInput.toString().length === 0,
        }}
        button2Label={t('Catalog.닫기')}
        button2Fn={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_add_close);
          setOpenCreateCatalogModal(false);
        }}
        supportingText={`${t('Catalog.아래의 필드에 카탈로그 제목을 입력해주세요. (최대 30자)')}`}
        inputOptions={{
          placeholder: t('Catalog.카탈로그 제목을 입력해주세요.'),
        }}
      />
      <RDSModal
        open={openPublishModal}
        onClose={() => setOpenPublishModal(false)}
        enableCloseButton={false}
        title={`${t('Catalog.3D 카탈로그 발행 기능 안내')}`}
        supportingText={
          <>
            - {`${t('Catalog.on을 하면 외부로 3D 카탈로그를 공유할 수 있습니다.')}`} <br />-{' '}
            {`${t('Catalog.외부에서 3D 카탈로그를 볼 때마다 view 수가 차감됩니다.')}`} <br />-{' '}
            {`${t('Catalog.view 수 차감을 원하지 않으면 버튼을 off 로 변경해주세요.')}`} <br />
          </>
        }
        button1Label={t('Catalog.확인했습니다.')}
        button1Fn={() => publishCatalog(switchClickedCatalogUid)}
        checkboxLabel={t('Catalog.다시 보지 않기')}
        checkboxState={dontShowAgain}
        checkboxHandleCheck={() => setDontShowAgain((prev) => !prev)}
      />
      <RDSModal
        open={openPublishCompleteModal}
        onClose={() => setOpenPublishCompleteModal(false)}
        enableCloseButton={false}
        title={`${t('Catalog.3D 카탈로그 발행 완료')}`}
        supportingText={
          <>
            {`${t('Catalog.3D 카탈로그 발행이 완료되었습니다.')}`} <br />
            {`${t('Catalog.아래 카탈로그 URL로 3D/AR 뷰어를 간편하게 공유해보세요!')}`}
          </>
        }
        linkText={publishUrl}
        button1Label={t('Catalog.복사하기')}
        button1Fn={() => copyPublishUrl()}
        button2Label={t('Catalog.닫기2')}
        button2Fn={() => setOpenPublishCompleteModal(false)}
      />
      <RDSModal
        open={openStopPublishModal}
        onClose={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_unpublish_modal_cancel);
          setOpenStopPublishModal(false);
        }}
        enableCloseButton={false}
        title={`${t('Catalog.발행 중단2')}`}
        supportingText={
          <>
            {`${t('Catalog.발행을 중단하시겠습니까?')}`} <br />
            {`${t('Catalog.중단하실 경우 이미 제공된 웹 페이지에 내용이 보이지 않게됩니다.')}`}
          </>
        }
        button1Color="warning"
        button1Label={<div data-test-id="catalog-publish-stop-btn">{`${t('Catalog.중단')}`}</div>}
        button1Fn={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_unpublish_modal);
          catalogPublishStop(switchClickedCatalogUid);
        }}
        button2Label={t('Catalog.닫기')}
        button2Fn={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_unpublish_modal_cancel);
          setOpenStopPublishModal(false);
        }}
      />
      <div className="CatalogList container-fluid">
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div>
            <Row>
              <Col xl={1}></Col>
              <Col xl={10}>
                <div className="CatalogList__Title">{`${t('Catalog.3D 카탈로그')}`}</div>
                <div className="CatalogList__SubText">
                  {`${t('Catalog.여러 개의 상품들을 3D/AR로 볼 수 있는 페이지를 제작하실 수 있습니다')}`}
                </div>
              </Col>
              <Col xl={1}></Col>
            </Row>
            <Row style={{ marginTop: '32px' }}>
              <Col xl={1}></Col>
              <Col xl={1}>
                <RDSButton
                  size="small"
                  type="outline"
                  customStyle={{ padding: '5px', height: '40px' }}
                  disabled={catalogIdList.length === 0}
                  onClick={() => {
                    amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_list_delete);
                    setOpenDeleteCatalogModel(true);
                  }}
                  fullWidth
                >
                  {`${t('Catalog.선택 삭제')}`}
                </RDSButton>
              </Col>
              <Col xl={3}>
                <RDSSearchBar
                  searchBarId={'CatalogSearch' + user.user_email}
                  size="small"
                  handleSubmit={(value: string) => {
                    if (value) {
                      amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_search);
                    }
                    setPaginationParam({ ...paginationParam, searchStrs: [value] });
                  }}
                  placeholder={t('Catalog.찾으시는 카탈로그가 있나요?')}
                  customStyles={{ backgroundColor: 'white', ['input']: { minWidth: 0 } }}
                />
              </Col>
              <Col xl={2}>
                <RDSDropdown
                  size="small"
                  customStyles={{
                    fontFamily: 'Pretendard, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '12px',
                    letterSpacing: '0.25px',
                    width: '100%',
                  }}
                  onChange={(value) => {
                    // amplitude ------
                    const changedAmplitudeType = changeCatalogListFilter(value);
                    if (changedAmplitudeType !== 'default')
                      amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_filter_states, {
                        type: changedAmplitudeType,
                      });
                    // ----------------
                    setStatus(value);
                  }}
                  value={status}
                >
                  <React.Fragment key=".0">
                    <RDSOption value="default">{`${t('Catalog.상태별')}`}</RDSOption>
                    <RDSOption value="saved">{`${t('Catalog.임시 저장')}`}</RDSOption>
                    <RDSOption value="published">{`${t('Catalog.발행 완료')}`}</RDSOption>
                    <RDSOption value="not_published">{`${t('Catalog.발행 중단')}`}</RDSOption>
                  </React.Fragment>
                </RDSDropdown>
              </Col>
              <Col xl={2}>
                <RDSDropdown
                  size="small"
                  customStyles={{
                    fontFamily: 'Pretendard, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '12px',
                    letterSpacing: '0.25px',
                    width: '100%',
                  }}
                  onChange={(value) => {
                    amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_filter_sequence, {
                      type: value === 'desc' ? 'latest' : 'old',
                    });
                    setRecent(value);
                  }}
                  value={recent}
                >
                  <React.Fragment key=".0">
                    <RDSOption value="desc">{`${t('Catalog.최신순')}`}</RDSOption>
                    <RDSOption value="asc">{`${t('Catalog.오래된 순')}`}</RDSOption>
                  </React.Fragment>
                </RDSDropdown>
              </Col>
              <Col xl={2}>
                <RDSIconTextButton
                  fullWidth
                  size="small"
                  icon={<AddIcon />}
                  onClick={() => {
                    amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_add);
                    setOpenCreateCatalogModal(true);
                  }}
                >
                  {`${t('Catalog.3D 카탈로그 제작')}`}
                </RDSIconTextButton>
              </Col>
              <Col xl={1}></Col>
            </Row>
            <Row style={{ marginTop: '24px' }}>
              <Col xl={1}></Col>
              <Col xl={10}>
                <div
                  className={catalogListData && catalogListData.count === 0 ? 'DataTable BorderRadius' : 'DataTable'}
                >
                  <DataTable
                    data={catalogListData ? catalogListData.data : []}
                    columns={columns}
                    noDataComponent={
                      modelCount === 0 ? (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {window.locale === 'ko' ? <NoModelsImg /> : <NoModelsImgEn />}
                          <RDSIconTextButton
                            size="small"
                            type="outline"
                            icon={<ReplyIcon />}
                            customStyle={{ marginBottom: '30px' }}
                            onClick={() => navigate('/dashboard/models')}
                          >
                            {`${t('Catalog.3D 모델 업로드하러 가기')}`}
                          </RDSIconTextButton>
                        </div>
                      ) : (
                        <>{window.locale === 'ko' ? <NoDataImg /> : <NoDataImgEn />}</>
                      )
                    }
                    pagination
                    paginationServer
                    paginationComponent={PaginationComponent}
                    paginationTotalRows={catalogListData ? catalogListData.count : 0}
                    onChangePage={(page: number) => onChangePage(page)}
                    onChangeRowsPerPage={(rowCount: number, page: number) => onChangeRowsPerPage(rowCount, page)}
                    selectableRows
                    selectableRowSelected={handlePreSelection}
                    onSelectedRowsChange={onSelectedRowsChange}
                  />
                </div>
              </Col>
              <Col xl={1}></Col>
            </Row>
          </div>
          <Row>
            <Col xl={1}></Col>
            <Col xl={10} className="CatalogList__Footer">
              <div className="CatalogList__Footer__Wrapper">
                <div className="CatalogList__Footer__Wrapper__Text">
                  {`${t('Catalog.플리카 서비스에 대해 어떻게 생각하시나요?')}`}
                  <RDSIconTextButton
                    type="outline"
                    size="xsmall"
                    icon={surveyResponse === 'like' ? <ThumbUpFilledIcon /> : <ThumbUpOutlinedIcon />}
                    customStyle={{ display: 'inline-block', marginLeft: '32px' }}
                    onClick={() => {
                      amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_evaluation, { type: 'good' });
                      surveyLike();
                    }}
                  >
                    {`${t('Catalog.좋은 서비스네요!')}`}
                  </RDSIconTextButton>
                  <RDSIconTextButton
                    type="outline"
                    size="xsmall"
                    icon={surveyResponse === 'dislike' ? <ThumbDownFilledIcon /> : <ThumbDownOutlinedIcon />}
                    customStyle={{ display: 'inline-block', marginLeft: '16px' }}
                    onClick={() => {
                      amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_evaluation, { type: 'bad' });
                      surveyDislike();
                    }}
                  >
                    {`${t('Catalog.아쉬워요.')}`}
                  </RDSIconTextButton>
                  <RDSLinkButton
                    size="xsmall"
                    label={t('Catalog.서비스 문의 및 건의하기')}
                    customStyle={{ marginLeft: '16px' }}
                    onClick={() => {
                      amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_evaluation_survey);
                      window.open('https://mrwfo0a34j9.typeform.com/to/YcrkPL1X');
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col xl={1}></Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CatalogList;
