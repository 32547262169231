import { useState } from 'react';
import { useQuery } from 'react-query';
import { getJWTHeaderFromLocalStorage } from '../../fetchInstance/axios';
import { ThreeDModel } from '../../types/modelTypes';
import { PaginationParam } from '../../types/types';
import { queryKeys } from './constants';
import { axiosInstance } from '../../fetchInstance/customAxios';

/**
 * API 문서
 * https://www.notion.so/reconlabs/model-get-845222a6bb1147c7894ef267c751e9f7
 * https://www.notion.so/reconlabs/model-list-get-4f01171ff209415598b6f05935c55d18
 * https://www.notion.so/reconlabs/model-transform-get-72f139b890fc4553bf63cf9656d88c46
 *  */
type GetModelResponse = ThreeDModel;
type GetModelsListResponse = {
  models: ThreeDModel[];
  count: number; //      "desc": "(검색된)총 모델 수"
  page: number; //      "desc": "range 입력 시의 현재 페이지 번호"
  totalPage: number; //      "desc": "range 입력 시의 전체 페이지 수"
};
type GetModelTransformResponse = any; // TBD
type GetModelViewerResponse = any;
type ModelStatParams = {
  viewer_type: string;
  period: string[];
  model_uid_arr?: string[];
  order?: string[];
};

const modelFallback: ThreeDModel = {
  // 고객수정 제품정보
  uid: '-', //    "desc": "모델의 Unique ID"
  model_memo: '-', //    "desc": "모델에 대한 메모"
  tags: '-', //    "desc": ", 로 구분된 태그 문자열"
  model_name: '',
  model_sales_url: '-',
  model_published: 0,
  model_resizable: 0,
  model_vertical_placeable: 0,
  // S3 관련
  s3_thumbnail_key: '-', //    "desc": "썸네일 s3 key"
  s3_bucket_name: '-', //    "desc": "모델이 저장된 s3 버킷명"
  s3_file_key: '-', //    "desc": "s3 object key"
  s3_file_type: '-', //    "desc": "s3 object type"
  s3_file_size: 0,
  // 업로드 정보
  source_upload_id: '-', //    "desc": "멀티파트 업로드 id"
  registered_date: '-', //    "desc": "모델이 생성된 시간"
  modified_date: '-', // "desc": "모델이 수정된 시간"
  model_path: '-', //    "desc": "디렉토리 형태의 모델 카테고리"
  // 파일정보
  source_file_name: '-', //    "desc": "모델 원본 파일명"
  // thumbnail_url: '-', //    "desc": "모델 썸네일 이미지의 signed url"
  file_extension: '-',
  file_size_byte: 0,
  viewer_uid: '-', // viewer mapping uid
  latest_transforms: {
    gif: { uid: '-', url: '-' },
    glb: { uid: '-', url: '-' },
    png: { uid: '-', url: '-' },
  },
};

async function getModel({ model_uid }: { model_uid: string | { mock: any } }): Promise<GetModelResponse> {
  // TRY CATCH는 불필요합니다. react-query에서 내부적으로 처리
  const { data } = await axiosInstance.get('/model', {
    params: { model_uid },
    headers: getJWTHeaderFromLocalStorage(),
  });
  return data.model;
}

export const getModelMetadata = async (viewer_uid: string) => {
  const res = await axiosInstance.get(
    `${process.env.REACT_APP_ARGATEWAY_API_ENDPOINT}/public/model/viewer?viewer_uid=${viewer_uid}`,
    {
      withCredentials: false,
    },
  );
  const data = res.data;
  return { result: true, data };
};

export async function getModelsList({
  paginationParam,
}: {
  paginationParam: PaginationParam;
}): Promise<GetModelsListResponse> {
  // TRY CATCH는 불필요합니다. react-query에서 내부적으로 처리
  const { data } = await axiosInstance.get('/model/list', {
    params: {
      ...paginationParam,
      range: `[${paginationParam.range}]`,
    },
    headers: getJWTHeaderFromLocalStorage(),
  });

  return data;
}

export async function getTrafficStat(params: ModelStatParams) {
  const { data } = await axiosInstance.get('/stat', {
    params: {
      viewer_type: params.viewer_type,
      period: JSON.stringify(params.period),
    },
    headers: getJWTHeaderFromLocalStorage(),
  });

  return data;
}

export async function getModelStat(params: ModelStatParams) {
  const { data } = await axiosInstance.get('/stat/model', {
    params: {
      viewer_type: params.viewer_type,
      period: JSON.stringify(params.period),
      model_uid_arr: JSON.stringify(params.model_uid_arr),
      order: JSON.stringify(params.order),
    },
    headers: getJWTHeaderFromLocalStorage(),
  });

  return data;
}

async function getModelTransform({ transform_uid }: { transform_uid: string }): Promise<GetModelTransformResponse> {
  const { data } = await axiosInstance.get('/model/transform', {
    params: { transform_uid },
    headers: getJWTHeaderFromLocalStorage(),
  });

  return data;
}

export async function getModelViewer({ viewer_uid }: { viewer_uid: string }): Promise<GetModelViewerResponse> {
  const { data } = await axiosInstance.get('/model/viewer', {
    params: { viewer_uid },
    headers: getJWTHeaderFromLocalStorage(),
  });

  return data;
}

export function useModel(uid: string) {
  const [modelUid, setModelUid] = useState(uid);
  const fallback: ThreeDModel = modelFallback;
  const { data: model = fallback, ...restQueryReturns } = useQuery([queryKeys.model, modelUid], () =>
    getModel({ model_uid: modelUid }),
  );
  // 필요하시다면, isLoading, isError를 추가해도 됩니다.
  // isLoading과 isError의 부담을 줄이고자 fallback을 넣어주었습니다
  return { model, modelUid, setModelUid, ...restQueryReturns };
}

export function useModelsList(defaultPagination: PaginationParam) {
  const [paginationParam, setPaginationParam] = useState<PaginationParam>(defaultPagination);
  const fallback: GetModelsListResponse = {
    models: [],
    count: 0,
    page: 1,
    totalPage: 1,
  };
  const { data = fallback, ...restQueryReturns } = useQuery([queryKeys.modelsList, paginationParam], () =>
    getModelsList({ paginationParam }),
  );
  const { models, count, page, totalPage } = data;
  return { models, count, page, totalPage, paginationParam, setPaginationParam, ...restQueryReturns };
}

export function useModelTransform(transformUid: string) {
  const fallback = {};
  const { data = fallback, ...restQueryReturns } = useQuery([queryKeys.modelTransform, transformUid], () =>
    getModelTransform({ transform_uid: transformUid }),
  );

  return { data, ...restQueryReturns };
}

export function useModelViewer(viewerUid: string) {
  const fallback = {};
  const { data = fallback, ...restQueryReturns } = useQuery([queryKeys.modelViewer, viewerUid], () =>
    getModelViewer({ viewer_uid: viewerUid }),
  );

  return { data, ...restQueryReturns };
}
