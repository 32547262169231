import { useQuery } from 'react-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../fetchInstance/customAxios';
import PopupNoticeModal from '../PopupNoticeModal';
import { RDSModal } from '@reconlabs/reconlabs-fe-components';
import useAuth from '../../hooks/utils/useAuth';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import amplitude from '../../utils/amplitude';
import amplitudeEvents from '../../constants/amplitudeEvents';
import { TIER } from '../../constants/values';
import { UserContext } from '../context/UserContextProvider';

const GlobalLayout = () => {
  const getPopupList = async () => {
    const { data } = await axiosInstance.get('/popup');
    return data;
  };
  const { data } = useQuery(['popup'], getPopupList);

  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOverViewCountModalOpen, setIsOverViewCountModalOpen } = useContext(UserContext);

  return (
    <div style={{ height: '100%' }}>
      {/* popup data가 있고, 그 데이터가 배열로 왔다면 순회하며 하나씩 팝업생성*/}
      {data &&
        (Array.isArray(data.data)
          ? data.data.map((popup: Popup, idx: number) =>
              // 현재는 팝업 하나만 허용
              idx === 0 ? (
                <PopupNoticeModal
                  key={idx}
                  type={popup.type}
                  title={popup.title}
                  content={popup.content}
                  cookieName={`popup.${popup.id}`}
                  width={600}
                  height={400}
                  headerImageSrc={`https://${process.env.REACT_APP_ASSET_DOMAIN}/icons/bang.png`}
                />
              ) : null,
            )
          : null)}
      <RDSModal
        open={isOverViewCountModalOpen}
        onClose={() => setIsOverViewCountModalOpen(false)}
        title={`${t(`Payment.플랜 사용량 초과.${user.user_tier}`)}`}
        supportingText={
          <div>
            <div>{`${t(`Payment.플랜 사용량 초과 모달 문구.조회 수.${user.user_tier}.1`)}`}</div>
            <div>{`${t(`Payment.플랜 사용량 초과 모달 문구.조회 수.${user.user_tier}.2`)}`}</div>
          </div>
        }
        button1Label={user.user_tier === TIER.Free ? t('Payment.플랜 구독하기') : t('Payment.문의하기')}
        button1Fn={
          user.user_tier === TIER.Free
            ? () => {
                amplitude.sendEvent(amplitudeEvents.payment.zero_payment_planover_free_modal_plans);
                setIsOverViewCountModalOpen(false);
                navigate('/dashboard/payment');
              }
            : () => {
                user.user_tier === TIER.Basic
                  ? amplitude.sendEvent(amplitudeEvents.payment.zero_payment_planover_basic_modal_helpcenter)
                  : amplitude.sendEvent(amplitudeEvents.payment.zero_payment_planover_plus_modal_helpcenter);
                setIsOverViewCountModalOpen(false);
                (window as any).ChannelIO('openChat');
              }
        }
        button2Label={t('global.닫기')}
        button2Fn={() => {
          amplitude.sendEvent(
            user.user_tier === TIER.Free
              ? amplitudeEvents.payment.zero_payment_planover_free_modal_close
              : user.user_tier === TIER.Basic
              ? amplitudeEvents.payment.zero_payment_planover_basic_modal_close
              : amplitudeEvents.payment.zero_payment_planover_basic_modal_close,
          );
          setIsOverViewCountModalOpen(false);
        }}
      ></RDSModal>
      <Outlet />
    </div>
  );
};

type Popup = {
  content: string;
  create_date: string;
  end_date: string;
  id: string;
  title: string;
  type: PopupType;
};

export type PopupType = 'temp' | 'emrgency' | 'notice';

export default GlobalLayout;
