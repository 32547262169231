import { useMutation, useQuery } from 'react-query';
import { SurveyType } from '../../constants/type';
import { setAccessToken } from '../../utils/localstorage';
import { getJWTHeaderFromLocalStorage } from '../../fetchInstance/axios';
import { axiosInstance } from '../../fetchInstance/customAxios';
import { queryKeys } from './constants';
import axios from 'axios';

export const getLoggedInUser = async () => {
  const res = await axiosInstance.get(`/login/check`, {
    headers: getJWTHeaderFromLocalStorage(),
  });
  const user = res.data.data;
  return user;
};

export const deleteUser = async () => {
  const res = await axiosInstance.delete(`/user`, {
    headers: getJWTHeaderFromLocalStorage(),
  });
  const access_token = res.data.token;
  setAccessToken(access_token);
  return res.data;
};

export const getMarketingAgreed = async () => {
  const res = await axiosInstance.get(`/user/agree`, {
    headers: getJWTHeaderFromLocalStorage(),
  });
  return res.data;
};

export const putMarketingAgreed = async (동의여부: boolean) => {
  const res = await axiosInstance.put(
    `/user/agree`,
    { marketing_agreed: 동의여부 },
    {
      headers: getJWTHeaderFromLocalStorage(),
    },
  );
  return res.data;
};
const getSurvey = async (surveyId: string) => {
  const { data } = await axiosInstance.get('/survey', {
    params: {
      survey_id: surveyId,
    },
    headers: getJWTHeaderFromLocalStorage(),
  });

  return data;
};

export const postSurvey = async (surveys: SurveyType[], token?: string) => {
  let res;
  if (token) {
    res = await axios.post(
      `${process.env.REACT_APP_SERVER_DOMAIN}/survey`,
      { response_array: surveys },
      { headers: { Authorization: `Bearer ${token}` } },
    );
  } else {
    res = await axiosInstance.post(`/survey`, { response_array: surveys }, { headers: getJWTHeaderFromLocalStorage() });
  }

  return res.data;
};

export const restoreUser = async () => {
  const res = await axiosInstance.put(
    `/user/restore`,
    {},
    {
      headers: getJWTHeaderFromLocalStorage(),
    },
  );
  const access_token = res.data.token;
  setAccessToken(access_token);
  return res.data;
};

export const useRestoreUser = () => {
  const { mutate, isSuccess } = useMutation(() => restoreUser(), {
    onError: () => {
      console.error('restore user error');
    },
    onSuccess: (res: any) => {
      const access_token = res.data.token;
      setAccessToken(access_token);
      return res.data;
    },
  });

  return { mutate, isUpdateSuccess: isSuccess };
};

export const useSurvey = () => {
  const { data, ...restQueryReturns } = useQuery([queryKeys.survey], () => getSurvey('service_eval'));
  return { data, ...restQueryReturns };
};
