import React, { useEffect, useRef } from 'react';
import { QueryClientProvider } from 'react-query';
import { Route, Routes, useLocation } from 'react-router-dom';
import EnvViewer from './components/EnvViewer';
import Dashboard from './components/Dashboard';
import Models from './components/pages/models/Models';
import ModelFileUploadResult from './components/pages/fileupload/ModelFileUploadResult';
import ModelDetail from './components/pages/models/ModelDetail';
import MainPage from './components/pages/MainPage';
import RDSUpload from './components/pages/fileupload/RDSUpload';
import Login from './components/pages/login/Login';
import Signedin from './components/pages/login/Signedin';
import SignUpFlow from './components/pages/login/SignUpFlow';
import './i18n/i18n';
import EmailVerify from './components/pages/login/EmailVerify';
import ResetPasswordPage from './components/pages/login/ResetPasswordPage';
import { queryClient } from './hooks/react-query/queryClient';
import Mypage from './components/pages/myPage/Mypage';
import Payment from './components/pages/payment/Payment';
import ErrorPage from './components/ErrorPage';
import UserContextProvider from './components/context/UserContextProvider';
import PublicRoutesLayout from './components/layout/PublicRoutesLayout';
import Analysis from './components/pages/traffic/Analysis';
import PlicarIntroduction from './components/pages/service/PlicarIntroduction';
import PrivateRoutesLayout from './components/layout/PrivateRoutesLayout';
import GlobalLayout from './components/layout/GlobalLayout';
import CatalogList from './components/pages/catalog/CatalogList';
import amplitude, { getAmplitudePageViewEventName } from './utils/amplitude';
import CatalogDetail from './components/pages/catalog/CatalogDetail';
import CatalogPreview from './components/pages/catalog/CatalogPreview';
import { PATH } from './paths';
import ModelOverview from './components/pages/models/ModelOverview';
import Viewer from './components/pages/Viewer';

const App: () => JSX.Element = () => {
  const location = useLocation();
  const prevURL = useRef<string>('');

  useEffect(() => {
    /** 승민) event기반의 amplitude이지만, 원활한 실사용을 위해 페이지 뷰 이벤트를 추가하였습니다. */
    amplitude.sendEvent('page_view', {
      url: window.location.href,
      referrer: document.referrer,
      query: location.search,
    });
    const fullURL = window.location.href;
    const eventName = getAmplitudePageViewEventName(location.pathname);
    if (prevURL.current !== fullURL && eventName !== 'error') {
      amplitude.sendEvent(eventName, {
        page_url_full: fullURL,
        page_referrer_full: !document.referrer ? 'null' : document.referrer,
        query: !location.search ? 'null' : location.search,
      });
      prevURL.current = fullURL;
    }
  }, [location]);

  return (
    /*----------------------------------------
                  Default Template
     ----------------------------------------*/
    <UserContextProvider>
      <QueryClientProvider client={queryClient}>
        <RDSUpload>
          <Routes>
            <Route path="signedin" element={<Signedin />} />
            <Route path="verify/email/:token" element={<EmailVerify />} />
            <Route path="reset_password/:token" element={<ResetPasswordPage />} />
            <Route path="/viewer" element={<Viewer />} />
            <Route path="/*" element={<GlobalLayout />}>
              <Route path="/*" element={<PublicRoutesLayout />}>
                <Route path={PATH.PUBLIC.agreement} element={<SignUpFlow />} />
                <Route path={PATH.PUBLIC.login} element={<Login />} />
                <Route path={PATH.PUBLIC.env} element={<EnvViewer />}></Route>
              </Route>
              <Route path="/*" element={<PrivateRoutesLayout />}>
                <Route path="dashboard" element={<Dashboard></Dashboard>}>
                  <Route path="main" element={<MainPage></MainPage>}></Route>
                  <Route path="models" element={<Models></Models>} />
                  <Route path="upload/result" element={<ModelFileUploadResult></ModelFileUploadResult>} />
                  <Route path="mypage" element={<Mypage />} />
                  <Route path="analysis" element={<Analysis />} />
                  <Route path="service/plicar" element={<PlicarIntroduction />} />
                  <Route path="catalog" element={<CatalogList />} />
                  <Route path="payment" element={<Payment />}>
                    <Route path=":status" element={<Payment />} />
                  </Route>
                  <Route path="*" element={<ErrorPage />} />
                </Route>
                <Route path="dashboard/model/overview" element={<ModelOverview></ModelOverview>}></Route>
                <Route path="dashboard/model/detail" element={<ModelDetail></ModelDetail>} />
                <Route path="dashboard/catalog/:catalogUid" element={<CatalogDetail></CatalogDetail>}></Route>
                <Route path="dashboard/catalog/preview/:catalogUid" element={<CatalogPreview></CatalogPreview>}></Route>
                <Route path="*" element={<ErrorPage />} />
              </Route>
              {/* login 사용자들의 에러페이지 */}
            </Route>
          </Routes>
        </RDSUpload>
      </QueryClientProvider>
    </UserContextProvider>
  );
};

export default App;
