import React, { useEffect, useRef, useState } from 'react';
import {
  RDSCheckbox,
  RDSIconButton,
  XMarkIcon,
  RDSInputField,
  RDSModal,
  RDSNotificationTooltip,
} from '@reconlabs/reconlabs-fe-components';
import { getModelViewerScriptSource } from '../../../utils/createARScripts';
import { CatalogItem } from './CatalogDetail';
import { useTranslation } from 'react-i18next';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';

type CatalogEditItemProps = {
  item: CatalogItem;
  setSelectedItems: Function;
  setCatalogItems: Function;
  deleteSelectedItems: Function;
  thumbnailUrl?: string;
  index: number;
  setIsChanged: Function;
  showSalesButton: boolean;
};

const CatalogEditItem = (props: CatalogEditItemProps) => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [itemName, setItemName] = useState<string>('');
  const [supportingText, setSupportingText] = useState<string>('');
  const [salesUrl, setSalesUrl] = useState<string>('');
  const itemNameIsChanged = useRef<boolean>(false);
  const supTextIsChanged = useRef<boolean>(false);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (document.querySelector('.plicarzero-viewer') === null) {
      const RDSPlicarViewerScript = document.createElement('script');
      RDSPlicarViewerScript.classList.add('plicarzero-viewer');
      RDSPlicarViewerScript.type = 'module';
      RDSPlicarViewerScript.src = getModelViewerScriptSource(); // TODO API Endpoint 변경 및 안정화시 plicar-viewer.min.js 로 변경
      document.head.appendChild(RDSPlicarViewerScript);
    }
  }, []);

  useEffect(() => {
    if (props.item) {
      setItemName(props.item.name);
      setSalesUrl(props.item.sales_url ? props.item.sales_url : '');
      setSupportingText(props.item.description ? props.item.description : '');
    }
  }, [props.item]);

  useEffect(() => {
    props.setCatalogItems((prevItems: CatalogItem[]) => {
      const newItems = [...prevItems];
      const editItem = newItems.find((item) => item.model_uid === props.item.model_uid);
      if (editItem) {
        editItem.name = itemName;
      }
      return newItems;
    });
  }, [itemName]);

  useEffect(() => {
    props.setCatalogItems((prevItems: CatalogItem[]) => {
      const newItems = [...prevItems];
      const editItem = newItems.find((item) => item.model_uid === props.item.model_uid);
      if (editItem) {
        editItem.sales_url = salesUrl ? salesUrl : null;
      }
      return newItems;
    });
  }, [salesUrl]);

  useEffect(() => {
    props.setCatalogItems((prevItems: CatalogItem[]) => {
      const newItems = [...prevItems];
      const editItem = newItems.find((item) => item.model_uid === props.item.model_uid);
      if (editItem) {
        editItem.description = supportingText;
      }
      return newItems;
    });
  }, [supportingText]);

  useEffect(() => {
    if (checked) {
      props.setSelectedItems((prevItems: CatalogItem[]) => {
        const newItems: CatalogItem[] = [...prevItems];
        newItems.push(props.item);
        return newItems;
      });
    } else {
      props.setSelectedItems((prevItems: CatalogItem[]) => {
        const newItems: CatalogItem[] = [];
        if (prevItems.length > 0) {
          prevItems.forEach((item) => {
            if (item.model_uid !== props.item.model_uid) {
              newItems.push(item);
            }
          });
        }
        return newItems;
      });
    }
  }, [checked]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const deleteItem = () => {
    props.setCatalogItems((prevItems: CatalogItem[]) => {
      const newItems: CatalogItem[] = [];
      prevItems.forEach((item) => {
        if (item !== props.item) {
          newItems.push(item);
        }
      });

      return newItems;
    });
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <RDSModal
        open={openDeleteModal}
        onClose={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_model_delete_modal_cancel);
          setOpenDeleteModal(false);
        }}
        enableCloseButton={false}
        title={`${t('Catalog.3D 모델을 삭제하시겠습니까?')}`}
        supportingText={`${t('Catalog.카탈로그에서 삭제한 3D 모델은 전체모델에서 다시 불러올 수 있습니다.')}`}
        button1Color="warning"
        button1Label={t('Catalog.삭제')}
        button1Fn={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_model_delete_modal_execute);
          deleteItem();
        }}
        button2Label={t('Catalog.취소')}
        button2Fn={() => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_model_delete_modal_cancel);
          setOpenDeleteModal(false);
        }}
      />
      <div className="CatalogEditItem">
        <div className="CatalogEditItem__Wrapper">
          <div className="CatalogEditItem__Wrapper__Control">
            <RDSCheckbox.IconType
              checked={checked}
              handleCheck={() => {
                setChecked((prev) => !prev);
              }}
            ></RDSCheckbox.IconType>
            <RDSIconButton
              type="noBox"
              color="grey"
              icon={<XMarkIcon />}
              onClick={() => {
                amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_delete);
                setOpenDeleteModal(true);
              }}
            />
          </div>
          {props.index === 0 ? (
            <RDSNotificationTooltip
              id="catalog_viewer_tooltip"
              serviceName="plicar-zero"
              position="bottom"
              content={t('Catalog.3D 뷰어로 확인하고 싶으신 경우 미리보기, 또는 발행하기 버튼을 클릭해주세요.')}
              customStyle={{ zIndex: '10' }}
            >
              <div className="CatalogEditItem__Wrapper__Viewer">
                <img
                  className="CatalogEditItem__Wrapper__Viewer__Thumbnail"
                  src={props.thumbnailUrl}
                  alt="썸네일 이미지"
                  draggable={false}
                />
              </div>
            </RDSNotificationTooltip>
          ) : (
            <div className="CatalogEditItem__Wrapper__Viewer">
              <img
                className="CatalogEditItem__Wrapper__Viewer__Thumbnail"
                src={props.thumbnailUrl}
                alt="썸네일 이미지"
                draggable={false}
              />
            </div>
          )}
          <div className="CatalogEditItem__Wrapper__Text">
            <div className="CatalogEditItem__Wrapper__Text__Header">{`${t('Catalog.제품명 입력(최대 200자)')}`}</div>
            <RDSInputField.Text
              value={itemName}
              handleInputChange={(e) => {
                props.setIsChanged();
                if (itemNameIsChanged.current === false) {
                  itemNameIsChanged.current = true;
                  amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_typing_title);
                }
                setItemName(e.currentTarget.value);
              }}
              customStyles={{ marginTop: '4px', ['.RDSInputField']: { background: '#f6f6fa' } }}
            />
            {window.locale === 'ko' ? (
              <RDSInputField.Box
                value={supportingText}
                handleInputChange={(e) => {
                  props.setIsChanged();
                  if (supTextIsChanged.current === false) {
                    supTextIsChanged.current = true;
                    amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_typing_body);
                  }
                  setSupportingText(e.currentTarget.value);
                }}
                height={280}
                customStyle={{ ['.RDSInputFieldBox']: { background: '#f6f6fa' } }}
                placeholder="상세 설명을 작성해주세요.&#13;&#10;예시) 색상:네이비&#13;&#10;사이즈: 100x100x100(mm)&#13;&#10;가격: 37만원"
                showLength
                maxLength={200}
              />
            ) : (
              <RDSInputField.Box
                value={supportingText}
                handleInputChange={(e) => {
                  if (supTextIsChanged.current === false) {
                    supTextIsChanged.current = true;
                    amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_typing_body);
                  }
                  setSupportingText(e.currentTarget.value);
                }}
                height={280}
                customStyle={{ ['.RDSInputFieldBox']: { background: '#f6f6fa' } }}
                placeholder="Insert a detailed description.&#13;&#10;Ex) Color: Navy&#13;&#10;Size: 100x100x100(mm)&#13;&#10;Price: $40"
                showLength
                maxLength={200}
              />
            )}
            {props.showSalesButton ? (
              <RDSInputField.Text
                value={salesUrl}
                handleInputChange={(e) => {
                  props.setIsChanged();
                  setSalesUrl(e.currentTarget.value);
                }}
                placeholder="https://"
                customStyles={{ marginTop: '4px', ['.RDSInputField']: { background: '#f6f6fa' } }}
                helperText={`${t('Catalog.구매 버튼에 판매 페이지 링크를 연결시킵니다.')}`}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CatalogEditItem;
