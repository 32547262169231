import React, { useEffect, useState } from 'react';
import {
  RDSSwitch,
  RDSInputField,
  RDSIconTextButton,
  HelpOutlineIcon,
  TooltipMaker,
  FileDownloadIcon,
} from '@reconlabs/reconlabs-fe-components';
import temp_ex_image from '../../../images/model_default_thumbnail.png';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { ThreeDModel } from '../../../types/modelTypes';
import ModelCardSwitchModal from '../../ModelCardSwitchModal';
import { useUpdateModel } from '../../../hooks/react-query/useUpdateThreeDModel';
import { useTranslation } from 'react-i18next';
import { urlToBlob } from '../../../utils/utils';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';

type ModelInfoCardProps = {
  model: ThreeDModel | undefined;
  viewerSwitch: boolean;
  openViewerSwitchModal: boolean;
  setViewerSwitch: Function;
  setOpenViewerSwitchModal: Function;
  handleSwitch: Function;
  setRDSAlert: Function;
};

const ModelInfoCard = (props: ModelInfoCardProps) => {
  /*----------------------------------------
                    Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const { viewerSwitch, openViewerSwitchModal, setViewerSwitch, setOpenViewerSwitchModal, handleSwitch } = props;
  const [title, setTitle] = useState<string>('-');
  const [link, setLink] = useState<string>('-');
  const [edittedTitle, setEdittedTitle] = useState<string>('-');
  const [edittedLink, setEdittedLink] = useState<string>('-');
  const [isExceed, setIsExceed] = useState<boolean>(false);
  const updateMutation = useUpdateModel({ range: [0, 49] }).mutate;
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    const model = props.model;
    if (model) {
      setTitle(model.model_name);
      setEdittedTitle(model.model_name);
      setLink(model.model_sales_url ? model.model_sales_url : '');
      setEdittedLink(model.model_sales_url ? model.model_sales_url : '');
      setViewerSwitch(!!model.model_published);
    }
  }, [props.model]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const getFile = () => {
    const model = props.model;
    const size = model ? getFileSize(model.s3_file_size) : 0;
    return `glb/${size}`;
  };

  const getFileSize = (size: number) => {
    let result;

    // 30MB를 초과한 경우 경고 텍스트 활성화
    if (size > 1024 * 1024 * 30) {
      if (!isExceed) {
        setIsExceed(true);
      }
    }

    // 0.1MB 보다 작으면 단위를 KB로 표시
    // 102.4 * 1024 byte = 102.4 KB = 0.1 MB
    if (size < 102.4 * 1024) {
      result = (size / 1024).toFixed(2) + 'KB';
    } else {
      result = (size / 1024 / 1024).toFixed(2) + 'MB';
    }

    return result;
  };

  const getUpdateDate = () => {
    const model = props.model;
    return model ? model.modified_date.slice(0, 10).replaceAll('-', '.') : '';
  };
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const onSwitchClicked = () => {
    if (localStorage.getItem('plicar-zero_closed_modal')) {
      handleSwitch(viewerSwitch);
    } else {
      setOpenViewerSwitchModal(true);
    }
  };

  const handleTitleSave = () => {
    setTitle(edittedTitle);
    updateMutation({
      uid: props.model ? props.model.uid : '',
      model_name: edittedTitle ? edittedTitle : '이름 없음',
      model_memo: props.model ? props.model.model_memo : '',
      model_sales_url: props.model ? props.model.model_sales_url : '',
      model_published: props.model ? props.model.model_published : 1,
    });
  };

  const handleLinkSave = () => {
    setLink(edittedLink);
    updateMutation({
      uid: props.model ? props.model.uid : '',
      model_name: props.model ? props.model.model_name : '',
      model_memo: props.model ? props.model.model_memo : '',
      model_sales_url: edittedLink,
      model_published: props.model ? props.model.model_published : 1,
    });
  };

  const handleGlbDownload = async () => {
    const blob: any = await urlToBlob(props.model ? props.model.latest_transforms.glb.url : '');
    const link = document.createElement('a');
    link.download = props.model ? props.model.source_file_name : '';
    link.href = window.URL.createObjectURL(blob);
    link.click();
    props.setRDSAlert({ type: 'success', message: t('Detail.다운로드가 완료되었습니다!') });
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <ModelCardSwitchModal
        openModal={openViewerSwitchModal}
        setOpenModal={setOpenViewerSwitchModal}
        viewerSwitch={viewerSwitch}
        setViewerSwitch={setViewerSwitch}
        handleSwitch={handleSwitch}
      />
      <Row className="Modelinfocard gx-0">
        <Col className="Modelinfocard__image" xl={2} md={2} xs={12}>
          <img
            src={
              props.model &&
              props.model.latest_transforms &&
              props.model.latest_transforms.png &&
              props.model.latest_transforms.png.url
                ? props.model.latest_transforms?.png?.url
                : temp_ex_image
            }
            alt="모델 이미지"
            draggable={false}
          />
          <RDSIconTextButton
            fullWidth
            size="xsmall"
            type="solid"
            color="grey"
            icon={<FileDownloadIcon />}
            onClick={() => {
              handleGlbDownload();
              amplitude.sendEvent(amplitudeEvents.detail.zero_utilization_detail_info_download_glb);
            }}
          >
            {`${t('Detail.3D 모델 다운로드')}`}
          </RDSIconTextButton>
        </Col>
        <Col className="Modelinfocard__info" xl={7} md={6} xs={12}>
          <div className="Modelinfocard__info__title">
            <RDSInputField.Underline
              displayValue={title}
              inputValue={edittedTitle}
              handleInputChange={(e) => setEdittedTitle(e)}
              handleOnSave={() => handleTitleSave()}
              handleReset={() => setEdittedTitle('')}
            />
          </div>
          <div className="Modelinfocard__info__text">
            {`${t('Models.업데이트')}`} <span>{getUpdateDate()}</span>
          </div>
          <div className="Modelinfocard__info__text">
            {`${t('Detail.판매 링크')}`}
            <RDSInputField.Underline
              size="small"
              width={'auto'}
              displayValue={link}
              inputValue={edittedLink}
              handleInputChange={(e) => setEdittedLink(e)}
              handleOnSave={() => handleLinkSave()}
              handleReset={() => setEdittedLink('')}
              maxLength={200}
            />
          </div>
          <div className="Modelinfocard__info__text">
            {`${t('Detail.확장자/용량')}`} <span>{getFile()}</span>
          </div>
          {isExceed && (
            <>
              <TooltipMaker
                position="bottom"
                contents={
                  window.locale === 'ko' ? (
                    <div>
                      3D 뷰어, AR을 빠른 속도로 감상하기 어려워질 수<br />
                      있습니다. 30MB 이하로 용량을 조절하여 다시 업<br />
                      로드 하기를 권장드립니다.
                    </div>
                  ) : (
                    <>
                      It can be difficult to experience 3D
                      <br />
                      viewers and AR at a fast speed. We
                      <br />
                      recommend you reduce the file size to
                      <br />
                      less than 30MB and re-upload it.
                    </>
                  )
                }
                customStyle={{ left: '7% !important' }}
              >
                <RDSIconTextButton
                  icon={<HelpOutlineIcon></HelpOutlineIcon>}
                  type="noBox"
                  size="xsmall"
                  color="warning"
                  customStyle={{ marginTop: '12px' }}
                >
                  {`${t('Detail.고용량 파일입니다.')}`}
                </RDSIconTextButton>
              </TooltipMaker>
            </>
          )}
        </Col>
        <Col className="Modelinfocard__switch" xl={2} md={2} xs={12}>
          <RDSSwitch isOn={viewerSwitch} enableLabel handleClick={onSwitchClicked} />
          {`${t('Models.AR, 3D 뷰어 공개')}`}
          <div className="Modelinfocard__switch__Explain">
            {`${t('Detail.3D, AR 뷰어를 삽입한 내 채널에 뷰어가 정상적으로 보여집니다.')}`}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ModelInfoCard;
