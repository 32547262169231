import React, { useContext, useEffect, useRef, useState } from 'react';
import Detail3dViewer from './Detail3dViewer';
import Detail3dGIFFile from './Detail3dGIFFile';
import ModelInfoCard from './ModelInfoCard';
import { useLocation, useNavigate } from 'react-router-dom';
import DetailPageLinks from './DetailPageLinks';
import OtherModels from './OtherModels';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  getModelViewerScript,
  getModelViewerScriptSource,
  getPublicModelViewerScript,
  getPublicModelViewerScriptWithProps,
} from '../../../utils/createARScripts';
import {
  RDSIconTextButton,
  ChevronBackIcon,
  RDSModal,
  RDSToast,
  RDSToastAlertObject,
  RDSCapsuleButton,
  DeleteOutlinedIcon,
  RDSTab,
  RDSTabs,
} from '@reconlabs/reconlabs-fe-components';
import { useModel } from '../../../hooks/react-query/useThreeDModel';
import { useDeleteModel, useUpdateModel } from '../../../hooks/react-query/useUpdateThreeDModel';
import { getQueryStringProps } from '../../../utils/utils';
import websocketHandler from '../../../utils/websocketHandler';
import { useTranslation } from 'react-i18next';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import { UserContext } from '../../context/UserContextProvider';
import DetailPageARviewer from './DetailPageARviewer';
import DetailViewerGuide from './DetailViewerGuide';
import DetailModelCount from './DetailModelCount';

const ModelDetail = () => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  // const
  let webSocket: any;
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const modelUid = getQueryStringProps(location.search, 'model_uid', true);
  const initialScrollTarget: number | undefined =
    location.search.indexOf('scroll_index') === -1
      ? undefined
      : Number(getQueryStringProps(location.search, 'scroll_index'));
  const [locale, setLocale] = useState<string>('ko');
  const scrollRef = useRef<any[]>([]);

  // boolean
  const [openModelDeleteModal, setOpenModelDeleteModal] = useState<boolean>(false);
  const [openModelDeleteDenyModal, setOpenModelDeleteDenyModal] = useState<boolean>(false);
  const [openModelOffDenyModal, setOpenModelOffDenyModal] = useState<boolean>(false);
  const [viewerSwitch, setViewerSwitch] = useState<boolean>(true);
  const [openViewerSwitchModal, setOpenViewerSwitchModal] = useState<boolean>(false);

  // context
  const { setModelDeleted } = useContext(UserContext);

  // Alert
  const [RDSAlert, setRDSAlert] = useState<RDSToastAlertObject>();

  // spinner
  const [spinner, setSpinner] = useState<string>('loading-spinner');

  // internal variables
  const [publishedCatalogModels, setPublishedCatalogModels] = useState<string[]>([]);
  const [savedCatalogModels, setSavedCatalogModels] = useState<string[]>([]);
  const [selectedTapIndex, setSelectedTapIndex] = useState<number>(initialScrollTarget ? initialScrollTarget : 0);

  // api calls
  const { model, setModelUid, isSuccess, isError, refetch } = useModel(modelUid);
  const {
    mutate: updateMutation,
    isLoading: isUpdateModelLoading,
    error: updateModelError,
  } = useUpdateModel({ range: [0, 49] });
  const { mutateAsync: deleteMutate } = useDeleteModel();
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    const language = localStorage.getItem('plicarzero-locale');
    if (language) {
      setLocale(language);
    }
  }, []);

  useEffect(() => {
    if ((selectedTapIndex === 0 || selectedTapIndex) && scrollRef.current[0]) {
      handleScroll(scrollRef.current[selectedTapIndex]);
    }
  }, [selectedTapIndex]);

  useEffect(() => {
    const error: any = updateModelError;
    if (error && error.response.status === 400) {
      setViewerSwitch(true);
      errorHandler(error);
      setOpenModelOffDenyModal(true);
    }
  }, [updateModelError]);

  useEffect(() => {
    if (locale) {
      localStorage.setItem('plicarzero-locale', locale);
      i18n.changeLanguage(locale);
    }
  }, [locale]);

  useEffect(() => {
    setModelUid(modelUid);
    setRDSAlert(undefined);
  }, [location, location.search]);

  useEffect(() => {
    if (isUpdateModelLoading) {
      setSpinner('delete-spinner');
    } else {
      setSpinner('loading-spinner');
    }
  }, [isUpdateModelLoading]);

  useEffect(() => {
    if (isError) {
      navigate('/dashboard/models');
    }
  }, [isError]);

  useEffect(() => {
    if (model && model.uid !== '-') {
      const url = `${process.env.REACT_APP_WEBSOCKET_SERVER_DOMAIN}`;
      webSocket = websocketHandler(url, webSocket, messageHandler);
    }

    return () => {
      if (webSocket) {
        webSocket.close();
      }
    };
  }, [model]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const deleteModel = async () => {
    setSpinner('delete-spinner');
    setOpenModelDeleteModal(false);
    try {
      await deleteMutate(modelUid);
      setModelDeleted(true);
      navigate('/dashboard/models');
    } catch (e: any) {
      setSpinner('');
      if (e && e.response.status === 400) {
        errorHandler(e);
      }
      setOpenModelDeleteDenyModal(true);
    }
  };

  const messageHandler = (data: any) => {
    if (model.uid === data.uid) {
      refetch();
    }
  };

  const errorHandler = (e: any) => {
    const data = e.response.data.catalogs;
    const catalogs: string[] = data.catalogs;
    const publishedCatalogs: string[] = data.published_catalogs;
    const saved: string[] = [];
    const published: string[] = [];

    if (catalogs && catalogs.length > 0) {
      catalogs.forEach((catalog: string) => {
        if (publishedCatalogs && publishedCatalogs.indexOf(catalog) >= 0) {
          published.push(catalog);
        } else {
          saved.push(catalog);
        }
      });
    }

    if (publishedCatalogs && publishedCatalogs.length > 0) {
      publishedCatalogs.forEach((catalog: string) => {
        if ((catalogs && catalogs.indexOf(catalog) === -1) || !catalogs) {
          published.push(catalog);
        }
      });
    }

    setPublishedCatalogModels(published);
    setSavedCatalogModels(saved);
  };
  /*----------------------------------------
                Event Handler
   ----------------------------------------*/
  const handleSwitch = async (viewerSwitch: boolean) => {
    const updateModel = {
      uid: model ? model.uid : '',
      model_name: model ? model.model_name : '',
      model_memo: model ? model.model_memo : '',
      model_sales_url: model ? model.model_sales_url : '',
      model_published: viewerSwitch ? 0 : 1,
    };
    updateMutation(updateModel);
    // Amplitude: 뷰어 공개 설정 변경 시 로깅
    amplitude.sendEvent(amplitudeEvents.detail.zero_detail_viewer_publish, { type: viewerSwitch ? 'on' : 'off' });
  };

  const handleScroll = (el: any) => {
    // TODO : 모든 요소가 렌더링되기 이전에 scroll 이 실행될때 제대로된 target position 으로 이동하지 못함
    if (initialScrollTarget === selectedTapIndex) {
      // 임시방편으로 모델관리페이지에서 넘어오는경우 setTimeout
      setTimeout(() => {
        el.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } else {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <RDSModal
        title={`${t('Detail.모델을 삭제할까요?')}`}
        enableCloseButton={false}
        button1Label={t('Detail.모델 삭제')}
        button1Fn={() => {
          amplitude.sendEvent(amplitudeEvents.detail.zero_utilization_detail_model_delete_confirm);
          deleteModel();
        }}
        button1Color={'warning'}
        button2Label={t('MyPage.취소')}
        button2Fn={() => {
          amplitude.sendEvent(amplitudeEvents.detail.zero_utilization_detail_model_delete_cancel);
          setOpenModelDeleteModal(false);
        }}
        supportingText={
          <>
            {`${t('Detail.삭제된 모델은 복구가 어렵습니다.')}`} <br /> <br />
            {`${t(
              'Detail.플리카 카탈로그 또는 외부 채널에 공개 중일 때 3D 모델이 삭제되면 에러 메시지가 보이게 되니 해당 페이지에서 별도로 상품 목록을 관리해주세요.',
            )}`}
          </>
        }
        open={openModelDeleteModal}
        onClose={() => {
          // Amplitude: 바깥 영역 클릭도 cancel로 간주하여 이벤트 전송
          amplitude.sendEvent(amplitudeEvents.detail.zero_detail_model_delete_cancel);
          setOpenModelDeleteModal(false);
        }}
      />
      <RDSModal
        title={`${t('Detail.카탈로그에 등록된 모델은 삭제할 수 없습니다')}`}
        enableCloseButton={false}
        button1Label={t('Detail.카탈로그 확인하기')}
        button1Fn={() => {
          navigate('/dashboard/catalog');
        }}
        button2Label={t('Detail.닫기')}
        button2Fn={() => {
          setOpenModelDeleteDenyModal(false);
        }}
        supportingText={
          <>
            {`${t('Detail.모델이 카탈로그에 포함되어서 삭제가 불가능합니다.')}`}
            {`${t('Detail.삭제를 하시려면 카탈로그에서 먼저 모델을 제거해주세요.')}`}
          </>
        }
        linkText={
          <div style={{ maxHeight: '130px', overflowY: 'scroll' }}>
            {publishedCatalogModels.length > 0 && (
              <div>
                <div> · {`${t('Detail.발행되어 있는 카탈로그')}`}</div>
                {publishedCatalogModels.map((model, index) => {
                  return (
                    <div key={model + index} style={{ marginLeft: '20px' }}>
                      - {model}
                    </div>
                  );
                })}
              </div>
            )}
            {savedCatalogModels.length > 0 && (
              <div>
                <div> · {`${t('Detail.저장되어 있는 카탈로그')}`}</div>
                {savedCatalogModels.map((model, index) => {
                  return (
                    <div key={model + index} style={{ marginLeft: '20px' }}>
                      - {model}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        }
        open={openModelDeleteDenyModal}
        onClose={() => {
          setOpenModelDeleteDenyModal(false);
        }}
      />
      <RDSModal
        title={`${t('Detail.AR, 3D 뷰어 공개를 중단할 수 없는 모델입니다')}`}
        enableCloseButton={false}
        button1Label={t('Detail.카탈로그 확인하기')}
        button1Fn={() => {
          navigate('/dashboard/catalog');
        }}
        button2Label={t('Detail.닫기')}
        button2Fn={() => {
          setOpenModelOffDenyModal(false);
        }}
        supportingText={
          <>
            {`${t(
              'Detail.모델이 카탈로그에 포함되어서 AR, 3D 뷰어 공개 중단이 불가능합니다. 중단을 원하시면 카탈로그에서 먼저 모델을 제거해주세요.',
            )}`}
          </>
        }
        linkText={
          <div style={{ maxHeight: '130px', overflowY: 'scroll' }}>
            {publishedCatalogModels.length > 0 && (
              <div>
                <div> · {`${t('Detail.발행되어 있는 카탈로그')}`}</div>
                {publishedCatalogModels.map((model, index) => {
                  return (
                    <div key={model + index} style={{ marginLeft: '20px' }}>
                      - {model}
                    </div>
                  );
                })}
              </div>
            )}
            {savedCatalogModels.length > 0 && (
              <div>
                <div> · {`${t('Detail.저장되어 있는 카탈로그')}`}</div>
                {savedCatalogModels.map((model, index) => {
                  return (
                    <div key={model + index} style={{ marginLeft: '20px' }}>
                      - {model}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        }
        open={openModelOffDenyModal}
        onClose={() => {
          setOpenModelOffDenyModal(false);
        }}
      />
      <RDSToast.Overlay
        type="loadingSpinner"
        toastOverlayId="delete-spinner"
        openedToastOverlay={spinner}
        setOpenedToastOverlay={setSpinner}
      />
      {isSuccess ? (
        <>
          <div className="ModeldetailHeader">
            <div className="ModeldetailHeader__Wrapper">
              <div className="ModeldetailHeader__Wrapper__Buttons">
                <RDSIconTextButton
                  icon={<ChevronBackIcon />}
                  onClick={() => {
                    // Amplitude: 전체 목록으로 돌아갈 시 이벤트 로깅
                    amplitude.sendEvent(amplitudeEvents.detail.zero_detail_link_allmodel);
                    navigate('/dashboard/models');
                  }}
                  type={'noBox'}
                  color={'grey'}
                  size="medium"
                  customStyle={{ width: '137px' }}
                >
                  {`${t('Detail.전체 모델 목록')}`}
                </RDSIconTextButton>
                <RDSIconTextButton
                  icon={<DeleteOutlinedIcon />}
                  onClick={() => setOpenModelDeleteModal(true)}
                  type={'outline'}
                  size="small"
                  color="grey"
                  customStyle={{ width: '18.7%' }}
                >
                  {`${t('Detail.모델 삭제')}`}
                </RDSIconTextButton>
              </div>
              <div className="ModeldetailHeader__Wrapper__Tabs">
                <RDSTabs value={selectedTapIndex} handleChange={(value: number) => setSelectedTapIndex(value)}>
                  <RDSTab label={`${t('Detail.모델정보')}`} value={0} width={'16.66%'}></RDSTab>
                  <RDSTab label={`${t('Detail.3D 뷰어')}`} value={1} width={'16.66%'}></RDSTab>
                  <RDSTab label={`${t('Detail.AR 뷰어')}`} value={2} width={'16.66%'}></RDSTab>
                  <RDSTab label={`${t('Detail.뷰어 삽입 방법')}`} value={3} width={'16.66%'}></RDSTab>
                  <RDSTab label={`GIF`} value={4} width={'16.66%'}></RDSTab>
                  <RDSTab label={`${t('Detail.데이터')}`} value={5} width={'16.66%'}></RDSTab>
                </RDSTabs>
              </div>
            </div>
          </div>
          <div className="Modeldetail container-fluid">
            <RDSCapsuleButton />
            <RDSToast.AlertArea openedAlert={RDSAlert}></RDSToast.AlertArea>
            <div className="Modeldetail__ResponsiveWrapper">
              <Row
                ref={(el: any) => {
                  scrollRef.current[0] = el;
                }}
                style={{ scrollMargin: '205px' }}
              >
                <Col xl={1} md={1}></Col>
                <Col xl={10} md={10} xs={12}>
                  <ModelInfoCard
                    model={model}
                    viewerSwitch={viewerSwitch}
                    setViewerSwitch={setViewerSwitch}
                    openViewerSwitchModal={openViewerSwitchModal}
                    setOpenViewerSwitchModal={setOpenViewerSwitchModal}
                    handleSwitch={handleSwitch}
                    setRDSAlert={setRDSAlert}
                  />
                </Col>
                <Col xl={1} md={1}></Col>
              </Row>
              <Row
                className="Modeldetail__inner__3Dviewer"
                ref={(el: any) => {
                  scrollRef.current[1] = el;
                }}
              >
                {model ? (
                  <Detail3dViewer
                    viewerUid={model.viewer_uid}
                    disabled={!viewerSwitch}
                    getModelViewerScript={getModelViewerScript}
                    getPublicModelViewerScript={getPublicModelViewerScript}
                    getPublicModelViewerScriptWithProps={getPublicModelViewerScriptWithProps}
                    getModelViewerScriptSource={getModelViewerScriptSource()}
                    setRDSAlert={setRDSAlert}
                    modelName={model.model_name}
                  />
                ) : (
                  ''
                )}
              </Row>
              <Row
                ref={(el: any) => {
                  scrollRef.current[2] = el;
                }}
                style={{ scrollMargin: '64px' }}
              >
                <DetailPageARviewer model={model} updateMutation={updateMutation} setRDSAlert={setRDSAlert} />
              </Row>
            </div>
            <Row style={{ background: '#ffffff' }}>
              <DetailPageLinks viewerUid={model.viewer_uid} disabled={!viewerSwitch} setRDSAlert={setRDSAlert} />
            </Row>
            <div className="Modeldetail__ResponsiveWrapper">
              <Row
                ref={(el: any) => {
                  scrollRef.current[3] = el;
                }}
                style={{ scrollMargin: '64px' }}
              >
                <Col xl={1}></Col>
                <Col xl={10}>
                  <DetailViewerGuide />
                </Col>
                <Col xl={1}></Col>
              </Row>
              <Row
                className="Modeldetail__inner__3DGIF"
                ref={(el: any) => {
                  scrollRef.current[4] = el;
                }}
              >
                {model ? (
                  <Detail3dGIFFile
                    disabled={!viewerSwitch}
                    gifUrl={model.latest_transforms?.gif?.url}
                    gifUid={model.latest_transforms?.gif?.uid}
                    name={model.model_name}
                    setRDSAlert={setRDSAlert}
                    viewerSwitch={viewerSwitch}
                  />
                ) : (
                  ''
                )}
              </Row>
              <Row
                ref={(el: any) => {
                  scrollRef.current[5] = el;
                }}
                style={{ scrollMargin: '64px' }}
              >
                <Col xl={1} />
                {model ? <DetailModelCount modelUid={modelUid} /> : ''}
                <Col xl={1} />
              </Row>
              <Row className="Modeldetail__inner__others">
                <OtherModels modelUid={model.uid} />
              </Row>
            </div>
          </div>
        </>
      ) : (
        <RDSToast.Overlay
          type="loadingSpinner"
          toastOverlayId="loading-spinner"
          openedToastOverlay={spinner}
          setOpenedToastOverlay={setSpinner}
        />
      )}
    </>
  );
};

export default ModelDetail;
