import React, { useEffect, useState } from 'react';
import { getLocale } from '../../utils/localstorage';
import i18n from '../../i18n/i18n';

const useLocale = () => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const [locale, setLocale] = useState<string>('ko');
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    const language = getLocale();
    if (language) {
      setLocale(language);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('plicarzero-locale', locale);
    i18n.changeLanguage(locale);
  }, [locale]);

  return { locale, setLocale };
};

export default useLocale;
