import { AnalysisViewerFilterType, PeriodFilterType } from '../constants/type';
import { dateToString, getEngMonth } from './utils';

const dayToTimeStamp = 24 * 60 * 60 * 1000;

/**
 * 트래픽 분석 페이지의 전체 모델탭 x축 라벨을 반환합니다.
 * @param period: PeriodFilterType
 * @returns string[]
 */
export const getAnalysisAllModelLabels = (period: PeriodFilterType, locale: string = 'ko') => {
  const result: string[] = [];
  const currentDate = new Date();
  const labelCount = 8;

  if (period === 'day') {
    for (let i = 0; i < labelCount; i++) {
      const targetDate = new Date(currentDate.getTime() - (labelCount - 1 - i) * dayToTimeStamp);
      result.push(`${targetDate.getMonth() + 1}/${targetDate.getDate()}`);
    }
  } else if (period === 'month') {
    for (let i = 0; i < labelCount; i++) {
      const targetDate = new Date();
      const targetMonth = currentDate.getMonth() + 1 - (labelCount - i);
      targetDate.setMonth(targetMonth);
      if (targetMonth === targetDate.getMonth() || targetMonth < 0) {
        if (locale === 'ko') {
          result.push(`${targetDate.getMonth() + 1}월`);
        } else {
          result.push(getEngMonth(targetDate.getMonth()));
        }
      } else {
        if (locale === 'ko') {
          result.push(`${targetDate.getMonth()}월`);
        } else {
          result.push(getEngMonth(targetDate.getMonth() - 1));
        }
      }
    }
  } else {
    const recentSunday = currentDate.getTime() - dayToTimeStamp * currentDate.getDay();

    for (let i = 0; i < labelCount; i++) {
      const startTime = recentSunday - dayToTimeStamp * 7 * (7 - i);
      const startWeekDate = dateToString(new Date(startTime), '-').slice(0, 10);
      const endWeekDate = dateToString(new Date(startTime + dayToTimeStamp * 6), '-').slice(0, 10);
      result.push(
        `${startWeekDate.slice(5, 10).replaceAll('-', '.')}-${endWeekDate.slice(5, 10).replaceAll('-', '.')}`,
      );
    }
  }

  return result;
};

/**
 * 트래픽 분석 페이지의 차트데이터 요청 파라미터를 반환합니다.
 * @param periodType
 * @returns [startDate: string, endDate: string]
 */
export const getPeriodArr = (periodType: string) => {
  let res: any[] = [];
  if (periodType === 'day') {
    const today = new Date();
    const startTime = today.getTime() - dayToTimeStamp * 7;
    const startDate = dateToString(new Date(startTime), '-').slice(0, 10);
    const endDate = dateToString(new Date(), '-').slice(0, 10);
    res.push(startDate, endDate);
  } else if (periodType === 'week') {
    const today = new Date();
    const recentSunday = today.getTime() - dayToTimeStamp * today.getDay();
    const startTime = recentSunday - dayToTimeStamp * 7 * 7;
    const startDate = dateToString(new Date(startTime), '-').slice(0, 10);
    const endDate = dateToString(new Date(), '-').slice(0, 10);
    res.push(startDate, endDate);
  } else if (periodType === 'month') {
    const targetDate = new Date();
    const currentMonth = targetDate.getMonth() + 1;
    targetDate.setMonth(currentMonth - 8);
    targetDate.setDate(1);
    const startDate = dateToString(new Date(targetDate), '-').slice(0, 10);
    const endDate = dateToString(new Date(), '-').slice(0, 10);
    res.push(startDate, endDate);
  }

  return res;
};

/**
 * 트래픽 분석페이지의 전체모델 탭에서 일별 데이터를 반환합니다.
 * @param items
 * @param currentRadio
 * @returns number[]
 */
export const getPeriodDataByDay = (items: any[], currentRadio: AnalysisViewerFilterType) => {
  let res: any[] = [];
  const today = new Date();

  for (let i = 0; i < 8; i++) {
    const targetTime = today.getTime() - dayToTimeStamp * (7 - i);
    const targetDate = dateToString(new Date(targetTime), '-').slice(0, 10);
    const arData = items.find((item: any) => item.date === targetDate && item.viewer_type === 'ar');
    const viewerData = items.find((item: any) => item.date === targetDate && item.viewer_type === 'plicar-viewer');

    if (currentRadio === 'ar') {
      if (arData) {
        res.push(arData.viewcount);
      } else {
        res.push(0);
      }
    } else if (currentRadio === '3d') {
      if (viewerData) {
        res.push(viewerData.viewcount);
      } else {
        res.push(0);
      }
    }
  }

  return res;
};

/**
 * 트래픽 분석 페이지의 전체모델 탭에서 주별 데이터를 반환합니다.
 * @param items
 * @param currentRadio
 * @returns number[]
 */
export const getPeriodDataByWeek = (items: any[], currentRadio: AnalysisViewerFilterType) => {
  const res: any[] = [];
  const today = new Date();
  const recentSunday = today.getTime() - dayToTimeStamp * today.getDay();

  for (let i = 0; i < 8; i++) {
    const startTime = recentSunday - dayToTimeStamp * 7 * (7 - i);
    const startWeekDate = dateToString(new Date(startTime), '-').slice(0, 10);
    const endWeekDate = dateToString(new Date(startTime + dayToTimeStamp * 6), '-').slice(0, 10);
    const arData = items.filter(
      (item: any) => startWeekDate <= item.date && endWeekDate >= item.date && item.viewer_type === 'ar',
    );
    const viewerData = items.filter(
      (item: any) => startWeekDate <= item.date && endWeekDate >= item.date && item.viewer_type === 'plicar-viewer',
    );

    if (currentRadio === 'ar') {
      if (arData) {
        let count = 0;
        arData.forEach((data) => {
          count += data.viewcount;
        });
        res.push(count);
      } else {
        res.push(0);
      }
    } else if (currentRadio === '3d') {
      if (viewerData) {
        let count = 0;
        viewerData.forEach((data) => {
          count += data.viewcount;
        });
        res.push(count);
      } else {
        res.push(0);
      }
    }
  }

  return res;
};

/**
 * 트래픽 분석 페이지의 전체모델 탭에서 월별 데이터를 반환합니다.
 * @param items
 * @param currentRadio
 * @returns number[]
 */
export const getPeriodDataByMonth = (items: any[], currentRadio: AnalysisViewerFilterType) => {
  const res: any[] = [];
  const today = new Date();
  const startMonth = today.getMonth() - 7;

  for (let i = 0; i < 8; i++) {
    const startTime = new Date();
    const endTime = new Date();
    startTime.setMonth(startMonth + i);
    startTime.setDate(1);
    endTime.setMonth(startMonth + i + 1);
    endTime.setDate(1);
    endTime.setTime(endTime.getTime() - dayToTimeStamp);
    const startDate = dateToString(startTime, '-').slice(0, 10);
    const endDate = dateToString(endTime, '-').slice(0, 10);

    const arData = items.filter(
      (item: any) => startDate <= item.date && endDate >= item.date && item.viewer_type === 'ar',
    );
    const viewerData = items.filter(
      (item: any) => startDate <= item.date && endDate >= item.date && item.viewer_type === 'plicar-viewer',
    );

    if (currentRadio === 'ar') {
      if (arData) {
        let count = 0;
        arData.forEach((data) => {
          count += data.viewcount;
        });
        res.push(count);
      } else {
        res.push(0);
      }
    } else if (currentRadio === '3d') {
      if (viewerData) {
        let count = 0;
        viewerData.forEach((data) => {
          count += data.viewcount;
        });
        res.push(count);
      } else {
        res.push(0);
      }
    }
  }

  return res;
};

/**
 * 정기 결제일을 기준으로 한달 단위로 지난 4개월의 기간을 반환합니다.
 * 차트데이터 요청 파라미터를 반환합니다.
 * @param baseDate 정기 기준 결제일
 * @param locale 언어정보
 * @returns res: string[], params: [string, string]
 */
export const getPeriodByMonthly = (baseDate: number, locale: string = 'ko') => {
  const res: any[] = [];
  const params: any[] = [];
  const today = new Date();

  for (let i = 0; i < 4; i++) {
    const startTime = new Date();
    const endTime = new Date();

    baseDate > today.getDate()
      ? startTime.setMonth(today.getMonth() - (1 + i))
      : startTime.setMonth(today.getMonth() - i);
    if (new Date(startTime.getFullYear(), startTime.getMonth() + 1, 0).getDate() < baseDate) {
      startTime.setDate(new Date(startTime.getFullYear(), startTime.getMonth() + 1, 0).getDate());
    } else {
      startTime.setDate(baseDate);
    }
    baseDate > today.getDate() ? endTime.setMonth(today.getMonth() - i) : endTime.setMonth(today.getMonth() - i + 1);
    if (new Date(endTime.getFullYear(), endTime.getMonth() + 1, 0).getDate() < baseDate) {
      endTime.setDate(new Date(endTime.getFullYear(), endTime.getMonth() + 1, 0).getDate() - 1);
    } else {
      endTime.setDate(baseDate - 1);
    }

    const startDate = dateToString(startTime, '-').slice(0, 10);
    const endDate = dateToString(endTime, '-').slice(0, 10);

    params.push([startDate, endDate]);

    locale === 'ko'
      ? res.push(
          `${numberToString(startTime.getMonth() + 1)}월 ${numberToString(startTime.getDate())}일 ~ ${numberToString(
            endTime.getMonth() + 1,
          )}월 ${numberToString(endTime.getDate())}일`,
        )
      : res.push(
          `${numberToString(startTime.getDate())} ${getEngMonth(startTime.getMonth(), true)} ~ ${numberToString(
            endTime.getDate(),
          )} ${getEngMonth(endTime.getMonth(), true)}`,
        );
  }

  return { res: res, params: params };
};

/**
 * number을 입력받아 10 보다 작은경우 앞에 0을 붙여 string 으로 반환합니다.
 * @param number
 * @returns string
 */
const numberToString = (number: number) => {
  return number < 10 ? `0${number}` : number;
};

/**
 * 데이터분석페이지의 월별사용량탭의 차트데이터와 x축 라벨을 반환합니다.
 * @param items
 * @param date
 * @returns data: number[], labels: string[]
 */
export const getPeriodDataByMonthly = (items: any[], date: string[]) => {
  const startDate = date[0];
  const endDate = date[1];
  let count = 0;
  const res: number[] = [];
  const labels: string[] = [];
  const params: any[] = [];

  for (let i = 0; i < 4; i++) {
    const startTime = new Date(startDate).getTime() + i * (dayToTimeStamp * 7);
    const endTime =
      i === 3 ? new Date(endDate) : new Date(startDate).getTime() + (i + 1) * (dayToTimeStamp * 7) - dayToTimeStamp;

    const firstDate = dateToString(new Date(startTime), '-').slice(0, 10);
    const lastDate = dateToString(new Date(endTime), '-').slice(0, 10);

    items
      .filter((item: any) => item.date >= firstDate && item.date < lastDate)
      .forEach((item: any) => (count += item.viewcount));

    params.push([firstDate, lastDate]);
    labels.push(`${firstDate.slice(5, 10).replaceAll('-', '.')}-${lastDate.slice(5, 10).replaceAll('-', '.')}`);
    res.push(count);
  }

  return {
    data: res,
    labels: labels,
    params: params,
  };
};

/**
 * 모델별 차트데이터를 반환합니다.
 * @param items /stat/model get api response
 * @param models model list
 * uidOrder: 데이터테이블의 표기 컬럼에서 background를 맞춰주기위한 string[] 입니다.
 * column 초기화시 row.uid를 순서로 해당 배열의 index로 색상을 찾습니다.
 * @returns data: number[], labels: string[], uidOrder: string[]
 */
export const getModelChartData = (items: any[], models: any[]) => {
  const chartData: number[] = [];
  const labels: string[] = [];
  const uidOrder: string[] = [];
  items.forEach((item) => {
    const target = models.find((model: any) => model.uid === item.model_uid);
    chartData.push(item.viewcount);
    labels.push(target.model_name);
    uidOrder.push(item.model_uid);
  });

  return {
    data: chartData,
    labels: labels,
    uidOrder: uidOrder,
  };
};
