import React, { useEffect, useState } from 'react';
import { PLICAR_COLORS, RDSDropdown, RDSOption, RDSPagination } from '@reconlabs/reconlabs-fe-components';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';

type PaginationComponentProps = {
  rowsPerPage: number;
  rowCount: number;
  onChangePage: Function;
  onChangeRowsPerPage: Function;
  currentPage: number;
};

const PaginationComponent = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  currentPage,
}: PaginationComponentProps) => {
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);

  useEffect(() => {
    // react-data-table-component의 기본 rowsPerPage는 10인데, 플리카 제로에서는 20부터로 한다.
    if (rowsPerPage === 10) {
      onChangeRowsPerPage(20);
      setPageSize(20);
    }
  }, [rowsPerPage]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 16,
        backgroundColor: PLICAR_COLORS.bg.white,
        padding: '8px 0',
        borderRadius: '0px 0px 8px 8px',
        borderTop: `1px solid ${PLICAR_COLORS.etc.divider1}`,
      }}
    >
      <RDSDropdown
        value={pageSize}
        onChange={(value: string) => {
          if (Number(value) === pageSize) return;
          setPageSize(Number(value));
          onChangeRowsPerPage(Number(value));
        }}
        defaultText="페이지 당 항목 수"
        size="small"
      >
        <RDSOption value={20}>20</RDSOption>
        <RDSOption value={50}>50</RDSOption>
        <RDSOption value={100}>100</RDSOption>
        <RDSOption value={500}>500</RDSOption>
      </RDSDropdown>

      <RDSPagination.Number
        total={rowCount}
        size="small"
        current={currentPage}
        handleChange={(page) => {
          amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_filter_pagination);
          onChangePage(page);
        }}
        pageSize={pageSize}
      />
    </div>
  );
};

export default PaginationComponent;
