// TODO: 이곳의 onEvent는 cancel 호출 시, abort되었는지 확인하기 위한 용도로 써야함 (지금은 로직을 바꿔놓았지만)
// RDSUpload에서 stop, cancel, restart호출하고 UI바꾸면 되지, 여기서 onevent로 받아다가 ui를 바꿀 이유는 없음

/**
 * 업로드api와 업로드하고 싶은 file 로 인스턴스를 생성하고, 이후 필요할 떄, onEvent, check 로직들을 추가할 수 있습니다.(처음에 전부 추가하여도 됩니다.)
 * @param api //  (multipartupload) create, getsignedurl, uploadpart, abort, complete
 * @param onEvent // (optional callback event) onStart, onUploadingPart, onStop ,onCancel, onComplete // 없으면 빈 object
 * @param checkContinueUpload // (optional checkContinue) ()=> boolean 이전에 업로드한 것이 저장되어 있으면 쓰는 로직
 */
export class MultipartUploader {
  id: string;
  api: MultipartUploadApi;
  filesQueue: FileObj[];
  // 각각 업로드 시작, 업로드, 업로드 완료 시에 외부에 보내주는 event발생
  onEvent: MultipartUploadOnEvent;
  checkContinueUpload: (filename: string) => boolean;
  partNumber: number;
  signedUrls: string[];
  state: UploadState;
  uploadingFile: FileObj;
  constructor(
    api: MultipartUploadApi,
    onEvent: MultipartUploadOnEvent = {}, // 기본 default = no onEvnet
    checkContinueUpload: (filename: string) => boolean = () => false,
  ) {
    this.id = '';
    this.api = api;
    this.filesQueue = [];
    this.onEvent = onEvent;
    this.checkContinueUpload = checkContinueUpload;
    this.partNumber = 1;
    this.signedUrls = [];
    this.state = 'await';
    this.uploadingFile = undefined!;
  }

  abortAll() {
    this.filesQueue = [];
    this.cancel(this.uploadingFile.fileId);
  }

  addFile(fileObj: FileObj) {
    this.filesQueue.push(fileObj);
  }

  addfilesQueue(fileObjArray: FileObj[]) {
    // console.log(fileObjArray, 'addfilequeue');
    this.filesQueue = this.filesQueue.concat(fileObjArray);
  }

  async start(fileId = null) {
    // console.log('현재의 filesqueue', this.filesQueue);
    // 업로드할 filesQueue queue가 비었는지 확인
    if (this.isEmptyQueue(this.filesQueue)) {
      // console.log('no more file in filesQueue queue, finish uploader');
      return;
    }
    // filename지정되어 있으면 그 파일만 업로드하고 종료
    if (fileId) {
      this.uploadOneFile(fileId);
      return;
    }
    const fileObj = this.filesQueue.shift()!;
    // 현재 uploadingfile가 upload 된 기록이 있는 경우
    const uploadingProgress = this.getUploadingProgressInLocalStorage(fileObj.file);
    // console.log('이전 업로드 되었던 정보', uploadingProgress);
    if (uploadingProgress) {
      this.continueUpload(fileObj, uploadingProgress);
    } else {
      // file upload 된 기록이 없는 경우
      try {
        await this.upload(fileObj);
        // if error occured, abort multipart upload ? = NOPE (when cacnel시 cancel로직 안에서 abort해서, 업로드 되던게있으면 에러를 뱉음 => 그래서 이곳 캐치문에 abort를 하면 abort중복됌 )
      } catch (error) {
        // console.log(error);
      }
    }

    // file이 stop된 거면 (upload 재귀하지않고) 로직 끝내기
    if (this.state === 'stopped') {
      return;
    }
    // filesQueue가 빌때까지 다시 진행
    await this.start();
  }

  // create multipartUplaod & get signedUrls
  async initiateUpload() {
    // console.log('initiate upload file = ', this.uploadingFile);
    // key is the filename that changed by backend
    const { id, urls } = await this.api.getSignedUrls(this.uploadingFile.file.name, this.uploadingFile.file.size);
    // uploader의 property를 upload중인 file에 대한 정보로 변경
    this.id = id;
    this.signedUrls = urls;
    // call onStart event
    this.onEvent.onStart && this.onEvent.onStart(this.uploadingFile, this.id);
  }

  async upload(fileObj: FileObj, startPartNumber: number = 1) {
    this.state = 'uploading';
    const file = fileObj.file;
    // console.log('start upload file = ', file.name, 'start partnumber=', startPartNumber, 'state =', this.state);
    this.uploadingFile = fileObj;
    // 처음 시작하는 업로드만 init함 (중지되었다 다시하는 것은 굳이 signedurl 다시받을 필요없이 property 에 저장되어있음)
    if (startPartNumber === 1) {
      await this.initiateUpload();
    }
    let partNumber = startPartNumber; // 파트를 나타내는 1~10,000 사이 숫자
    let iteration = startPartNumber - 1; // 업로드 인덱스를 타나내는 0 부터 시작하는 숫자
    const maxPartNumber = this.signedUrls.length; //
    const segmentSize = Math.ceil(file.size / maxPartNumber);

    for (partNumber; partNumber <= maxPartNumber; partNumber++) {
      const progressPercentage = Math.round((partNumber / maxPartNumber) * 100);
      if (this.checkCancel()) {
        // cancel 시 upload 로직 종료
        return;
      }
      // check stop 이 존재하고, 실행시 true면 종료 (cancel 과 달리 do NOT abort)
      if (this.checkStop()) {
        this.onEvent.onStop && this.onEvent.onStop(fileObj);
        return;
      }
      /* 1. 파일 10MB 단위로 슬라이스하여 ArrayBuffer로 비동기적으로 변환 후 이터레이션 횟수를 증가시켜준다 */
      const uploadChunk = await file
        .slice(
          iteration * segmentSize, // 자르기 시작데이터
          partNumber === maxPartNumber // 마지막 청크일 경우
            ? file.size // 파일끝까지 업로드
            : (iteration + 1) * segmentSize,
        )
        .arrayBuffer(); // file 읽은 부분 Buffer로 만듦
      // console.log('maxpart', maxPartNumber, 'partnum', partNumber, 'iter', iteration);
      const signedUrl = this.signedUrls[iteration];
      // console.log(signedUrl, uploadChunk);
      this.onEvent.onUploadingPart && this.onEvent.onUploadingPart(fileObj, progressPercentage);
      const res = await this.api.uploadPart(signedUrl, uploadChunk);
      iteration += 1;
      // update
      // this.saveProgressInLocalstorage(partNumber);
      this.partNumber = partNumber;
    }
    // todo: 위에있는 check cancel 을 내려야할지 고려
    if (!this.checkCancel()) {
      this.state = 'completing';
      await this.api.completeMultipartUpload(this.id);
      // this.saveProgressInLocalstorage(uploadId, key, partNumber, 'complete');
      this.onEvent.onComplete && this.onEvent.onComplete(fileObj);
    }
    this.state = 'await';
  }

  setOnEvent(onEvent: MultipartUploadOnEvent) {
    this.onEvent = onEvent;
  }
  setCheckStop(checkStop: () => boolean) {
    this.checkStop = checkStop;
  }
  setCheckCancel(checkCancel: () => boolean) {
    this.checkCancel = checkCancel;
  }
  setCheckContinueUpload(checkContinueUpload: (filename: string) => boolean) {
    this.checkContinueUpload = checkContinueUpload;
  }

  stop() {
    // make uploading logic stopped
    this.state = 'stopped';
  }
  checkStop() {
    // check uploading file state === 'stopped'
    if (this.state === 'stopped') {
      return true;
    }
    return false;
  }
  checkCancel() {
    if (this.state === 'cancelled') {
      return true;
    }
    return false;
  }

  async continueUpload(fileObj: FileObj, progress: UploadProgress) {
    if (this.checkContinueUpload(this.uploadingFile.fileId)) {
      if (history?.state === 'complete') {
        // console.log('file is already uploaded');
        this.onEvent.onComplete && this.onEvent.onComplete(this.uploadingFile);
        return;
      }
      // identify file with size, lastModfied, and mabye initial small blob
      const { id } = progress;
      try {
        await this.upload(fileObj);
      } catch (error) {
        // console.log(error);
        this.cancel(fileObj.fileId);
      }
      return;
    }
    // when user doesnt want to continue the upload, remove saved progress
    else {
      this.removeProgressInLocalstorage();
    }
  }

  isEmptyQueue(queue: any[]) {
    if (queue.length === 0) {
      return true;
    }
    return false;
  }

  uploadOneFile(fileId: string) {
    const fileObj = this.findFileFromQueue(fileId);
    // 그 파일을 찾으면 upload 찾지 못하면 finish logic
    if (!fileObj) {
      // console.log('cannot find the file in filesQueue queue');
      return;
    }
    this.upload(fileObj);
  }

  // this.upload 가 아니라 this.start로 바꿔야 다시 재귀하면서
  async restart() {
    if (this.state === 'stopped') {
      // console.log('restarting upload partNumber = ', this.partNumber);
      await this.upload(this.uploadingFile, this.partNumber);
      if (this.state === 'stopped') {
        return;
      }
      await this.start();
    } else {
      // console.log('uploading state is not "stopped" cannot restart file uploading');
    }
  }

  async cancel(fileId: string) {
    //todo: onCancel은 백엔드 결과를 알려주는 거여야지 프론트의 state바꾸는건 직접 cancel() 실행했을때 바꿔주면됨
    this.onEvent.onCancel && this.onEvent.onCancel(fileId);
    // upload중인 file과 아닌 file의 cancel 분기
    if (this.uploadingFile.fileId === fileId) {
      // console.log('okok aborting');
      if (this.state === 'completing') {
        return;
      }
      if (this.state === 'uploading') {
        this.state = 'cancelled';
        await this.api.abortMultipartUpload(this.id);
        this.removeProgressInLocalstorage();
      }
      if (this.state === 'stopped') {
        // stop해서 멈춰있던 uploader를 cancel하면 재시작되지않으므로 stop이였던 것은 cancel시 다시 업로드시작해줌
        // 다른 경우는 this.state를 cancelled로 바꿔줘서 로직 진행할 수 있도록 함
        this.start();
      }
    } else {
      this.deleteFileFromQueue(fileId);
      // console.log('canceling the waiting file', ' filesqueue', this.filesQueue);
    }
  }

  saveProgressInLocalstorage(id: string, partNumber: number, state: string = 'ongoing') {}
  removeProgressInLocalstorage() {}

  getUploadingProgressInLocalStorage(file: File): UploadProgress | undefined {
    const fileID = this.makeFileID(file);
    const fileInfo = window.localStorage.getItem(fileID);
    // console.log(fileInfo);
    if (fileInfo !== null) {
      return JSON.parse(fileInfo);
    }
  }
  findFileFromQueue = (fileId: string) => {
    return this.filesQueue.find((fileObj) => fileObj.fileId === fileId);
  };
  deleteFileFromQueue = (fileId: string) => {
    const updatedQueue = this.filesQueue.filter((fileObj) => fileObj.fileId !== fileId);
    this.filesQueue = updatedQueue;
  };

  makeFileID(file: File) {
    return `${this.id}_${file.size}_${file.name}`;
  }
}

export type MultipartUploadOnEvent = {
  onStart?: (fileObj: FileObj, uid: string) => void;
  onUploadingPart?: (fileObj: FileObj, progress: number) => void;
  onCancel?: (fileId: string) => void;
  onStop?: (fileObj: FileObj) => void;
  onComplete?: (fileObj: FileObj) => void;
};
export type MultipartUploadApi = {
  getSignedUrls: (filename: string, fileSize: number) => Promise<{ id: string; urls: string[] }>;
  uploadPart: (signedUrl: string, uploadChunk: any) => any;
  abortMultipartUpload: (id: string) => any;
  completeMultipartUpload: (id: string) => any;
  continueMultipartUpload?: () => any;
};
export type UploadStartConfig = {
  filename?: string;
};
export type UploadProgress = {
  id: string;
  state: UploadState;
};
export type UploadState = 'await' | 'uploading' | 'stopped' | 'cancelled' | 'completing';

export type FileObj = { file: File; fileId: string };
