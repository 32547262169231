import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import amplitudeEvents from '../../constants/amplitudeEvents';
import { DEFAULT_LOGGEDIN_ROUTE } from '../../constants/urls';
import { UserContext } from '../../components/context/UserContextProvider';
import amplitude from '../../utils/amplitude';
import { getLoggedInUser } from '../react-query/useZeroUser';
import { PATH } from '../../paths';

const useAuth = () => {
  const navigate = useNavigate();
  const { user, setUserInfo } = useContext(UserContext);

  const checkUserWithToken = () => {
    useQuery(['user'], getLoggedInUser, {
      onSuccess: (user) => {
        // 가입된 정당한 유저의 경우
        if (user.user_status === 'confirmed') {
          if (window.location.pathname === '/login' || window.location.pathname === '/') {
            // 성공 시 앰플리튜드 자동 로그인 이벤트 전송
            amplitude.sendEvent(amplitudeEvents.signin.zero_signin_auto, { register_type: user.register_type });
            navigate(DEFAULT_LOGGEDIN_ROUTE);
          }
        }
        setUserInfo(user);
        // 가입되지 않은 유저의 경우
        if (user.user_status === 'pending') {
          navigate('/agreement?login_type=oauth');
        }
        if (user.user_status === 'verifying') {
          alert('이메일 인증을 완료해주세요');
          navigate('/login');
        }
      },
      onError: () => {
        // 로그인이 필요한 페이지에 접근하였 때에 에러 시 로그인 페이지로 이동
        if ([PATH.PUBLIC.agreement, PATH.PUBLIC.env, PATH.PUBLIC.login].includes(window.location.pathname.replace('/','')) === false) {
          navigate('/login');
        }
      },
    });
  };

  return { user, setUserInfo, checkUserWithToken };
};

export default useAuth;
