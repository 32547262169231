import React, { useEffect, useState } from 'react';
import { getARGatewayUrl, getModelViewerScriptSource } from '../../../utils/createARScripts';
import { CatalogItem } from './CatalogDetail';
import CatalogPreviewModelViewer from './CatalogPreviewModelViewer';
import {
  RDSIconButton,
  RDSIconTextButton,
  ThreeDIcon,
  ModalMaker,
  CustomQRCode,
  RDSButton,
} from '@reconlabs/reconlabs-fe-components';
import { useTranslation } from 'react-i18next';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';

const FullScreenIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 14H5V19H10V17H7V14ZM5 10H7V7H10V5H5V10ZM17 17H14V19H19V14H17V17ZM14 5V7H17V10H19V5H14Z"
        fill="black"
        fillOpacity="0.38"
      />
    </svg>
  );
};

const PurchaseIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.457 9.83464C13.082 9.83464 13.632 9.49297 13.9154 8.9763L16.8987 3.56797C17.207 3.01797 16.807 2.33464 16.1737 2.33464H3.84036L3.05703 0.667969H0.332031V2.33464H1.9987L4.9987 8.65964L3.8737 10.693C3.26536 11.8096 4.06536 13.168 5.33203 13.168H15.332V11.5013H5.33203L6.2487 9.83464H12.457ZM4.63203 4.0013H14.757L12.457 8.16797H6.60703L4.63203 4.0013ZM5.33203 14.0013C4.41536 14.0013 3.6737 14.7513 3.6737 15.668C3.6737 16.5846 4.41536 17.3346 5.33203 17.3346C6.2487 17.3346 6.9987 16.5846 6.9987 15.668C6.9987 14.7513 6.2487 14.0013 5.33203 14.0013ZM13.6654 14.0013C12.7487 14.0013 12.007 14.7513 12.007 15.668C12.007 16.5846 12.7487 17.3346 13.6654 17.3346C14.582 17.3346 15.332 16.5846 15.332 15.668C15.332 14.7513 14.582 14.0013 13.6654 14.0013Z"
      fill="#0C0D0D"
    />
  </svg>
);

type CatalogPreviewModelCardProps = {
  item: CatalogItem;
  companyName: string;
  showLink: boolean;
};

const CatalogPreviewModelCard = (props: CatalogPreviewModelCardProps) => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const [openModelViewerModal, setOpenModelViewerModal] = useState<boolean>(false);
  const [openARViewerModal, setOpenARViewerModal] = useState<boolean>(false);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (document.querySelector('.plicarzero-viewer') === null) {
      const RDSPlicarViewerScript = document.createElement('script');
      RDSPlicarViewerScript.classList.add('plicarzero-viewer');
      RDSPlicarViewerScript.type = 'module';
      RDSPlicarViewerScript.src = getModelViewerScriptSource(); // TODO API Endpoint 변경 및 안정화시 plicar-viewer.min.js 로 변경
      document.head.appendChild(RDSPlicarViewerScript);
    }
  }, []);

  const removeProtocolInUrl = (url: string) => {
    let res = '';
    if (url && url.indexOf('https://') >= 0) {
      res = url.slice(8);
    }

    return res;
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <ModalMaker
        isVisible={openModelViewerModal}
        setIsVisible={setOpenModelViewerModal}
        modalStyle={{ background: 'none', pointerEvents: 'none' }}
        backdropColor={'rgba(0, 0, 0, 0.8)'}
      >
        <CatalogPreviewModelViewer viewerData={props.item.viewer_data} />
      </ModalMaker>
      <ModalMaker isVisible={openARViewerModal} setIsVisible={setOpenARViewerModal}>
        <div className={window.locale === 'ko' ? 'ARViewerModal' : 'ARViewerModal ARViewerModal-en'}>
          <div className="ARViewerModal__Title">{`${t('Catalog.플리카 AR 뷰어')}`}</div>
          <div className="ARViewerModal__SubText">{`${t('Catalog.온라인 상품을 내 공간에 가져와 보세요!')}`}</div>
          <div className="ARViewerModal__QRCode">
            <div className="ARViewerModal__QRCode__Wrapper">
              <CustomQRCode
                width="251px"
                height="251px"
                url={
                  props.showLink
                    ? getARGatewayUrl(props.item.viewer_uid!) +
                      `?token=${localStorage.getItem('plicarzero.access_token')}${
                        props.item.sales_url && `&salesUrl=${removeProtocolInUrl(props.item.sales_url)}`
                      }`
                    : getARGatewayUrl(props.item.viewer_uid!) +
                      `?token=${localStorage.getItem('plicarzero.access_token')}`
                }
              />
            </div>
          </div>
          <div className="ARViewerModal__SupportingText">
            {`${t('Catalog.스마트폰 기본 카메라 앱에서 QR코드를 비춘 후 보여지는 링크를 눌러주세요.')}`}
          </div>
          <div className="ARViewerModal__Footer">
            {`${t('Catalog.AR기능은 현재 아래의 기기에서만 정상 작동 합니다')}`} <br />
            iPhone: {`${t('Catalog.iOS 12이상')}`} <br />
            Android: {`${t('Catalog.8.0+ 이상 (AR Core 1.9 지원기기)')}`}
          </div>
          <div className="ARViewerModal__Button">
            <RDSButton type="noBox" onClick={() => setOpenARViewerModal(false)}>
              {`${t('Catalog.닫기')}`}
            </RDSButton>
          </div>
        </div>
      </ModalMaker>
      <div className="CatalogModelCard">
        <div>
          <div
            className="CatalogModelCard__Paper"
            onMouseUp={() => {
              amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_preview_3dviewer_turn);
            }}
            onTouchEnd={(e) => {
              amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_preview_3dviewer_turn);
              e.preventDefault();
            }}
          >
            <div
              className="CatalogModelCard__Paper__Icon"
              // 확장 아이콘 클릭 시 상위의 뷰어 조작 이벤트 mouseup, touchend가 동작하지 않게 하기 위한 stopPropagation입니다.
              onMouseUp={(e) => {
                e.stopPropagation();
              }}
              onTouchEnd={(e) => {
                e.stopPropagation();
              }}
            >
              <RDSIconButton
                icon={<FullScreenIcon />}
                type={'noBox'}
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_preview_3dviewer_enlarge);
                  setOpenModelViewerModal(true);
                }}
              />
            </div>
            {/* @ts-ignore */}
            <plicarzero-viewer
              style={{ width: '100%', height: '100%' }}
              viewer-uid={''}
              viewer-data={JSON.stringify(props.item.viewer_data)}
              exposure="1"
              environment-image={'neutral'}
              camera-controls
              camera-orbit="25deg 75deg 105%"
              max-camera-orbit="Infinity 170deg 120%"
              min-camera-orbit="-Infinity 10deg auto"
            />
          </div>
          <div className="CatalogModelCard__Info">
            <div>
              <div className="CatalogModelCard__Info__Title">{props.item.name}</div>
              <div className="CatalogModelCard__Info__Text">{props.item.description}</div>
            </div>
          </div>
        </div>
        <div>
          <RDSIconTextButton
            type="outline"
            size="medium"
            icon={<ThreeDIcon />}
            color="grey"
            fullWidth
            onClick={() => {
              amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_preview_arviewer, {
                company: props.companyName,
                model_name: props.item.name,
              });
              setOpenARViewerModal(true);
            }}
            customStyle={{ marginBottom: props.showLink && !props.item.sales_url ? '58px' : '0', marginTop: '8px' }}
          >
            {`${t('Catalog.AR로 보기')}`}
          </RDSIconTextButton>
          {props.showLink && props.item.sales_url && (
            <RDSIconTextButton
              icon={<PurchaseIcon />}
              fullWidth
              customStyle={{ marginTop: '8px' }}
              color="grey"
              onClick={() => window.open(props.item.sales_url!)}
            >
              {`${t('Catalog.구매하러 가기')}`}
            </RDSIconTextButton>
          )}
        </div>
      </div>
    </>
  );
};

export default CatalogPreviewModelCard;
