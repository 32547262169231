import { RDSButton } from '@reconlabs/reconlabs-fe-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import amplitude from '../../../utils/amplitude';

const WithdrawalComplete = ({ onClickCancel }: { onClickCancel: Function }) => {
  const { t } = useTranslation();
  return (
    <div className="notice">
      <img className="notice__complete__image" src="/images/user_withdrawl_complete.png" alt="회원 탈퇴 완료" />
      <div className="notice__title">{`${t('MyPage.회원 탈퇴 완료')}`}</div>
      <div>
        {`${t('MyPage.회원 탈퇴가 완료되었습니다.')}`}
        <p />
        {`${t('MyPage.더 나은 경험을 제공해드릴 수 있는 플리카로 성장하겠습니다.')}`}
        <br />
        <br />
        {`${t('MyPage.이용해주셔서 감사합니다.')}`}
      </div>
      <br />
      <RDSButton
        fullWidth
        onClick={() => {
          amplitude.sendEvent(amplitudeEvents.mypage.zero_mypage_withdrawal_success);
          onClickCancel();
        }}
      >
        {`${t('MyPage.확인')}`}
      </RDSButton>
    </div>
  );
};

export default WithdrawalComplete;
