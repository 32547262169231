import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCatalog } from '../../../hooks/react-query/useCatalog';
import { RDSPagination } from '@reconlabs/reconlabs-fe-components';
import CatalogPreviewModelCard from './CatalogPreviewModelCard';
import { useTranslation } from 'react-i18next';
import { getQueryStringProps } from '../../../utils/utils';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';

const CatalogPreview = () => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const location = useLocation();
  const catalogUid = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
  const { data } = useCatalog(catalogUid);
  const [catalogName, setCatalogName] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [catalogItems, setCatalogItems] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentItems, setCurrentItems] = useState<any[]>([]);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    const body = document.querySelector('body');
    body?.setAttribute('style', 'background-color: #ffffff');
    return () => {
      body?.setAttribute('style', 'background-color: #f6f6fa');
    };
  }, []);

  useEffect(() => {
    if (location.search) {
      const locale = getQueryStringProps(location.search, 'locale');
      window.i18n(locale);
    }
  }, [location, location.search]);

  useEffect(() => {
    if (data && data.result === 'success') {
      setCatalogName(data.catalog_name);
      setCompanyName(data.catalog_metadata.company_name);
      setCatalogItems(data.catalog_items);
    }
  }, [data]);

  useEffect(() => {
    if (catalogItems && catalogItems.length > 0) {
      const startIndex = (currentPage - 1) * 6;
      const endIndex = startIndex + 6;
      setCurrentItems(catalogItems.slice(startIndex, endIndex));
    }
  }, [catalogItems, currentPage]);
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div className="Catalog">
      <div className="Catalog__Header">
        {`${t('Catalog.이 페이지는 미리보기 페이지입니다. 카탈로그를 공유하기 위해서는 발행하기를 눌러주세요.')}`}
      </div>
      <div className="Catalog__Container">
        <div className="Catalog__Container__Title">{catalogName}</div>
        <div className="Catalog__Container__Sub">{companyName}</div>
        <div className="Catalog__Container__Contents">
          {currentItems && currentItems.length > 0
            ? currentItems.map((item: any) => {
                return (
                  <CatalogPreviewModelCard
                    key={item.model_uid}
                    item={item}
                    companyName={companyName}
                    showLink={data.catalog_show_sales_url == 1}
                  />
                );
              })
            : ''}
        </div>
        <div className="Catalog__Pagination">
          {data && data.catalog_items ? (
            <RDSPagination.Number
              size="small"
              pageSize={6}
              total={data.catalog_items.length}
              current={currentPage}
              minimumCustomLabel={''}
              handleChange={(page) => {
                amplitude.sendEvent(amplitudeEvents.catalog.zero_catalog_editor_preview_pagination);
                setCurrentPage(page);
              }}
            />
          ) : (
            ''
          )}
          <div className="Catalog__Pagination__Footer">Powered by PlicAR</div>
        </div>
      </div>
    </div>
  );
};

export default CatalogPreview;
