import axios from 'axios';
import { refreshToken } from '../utils/login';
import { MultipartUploadApi } from '../utils/MultipartUploader';
import { getJWTHeaderFromLocalStorage } from '../fetchInstance/axios';
import { axiosInstance } from '../fetchInstance/customAxios';

// GET 멀티파트 업로드용 signedUrl을 요청
export const _getMultipartUploadSignedUrls = async (fileName: string, fileSize: number) => {
  let res;
  try {
    res = await axiosInstance.get(`/model/upload/signedurl`, {
      params: {
        file_name: fileName,
        file_size: fileSize,
        tags: JSON.stringify(['tag1', 'tag2']),
        category: 'reconlabs/temp/test',
      },
      headers: getJWTHeaderFromLocalStorage(),
    });
  } catch (e) {
    // console.log(e);
    throw new Error('GET signedUrl 획득에 실패했습니다');
  }
  // console.log(res.data);
  return { id: res.data.model_uid, urls: res.data.urls };
};

// POST 멀티파트 업로드 완료처리
export const _postMultipartUploadComplete = async (modelId: string) => {
  try {
    await axiosInstance.post(
      `/model/upload/complete`,
      {
        model_uid: modelId,
      },
      {
        headers: getJWTHeaderFromLocalStorage(),
      },
    );
  } catch (e) {
    // console.log(e);
    throw new Error('POST 멀티파트업로드 완료처리 실패');
  }
};

// PUT signedUrl로 데이터 업로드
export const _putUpload = async (uploadUrl: string, body: any) => {
  let res;

  try {
    res = await axios.put(uploadUrl, body, { withCredentials: false });
  } catch (e) {
    throw new Error('PUT via signedUrl 실패');
  }
  return res;
};

// POST 멀티파트 업로드 중단처리
export const _postAbortMultipartUpload = async (modelId: string): Promise<any> => {
  try {
    await axiosInstance.post(
      `/model/upload/abort`,
      { model_uid: modelId },
      {
        headers: getJWTHeaderFromLocalStorage(),
      },
    );
  } catch (e) {
    // console.log(e);
    throw new Error('POST 멀티파트 업로드 중단처리 실패');
  }
};
export const multipartUploadApi: MultipartUploadApi = {
  getSignedUrls: _getMultipartUploadSignedUrls,
  abortMultipartUpload: _postAbortMultipartUpload,
  completeMultipartUpload: _postMultipartUploadComplete,
  uploadPart: _putUpload,
};
