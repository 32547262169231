import React, { useEffect, useState, useContext } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { RDSCard, RDSCarousel, RDSToast, RDSModal } from '@reconlabs/reconlabs-fe-components';
import { useModelsList } from '../../../hooks/react-query/useThreeDModel';
import { ThreeDModel } from '../../../types/modelTypes';
import ModelCardSwitchModal from '../../ModelCardSwitchModal';
import { useNavigate } from 'react-router-dom';
import { useUpdateModel } from '../../../hooks/react-query/useUpdateThreeDModel';
import { useTranslation } from 'react-i18next';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';

type OtherModelsProps = {
  modelUid: string;
};

const OtherModels = (props: OtherModelsProps) => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  // const
  const { t } = useTranslation();
  const navigate = useNavigate();

  // internal variables
  const [openViewerSwitchModal, setOpenViewerSwitchModal] = useState<boolean>(false);
  const [otherModels, setOtherModels] = useState<ThreeDModel[]>([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const [selectedSwitch, setSelectedSwitch] = useState<boolean>(false);
  const [updateModel, setUpdateModel] = useState<ThreeDModel>();
  const [openModelOffDenyModal, setOpenModelOffDenyModal] = useState<boolean>(false);
  const [publishedCatalogModels, setPublishedCatalogModels] = useState<string[]>([]);
  const [savedCatalogModels, setSavedCatalogModels] = useState<string[]>([]);

  // spinner
  const [spinner, setSpinner] = useState<string>('');

  // api calls
  const [queryParams, setQueryParams] = useState<{ range: number[] }>({ range: [0, 49] });
  const { models } = useModelsList(queryParams);
  const {
    mutate: updateMutation,
    isUpdateSuccess,
    isLoading: isUpdateModelLoading,
    error: updateModelError,
  } = useUpdateModel(queryParams);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (models && models.length > 0 && models[0].uid !== '-') {
      setOtherModels(models.filter((model) => model.uid !== props.modelUid));
    }
  }, [models, models.length, props.modelUid]);

  useEffect(() => {
    const error: any = updateModelError;
    if (error && error.response.status === 400) {
      setSelectedSwitch(true);
      errorHandler(error);
      setOpenModelOffDenyModal(true);
    }
  }, [updateModelError]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setOtherModels(models.filter((model) => model.uid !== props.modelUid));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isUpdateModelLoading) {
      setSpinner('updating-spinner');
    } else {
      setSpinner('');
    }
  }, [isUpdateModelLoading]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const getDate = (model: ThreeDModel) => {
    let text: string = '';
    if (model.registered_date === model.modified_date) {
      text = model.registered_date.slice(0, 11) + t('Models.업로드');
    } else {
      text = model.modified_date.slice(0, 11) + t('Models.업데이트');
    }

    return text;
  };

  const errorHandler = (e: any) => {
    const data = e.response.data.catalogs;
    const catalogs: string[] = data.catalogs;
    const publishedCatalogs: string[] = data.published_catalogs;
    const saved: string[] = [];
    const published: string[] = [];

    if (catalogs.length > 0) {
      catalogs.forEach((catalog: string) => {
        if (publishedCatalogs && publishedCatalogs.indexOf(catalog) >= 0) {
          published.push(catalog);
        } else {
          saved.push(catalog);
        }
      });
    }

    setPublishedCatalogModels(published);
    setSavedCatalogModels(saved);
  };
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const handleSwitchClick = (model: ThreeDModel) => {
    if (localStorage.getItem('plicar-zero_closed_modal')) {
      handleSwitch(model);
    } else {
      setSelectedSwitch(!!model.model_published);
      setUpdateModel(model);
      setOpenViewerSwitchModal(true);
    }
  };

  const handleSwitch = async (model: ThreeDModel) => {
    let updateModel: any;
    if (model) {
      updateModel = {
        uid: model.uid,
        model_name: model.model_name,
        model_memo: model.model_memo,
        model_sales_url: model.model_sales_url,
        model_published: model.model_published ? 0 : 1,
      };
    } else {
      updateModel = {
        uid: updateModel ? updateModel.uid : '',
        model_name: updateModel ? updateModel.model_name : '',
        model_memo: updateModel ? updateModel.model_memo : '',
        model_sales_url: updateModel ? updateModel.model_sales_url : '',
        model_published: updateModel?.model_published ? 0 : 1,
      };
    }
    updateMutation(updateModel);
    amplitude.sendEvent(amplitudeEvents.detail.zero_detail_otherlist_card_viewer_publish, {
      type: updateModel?.model_published === 1 ? 'off' : 'on',
    });
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <ModelCardSwitchModal
        openModal={openViewerSwitchModal}
        setOpenModal={setOpenViewerSwitchModal}
        viewerSwitch={selectedSwitch}
        setViewerSwitch={setSelectedSwitch}
        updateModel={handleSwitch}
      />
      <RDSToast.Overlay
        type="loadingSpinner"
        toastOverlayId="updating-spinner"
        openedToastOverlay={spinner}
        setOpenedToastOverlay={setSpinner}
        customStyle={{ width: 'fit-content' }}
      />
      <RDSModal
        title={`${t('Detail.AR, 3D 뷰어 공개를 중단할 수 없는 모델입니다')}`}
        enableCloseButton={false}
        button1Label={t('Detail.카탈로그 확인하기')}
        button1Fn={() => {
          navigate('/dashboard/catalog');
        }}
        button2Label={t('Detail.닫기')}
        button2Fn={() => {
          setOpenModelOffDenyModal(false);
        }}
        supportingText={
          <>
            {`${t(
              'Detail.모델이 카탈로그에 포함되어서 AR, 3D 뷰어 공개 중단이 불가능합니다. 중단을 원하시면 카탈로그에서 먼저 모델을 제거해주세요.',
            )}`}
          </>
        }
        linkText={
          <div style={{ maxHeight: '130px', overflowY: 'scroll' }}>
            {publishedCatalogModels.length > 0 && (
              <div>
                <div>{`${t('Detail.발행되어 있는 카탈로그')}`}</div>
                {publishedCatalogModels.map((model, index) => {
                  return (
                    <div key={model + index} style={{ marginLeft: '20px' }}>
                      {model}
                    </div>
                  );
                })}
              </div>
            )}
            {savedCatalogModels.length > 0 && (
              <div>
                <div>{`${t('Detail.저장되어 있는 카탈로그')}`}</div>
                {savedCatalogModels.map((model, index) => {
                  return (
                    <div key={model + index} style={{ marginLeft: '20px' }}>
                      {model}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        }
        open={openModelOffDenyModal}
        onClose={() => {
          setOpenModelOffDenyModal(false);
        }}
      />
      {models && models.length > 1 ? (
        <div className="OtherModels">
          <Row>
            <Col xl={1}></Col>
            <Col className="OtherModels__title" xl={10}>
              {`${t('Detail.다른 모델 페이지로 이동')}`}
            </Col>
            <Col xl={1}></Col>
          </Row>
          <Row>
            <Col className="OtherModels__cards" xl={12}>
              <RDSCarousel
                current={currentSlideIndex}
                nextButtonFn={(newSlide) => {
                  amplitude.sendEvent(amplitudeEvents.detail.zero_detail_otherlist_horizontalscroll, {
                    direction: 'right',
                  });
                  setCurrentSlideIndex(newSlide);
                }}
                prevButtonFn={(newSlide) => {
                  amplitude.sendEvent(amplitudeEvents.detail.zero_detail_otherlist_horizontalscroll, {
                    direction: 'left',
                  });

                  setCurrentSlideIndex(newSlide);
                }}
              >
                {otherModels.map((model: ThreeDModel) => {
                  return (
                    <RDSCard.Thumbnail
                      key={model.uid}
                      title={model.model_name}
                      supportingText={getDate(model)}
                      switchText={t('Models.AR, 3D 뷰어 공개')}
                      isSwitchOn={!!model.model_published}
                      imageSrc={model.latest_transforms?.png?.url}
                      handleClick={() => {
                        amplitude.sendEvent(amplitudeEvents.detail.zero_detail_otherlist_card_select);
                        navigate(`/dashboard/model/detail?model_uid=${model.uid}`);
                      }}
                      handleSwitchClick={() => handleSwitchClick(model)}
                    />
                  );
                })}
              </RDSCarousel>
            </Col>
          </Row>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default OtherModels;
