import { getAccessToken, JWT_ACCESS_TOKEN_LOCALSTORAGE_KEY } from '../utils/localstorage';

export const baseUrl = process.env.REACT_APP_SERVER_DOMAIN;

export function getJWTHeaderFromLocalStorage(): Record<string, string> {
  const access_token = getAccessToken();
  if (access_token) {
    return { Authorization: `Bearer ${access_token}` };
  } else {
    console.log(`local storage에 인증토큰 ${JWT_ACCESS_TOKEN_LOCALSTORAGE_KEY}이 없습니다`);
    return {};
    // throw new Error(`local storage에 인증토큰 ${JWT_ACCESS_TOKEN_LOCALSTORAGE_KEY}이 없습니다`);
  }
}
