import React, { useState } from 'react';
import { RDSModal } from '@reconlabs/reconlabs-fe-components';
import { useTranslation } from 'react-i18next';
import switchOnImg from '../images/ViewerOnModalImage.png';
import switchOnImgEn from '../images/ViewerOnModalImageEn.png';
import switchOffImg from '../images/ViewerOffModalImage.png';
import switchOffImgEn from '../images/ViewerOffModalImageEn.png';
import amplitude from '../utils/amplitude';
import amplitudeEvents from '../constants/amplitudeEvents';

type ModelCardSwitchModalProps = {
  openModal: boolean;
  viewerSwitch: boolean;
  setViewerSwitch: Function;
  setOpenModal: Function;
  handleSwitch?: Function;
  updateModel?: Function;
};

const ModelCardSwitchModal = (props: ModelCardSwitchModalProps) => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const [checkbox, setCheckbox] = useState<boolean>(false);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const getTitle = () => {
    return props.viewerSwitch ? t('Models.AR, 3D 뷰어 외부 공개 활성화 중단') : t('Models.AR, 3D 모델 뷰어 공개');
  };

  const getButton = () => {
    return props.viewerSwitch ? t('Models.중단합니다') : t('Models.공개합니다');
  };

  const getSupportingText = () => {
    return props.viewerSwitch ? (
      <>
        {`${t(
          'Models.외부 사이트에 공개 중인 AR, 3D 뷰어를 OFF 상태로 변경하면 에러 메시지가 노출되므로 해당 사이트에서 별도의 리스트 관리가 필요할 수 있습니다.',
        )}`}
        <br /> <br />
        {`${t('Models.AR, 3D 뷰어의 외부 채널 공개를 중단하시겠습니까?')}`}
      </>
    ) : (
      <>
        {`${t(
          'Models.뷰어 공개가 ON 상태일 때 외부 사이트에 AR, 3D 뷰어가 공개되어 방문자가 증가하는 만큼 트래픽이 발생할 수 있으며, 뷰어를 보는 수 만큼 ‘뷰 수’가 카운팅됩니다.',
        )}`}
        <br /> <br />
        {`${t('Models.AR, 3D 뷰어를 외부 채널에 공개할까요?')}`}
      </>
    );
  };

  const getImageSrc = () => {
    let result;

    if (props.viewerSwitch) {
      result = window.locale === 'ko' ? switchOffImg : switchOffImgEn;
    } else {
      result = window.locale === 'ko' ? switchOnImg : switchOnImgEn;
    }

    return result;
  };
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const handleButtonClicked = async () => {
    if (checkbox) {
      localStorage.setItem('plicar-zero_closed_modal', 'model_list_switch_modal');
    }
    props.setOpenModal(false);
    props.handleSwitch ? props.handleSwitch(props.viewerSwitch) : '';
    if (props.viewerSwitch === true) {
      amplitude.sendEvent(amplitudeEvents.detail.zero_detail_viewer_unpublish_modal_noshow, {
        type: checkbox ? 'on' : 'off',
      });
      amplitude.sendEvent(amplitudeEvents.detail.zero_detail_viewer_unpublish);
    }
    props.updateModel ? props.updateModel() : '';
    props.setViewerSwitch((prevState: boolean) => !prevState);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <RDSModal
      title={getTitle()}
      buttonType={'noBox'}
      enableCloseButton={false}
      checkboxLabel={t('Models.다시 보지 않기')}
      checkboxState={checkbox}
      imageAtTop={false}
      imageSrc={getImageSrc()}
      checkboxHandleCheck={() => setCheckbox((prevState: boolean) => !prevState)}
      button1Label={getButton()}
      button1Fn={handleButtonClicked}
      button1Color={!props.viewerSwitch ? 'warning' : 'primary'}
      button2Label={props.viewerSwitch ? t('MyPage.공개유지') : t('MyPage.취소')}
      button2Fn={() => {
        if (props.viewerSwitch === true) {
          amplitude.sendEvent(amplitudeEvents.detail.zero_detail_viewer_unpublish_cancel);
        }
        props.setOpenModal(false);
      }}
      supportingText={getSupportingText()}
      open={props.openModal}
      onClose={() => {
        if (props.viewerSwitch === true) {
          amplitude.sendEvent(amplitudeEvents.detail.zero_detail_viewer_unpublish_cancel);
        }
        props.setOpenModal(false);
      }}
    />
  );
};

export default ModelCardSwitchModal;
