import React, { useState } from 'react';
import { RDSIconButton, XMarkIcon, RDSModal } from '@reconlabs/reconlabs-fe-components';
import { convertSizeUnit } from '../../../../utils/utils';
import { UploadFile } from '../RDSUpload';
import { useTranslation } from 'react-i18next';
import amplitude from '../../../../utils/amplitude';
import amplitudeEvents from '../../../../constants/amplitudeEvents';

const UploadingFileBox = ({
  uploadFile,
  abortUpload,
}: {
  uploadFile: UploadFile;
  abortUpload: Function;
  stopUpload: Function;
  restartUpload: Function;
}) => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const { t } = useTranslation();
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  /*----------------------------------------
                Bueiness Logic
   ----------------------------------------*/
  const uploadCancel = async () => {
    await abortUpload(uploadFile);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <RDSModal
        open={openCancelModal}
        onClose={() => setOpenCancelModal(false)}
        buttonType="noBox"
        title={`${t('global.업로드를 중단할까요?')}`}
        supportingText={`${t('global.중단하시면 해당 파일은 목록에서 사라집니다.')}`}
        button1Label={t('global.중단')}
        button1Fn={() => {
          amplitude.sendEvent(amplitudeEvents.bottomsheet.zero_allmodel_bottomsheet_list_uploading_delete, {
            button: 'stop',
          });

          uploadCancel();
        }}
        button2Label={t('MyPage.취소')}
        button2Fn={() => {
          amplitude.sendEvent(amplitudeEvents.bottomsheet.zero_allmodel_bottomsheet_list_uploading_delete, {
            button: 'cancel',
          });

          setOpenCancelModal(false);
        }}
        enableCloseButton={false}
        customStyle={{
          ['.RDSButton__noBox__primary ']: {
            color: '#E94A51',
          },
        }}
      />
      <div className="UploadWaiting Separator">
        <div className="UploadWaiting__header">
          <div className="UploadWaiting__header__name">{uploadFile.file.name}</div>
          <div className="UploadWaiting__header__wrapper">
            <div className="UploadWaiting__header__wrapper__volume">({convertSizeUnit(uploadFile.file.size)}MB)</div>
            {uploadFile.progress !== 100 ? (
              <RDSIconButton
                icon={<XMarkIcon />}
                size="small"
                color="grey"
                type="noBox"
                onClick={() => setOpenCancelModal(true)}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        {uploadFile.progress !== 100 ? (
          <div className="UploadWaiting__state">
            <div className="UploadWaiting__state__text">{uploadFile.progress ? uploadFile.progress : 0}%</div>
            <progress value={uploadFile.progress} max={100}></progress>
          </div>
        ) : (
          <div className={window.locale === 'ko' ? 'UploadWaiting__create' : 'UploadWaiting__create-en'}>
            <div
              className={
                window.locale === 'ko' ? 'UploadWaiting__state__text' : 'UploadWaiting__create-en__state__text-en'
              }
            >{`${t('global.처리중')}`}</div>
            <div
              className={
                window.locale === 'ko' ? 'UploadWaiting__create__progress' : 'UploadWaiting__create-en__progress-en'
              }
            ></div>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadingFileBox;
