import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { STATIC_FILES_URL } from '../../constants/urls';
import { getModelMetadata } from '../../hooks/react-query/useThreeDModel';
import { getModelViewerScriptSource } from '../../utils/createARScripts';
import { getQueryStringProps } from '../../utils/utils';

const hdrImage = {
  first: `${STATIC_FILES_URL}/images/hdr/plicar-studio.hdr`,
  second: `${STATIC_FILES_URL}/images/hdr/plicar-studio2.hdr`,
  studio: `${STATIC_FILES_URL}/images/hdr/hdr-studio.hdr`,
  neutral: `${STATIC_FILES_URL}/images/hdr/hdr-neutral.hdr`,
  outdoor: `${STATIC_FILES_URL}/images/hdr/hdr-outdoor.hdr`,
  indoor: `${STATIC_FILES_URL}/images/hdr/hdr-indoor.hdr`,
};

const Viewer = () => {
  const location = useLocation();
  const viewerUid = getQueryStringProps(location.search, 'viewer_uid', true);
  const measure = getQueryStringProps(location.search, 'measure', true);
  const exposure = getQueryStringProps(location.search, 'exposure', true);
  const shadowIntensity = getQueryStringProps(location.search, 'shadowIntensity', true);
  const shadowSoftness = getQueryStringProps(location.search, 'shadowSoftness', true);
  const lightingMode = getQueryStringProps(location.search, 'lightingMode', true);

  const environmentImage = useMemo(() => {
    switch (lightingMode) {
      case 'default':
        return 'neutral';
      case 'indoor':
        return hdrImage.indoor;
      case 'neutral':
        return hdrImage.neutral;
      case 'outdoor':
        return hdrImage.outdoor;
      case 'studio':
        return hdrImage.studio;
      case 'hdr':
        return hdrImage.second;
      default:
        return 'neutral';
    }
  }, [lightingMode]);
  const [modelName, setModelName] = useState<string>('');

  useEffect(() => {
    if (document.querySelector('.plicarzero-viewer') === null) {
      const RDSPlicarViewerScript = document.createElement('script');
      RDSPlicarViewerScript.classList.add('plicarzero-viewer');
      RDSPlicarViewerScript.type = 'module';
      RDSPlicarViewerScript.src = getModelViewerScriptSource(); // TODO API Endpoint 변경 및 안정화시 plicar-viewer.min.js 로 변경
      document.head.appendChild(RDSPlicarViewerScript);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const res = await getModelMetadata(viewerUid);
      setModelName(res.data.data.modelName);
    })();
  }, []);

  return (
    <div className="Viewer">
      <div className="Viewer__Container">
        <div className="Viewer__Title">3D 뷰어</div>
        <div className="Viewer__Name">{modelName}</div>
        <div className="Viewer__Wrapper">
          {/* @ts-ignore */}
          <plicarzero-viewer
            id="plicarzero-viewer"
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '8px',
              backgroundColor: '#FFFFFF',
            }}
            viewer-uid={viewerUid}
            viewer-data={''}
            exposure={exposure ? exposure : '1'}
            measure={measure ? measure : ''}
            shadow-intensity={shadowIntensity ? shadowIntensity : '1'}
            shadow-softness={shadowSoftness ? shadowSoftness : '0.5'}
            environment-image={environmentImage}
            camera-controls
            camera-orbit="25deg 75deg 105%"
            max-camera-orbit="Infinity 170deg 120%"
            min-camera-orbit="-Infinity 10deg auto"
          >
            {measure && (
              <svg id="lines" xmlns="http://www.w3.org/2000/svg" className="dimensionLineContainer">
                <line className="dimensionLine"></line>
                <line className="dimensionLine"></line>
                <line className="dimensionLine"></line>
                <line className="dimensionLine"></line>
                <line className="dimensionLine"></line>
              </svg>
            )}
            {/* @ts-ignore */}
          </plicarzero-viewer>
        </div>
      </div>
    </div>
  );
};

export default Viewer;
