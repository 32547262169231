/** @jsxImportSource @emotion/react */
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './scss/main.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import amplitude from './utils/amplitude';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
/*----------------------------------------
                Default Template
 ----------------------------------------*/
if (process.env.REACT_APP_PLICAR_ZERO_ANALYTICS_API_KEY) {
  // @ts-ignore
  if (!window.Cypress) {
    amplitude.initialize(process.env.REACT_APP_PLICAR_ZERO_ANALYTICS_API_KEY);
  }
}

root.render(
  <BrowserRouter>
    <ThemeProvider breakpoints={['xl', 'md', 'xs']} minBreakpoint="xs">
      <App />
    </ThemeProvider>
  </BrowserRouter>,
);
