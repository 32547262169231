import React from 'react';
import RDSUpload from './RDSUpload';
import UploadDoneFileBox from './UploadProgressStateBox/UploadDoneFileBox';
import UploadFailedFileBox from './UploadProgressStateBox/UploadFailedFileBox';
import UploadingFileBox from './UploadProgressStateBox/UploadingFileBox';
import UploadWaitingFileBox from './UploadProgressStateBox/UploadWaitingFileBox';
import { UploadFile } from './RDSUpload';

const UploadProgress = () => {
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const itemsFilter = (uploadFiles: UploadFile[], state: string) => {
    return uploadFiles.filter((item: UploadFile) => item.state === state);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <RDSUpload.Progress>
      {({ uploadFiles, abortUpload, stopUpload, restartUpload }) => {
        const viewerCompleteItems = itemsFilter(uploadFiles, 'viewerComplete');
        const completeItems = itemsFilter(uploadFiles, 'complete');
        const ongoingItems = itemsFilter(uploadFiles, 'ongoing');
        const stoppedItems = itemsFilter(uploadFiles, 'stopped');
        const waitingItems = itemsFilter(uploadFiles, 'waiting');
        const errorItems: UploadFile[] = uploadFiles.filter((item: UploadFile) => {
          return (
            item.state === 'invalidFile' ||
            item.state === 'unknownFail' ||
            item.state === 'canceled' ||
            item.state === 'overPlan' ||
            item.state === 'overCount'
          );
        });
        return (
          <>
            {viewerCompleteItems.map((item: UploadFile) => {
              return <UploadDoneFileBox key={item.id} uploadFile={item} />;
            })}
            {completeItems.map((item: UploadFile) => {
              return (
                <UploadingFileBox
                  key={item.id}
                  uploadFile={item}
                  abortUpload={abortUpload}
                  stopUpload={stopUpload}
                  restartUpload={restartUpload}
                />
              );
            })}
            {ongoingItems.map((item: UploadFile) => {
              return (
                <UploadingFileBox
                  key={item.id}
                  uploadFile={item}
                  abortUpload={abortUpload}
                  stopUpload={stopUpload}
                  restartUpload={restartUpload}
                />
              );
            })}
            {stoppedItems.map((item: UploadFile) => {
              return (
                <UploadingFileBox
                  key={item.id}
                  uploadFile={item}
                  abortUpload={abortUpload}
                  stopUpload={stopUpload}
                  restartUpload={restartUpload}
                />
              );
            })}
            {waitingItems.map((item: UploadFile) => {
              return <UploadWaitingFileBox key={item.id} uploadFile={item} abortUpload={abortUpload} />;
            })}
            {errorItems.map((item: UploadFile) => {
              return <UploadFailedFileBox key={item.id} uploadFile={item} />;
            })}
          </>
        );
      }}
    </RDSUpload.Progress>
  );
};

export default UploadProgress;
