import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type AnalysisMonthlyUsageProps = {
  label: string;
  labels: string[];
  monthlyData: number[];
};

const AnalysisMonthlyUsage = ({ labels, monthlyData, label }: AnalysisMonthlyUsageProps) => {
  return (
    <Line
      data={{
        labels: labels,
        datasets: [
          {
            label: label,
            data: monthlyData,
            borderWidth: 1,
            fill: true,
            borderColor: '#F0919E',
            backgroundColor: '#F0919E',
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 20,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      }}
    />
  );
};

export default AnalysisMonthlyUsage;
