import React, { useEffect, useState } from 'react';
import LoginInputForm from './LoginInputForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { oAuthLogin } from '../../../utils/login';
import { RDSDropdown, RDSOption, TooltipMaker } from '@reconlabs/reconlabs-fe-components';
import { Oauth, OauthKr } from '../../../constants/type';
import { useTranslation } from 'react-i18next';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import useLocale from '../../../hooks/utils/useLocale';
import Footer from './Footer';
import ResetPasswordModal from './ResetPasswordModal';
import Logo from '../../../images/logo.png';
import { DEFAULT_LOGGEDIN_ROUTE } from '../../../constants/urls';
import { getQueryStringProps } from '../../../utils/utils';
import { setToken } from '../../../utils/localstorage';

const Login = () => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState<boolean>(false);
  const [errorRegisterTypeOauthUser, setErrorRegisterTypeOauthUser] = useState<Oauth | null>(null);
  const [toast, setToast] = useState<string>('');
  const { search } = useLocation();
  const [adminToken, setAdminToken] = useState<string>('');
  const { locale, setLocale } = useLocale();
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (search && process.env.REACT_APP_BUILD_STAGE === 'prod-admin') {
      setAdminToken(getQueryStringProps(search, 'token'));
    }
  }, [search]);

  useEffect(() => {
    (async () => {
      if (adminToken) {
        try {
          setToken({ access_token: adminToken });
          navigate(DEFAULT_LOGGEDIN_ROUTE);
        } catch {
          console.warn('token error');
        }
      }
    })();
  }, [adminToken]);

  // 툴팁 사라지게 하기
  useEffect(() => {
    setTimeout(() => {
      setErrorRegisterTypeOauthUser(null);
    }, 2000);
  }, [errorRegisterTypeOauthUser]);
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const onClickOauth = (e: any) => {
    // e.target.id = kakao, naver, google
    const platformName = e.target.id as OauthKr;
    amplitude.sendEvent(amplitudeEvents.signin[platformName]);
    oAuthLogin(platformName);
  };

  const onClickSignup = () => {
    amplitude.sendEvent(amplitudeEvents.signup.zero_signup_try);
    navigate('/agreement');
  };

  const changeLocale = (locale: string) => {
    amplitude.sendEvent(amplitudeEvents.signin.zero_signin_language_switch, { lang: locale === 'ko' ? 'kor' : 'eng' });
    setLocale(locale);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <ResetPasswordModal
        isVisible={openResetPasswordModal}
        setOpenResetPasswordModal={setOpenResetPasswordModal}
        setToast={setToast}
      />
      <div className="Login">
        <div className="Login__inner">
          <div className="Login__inner__title">
            <img src={Logo} alt="로고 이미지" draggable={false} />
          </div>
          <LoginInputForm
            setOpenResetPasswordModal={setOpenResetPasswordModal}
            setErrorRegisterTypeOauthUser={setErrorRegisterTypeOauthUser}
          />
          <div className="Login__inner__signup">
            {`${t('Login.플리카가 처음이신가요?')}`} <button onClick={onClickSignup}>{`${t('Login.회원가입')}`}</button>
          </div>
          <div className="Login__inner__oauth">
            <div className="Login__inner__oauth__separator"></div>
            <div
              className={window.locale === 'ko' ? 'Login__inner__oauth__header' : 'Login__inner__oauth__header-en'}
            >{`${t('Login.간편하게 시작하기')}`}</div>
            <div className="Login__inner__oauth__separator"></div>
          </div>
          <div className="Login__inner__platform">
            <Login.SocialLoginIcon
              t={t}
              platformName={'카카오'}
              imageSrc="images/kakao.png"
              showToolTip={errorRegisterTypeOauthUser === 'kakao'}
              onClick={onClickOauth}
            />
            <Login.SocialLoginIcon
              t={t}
              platformName={'네이버'}
              imageSrc="images/naver.png"
              showToolTip={errorRegisterTypeOauthUser === 'naver'}
              onClick={onClickOauth}
            />
            <Login.SocialLoginIcon
              t={t}
              platformName={'구글'}
              imageSrc="images/google.png"
              showToolTip={errorRegisterTypeOauthUser === 'google'}
              onClick={onClickOauth}
            />
          </div>
          <Footer />
          <div className="Login__inner__i18n">
            <RDSDropdown
              size="small"
              customStyles={{
                fontFamily: 'Pretendard, sans-serif',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '12px',
                letterSpacing: '0.25px',
              }}
              onChange={(locale) => changeLocale(locale)}
              value={locale}
            >
              <React.Fragment key=".0">
                <RDSOption value="ko">한국(한국어)</RDSOption>
                <RDSOption value="en">USA(English)</RDSOption>
              </React.Fragment>
            </RDSDropdown>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

type SocialLoginIconProps = {
  t: any;
  platformName: OauthKr;
  imageSrc: string;
  showToolTip: boolean;
  onClick: Function;
};
const SocialLoginIcon = ({ t, platformName, imageSrc, showToolTip, onClick }: SocialLoginIconProps) => {
  return (
    <div>
      <TooltipMaker
        contents={t(`Login.${platformName} 유저입니다. ${platformName} 로그인을 이용해주세요.`)}
        position={'top'}
        showTooltip={showToolTip}
      >
        <div className="Login__inner__platform__container">
          <img
            id={platformName}
            src={imageSrc}
            width={'40px'}
            alt={platformName}
            onClick={(e) => {
              onClick(e);
            }}
          />
          <div className="Login__inner__platform__container__name">{t(`Login.${platformName}`)}</div>
        </div>
      </TooltipMaker>
    </div>
  );
};
Login.SocialLoginIcon = SocialLoginIcon;
