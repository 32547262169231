import { STATIC_FILES_URL, PLICAR_ZERO_ARGATEWAY_URL, plicarButtonImgUrl } from '../constants/urls';

export const getARGatewayUrl = (viewerUid: string) => {
  return `${PLICAR_ZERO_ARGATEWAY_URL}/${viewerUid}`;
};
export const getPublicModelViewerScript = (viewerUid: string) => {
  return viewerUid
    ? `<script type='module' src='${getModelViewerScriptSource()}'></script> <plicarzero-viewer viewer-uid=${viewerUid} camera-controls exposure='1' environment-image='neutral' style="touch-action: none;"> </plicarzero-viewer>`
    : ''; // 스크롤 방지용 touch-action:none 속성
};
export const getPublicModelViewerScriptWithProps = (
  viewerUid: string,
  environmentImage?: any,
  measure?: string,
  exposure?: string,
  shadowIntensity?: string,
  shadowSoftness?: string,
) => {
  return viewerUid
    ? `<script type='module' src='${getModelViewerScriptSource()}'></script>
    <plicarzero-viewer
      id='plicarzero-viewer'
      viewer-uid=${viewerUid}
      camera-controls
      environment-image=${environmentImage ? `'${environmentImage}'` : 'neutral'}
      ${measure ? `measure='${measure}'` : ''}
      exposure=${exposure ? exposure : '1'}
      shadow-intensity=${shadowIntensity ? shadowIntensity : '1'}
      shadow-softness=${shadowSoftness ? shadowSoftness : '0.5'}
      style="touch-action: none;">${measure ? getDimElements() : ''} </plicarzero-viewer>`
    : '';
};
export const getPublicModelViewerIframeScript = (viewerUid: string) => {
  return viewerUid
    ? `<iframe src="${process.env.REACT_APP_PLICAR_ZERO_PROTOCOL}://${process.env.REACT_APP_PLICAR_ZERO_DOMAIN}${
        process.env.REACT_APP_PLICAR_ZERO_PORT ? `:${process.env.REACT_APP_PLICAR_ZERO_PORT}` : ''
      }/viewer?viewer_uid=${viewerUid}" title="plicar viewer" width="500" height="500"></iframe>`
    : '';
};
export const getPublicModelViewerIframeScriptWithProps = (
  viewerUid: string,
  lightingMode?: any,
  measure?: string,
  exposure?: string,
  shadowIntensity?: string,
  shadowSoftness?: string,
) => {
  return viewerUid
    ? `<iframe
        src="${process.env.REACT_APP_PLICAR_ZERO_PROTOCOL}://${process.env.REACT_APP_PLICAR_ZERO_DOMAIN}${
        process.env.REACT_APP_PLICAR_ZERO_PORT ? `:${process.env.REACT_APP_PLICAR_ZERO_PORT}` : ''
      }/viewer?viewer_uid=${viewerUid}&lightingMode=${lightingMode}${measure ? `&measure=${measure}` : ''}${
        exposure ? `&exposure=${exposure}` : ''
      }${shadowIntensity ? `&shadowIntensity=${shadowIntensity}` : ''}${
        shadowSoftness ? `&shadowSoftness=${shadowSoftness}` : ''
      }"
        title="plicar viewer"
        width="500"
        height="500"></iframe>`
    : '';
};
export const getModelViewerScript = (viewerData: string) => {
  return viewerData
    ? `<script type='module' src='${getModelViewerScriptSource()}'></script> <plicarzero-viewer viewer-data=${viewerData} camera-controls exposure='1' environment-image='neutral' style="touch-action: none;"> </plicarzero-viewer>`
    : ''; // 스크롤 방지용 touch-action:none 속성
};
export const getModelViewerScriptSource = () => {
  return `${STATIC_FILES_URL}/script/plicar-viewer.min.js`;
};
// 스크립트 로드 버튼(구 AR다이렉트 버튼) 복사하기
export type buttonValueTypes = 'primary' | 'secondary' | 'tertiary';
export const getARDirectButtonScript = (viewerUid: string, colorType: buttonValueTypes) =>
  `<script src="${STATIC_FILES_URL}/script/plicarzero-button.min.js"></script><plicarzero-button viewer-uid="${viewerUid}" color-type="${colorType}"></plicarzero-button>`;

// ARGateway 경유 버튼(구 AR링크 버튼) 복사하기
export const getARGatewayButtonScript = (viewerUid: string, colorType: buttonValueTypes) => {
  return `<a href="${PLICAR_ZERO_ARGATEWAY_URL}/${viewerUid}?access_type=AR_Button_Link"><img src="${plicarButtonImgUrl[colorType]}"/></a>`;
};

const getDimElements = () => {
  return `<svg id="lines" xmlns="http://www.w3.org/2000/svg" class="dimensionLineContainer"><line class="dimensionLine"></line><line class="dimensionLine"></line><line class="dimensionLine"></line><line class="dimensionLine"></line><line class="dimensionLine"></line></svg>`;
};
