import React, { useContext, useEffect, useState } from 'react';
import {
  ChevronBackIcon,
  CodeIcon,
  CustomQRCode,
  DeleteOutlinedIcon,
  FileDownloadIcon,
  LinkIcon,
  RDSDropdown,
  RDSIconButton,
  RDSIconTextButton,
  RDSInputField,
  RDSModal,
  RDSOption,
  RDSSwitch,
  RDSToast,
  RDSToastAlertObject,
  TooltipMaker,
} from '@reconlabs/reconlabs-fe-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { getModelViewer, useModel } from '../../../hooks/react-query/useThreeDModel';
import { getQueryStringProps, getResizedData, urlToBlob } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';
import {
  getARDirectButtonScript,
  getARGatewayButtonScript,
  getARGatewayUrl,
  getModelViewerScriptSource,
  getPublicModelViewerIframeScript,
  getPublicModelViewerScript,
} from '../../../utils/createARScripts';
import ModelOverviewARViewerImage from '../../../images/ModelOverviewARViewerImage.png';
import { copyToClipboard } from '../../../utils/clipboardUtils';
import { useDeleteModel, useUpdateModel } from '../../../hooks/react-query/useUpdateThreeDModel';
import { UserContext } from '../../context/UserContextProvider';
import ModelCardSwitchModal from '../../ModelCardSwitchModal';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import Cafe24Image from '../../../images/arviewer/Cafe24Image.png';
import ImwebImage from '../../../images/arviewer/ImwebImage.png';
import WordpressImage from '../../../images/arviewer/WordpressImage.png';
import ShowpifyImage from '../../../images/arviewer/ShowpifyImage.png';
import CodeImage from '../../../images/arviewer/CodeImage.png';
import NaverImage from '../../../images/arviewer/NaverImage.png';

const ArrowForwardIcon = () => (
  <svg width="24" height="24" viewBox="-5 -5 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.9987 0.333252L5.8237 1.50825L10.4737 6.16659H0.332031V7.83325H10.4737L5.8237 12.4916L6.9987 13.6666L13.6654 6.99992L6.9987 0.333252Z"
      fill="#0C0D0D"
    />
  </svg>
);

const IframeIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8333 0.333252H2.16667C1.24167 0.333252 0.5 1.08325 0.5 1.99992V11.9999C0.5 12.9166 1.24167 13.6666 2.16667 13.6666H13.8333C14.75 13.6666 15.5 12.9166 15.5 11.9999V1.99992C15.5 1.08325 14.7583 0.333252 13.8333 0.333252ZM13.8333 11.9999H2.16667V3.66659H13.8333V11.9999Z"
      fill="#646466"
    />
  </svg>
);

const ModelOverview = () => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  // const
  const navigate = useNavigate();
  const location = useLocation();
  const modelUid = getQueryStringProps(location.search, 'model_uid');
  const { t } = useTranslation();

  // internal variables
  const [viewerData, setViewerData] = useState<any>();
  const [publishedCatalogModels, setPublishedCatalogModels] = useState<string[]>([]);
  const [savedCatalogModels, setSavedCatalogModels] = useState<string[]>([]);
  const [edittedModelName, setEdittedModelName] = useState<string>('');
  const [edittedSalesUrl, setEdittedSalesUrl] = useState<string>('');
  const [fileFormat, setFileFormat] = useState<'png' | 'jpg'>('png');

  // context
  const { setModelDeleted } = useContext(UserContext);

  // spinner
  const [spinner, setSpinner] = useState<string>('loading-spinner');

  // Alert
  const [RDSAlert, setRDSAlert] = useState<RDSToastAlertObject>();

  // boolean
  const [openModelDeleteModal, setOpenModelDeleteModal] = useState<boolean>(false);
  const [openModelDeleteDenyModal, setOpenModelDeleteDenyModal] = useState<boolean>(false);
  const [openViewerSwitchModal, setOpenViewerSwitchModal] = useState<boolean>(false);
  const [openModelOffDenyModal, setOpenModelOffDenyModal] = useState<boolean>(false);
  const [isHoverGif, setIsHoverGif] = useState<boolean>(false);

  // api calls
  const { model, isSuccess } = useModel(modelUid);
  const { mutateAsync: deleteMutate } = useDeleteModel();
  const {
    mutate: updateMutation,
    isLoading: isUpdateModelLoading,
    error: updateModelError,
  } = useUpdateModel({ range: [0, 49] });
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    (async () => {
      if (isSuccess) {
        const res = await getModelViewer({ viewer_uid: model.viewer_uid });
        setViewerData(res.data);
      }
    })();
  }, [isSuccess]);

  useEffect(() => {
    if (document.querySelector('.plicarzero-viewer') === null) {
      const RDSPlicarViewerScript = document.createElement('script');
      RDSPlicarViewerScript.classList.add('plicarzero-viewer');
      RDSPlicarViewerScript.type = 'module';
      RDSPlicarViewerScript.src = getModelViewerScriptSource();
      document.head.appendChild(RDSPlicarViewerScript);
    }

    // page open event
    amplitude.sendEvent(amplitudeEvents.overview.zero_utilization_summary);
  }, []);

  useEffect(() => {
    const error: any = updateModelError;
    if (error && error.response.status === 400) {
      errorHandler(error);
      setOpenModelOffDenyModal(true);
    }
  }, [updateModelError]);

  useEffect(() => {
    if (isUpdateModelLoading) {
      setSpinner('delete-spinner');
    } else {
      setSpinner('loading-spinner');
    }
  }, [isUpdateModelLoading]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const deleteModel = async () => {
    setSpinner('delete-spinner');
    setOpenModelDeleteModal(false);
    try {
      await deleteMutate(modelUid);
      setModelDeleted(true);
      navigate('/dashboard/models');
    } catch (e: any) {
      setSpinner('');
      if (e && e.response.status === 400) {
        errorHandler(e);
      }
      setOpenModelDeleteDenyModal(true);
    }
  };

  const errorHandler = (e: any) => {
    const data = e.response.data.catalogs;
    const catalogs: string[] = data.catalogs;
    const publishedCatalogs: string[] = data.published_catalogs;
    const saved: string[] = [];
    const published: string[] = [];

    if (catalogs && catalogs.length > 0) {
      catalogs.forEach((catalog: string) => {
        if (publishedCatalogs && publishedCatalogs.indexOf(catalog) >= 0) {
          published.push(catalog);
        } else {
          saved.push(catalog);
        }
      });
    }

    if (publishedCatalogs && publishedCatalogs.length > 0) {
      publishedCatalogs.forEach((catalog: string) => {
        if ((catalogs && catalogs.indexOf(catalog) === -1) || !catalogs) {
          published.push(catalog);
        }
      });
    }

    setPublishedCatalogModels(published);
    setSavedCatalogModels(saved);
  };

  const getFile = () => {
    const size = model ? getFileSize(model.s3_file_size) : 0;
    return `${model.s3_file_type}/${size}`;
  };

  const getFileSize = (size: number) => {
    let result;

    if (size < 102.4 * 1024) {
      result = (size / 1024).toFixed(2) + 'KB';
    } else {
      result = (size / 1024 / 1024).toFixed(2) + 'MB';
    }

    return result;
  };

  const glbDownload = async () => {
    const blob: any = await urlToBlob(model.latest_transforms.glb.url);
    const link = document.createElement('a');
    link.download = getFileName(model.model_name);
    link.href = window.URL.createObjectURL(blob);
    link.click();
    setRDSAlert({ type: 'success', message: t('Detail.다운로드가 완료되었습니다!') });
  };

  const getFileName = (name: string) => {
    return name.slice(0, name.lastIndexOf('.')) + '.glb';
  };

  const getGifFileName = (name: string) => {
    return name.slice(0, name.lastIndexOf('.')) + '.gif';
  };

  const downloadQRImage = () => {
    const qrCode = document.querySelector('.publish-qr-code') as HTMLCanvasElement;
    const link = document.createElement('a');
    link.download = `PlicAR_QRCode_${model.viewer_uid}.png`;
    link.href = qrCode.toDataURL();
    link.click();
    setRDSAlert({ type: 'success', message: t('Detail.다운로드가 완료되었습니다!') });
  };

  const gifDownload = async () => {
    const blob: any = await urlToBlob(model.latest_transforms.gif.url);
    const link = document.createElement('a');
    link.download = getGifFileName(model.model_name);
    link.href = window.URL.createObjectURL(blob);
    link.click();
    setRDSAlert({ type: 'success', message: t('Detail.다운로드가 완료되었습니다!') });
  };
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const onSwitchClicked = () => {
    if (localStorage.getItem('plicar-zero_closed_modal')) {
      handleSwitch(!!model.model_published);
    } else {
      setOpenViewerSwitchModal(true);
    }
  };

  const handleSwitch = async (viewerSwitch: boolean) => {
    const updateModel = {
      uid: model ? model.uid : '',
      model_name: model ? model.model_name : '',
      model_memo: model ? model.model_memo : '',
      model_sales_url: model ? model.model_sales_url : '',
      model_published: viewerSwitch ? 0 : 1,
    };
    updateMutation(updateModel);
    // Amplitude: 뷰어 공개 설정 변경 시 로깅
    // amplitude.sendEvent(amplitudeEvents.detail.zero_detail_viewer_publish, { type: viewerSwitch ? 'on' : 'off' });
  };

  const handleModelNameSave = () => {
    updateMutation({
      uid: model.uid,
      model_name: edittedModelName!,
      model_memo: model.model_memo,
      model_sales_url: model.model_sales_url,
      model_published: model.model_published,
    });
  };

  const handleSalesUrlSave = () => {
    updateMutation({
      uid: model.uid,
      model_name: model.model_name,
      model_memo: model.model_memo,
      model_sales_url: edittedSalesUrl!,
      model_published: model.model_published,
    });
  };

  const handleCopyToClipboard = (value: string) => {
    copyToClipboard(value);
    setRDSAlert({ type: 'success', message: t('Detail.복사가 완료되었습니다!') });
  };

  const handleNavigator = (target: number) => {
    navigate(`/dashboard/model/detail?model_uid=${model.uid}&scroll_index=${target}`);
  };

  const exportImage = async () => {
    const plicarViewerElement = document.querySelector('#Overview-PlicarViewer');
    // @ts-ignore
    const base64Data = plicarViewerElement?.toDataURL();
    const resizedBase64Data = await getResizedData(base64Data, fileFormat, '21:16', 300);
    if (resizedBase64Data && typeof resizedBase64Data === 'string') {
      const base64Response = await fetch(resizedBase64Data);
      const blob = await base64Response.blob();
      const link = document.createElement('a');
      link.download = getExportFileName(model.model_name);
      link.href = window.URL.createObjectURL(blob);
      link.click();
    }
  };

  const getExportFileName = (name: string) => {
    const dataFormat = fileFormat === 'jpg' ? '.jpg' : '.png';
    return name.slice(0, name.lastIndexOf('.')) + dataFormat;
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <RDSModal
        title={`${t('Detail.모델을 삭제할까요?')}`}
        enableCloseButton={false}
        button1Label={t('Detail.모델 삭제')}
        button1Fn={() => {
          amplitude.sendEvent(amplitudeEvents.overview.zero_utilization_summary_model_delete_confirm);
          deleteModel();
        }}
        button1Color={'warning'}
        button2Label={t('MyPage.취소')}
        button2Fn={() => {
          amplitude.sendEvent(amplitudeEvents.overview.zero_utilization_summary_model_delete_cancel);
          setOpenModelDeleteModal(false);
        }}
        supportingText={
          <>
            {`${t('Detail.삭제된 모델은 복구가 어렵습니다.')}`} <br /> <br />
            {`${t(
              'Detail.플리카 카탈로그 또는 외부 채널에 공개 중일 때 3D 모델이 삭제되면 에러 메시지가 보이게 되니 해당 페이지에서 별도로 상품 목록을 관리해주세요.',
            )}`}
          </>
        }
        open={openModelDeleteModal}
        onClose={() => {
          // Amplitude: 바깥 영역 클릭도 cancel로 간주하여 이벤트 전송
          amplitude.sendEvent(amplitudeEvents.overview.zero_utilization_summary_model_delete_cancel);
          setOpenModelDeleteModal(false);
        }}
      />
      <RDSModal
        title={`${t('Detail.카탈로그에 등록된 모델은 삭제할 수 없습니다')}`}
        enableCloseButton={false}
        button1Label={t('Detail.카탈로그 확인하기')}
        button1Fn={() => {
          navigate('/dashboard/catalog');
        }}
        button2Label={t('Detail.닫기')}
        button2Fn={() => {
          setOpenModelDeleteDenyModal(false);
        }}
        supportingText={
          <>
            {`${t('Detail.모델이 카탈로그에 포함되어서 삭제가 불가능합니다.')}`}
            {`${t('Detail.삭제를 하시려면 카탈로그에서 먼저 모델을 제거해주세요.')}`}
          </>
        }
        linkText={
          <div style={{ maxHeight: '130px', overflowY: 'scroll' }}>
            {publishedCatalogModels.length > 0 && (
              <div>
                <div> · {`${t('Detail.발행되어 있는 카탈로그')}`}</div>
                {publishedCatalogModels.map((model, index) => {
                  return (
                    <div key={model + index} style={{ marginLeft: '20px' }}>
                      - {model}
                    </div>
                  );
                })}
              </div>
            )}
            {savedCatalogModels.length > 0 && (
              <div>
                <div> · {`${t('Detail.저장되어 있는 카탈로그')}`}</div>
                {savedCatalogModels.map((model, index) => {
                  return (
                    <div key={model + index} style={{ marginLeft: '20px' }}>
                      - {model}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        }
        open={openModelDeleteDenyModal}
        onClose={() => {
          setOpenModelDeleteDenyModal(false);
        }}
      />
      <ModelCardSwitchModal
        openModal={openViewerSwitchModal}
        setOpenModal={setOpenViewerSwitchModal}
        viewerSwitch={!!model.model_published}
        setViewerSwitch={() => {}}
        handleSwitch={handleSwitch}
      />
      <RDSModal
        title={`${t('Detail.AR, 3D 뷰어 공개를 중단할 수 없는 모델입니다')}`}
        enableCloseButton={false}
        button1Label={t('Detail.카탈로그 확인하기')}
        button1Fn={() => {
          navigate('/dashboard/catalog');
        }}
        button2Label={t('Detail.닫기')}
        button2Fn={() => {
          setOpenModelOffDenyModal(false);
        }}
        supportingText={
          <>
            {`${t(
              'Detail.모델이 카탈로그에 포함되어서 AR, 3D 뷰어 공개 중단이 불가능합니다. 중단을 원하시면 카탈로그에서 먼저 모델을 제거해주세요.',
            )}`}
          </>
        }
        linkText={
          <div style={{ maxHeight: '130px', overflowY: 'scroll' }}>
            {publishedCatalogModels.length > 0 && (
              <div>
                <div> · {`${t('Detail.발행되어 있는 카탈로그')}`}</div>
                {publishedCatalogModels.map((model, index) => {
                  return (
                    <div key={model + index} style={{ marginLeft: '20px' }}>
                      - {model}
                    </div>
                  );
                })}
              </div>
            )}
            {savedCatalogModels.length > 0 && (
              <div>
                <div> · {`${t('Detail.저장되어 있는 카탈로그')}`}</div>
                {savedCatalogModels.map((model, index) => {
                  return (
                    <div key={model + index} style={{ marginLeft: '20px' }}>
                      - {model}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        }
        open={openModelOffDenyModal}
        onClose={() => {
          setOpenModelOffDenyModal(false);
        }}
      />
      <RDSToast.AlertArea openedAlert={RDSAlert}></RDSToast.AlertArea>
      <RDSToast.Overlay
        type="loadingSpinner"
        toastOverlayId="delete-spinner"
        openedToastOverlay={spinner}
        setOpenedToastOverlay={setSpinner}
      />
      {!isSuccess && (
        <RDSToast.Overlay
          type="loadingSpinner"
          toastOverlayId="loading-spinner"
          openedToastOverlay={spinner}
          setOpenedToastOverlay={setSpinner}
        />
      )}
      <div className="ModelOverview container-fluid">
        <div style={{ margin: '0 auto', width: '1230px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '32px' }}>
            <RDSIconTextButton type="noBox" size="small" icon={<ChevronBackIcon />} onClick={() => navigate(-1)}>
              {`${t('ModelOverview.뒤로가기')}`}
            </RDSIconTextButton>
            <RDSIconTextButton
              type="outline"
              size="xsmall"
              color="grey"
              icon={<DeleteOutlinedIcon />}
              customStyle={{ width: '146px' }}
              onClick={() => setOpenModelDeleteModal(true)}
            >
              {`${t('ModelOverview.모델삭제')}`}
            </RDSIconTextButton>
          </div>
          <div className="ModelOverview__Title">{`${t('ModelOverview.모델 관리 목록')}`}</div>
          <div className="ModelOverview__Wrapper">
            <div className="ModelOverview__Wrapper__3Dviewer">
              <div className="ModelOverview__Wrapper__3Dviewer__Header">
                {`${t('ModelOverview.3D 뷰어')}`}
                <div style={{ position: 'absolute', right: 0 }}>
                  <RDSIconButton
                    size="xsmall"
                    type="outline"
                    color="grey"
                    icon={<ArrowForwardIcon />}
                    onClick={() => handleNavigator(1)}
                  />
                </div>
              </div>
              <div className="ModelOverview__Wrapper__3Dviewer__Export">
                <div className="ModelOverview__Wrapper__3Dviewer__Export__Text">{`${t(
                  'ModelOverview.뷰어 내보내기',
                )}`}</div>
                <div className="ModelOverview__Wrapper__3Dviewer__Export__Buttons">
                  <RDSIconTextButton
                    icon={<CodeIcon />}
                    type="outline"
                    size="xsmall"
                    color="grey"
                    customStyle={{ width: '132px', height: '34px' }}
                    onClick={() => {
                      copyToClipboard(getPublicModelViewerScript(model.viewer_uid));
                      setRDSAlert({ type: 'success', message: t('Detail.복사가 완료되었습니다!') });

                      // button click event
                      amplitude.sendEvent(amplitudeEvents.overview.zero_utilization_summary_3dviewer_export_javascript);
                    }}
                  >
                    {`${t('ModelOverview.자바스크립트')}`}
                  </RDSIconTextButton>
                  <RDSIconTextButton
                    icon={<IframeIcon />}
                    type="outline"
                    size="xsmall"
                    color="grey"
                    customStyle={{ width: '132px', height: '34px' }}
                    onClick={() => {
                      copyToClipboard(getPublicModelViewerIframeScript(model.viewer_uid));
                      setRDSAlert({ type: 'success', message: t('Detail.복사가 완료되었습니다!') });

                      // button click event
                      amplitude.sendEvent(amplitudeEvents.overview.zero_utilization_summary_3dviewer_export_iframe);
                    }}
                  >
                    iframe
                  </RDSIconTextButton>
                  <RDSIconTextButton
                    icon={<LinkIcon />}
                    type="outline"
                    size="xsmall"
                    color="grey"
                    customStyle={{ width: '132px', height: '34px' }}
                    onClick={() => {
                      copyToClipboard(
                        `${process.env.REACT_APP_PLICAR_ZERO_PROTOCOL}://${process.env.REACT_APP_PLICAR_ZERO_DOMAIN}${
                          process.env.REACT_APP_PLICAR_ZERO_PORT ? `:${process.env.REACT_APP_PLICAR_ZERO_PORT}` : ''
                        }/viewer?viewer_uid=${model.viewer_uid}`,
                      );
                      setRDSAlert({ type: 'success', message: t('Detail.복사가 완료되었습니다!') });

                      // button click event
                      amplitude.sendEvent(amplitudeEvents.overview.zero_utilization_summary_3dviewer_export_viewerpage);
                    }}
                  >
                    {`${t('ModelOverview.뷰어전용 링크')}`}
                  </RDSIconTextButton>
                </div>
              </div>
              <div className="ModelOverview__Wrapper__3Dviewer__Viewer">
                {viewerData ? (
                  <>
                    {/* @ts-ignore */}
                    <plicarzero-viewer
                      id="Overview-PlicarViewer"
                      style={{ width: '100%', height: '100%' }}
                      viewer-uid={''}
                      viewer-data={JSON.stringify(viewerData)}
                      exposure="1"
                      environment-image={'neutral'}
                      camera-controls
                      camera-orbit="25deg 75deg 105%"
                      max-camera-orbit="Infinity 170deg 120%"
                      min-camera-orbit="-Infinity 10deg auto"
                    />
                  </>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >{`${t('Detail.뷰어 생성 중입니다...')}`}</div>
                )}
              </div>
              <div className="ModelOverview__Wrapper__3Dviewer__Export">
                <div className="ModelOverview__Wrapper__3Dviewer__Export__Text" style={{ paddingTop: '15px' }}>
                  {`${t('ModelOverview.이미지 캡처하기')}`}
                </div>
                <div className="ModelOverview__Wrapper__3Dviewer__Export__Buttons">
                  <RDSDropdown
                    size="small"
                    customStyles={{
                      fontFamily: 'Pretendard, sans-serif',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      fontSize: '12px',
                      letterSpacing: '0.25px',
                      width: '50%',
                      height: '38px',
                    }}
                    onChange={(value) => setFileFormat(value)}
                    value={fileFormat}
                  >
                    <React.Fragment key=".0">
                      <RDSOption value="png">png</RDSOption>
                      <RDSOption value="jpg">jpg</RDSOption>
                    </React.Fragment>
                  </RDSDropdown>
                  <RDSIconTextButton
                    icon={<FileDownloadIcon />}
                    size="xsmall"
                    onClick={() => {
                      exportImage();
                      // button click event
                      amplitude.sendEvent(amplitudeEvents.overview.zero_utilization_summary_3dviewer_capture_download);
                    }}
                    customStyle={{ width: '50%', height: '38px' }}
                  >
                    {`${t('Detail.다운로드')}`}
                  </RDSIconTextButton>
                </div>
              </div>
            </div>
            <div className="ModelOverview__Wrapper__ModelInfo">
              <RDSInputField.Underline
                size="large"
                displayValue={model.model_name}
                inputValue={edittedModelName}
                handleInputChange={(value) => setEdittedModelName(value)}
                handleOnSave={() => handleModelNameSave()}
              />
              <div className="ModelOverview__Wrapper__ModelInfo__DetailInfo">
                <div className="ModelOverview__Wrapper__ModelInfo__DetailInfo__Byte">
                  <div className="ModelOverview__Wrapper__ModelInfo__DetailInfo__Byte__Text">
                    <div className={window.locale === 'ko' ? 'MD-16-BOLD' : 'MD-16-BOLD MD-16-BOLD-en'}>{`${t(
                      'ModelOverview.확장자/용량',
                    )}`}</div>
                    <div className="MD-16-MEDIUM">{getFile()}</div>
                  </div>
                  <RDSIconTextButton
                    type="solid"
                    size="xsmall"
                    color="grey"
                    icon={<FileDownloadIcon />}
                    onClick={() => {
                      glbDownload();

                      // button click event
                      amplitude.sendEvent(amplitudeEvents.overview.zero_utilization_summary_info_download_glb);
                    }}
                  >
                    {`${t('ModelOverview.3D 모델 다운로드.glb')}`}
                  </RDSIconTextButton>
                </div>
                <div className="ModelOverview__Wrapper__ModelInfo__DetailInfo__Control">
                  <div style={{ display: 'flex', gap: '20px' }}>
                    <div className={window.locale === 'ko' ? 'MD-16-BOLD' : 'MD-16-BOLD MD-16-BOLD-en'}>{`${t(
                      'ModelOverview.판매 링크',
                    )}`}</div>
                    <RDSInputField.Underline
                      width={300}
                      size="small"
                      displayValue={model.model_sales_url}
                      inputValue={edittedSalesUrl}
                      handleInputChange={(value) => setEdittedSalesUrl(value)}
                      handleOnSave={() => handleSalesUrlSave()}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '20px' }}>
                    <div className={window.locale === 'ko' ? 'MD-16-BOLD' : 'MD-16-BOLD MD-16-BOLD-en'}>{`${t(
                      'ModelOverview.뷰어 공개',
                    )}`}</div>
                    <RDSSwitch enableLabel isOn={!!model.model_published} handleClick={() => onSwitchClicked()} />
                  </div>
                </div>
                <div style={{ position: 'absolute', right: '12px' }}>
                  <RDSIconButton
                    data-test-id="navigate-detail"
                    size="xsmall"
                    type="outline"
                    color="grey"
                    icon={<ArrowForwardIcon />}
                    onClick={() => handleNavigator(0)}
                  ></RDSIconButton>
                </div>
              </div>
              <div className="ModelOverview__Wrapper__ModelInfo__Wrapper">
                <div className="ModelOverview__Wrapper__ModelInfo__ARViewer">
                  <div className="ModelOverview__Wrapper__ModelInfo__ARViewer__Title">
                    {`${t('ModelOverview.AR 뷰어')}`}
                    <RDSIconButton
                      size="xsmall"
                      type="outline"
                      color="grey"
                      icon={<ArrowForwardIcon />}
                      onClick={() => handleNavigator(2)}
                    />
                  </div>
                  <div style={{ display: 'flex', marginTop: '16px', justifyContent: 'space-between' }}>
                    <div style={{ width: '164px' }}>
                      <CustomQRCode width="164px" height="164px" url={getARGatewayUrl(model.viewer_uid)} />
                      <RDSIconTextButton
                        fullWidth
                        type="solid"
                        size="xsmall"
                        color="grey"
                        icon={<FileDownloadIcon />}
                        customStyle={{ marginTop: '12px' }}
                        onClick={() => {
                          downloadQRImage();

                          // button click event
                          amplitude.sendEvent(amplitudeEvents.overview.zero_utilization_summary_arviewer_export_qrcode);
                        }}
                      >
                        {`${t('ModelOverview.다운로드.QR')}`}
                      </RDSIconTextButton>
                      <RDSIconTextButton
                        fullWidth
                        type="outline"
                        size="xsmall"
                        color="grey"
                        icon={<LinkIcon />}
                        customStyle={{ marginTop: '8px' }}
                        onClick={() => {
                          handleCopyToClipboard(getARGatewayUrl(model.viewer_uid));

                          // button click event
                          amplitude.sendEvent(amplitudeEvents.overview.zero_utilization_summary_arviewer_export_link);
                        }}
                      >
                        {`${t('ModelOverview.링크 복사')}`}
                      </RDSIconTextButton>
                    </div>
                    <div style={{ width: '164px' }}>
                      <img
                        style={{ width: '164px', height: '164px' }}
                        src={ModelOverviewARViewerImage}
                        alt="AR 뷰어 이미지"
                      />
                      <TooltipMaker
                        contents={
                          <div style={{ display: 'flex', gap: '10px' }}>
                            <div style={{ display: 'flex', gap: '10px', margin: '6px 0' }}>
                              <img
                                className="DetailPageLinks__More__Contents__Icons__Shadow"
                                width={32}
                                height={32}
                                src={Cafe24Image}
                                alt="카페24 이미지"
                                draggable={false}
                              />
                              <img width={32} height={32} src={ImwebImage} alt="아이엠웹 이미지" draggable={false} />
                              <img
                                className="DetailPageLinks__More__Contents__Icons__Shadow"
                                width={32}
                                height={32}
                                src={WordpressImage}
                                alt="Wordpress 이미지"
                                draggable={false}
                              />
                              <img
                                className="DetailPageLinks__More__Contents__Icons__Shadow"
                                width={32}
                                height={32}
                                src={ShowpifyImage}
                                alt="쇼피파이 이미지"
                                draggable={false}
                              />
                              <img
                                className="DetailPageLinks__More__Contents__Icons__Shadow"
                                width={32}
                                height={32}
                                src={CodeImage}
                                alt="코드 이미지"
                                draggable={false}
                              />
                            </div>
                            <div>
                              카페24, 아임웹, 워드프레스, <br />
                              쇼피파이, 자체 개발
                            </div>
                          </div>
                        }
                        position="top"
                      >
                        <RDSIconTextButton
                          fullWidth
                          type="outline"
                          size="xsmall"
                          color="grey"
                          icon={<CodeIcon />}
                          customStyle={{ marginTop: '18px' }}
                          onClick={() => {
                            handleCopyToClipboard(getARDirectButtonScript(model.viewer_uid, 'primary'));

                            // button click event
                            amplitude.sendEvent(
                              amplitudeEvents.overview.zero_utilization_summary_arviewer_arlinkbtn_export_javascript,
                            );
                          }}
                        >
                          {`${t('ModelOverview.자바스크립트')}`}
                        </RDSIconTextButton>
                      </TooltipMaker>
                      <TooltipMaker
                        contents={
                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <img width={32} height={32} src={NaverImage} alt="네이버 이미지" draggable={false} />
                            네이버스마트스토어
                          </div>
                        }
                        position="top"
                      >
                        <RDSIconTextButton
                          fullWidth
                          type="outline"
                          size="xsmall"
                          color="grey"
                          icon={<LinkIcon />}
                          customStyle={{ marginTop: '8px' }}
                          onClick={() => {
                            handleCopyToClipboard(getARGatewayButtonScript(model.viewer_uid, 'primary'));

                            // button click event
                            amplitude.sendEvent(
                              amplitudeEvents.overview.zero_utilization_summary_arviewer_arlinkbtn_export_hyperlink,
                            );
                          }}
                        >
                          {`${t('ModelOverview.하이퍼링크')}`}
                        </RDSIconTextButton>
                      </TooltipMaker>
                    </div>
                  </div>
                </div>
                <div className="ModelOverview__Wrapper__ModelInfo__GIF">
                  <div className="ModelOverview__Wrapper__ModelInfo__GIF__Text">
                    GIF
                    <div style={{ position: 'absolute', top: '3px', right: 0 }}>
                      <RDSIconButton
                        size="xsmall"
                        type="outline"
                        color="grey"
                        icon={<ArrowForwardIcon />}
                        onClick={() => handleNavigator(4)}
                      />
                    </div>
                  </div>
                  <div onMouseEnter={() => setIsHoverGif(true)} onMouseLeave={() => setIsHoverGif(false)}>
                    {model.latest_transforms.gif.url ? (
                      <>
                        <img
                          style={{ display: isHoverGif ? '' : 'none' }}
                          src={model.latest_transforms.gif.url}
                          alt="GIF 이미지"
                          draggable={false}
                        />
                        <img
                          style={{ display: isHoverGif ? 'none' : '' }}
                          src={model.latest_transforms.png.url}
                          alt="PNG 이미지"
                          draggable={false}
                        />
                      </>
                    ) : (
                      <div
                        style={{
                          width: '198px',
                          height: '198px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {`${t('Detail.뷰어 생성 중입니다...')}`}
                      </div>
                    )}
                  </div>
                  <div>
                    <RDSIconTextButton
                      fullWidth
                      type="solid"
                      size="xsmall"
                      color="grey"
                      icon={<FileDownloadIcon />}
                      customStyle={{ marginTop: '12px' }}
                      onClick={() => {
                        gifDownload();

                        // button click event
                        amplitude.sendEvent(amplitudeEvents.overview.zero_utilization_summary_gif_download);
                      }}
                    >
                      {`${t('ModelOverview.다운로드.gif')}`}
                    </RDSIconTextButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModelOverview;
