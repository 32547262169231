import React, { useState } from 'react';
import './../../../scss/selectableIconButton.scss';

type SelectableIconButtonProps = {
  icon: JSX.Element;
  type: string;
  children?: string | JSX.Element;
  selected?: boolean;
  onClick?: Function;
};

const SelectableIconButton = (props: SelectableIconButtonProps) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const [selected, setSelected] = useState<boolean>(false);
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <button
      className={selected ? 'SelectableIconTextButton Selected' : 'SelectableIconTextButton'}
      type="button"
      onClick={() => {
        // click으로 선택되었는지 불리언값 전달
        setSelected((prevState: boolean) => {
          props.onClick && props.onClick(!prevState);
          return !prevState;
        });
      }}
    >
      <div className="SelectableIconTextButton__IconArea">
        {React.cloneElement(props.icon, {
          color: 'inherit',
          width: 16,
          height: 16,
        })}
        {props.children}
      </div>
    </button>
  );
};

export default SelectableIconButton;
