// 채널톡 공식문서 https://developers.channel.io/docs/web-installation#single-page-application
export class ChannelService {
  constructor() {
    this.loadScript();
    this.isBooted = false;
  }
  loadScript() {
    var w = window;
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {})('ChannelIO script included twice.');
    }
    var ch = function () {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      var x = document.getElementsByTagName('script')[0];
      if (x === undefined) {
        document.body.appendChild(s);
      } else {
        x.parentNode.insertBefore(s, x);
      }
    }
    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  }

  boot(settings) {
    window.ChannelIO('boot', settings);
    this.isBooted = true;
  }

  shutdown() {
    window.ChannelIO('shutdown');
    this.isBooted = false;
  }

  bootAndUpdate(pluginKey, projectId) {
    window.ChannelIO('boot', {
      pluginKey: pluginKey,
      profile: {
        projectId: projectId,
      },
    });
    window.ChannelIO('updateUser', {
      language: 'ko',
    });
    this.isBooted = true;
  }

  onHideMessenger(eventHandler) {
    window.ChannelIO('onHideMessenger', () => {
      eventHandler();
    });
  }
}
export default new ChannelService();
