import { getAccessToken } from './localstorage';

export default (url: string, webSocket: any, onmessageHandler: Function) => {
  let interval: any;

  const token = getAccessToken();
  webSocket = new WebSocket(`${url}?token=${token}`);

  webSocket.onopen = () => {
    interval = setInterval(() => {
      webSocket.send(JSON.stringify({ message: 'ping' }));
    }, 60 * 1000);
  };

  webSocket.onmessage = (message: any) => {
    const data = JSON.parse(message.data);
    onmessageHandler(data);
  };

  webSocket.onclose = () => {
    clearInterval(interval);
  };

  return webSocket;
};
