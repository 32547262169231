import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_LOGGEDIN_ROUTE } from '../constants/urls';

const ErrorPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(DEFAULT_LOGGEDIN_ROUTE);
  });
  return <div>해당 페이지를 찾을 수 없습니다</div>;
};

export default ErrorPage;
