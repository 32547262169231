import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cafe24Image1 from '../../../../images/Cafe24Image1.png';
import Cafe24Image2 from '../../../../images/Cafe24Image2.png';
import Cafe24Image3 from '../../../../images/Cafe24Image3.png';
import Cafe24Image4 from '../../../../images/Cafe24Image4.png';

const Cafe24Guide = () => {
  const divRefs = useRef<any>([]);
  const [tapIndex, setTapIndex] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (tapIndex === 0 || tapIndex) {
      divRefs.current.forEach((el: any, index: number) => {
        if (tapIndex === index) {
          el.classList.add('selected');
        } else {
          el.classList.remove('selected');
        }
      });
    }
  }, [tapIndex]);

  const getContents = () => {
    return tapIndex === 0 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">01</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>수정 페이지</span>에 들어가
                <br />
                <span style={{ color: '#4758D3' }}>[직접 작성]</span>영역을 눌러주세요.
              </>
            ) : (
              <>
                Please go to the <span style={{ color: '#4758D3' }}>Edit Page</span> and click on the
                <br /> <span style={{ color: '#4758D3' }}>[Custom]</span> section.
              </>
            )}
          </div>
        </div>
        <div>
          <img width={800} src={Cafe24Image1} alt="카페24 가이드 이미지01" />
        </div>
      </div>
    ) : tapIndex === 1 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">02</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                버튼 삽입을 원하는 위치에 <span style={{ color: '#4758D3' }}>커서</span>를 올리고
                <br />
                <span style={{ color: '#4758D3' }}>[코드 보기]</span>를 눌러주세요.
              </>
            ) : (
              <>
                Please place the cursor where you want to insert
                <br /> the button, then click on <span style={{ color: '#4758D3' }}>[View Code]</span>.
              </>
            )}
          </div>
        </div>
        <div>
          <img width={800} src={Cafe24Image2} alt="카페24 가이드 이미지02" />
        </div>
      </div>
    ) : tapIndex === 2 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">03</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                <span style={{ color: '#4758D3' }}>플리카</span>에서 내 웹사이트에 넣고 싶은
                <br />
                <span style={{ color: '#4758D3' }}>버튼 디자인</span>을 선택해
                <br />
                <span style={{ color: '#4758D3' }}>[자바스크립트]</span> 버튼을 눌러 복사해주세요.
              </>
            ) : (
              <>
                Please select the <span style={{ color: '#4758D3' }}>button design</span> you want to use
                <br /> on your website from <span style={{ color: '#4758D3' }}>PlicAR</span> and copy the
                <br /> <span style={{ color: '#4758D3' }}>[JavaScript]</span> button by clicking it.
              </>
            )}
          </div>
        </div>
        <div>
          <img width={800} src={Cafe24Image3} alt="카페24 가이드 이미지03" />
        </div>
      </div>
    ) : tapIndex === 3 ? (
      <div className="DetailViewerGuide__Contents__Wrapper__Contents">
        <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text">
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Number">04</div>
          <div className="DetailViewerGuide__Contents__Wrapper__Contents__Text__Sub">
            {window.locale === 'ko' ? (
              <>
                복사한 자바스크립트를 <span style={{ color: '#4758D3' }}>붙여넣기</span> 하고
                <br />
                미리보기로 확인 후 수정을 <span style={{ color: '#4758D3' }}>완료</span>해주세요.
              </>
            ) : (
              <>
                Please <span style={{ color: '#4758D3' }}>paste the copied JavaScript</span>, preview
                <br /> the changes, and complete the modification.
              </>
            )}
          </div>
        </div>
        <div>
          <img width={800} src={Cafe24Image4} alt="카페24 가이드 이미지04" />
        </div>
      </div>
    ) : (
      ''
    );
  };

  return (
    <div className="DetailViewerGuide__Contents__Wrapper">
      <div className="DetailViewerGuide__Contents__Wrapper__Tap">
        <div
          ref={(el: any) => (divRefs.current[0] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item selected"
          onClick={() => setTapIndex(0)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">01</div>
          {`${t('Detail.직접 작성')}`}
        </div>
        <div
          ref={(el: any) => (divRefs.current[1] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item"
          onClick={() => setTapIndex(1)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">02</div>
          {`${t('Detail.코드 보기')}`}
        </div>
        <div
          ref={(el: any) => (divRefs.current[2] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item"
          onClick={() => setTapIndex(2)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">03</div>
          {`${t('Detail.코드 복사')}`}
        </div>
        <div
          ref={(el: any) => (divRefs.current[3] = el)}
          className="DetailViewerGuide__Contents__Wrapper__Tap__Item"
          onClick={() => setTapIndex(3)}
        >
          <div className="DetailViewerGuide__Contents__Wrapper__Tap__Item__Bold">04</div>
          {`${t('Detail.코드 붙여넣기')}`}
        </div>
      </div>
      {getContents()}
    </div>
  );
};

export default Cafe24Guide;
