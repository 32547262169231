import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import {
  RDSButton,
  RDSModal,
  CheckOutlinedIcon,
  AlertErrorFilledIcon,
  RDSIconTextButton,
  RDSSnackbar,
  AlertErrorIcon,
  CheckCircleFilledIcon,
} from '@reconlabs/reconlabs-fe-components';
import useAuth from '../../../hooks/utils/useAuth';
import { useTranslation } from 'react-i18next';
import { TIER } from '../../../constants/values';
import {
  loadPayment,
  billing,
  checkBillingInfo,
  repayment,
  changeNextTier,
  getUpgradeTierPaymentInfo,
  upgradeTier,
} from '../../../utils/payment';
import { BillingPurpose, Tier } from '../../../constants/type';
import { BILLING_PURPOSE } from '../../../constants/values';
import { getLoggedInUser } from '../../../hooks/react-query/useZeroUser';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import { dateToRemainingPeriod, getEngMonth, numberWithCommas } from '../../../utils/utils';

const PaymentPlan = ({ handleLoader }: { handleLoader: Function }) => {
  const { user, setUserInfo } = useAuth();
  const { status } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [nextPlan, setNextPlan] = useState('');
  const [tempNextPlan, setTempNextPlan] = useState<Tier | ''>('');
  const [isPaymentPause, setIsPaymentPause] = useState(false);
  const [isComparePlanModalOpen, setIsComparePlanModalOpen] = useState<boolean>(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [isfailModalOpen, setIsfailModalOpen] = useState<boolean>(false);
  const [isPlanChangeModalOpen, setIsPlanChangeModalOpen] = useState(false);
  const [isPlanChangeCancelModalOpen, setIsPlanChangeCancelModalOpen] = useState(false);
  const [openBasicToPlusModal, setOpenBasicToPlusModal] = useState<boolean>(false);
  const [openedSnackbar, setOpenedSnackbar] = useState('');
  const [changePlanSnackbarContent, setchangePlanSnackbarContent] = useState('');
  const [baseTime, setBaseTime] = useState<string>('');
  const [additionalAmount, setAdditionalAmount] = useState<number>(0);
  const [clickedTierButton, setClickedTierButton] = useState<Tier>();

  //플랜 변경 예정 상태인지 확인
  useEffect(() => {
    setBillingDate();
  }, []);

  //결제 예정일 받아오기
  const setBillingDate = async () => {
    const res = await checkBillingInfo();
    if (!res) return;
    setNextPlan(res.nextTier);
    setchangePlanSnackbarContent(
      new Date(res.nextPaymentDate)
        .toLocaleDateString()
        .replace('.', t(`Payment.년`))
        .replace('.', t(`Payment.월`))
        .replace('.', t(`Payment.일`)) + t(`Payment.플랜 변경 확인 문구`),
    );
    setIsPaymentPause(res.pause);
  };

  //성공/실패 url로 리다이렉트시 처리
  useEffect(() => {
    if ((searchParams.get('purpose') as BillingPurpose) === BILLING_PURPOSE.Billing) {
      if (status === 'success') {
        const authKey = searchParams.get('authKey') as string;
        const selectedTier = searchParams.get('tier') as Tier;
        (async (authKey, tier) => {
          try {
            handleLoader('start');
            const res = await billing(authKey, tier);
            handleLoader('end');
            if (res.status === 200) {
              setIsSuccessModalOpen(true);
            }
          } catch (err) {
            // console.log('Payment or billing registration error', err);
            handleLoader('end');
            setIsfailModalOpen(true);
          }
        })(authKey, selectedTier);
      } else if (status === 'fail') {
        setIsfailModalOpen(true);
      }
    }
  }, [status]);

  //다음 정기 결제 티어 변경(다운그레이드) 예약/취소
  const handleChangeNextPlan = async (e: any) => {
    if (tempNextPlan === '') return;

    if (user.user_tier === TIER.Basic) {
      tempNextPlan === TIER.Free
        ? amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_free_select_reserve)
        : amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_free_select_reserve_cancel_success);
    } else if (user.user_tier === TIER.Plus) {
      if (tempNextPlan === TIER.Free) {
        amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_free_select_reserve);
      } else if (tempNextPlan === TIER.Basic) {
        amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_basic_select_reserve);
      } else {
        clickedTierButton === TIER.Free
          ? amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_free_select_reserve_cancel_success)
          : amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_basic_select_reserve_cancel_success);
      }
    }

    handleLoader('start');
    handleClose();
    setNextPlan(await changeNextTier(tempNextPlan));
    user.user_tier === tempNextPlan || setOpenedSnackbar('changePlanSnackbar');
    handleLoader('end');
  };

  //일시 정지 해제(재결제 시도)
  const handleRepayment = async () => {
    try {
      handleLoader('start');
      const res = await repayment();
      handleLoader('end');
      if (res.status === 200) {
        setIsSuccessModalOpen(true);
      } else throw Error('repayment response status is not 200');
    } catch (err) {
      handleLoader('end');
      setIsfailModalOpen(true);
    }
  };

  //채널톡 오픈
  const handleAsk = () => (window as any).ChannelIO('openChat');

  //모달 닫기
  const handleClose = async () => {
    if (isSuccessModalOpen) {
      setUserInfo(await getLoggedInUser());
      setBillingDate();
    }
    setIsComparePlanModalOpen(false);
    setIsSuccessModalOpen(false);
    setIsfailModalOpen(false);
    setIsPlanChangeModalOpen(false);
    setIsPlanChangeCancelModalOpen(false);
    navigate('/dashboard/payment');
  };

  //유저 티어에 따른 버튼 정의
  const planButton: { [key: string]: any } = {
    Free: {
      basicButtonText: t('Payment.변경하기'),
      basicButtonHandler: () => {
        amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_basic_select);
        loadPayment(
          user.uid || '',
          `${process.env.REACT_APP_PLICAR_ZERO_PROTOCOL}://${process.env.REACT_APP_PLICAR_ZERO_DOMAIN}:${process.env.REACT_APP_PLICAR_ZERO_PORT}/dashboard/payment`,
          `purpose=${BILLING_PURPOSE.Billing}&tier=Basic`,
        );
      },
      plusButtonText: t('Payment.변경하기'),
      plusButtonHandler: () => {
        amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_plus_select);
        loadPayment(
          user.uid || '',
          `${process.env.REACT_APP_PLICAR_ZERO_PROTOCOL}://${process.env.REACT_APP_PLICAR_ZERO_DOMAIN}:${process.env.REACT_APP_PLICAR_ZERO_PORT}/dashboard/payment`,
          `purpose=${BILLING_PURPOSE.Billing}&tier=Plus`,
        );
      },
      enterpriseButtonText: t('Payment.문의하기'),
      enterpriseButtonHandler: () => {
        amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_enterprise_contact);
        handleAsk();
      },
    },
    Basic: {
      freeButtonText: nextPlan === TIER.Free ? t('Payment.변경 예정 취소') : t('Payment.변경하기'),
      freeButtonHandler: () => {
        if (nextPlan === TIER.Free) {
          amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_free_select_reserve_cancel);
          setTempNextPlan(TIER.Basic);
          setIsPlanChangeCancelModalOpen(true);
        } else if (nextPlan === TIER.Basic) {
          amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_free_select);
          setTempNextPlan(TIER.Free);
          setIsPlanChangeModalOpen(true);
        }
      },
      basicButtonText: t('Payment.일시 정지 해제'),
      basicButtonHandler: handleRepayment,
      plusButtonText: t('Payment.변경하기'),
      plusButtonHandler: async () => {
        handleLoader('start');
        amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_plus_select);
        const res = await getUpgradeTierPaymentInfo(TIER.Plus);
        setBaseTime(res.nextPaymentDate.replace(' ', 'T'));
        setAdditionalAmount(res.amount);
        handleLoader('end');
        setOpenBasicToPlusModal(true);
      },
      enterpriseButtonText: t('Payment.문의하기'),
      enterpriseButtonHandler: () => {
        amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_enterprise_contact);
        handleAsk();
      },
    },
    Plus: {
      freeButtonText: nextPlan === TIER.Free ? t('Payment.변경 예정 취소') : t('Payment.변경하기'),
      freeButtonHandler: () => {
        if (nextPlan === TIER.Free) {
          amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_free_select_reserve_cancel);
          setClickedTierButton(TIER.Free);
          setTempNextPlan(TIER.Plus);
          setIsPlanChangeCancelModalOpen(true);
        } else if (nextPlan === TIER.Plus) {
          amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_free_select);
          setTempNextPlan(TIER.Free);
          setIsPlanChangeModalOpen(true);
        }
      },
      basicButtonText: nextPlan === TIER.Basic ? t('Payment.변경 예정 취소') : t('Payment.변경하기'),
      basicButtonHandler: () => {
        if (nextPlan === TIER.Basic) {
          amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_basic_select_reserve_cancel);
          setClickedTierButton(TIER.Basic);
          setTempNextPlan(TIER.Plus);
          setIsPlanChangeCancelModalOpen(true);
        } else if (nextPlan === TIER.Plus) {
          amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_basic_select);
          setTempNextPlan(TIER.Basic);
          setIsPlanChangeModalOpen(true);
        }
      },
      plusButtonText: t('Payment.일시 정지 해제'),
      plusButtonHandler: handleRepayment,
      enterpriseButtonText: t('Payment.문의하기'),
      enterpriseButtonHandler: () => {
        amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_enterprise_contact);
        handleAsk();
      },
    },
    Enterprise: {
      freeButtonText: t('Payment.문의하기'),
      freeButtonHandler: handleAsk,
      basicButtonText: t('Payment.문의하기'),
      basicButtonHandler: () => {
        amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_enterprise_contact);
        handleAsk();
      },
      plusButtonText: t('Payment.문의하기'),
      plusButtonHandler: handleAsk,
    },
  };

  const {
    freeButtonText,
    basicButtonText,
    plusButtonText,
    enterpriseButtonText,
    freeButtonHandler,
    basicButtonHandler,
    plusButtonHandler,
    enterpriseButtonHandler,
  } = planButton[user.user_tier || TIER.Free];

  return (
    <>
      <div className="PaymentPlan">
        <div className="PaymentPlan__Wrapper">
          <Plan
            title="Free"
            inUse={user.user_tier === TIER.Free}
            price={[t('Payment.무료 체험'), t('Payment.가격.Free')]}
            explain={t('Payment.플랜 설명.Free')}
            list={[
              t('Payment.Free 기능.1'),
              t('Payment.Free 기능.2'),
              t('Payment.Free 기능.3'),
              t('Payment.Free 기능.4'),
              t('Payment.Free 기능.5'),
            ]}
            button={{ text: freeButtonText, handler: freeButtonHandler, nextFlag: nextPlan === TIER.Free }}
          />
          <Plan
            title="Basic"
            inUse={user.user_tier === TIER.Basic}
            price={[t('Payment.매월'), t('Payment.가격.Basic')]}
            explain={t('Payment.플랜 설명.Basic')}
            list={[
              t('Payment.Basic 기능.1'),
              t('Payment.Basic 기능.2'),
              t('Payment.Basic 기능.3'),
              t('Payment.Basic 기능.4'),
            ]}
            button={{
              text: basicButtonText,
              handler: basicButtonHandler,
              nextFlag: user.user_tier === TIER.Basic ? isPaymentPause : nextPlan === TIER.Basic,
            }}
          />
          <Plan
            title="Plus"
            inUse={user.user_tier === TIER.Plus}
            price={[t('Payment.매월'), t('Payment.가격.Plus')]}
            explain={t('Payment.플랜 설명.Plus')}
            list={[
              t('Payment.Plus 기능.1'),
              t('Payment.Plus 기능.2'),
              t('Payment.Plus 기능.3'),
              t('Payment.Plus 기능.4'),
            ]}
            button={{
              text: plusButtonText,
              handler: plusButtonHandler,
              nextFlag: user.user_tier === TIER.Plus ? isPaymentPause : nextPlan === TIER.Plus,
            }}
          />
          <Plan
            title="Enterprise"
            inUse={user.user_tier === TIER.Enterprise}
            price={[t('Payment.플랜 컨설팅'), t('Payment.가격.Enterprise')]}
            explain={t('Payment.플랜 설명.Enterprise')}
            list={[
              t('Payment.Enterprise 기능.1'),
              t('Payment.Enterprise 기능.2'),
              t('Payment.Enterprise 기능.3'),
              t('Payment.Enterprise 기능.4'),
              t('Payment.Enterprise 기능.5'),
            ]}
            button={{ text: enterpriseButtonText, handler: enterpriseButtonHandler }}
          />
        </div>
      </div>
      <RDSIconTextButton
        icon={<AlertErrorFilledIcon />}
        children={t('Payment.플랜 상세 비교')}
        type="noBox"
        color="secondary"
        size="xsmall"
        onClick={() => {
          amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_detail);
          setIsComparePlanModalOpen(true);
        }}
        customStyle={{ padding: '0 8.3333%' }}
      />
      <ComparePlanModal open={isComparePlanModalOpen} onClose={handleClose} />
      <RDSModal
        open={isSuccessModalOpen}
        enableCloseButton={false}
        enableCloseOnBackdropClick={false}
        onClose={handleClose}
        imageSrc="/images/payment_complete.png"
        title={`${t('Payment.결제 완료')}!`}
        supportingText={`${t('Payment.결제 완료 모달 문구')}`}
        buttonType="noBox"
        button1Label={t('global.확인')}
        button1Fn={() => {
          amplitude.sendEvent(
            amplitudeEvents.payment.zero_payment_tabs_plans_basic_select_success_history_modal_confirm,
          );
          handleClose();
        }}
      />
      <RDSModal
        open={isfailModalOpen}
        enableCloseButton={false}
        enableCloseOnBackdropClick={false}
        onClose={handleClose}
        title={`${t('Payment.결제 실패')}`}
        supportingText={
          <div>
            <div>{`${t('Payment.결제 실패 모달 문구.1')}`}</div>
            <div>{`${t('Payment.결제 실패 모달 문구.2')}`}</div>
          </div>
        }
        buttonType="noBox"
        button1Label={t('Payment.문의하기')}
        button1Fn={() => {
          amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_basic_select_failed_modal_contact);
          handleAsk();
        }}
        button2Label={t('global.닫기')}
        button2Fn={() => {
          amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans_basic_select_failed_modal_close);
          handleClose();
        }}
      />
      <RDSModal
        enableCloseButton={false}
        open={isPlanChangeModalOpen}
        onClose={handleClose}
        title={`${t('Payment.플랜 변경')}`}
        supportingText={`${t('Payment.플랜 변경 모달 문구.1')}${tempNextPlan}${t('Payment.플랜 변경 모달 문구.2')}`}
        button1Label={t('Payment.변경하기')}
        button1Fn={handleChangeNextPlan}
        button2Label={t('global.취소')}
        button2Fn={handleClose}
      />
      <RDSSnackbar.CloseButton
        snackbarId="changePlanSnackbar"
        content={changePlanSnackbarContent}
        icon={<AlertErrorIcon />}
        openedSnackbar={openedSnackbar}
        setOpenedSnackbar={setOpenedSnackbar}
      />
      <RDSModal
        enableCloseButton={false}
        open={isPlanChangeCancelModalOpen}
        onClose={handleClose}
        title={`${t('Payment.플랜 변경 취소')}`}
        supportingText={`${t('Payment.플랜 변경 취소 모달 문구')}`}
        button1Label={t('Payment.변경 취소')}
        button1Fn={handleChangeNextPlan}
        button2Label={t('global.닫기')}
        button2Fn={handleClose}
      />
      <RDSModal //todo: 업그레이드 통합 모달로 개선
        enableCloseButton={false}
        open={openBasicToPlusModal}
        onClose={() => setOpenBasicToPlusModal(false)}
        title={`${t('Payment.플랜 업그레이드')}`}
        supportingText={
          window.locale === 'ko' ? (
            <div className="PlusPaymentModal__SupportingText">
              다음 정기 결제일이{' '}
              <strong>{`${new Date(baseTime).getMonth() + 1}월 ${new Date(baseTime).getDate()}일`}</strong> 이시군요!
              <br />
              빠른 업그레이드를 위해 <strong>{dateToRemainingPeriod(new Date(baseTime)).period}</strong> 까지 이용하실{' '}
              <span style={{ color: '#4758D3' }}>차액만큼 추가 결제</span>를 도와드릴게요.
            </div>
          ) : (
            <div className="PlusPaymentModal__SupportingText">
              Your next billing date is{' '}
              <strong>
                {new Date(baseTime).getDate()} {getEngMonth(new Date(baseTime).getMonth())}
              </strong>
              .
              <br />
              To upgrade quickly, we'll help you make{' '}
              <span style={{ color: '#4758D3' }}>additional payments for the price difference</span> between{' '}
              <strong>{dateToRemainingPeriod(new Date(baseTime), 'en').period}</strong>.
            </div>
          )
        }
        linkText={
          <div className="PlusPaymentModal__LinkText">
            <div className="PlusPaymentModal__LinkText__Title">{`${t('Payment.차액안내')}`}</div>
            {`${t('Payment.(플러스) 149,000원 - (베이직) 49,000원 = 100,000원')}`} <br />
            (100,000{`${t('Payment.원')}`}/1{`${t('Payment.개월')}`}) x {dateToRemainingPeriod(new Date(baseTime)).days}
            {`${t('Payment.일')}`} = {`${numberWithCommas(additionalAmount)}${t('Payment.원')}`}
          </div>
        }
        additionalContents={
          <div className="PlusPaymentModal__AdditionalContents">
            {`${t('Payment.추가 결제금액')}`} : {`${numberWithCommas(additionalAmount)}${t('Payment.원')}`}
            <br />
            {`${t('Payment.바로 업그레이드된 서비스를 이용하시겠습니까?')}`}
          </div>
        }
        button1Label={t('Payment.추가 결제하기')}
        button1Fn={async () => {
          handleLoader('start');
          try {
            await upgradeTier(TIER.Plus);
            setUserInfo(await getLoggedInUser());
            setNextPlan(TIER.Plus);
          } catch (error) {
            setIsfailModalOpen(true);
            console.error(error);
          } finally {
            handleLoader('end');
            setOpenBasicToPlusModal(false);
          }
        }}
        button2Label={t('global.취소')}
        button2Fn={() => setOpenBasicToPlusModal(false)}
        customStyle={{
          '.RDSModal': {
            width: '500px',
          },
        }}
      />
    </>
  );
};

type PlanProps = {
  title: String;
  price: Array<String>;
  explain: String;
  list: Array<String>;
  inUse: boolean;
  button: {
    nextFlag?: boolean;
    handler: Function;
    text: string;
  };
};
const Plan = ({ title, price, explain, list, inUse, button }: PlanProps) => {
  let index = 0;
  const { t } = useTranslation();
  const buttonCustomStyle = { width: '100%', height: '50px', padding: '10px' };

  return (
    <div
      className={`PaymentPlan__item ${inUse && 'PaymentPlan__item__inUse'} ${
        window.locale === 'en' && 'PaymentPlan__itemEn'
      }`}
    >
      <div className={`PaymentPlan__item__title`}>{title}</div>
      <div
        className={`PaymentPlan__item__price ${
          title === 'Enterprise' && window.locale === 'en' && 'PaymentPlan__item__price__enterpriseEn'
        }`}
      >
        <div>{price[index]}</div>
        <div style={{ position: 'relative' }}>
          {price[index + 1]}
          {(title === 'Basic' || title === 'Plus') && (
            <>
              <div
                className="PaymentPlan__item__price__discount"
                style={{
                  fontSize: '28px',
                  fontWeight: '800',
                  letterSpacing: '0.25px',
                  lineHeight: '46px',
                }}
              >
                {title === 'Basic'
                  ? window.locale === 'ko'
                    ? '49,000원'
                    : '49,000 won'
                  : window.locale === 'ko'
                  ? '149,000원'
                  : '149,000 won'}
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '40%',
                  width: '100%',
                  height: '8px',
                  background: 'rgba(255, 0, 0, 0.5)',
                }}
              ></div>
            </>
          )}
        </div>
      </div>
      <div className="PaymentPlan__item__explain">{explain}</div>
      {list.map((item, index) => {
        return (
          <div key={index} className="PaymentPlan__item__list">
            <CheckOutlinedIcon color="#72D7E4" />
            {item}
          </div>
        );
      })}
      <div style={{ position: 'absolute', bottom: '24px', left: '24px', right: '24px' }}>
        {inUse && !button.nextFlag ? (
          <RDSIconTextButton
            color="primary"
            icon={<CheckCircleFilledIcon />}
            children={t('Payment.이용 중')}
            type="outline"
            disabled={true}
            fullWidth={true}
            customStyle={buttonCustomStyle}
          />
        ) : (
          <RDSButton
            color={button.nextFlag ? 'secondary' : 'primary'}
            type={button.nextFlag ? 'outline' : 'solid'}
            onClick={button.handler}
            fullWidth={true}
            customStyle={buttonCustomStyle}
          >
            {button.text}
          </RDSButton>
        )}
      </div>
    </div>
  );
};

const ComparePlanModal = ({ open, onClose }: { open: boolean; onClose: Function }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      const col1List: NodeList = document.querySelectorAll('.PaymentPlan__Modal .col-xl-1');
      if (col1List && col1List.length > 0) {
        col1List.forEach((el) => {
          el.addEventListener('click', () => {
            onClose();
          });
        });
      }
    }
  }, [open]);

  if (!open) return null;

  const handleClickBackground = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) onClose();
  };

  return (
    <div className="PaymentPlan__Modal" onClick={handleClickBackground}>
      <div className="PaymentPlan__Modal__content container-fluid">
        <Row>
          <Col xl={1}></Col>
          <Col xl={2}></Col>
          <Col xl={2}>Free</Col>
          <Col xl={2}>Basic</Col>
          <Col xl={2}>Plus</Col>
          <Col xl={2}>Enterprise</Col>
          <Col xl={1}></Col>
        </Row>
        <Row>
          <Col xl={1}></Col>
          <Col xl={2}>{`${t('Payment.업로드 가능 모델 수')}`}</Col>
          <Col xl={2}>{`100${t('Payment.개')}`}</Col>
          <Col xl={2}>{`${t('Payment.무제한')}`}</Col>
          <Col xl={2}>{`${t('Payment.무제한')}`}</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={1}></Col>
        </Row>
        <Row>
          <Col xl={1}></Col>
          <Col xl={2}>{`${t('Payment.제공 View 수')}`}</Col>
          <Col xl={2}>{`100${t('Payment.뷰/월')}`}</Col>
          <Col xl={2}>{`1000${t('Payment.뷰/월')}`}</Col>
          <Col xl={2}>{`5000${t('Payment.뷰/월')}`}</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={1}></Col>
        </Row>
        <Row>
          <Col xl={1}></Col>
          <Col xl={2}>{`${t('Payment.모델 1개 최대 용량')}(MB)`}</Col>
          <Col xl={2}>50MB</Col>
          <Col xl={2}>50MB</Col>
          <Col xl={2}>50MB</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={1}></Col>
        </Row>
        <Row>
          <Col xl={1}></Col>
          <Col xl={2}>{`${t('Payment.AR 뷰어 제공')}`}</Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={1}></Col>
        </Row>
        <Row>
          <Col xl={1}></Col>
          <Col xl={2}>{`${t('Payment.3D 뷰어 제공')}`}</Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={1}></Col>
        </Row>
        <Row>
          <Col xl={1}></Col>
          <Col xl={2}>{`${t('Payment.자동 생성 GIF 제공')}`}</Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={1}></Col>
        </Row>
        <Row>
          <Col xl={1}></Col>
          <Col xl={2}>{`${t('Payment.워터마크 제거')}`}</Col>
          <Col xl={2}>-</Col>
          <Col xl={2}>-</Col>
          <Col xl={2}>-</Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={1}></Col>
        </Row>
        <Row>
          <Col xl={1}></Col>
          <Col xl={2}>{`${t('Payment.디지털 카탈로그')}`}</Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={2}>
            <CheckOutlinedIcon color="#6073ff" />
          </Col>
          <Col xl={1}></Col>
        </Row>
        <Row>
          <Col xl={1}></Col>
          <Col xl={2}>{`${t('Payment.모델 생성 서비스')}`}</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={1}></Col>
        </Row>
        <Row>
          <Col xl={1}></Col>
          <Col xl={2}>{`${t('Payment.Instagram AR 필터')}`}</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={1}></Col>
        </Row>
        <Row>
          <Col xl={1}></Col>
          <Col xl={2}>{`${t('Payment.디지털 쇼룸 이미지')}`}</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={2}>{`${t('Payment.별도 문의')}`}</Col>
          <Col xl={1}></Col>
        </Row>
      </div>
    </div>
  );
};

export default PaymentPlan;
