import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  RDSTabs,
  RDSTab,
  RDSToast,
  RDSIconButton,
  ChevronBackIcon,
  ChevronForwardIcon,
  useRDSMediaQuery,
} from '@reconlabs/reconlabs-fe-components';
import { useTranslation } from 'react-i18next';
import PaymentPlan from './PaymentPlan';
import PaymentHistory from './PaymentHistory';
import PaymentInfo from './PaymentInfo';
import useAuth from '../../../hooks/utils/useAuth';
import { TIER } from '../../../constants/values';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

export const TAB = { paymentPlan: 'PaymentPlan', paymentHistory: 'PaymentHistory', paymentInfo: 'PaymentInfo' };

const Payment = () => {
  const windowSize = useRDSMediaQuery(['md', 'lg'], [1700]);
  const { t } = useTranslation();
  const { user } = useAuth();
  const [selectedTab, setSelectedTab] = useState(TAB.paymentPlan);
  const [searchParams] = useSearchParams();
  const [spinner, setSpinner] = useState('');
  const loadingItemNum = useRef(0);
  const [isVisibleBackButton, setIsVisibleBackButton] = useState<boolean>(false);
  const [isVisibleNextButton, setIsVisibleNextButton] = useState<boolean>(true);

  useEffect(() => {
    setSelectedTab(searchParams.get('tab') || TAB.paymentPlan);
  }, []);

  useEffect(() => {
    if (windowSize === 'lg') {
      setIsVisibleBackButton(false);
      setIsVisibleNextButton(false);
    } else {
      setIsVisibleNextButton(true);
    }
  }, [windowSize]);

  const handleScroll = (left: number) => {
    document.querySelector('.PaymentPlan')?.scrollTo({ top: 0, left: left, behavior: 'smooth' });
  };

  const handleLoader = (loadOption: 'start' | 'end') => {
    if (loadOption === 'start') {
      setSpinner('loading-spinner');
      loadingItemNum.current++;
    } else if (loadOption === 'end') {
      loadingItemNum.current === 1 && setSpinner('');
      loadingItemNum.current--;
    }
  };

  return (
    <div className="Payment container-fluid">
      <RDSToast.Overlay
        type="loadingSpinner"
        toastOverlayId="loading-spinner"
        openedToastOverlay={spinner}
        setOpenedToastOverlay={setSpinner}
      />
      <Row>
        <Col xl={1}></Col>
        <Col xl={10}>
          <RDSTabs
            value={selectedTab}
            handleChange={(value: string) => {
              value === TAB.paymentPlan && amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_plans);
              value === TAB.paymentHistory && amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_history);
              value === TAB.paymentInfo && amplitude.sendEvent(amplitudeEvents.payment.zero_payment_tabs_management);
              setSelectedTab(value);
            }}
          >
            <RDSTab
              label={`${t('Payment.플랜 비교')}`}
              value={TAB.paymentPlan}
              customStyle={{ width: '250px', height: '30px' }}
            />
            <RDSTab
              label={`${t('Payment.결제 내역')}`}
              value={TAB.paymentHistory}
              customStyle={{ width: '250px', height: '30px' }}
            />
            {user.user_tier !== TIER.Free ? (
              <RDSTab
                label={`${t('Payment.결제 정보 관리')}`}
                value={TAB.paymentInfo}
                customStyle={{ width: '250px', height: '30px' }}
              />
            ) : (
              <></>
            )}
            <RDSTab borderline />
          </RDSTabs>
        </Col>
        <Col xl={1}></Col>
      </Row>
      <div className="Payment__ContentsWrapper">
        <div className="Payment__BackButton">
          <RDSIconButton
            icon={<ChevronBackIcon />}
            customStyle={selectedTab === TAB.paymentPlan && isVisibleBackButton ? {} : { display: 'none' }}
            onClick={() => {
              handleScroll(0);
              setIsVisibleBackButton(false);
              setIsVisibleNextButton(true);
            }}
          ></RDSIconButton>
        </div>
        <div>
          <div className={selectedTab === TAB.paymentPlan ? 'Payment__visible' : 'hidden'}>
            <PaymentPlan handleLoader={handleLoader} />
          </div>
          <div className={selectedTab === TAB.paymentHistory ? 'Payment__visible' : 'hidden'}>
            <PaymentHistory handleLoader={handleLoader} />
          </div>
          {selectedTab === TAB.paymentInfo ? <PaymentInfo handleLoader={handleLoader} /> : <></>}
        </div>
        <div className="Payment__NextButton">
          <RDSIconButton
            icon={<ChevronForwardIcon />}
            customStyle={selectedTab === TAB.paymentPlan && isVisibleNextButton ? {} : { display: 'none' }}
            onClick={() => {
              handleScroll(421);
              setIsVisibleBackButton(true);
              setIsVisibleNextButton(false);
            }}
          ></RDSIconButton>
        </div>
      </div>
    </div>
  );
};

export default Payment;
