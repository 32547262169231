import React from 'react';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import amplitude from '../../../utils/amplitude';

const Footer = () => {
  return (
    <div className="Login__inner__footer">
      {window.locale === 'ko' ? (
        <>
          회원가입 시{' '}
          <a
            target={'_blank'}
            href="https://reconlabs.notion.site/d1b81cbce44148c3bcacba381445c8e1"
            onClick={() =>
              amplitude.sendEvent(amplitudeEvents.signin.zero_signin_policy, { type: 'personal_information' })
            }
          >
            개인정보 처리방침
          </a>
          ,{' '}
          <a
            target={'_blank'}
            href="https://reconlabs.notion.site/106b051b77c144199b28217f849d8b66"
            onClick={() => amplitude.sendEvent(amplitudeEvents.signin.zero_signin_policy, { type: 'terms_conditions' })}
          >
            이용약관
          </a>
          ,{' '}
          <a
            target={'_blank'}
            href="https://reconlabs.notion.site/0f7df0cb2d7942ad822f6f88e254bbfd"
            onClick={() => amplitude.sendEvent(amplitudeEvents.signin.zero_signin_policy, { type: 'copyright' })}
          >
            저작권 정책
          </a>{' '}
          <br />
          확인하였으며, 동의합니다.
        </>
      ) : (
        <>
          When signing up, I have read and agree to the <br />
          <a
            target={'_blank'}
            href="https://reconlabs.notion.site/d1b81cbce44148c3bcacba381445c8e1"
            onClick={() =>
              amplitude.sendEvent(amplitudeEvents.signin.zero_signin_policy, { type: 'personal_information' })
            }
          >
            Privacy policy
          </a>
          ,{' '}
          <a
            target={'_blank'}
            href="https://reconlabs.notion.site/106b051b77c144199b28217f849d8b66"
            onClick={() => amplitude.sendEvent(amplitudeEvents.signin.zero_signin_policy, { type: 'terms_conditions' })}
          >
            Terms of service
          </a>
          , and{' '}
          <a
            target={'_blank'}
            href="https://reconlabs.notion.site/0f7df0cb2d7942ad822f6f88e254bbfd"
            onClick={() => amplitude.sendEvent(amplitudeEvents.signin.zero_signin_policy, { type: 'copyright' })}
          >
            Copyright policy.
          </a>{' '}
        </>
      )}
    </div>
  );
};

export default Footer;
