import React, { useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  RDSIconTextButton,
  UploadIcon,
  RDSNotificationTooltip,
  RDSCard,
  RDSText,
  RDSPagination,
  RDSModal,
  RDSToast,
  RDSToastAlertObject,
  RDSCapsuleButton,
} from '@reconlabs/reconlabs-fe-components';
import { useModelsList } from '../../../hooks/react-query/useThreeDModel';
import { ThreeDModel } from '../../../types/modelTypes';
import { useUpdateModel } from '../../../hooks/react-query/useUpdateThreeDModel';
import { UpdateModelInfo } from '../../../types/modelTypes';
import RDSUpload, { RDSUploadContext } from '../fileupload/RDSUpload';
import { validationFile } from '../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import websocketHandler from '../../../utils/websocketHandler';
import { useTranslation } from 'react-i18next';
import switchOnImg from '../../../images/ViewerOnModalImage.png';
import switchOnImgEn from '../../../images/ViewerOnModalImageEn.png';
import switchOffImg from '../../../images/ViewerOffModalImage.png';
import switchOffImgEn from '../../../images/ViewerOffModalImageEn.png';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import { UserContext } from '../../context/UserContextProvider';
import { checkRemainingModelCount, checkModelSizeLimit } from '../../../utils/limit';
import { DashboardContext } from '../../context/DashboardContextProvider';
import OverViewCountModal from '../../global/OverViewCountModal';
import { useLimits } from '../../../hooks/react-query/useLimits';

const Models = () => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  // const
  let webSocket: any;
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  // context
  const { uploadFiles, uploader } = useContext(RDSUploadContext);
  const { modelDeleted, setModelDeleted, setNewModels } = useContext(UserContext);
  const { openUploadWindow, setOpenUploadWindow, setIsOverUploadModalOpen, setRemainingUploadCount } =
    useContext(DashboardContext);

  // boolean
  const [openSwitchModal, setOpenSwitchModal] = useState<boolean>(false);
  const [switchModalCheckbox, setSwitchModalCheckbox] = useState<boolean>(false);
  const [clickedModelSwitchState, setClickedModalSwitchState] = useState<boolean>(false);
  const [openModelOffDenyModal, setOpenModelOffDenyModal] = useState<boolean>(false);
  const [isOverViewCountModalOpen, setIsOverViewCountModalOpen] = useState(false);
  const [isOverViewCount, setIsOverViewCount] = useState<boolean>(false);

  // spinner
  const [spinner, setSpinner] = useState<string>('loading-spinner');

  // internal variables
  const [switchModel, setSwitchModel] = useState<ThreeDModel>();
  const [internalCurrentPage, setInternalCurrentPange] = useState<number>(1);
  const [publishedCatalogModels, setPublishedCatalogModels] = useState<string[]>([]);
  const [savedCatalogModels, setSavedCatalogModels] = useState<string[]>([]);
  const [sizeLimit, setSizeLimit] = useState<string>('');

  // Alert
  const [RDSAlert, setRDSAlert] = useState<RDSToastAlertObject>();

  // API calls
  const {
    models,
    count: totalModelCount,
    totalPage,
    paginationParam,
    setPaginationParam,
    isLoading: isModelListLoading,
    isError: isModelListError,
    refetch,
  } = useModelsList({ range: [0, 49] });
  const {
    mutate: updateMutation,
    isLoading: isUpdateModelLoading,
    error: updateModelError,
  } = useUpdateModel(paginationParam);
  const { data: limitData, refetch: limitRefetch } = useLimits();
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    setNewModels(false);
  }, []);

  useEffect(() => {
    (async () => {
      const size = await checkModelSizeLimit();
      setSizeLimit(size);
    })();
  }, []);

  useEffect(() => {
    if (limitData?.result === 'success') {
      setIsOverViewCount(limitData?.isOverViewCount);
    }
  }, [limitData]);

  useEffect(() => {
    if (openUploadWindow) {
      inputRef.current?.click();
      setOpenUploadWindow(false);
    }
  }, [openUploadWindow]);

  useEffect(() => {
    const error: any = updateModelError;
    if (error && error.response.status === 400) {
      errorHandler(error);
      setOpenModelOffDenyModal(true);
    }
  }, [updateModelError]);

  useEffect(() => {
    if (isUpdateModelLoading) {
      setSpinner('updating-spinner');
    } else {
      setSpinner('loading-spinner');
    }
  }, [isUpdateModelLoading]);

  useEffect(() => {
    if (modelDeleted) {
      setRDSAlert({ type: 'success', message: t('Models.모델을 삭제했습니다.') });
      setModelDeleted(false);
    }
  }, [modelDeleted]);

  useEffect(() => {
    if (models && models.length > 0) {
      const url = `${process.env.REACT_APP_WEBSOCKET_SERVER_DOMAIN}`;
      webSocket = websocketHandler(url, webSocket, messageHandler);
    }

    return () => {
      if (webSocket) {
        webSocket.close();
      }
    };
  }, [models.length]);

  useEffect(() => {
    const startIndex = (internalCurrentPage - 1) * 50;
    const endIndex = internalCurrentPage * 50 - 1;
    setPaginationParam({ range: [startIndex, endIndex] });
  }, [internalCurrentPage]);

  useEffect(() => {
    modelListRefetch();
  }, [uploadFiles.filter((file) => file.state === 'viewerComplete').length]);
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const handleSwitchClick = async (model: ThreeDModel) => {
    setClickedModalSwitchState(!!model.model_published);

    if (localStorage.getItem('plicar-zero_closed_modal')) {
      handleSwitch(model);
    } else {
      setSwitchModel(model);
      setOpenSwitchModal(true);
    }
  };

  const handleSwitch = async (model?: ThreeDModel) => {
    const updateModel: UpdateModelInfo = model
      ? {
          uid: model.uid,
          model_memo: model.model_memo,
          model_sales_url: model.model_sales_url,
          model_name: model.model_name,
          model_published: model.model_published ? 0 : 1,
        }
      : {
          uid: switchModel?.uid ? switchModel.uid : '',
          model_memo: switchModel?.model_memo ? switchModel.model_memo : '',
          model_sales_url: switchModel?.model_sales_url ? switchModel.model_sales_url : '',
          model_name: switchModel?.model_name ? switchModel.model_name : '',
          model_published: switchModel?.model_published ? 0 : 1,
        };

    if (switchModalCheckbox) {
      localStorage.setItem('plicar-zero_closed_modal', 'model_list_switch_modal');
    }

    // Amplitude: 모델 카드에서 공개 스위칭 시 이벤트 전송
    amplitude.sendEvent(amplitudeEvents.allmodel.zero_allmodel_list_card_viewer_publish, {
      type: updateModel.model_published === 0 ? 'off' : 'on',
    });

    setOpenSwitchModal(false);
    updateMutation(updateModel);
  };

  const handleCurrentPageChange = (page: number) => {
    setInternalCurrentPange(page);
  };

  const handleCardClick = (model: ThreeDModel) => {
    // navigate('/dashboard/model/detail?model_uid=' + model.uid);
    navigate('/dashboard/model/overview?model_uid=' + model.uid);
  };
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const errorHandler = (e: any) => {
    const data = e.response.data.catalogs;
    const catalogs: string[] = data.catalogs;
    const publishedCatalogs: string[] = data.published_catalogs;
    const saved: string[] = [];
    const published: string[] = [];

    if (catalogs.length > 0) {
      catalogs.forEach((catalog: string) => {
        if (publishedCatalogs && publishedCatalogs.indexOf(catalog) >= 0) {
          published.push(catalog);
        } else {
          saved.push(catalog);
        }
      });
    }

    setPublishedCatalogModels(published);
    setSavedCatalogModels(saved);
  };

  const messageHandler = (data: any) => {
    if (models.find((model: ThreeDModel) => model.uid === data.uid)) {
      refetch();
    }
  };

  const modelListRefetch = () => {
    if (
      uploader.state === 'await' &&
      uploadFiles.filter((file) => file.state === 'viewerComplete').length > 0 &&
      uploadFiles.filter((file) => file.state === 'complete').length === 0 &&
      uploadFiles.filter((file) => file.state === 'waiting').length === 0 &&
      uploadFiles.filter((file) => file.state === 'ongoing').length === 0
    ) {
      refetch();
    }
  };

  const getSupportingText = (model: ThreeDModel) => {
    let text: string = '';
    if (model.registered_date.slice(0, 16) === model.modified_date.slice(0, 16)) {
      text = model.registered_date.slice(0, 11) + t('Models.업로드');
    } else {
      text = model.modified_date.slice(0, 11) + t('Models.업데이트');
    }

    return text;
  };

  const getModalTitleText = () => {
    return clickedModelSwitchState ? t('Models.AR, 3D 뷰어 외부 공개 활성화 중단') : t('Models.AR, 3D 모델 뷰어 공개');
  };

  const getModalButton = () => {
    return clickedModelSwitchState ? t('Models.중단합니다') : t('Models.공개합니다');
  };

  const getModalSupportingText = () => {
    return clickedModelSwitchState ? (
      <>
        {`${t(
          'Models.외부 사이트에 공개 중인 AR, 3D 뷰어를 OFF 상태로 변경하면 에러 메시지가 노출되므로 해당 사이트에서 별도의 리스트 관리가 필요할 수 있습니다.',
        )}`}
        <br /> <br />
        {`${t('Models.AR, 3D 뷰어의 외부 채널 공개를 중단하시겠습니까?')}`}
      </>
    ) : (
      <>
        {`${t(
          'Models.뷰어 공개가 ON 상태일 때 외부 사이트에 AR, 3D 뷰어가 공개되어 방문자가 증가하는 만큼 트래픽이 발생할 수 있으며, 뷰어를 보는 수 만큼 ‘뷰 수’가 카운팅됩니다.',
        )}`}
        <br /> <br />
        {`${t('Models.AR, 3D 뷰어를 외부 채널에 공개할까요?')}`}
      </>
    );
  };

  const getImageSrc = () => {
    let result;

    if (clickedModelSwitchState) {
      result = window.locale === 'ko' ? switchOffImg : switchOffImgEn;
    } else {
      result = window.locale === 'ko' ? switchOnImg : switchOnImgEn;
    }

    return result;
  };

  /*----------------------------------------
          Conditional Render Template
   ----------------------------------------*/
  const renderModelsComponent = () => {
    if (isModelListLoading) {
      return (
        <RDSToast.Overlay
          type="loadingSpinner"
          toastOverlayId="loading-spinner"
          openedToastOverlay={spinner}
          setOpenedToastOverlay={setSpinner}
        />
      );
    } else if (isModelListError) {
      return (
        <div>
          <RDSText type={'SM/12_medium'}>Error</RDSText>
        </div>
      );
    } else if (models && totalModelCount > 0) {
      return models.map((model: ThreeDModel, index: number) => {
        return (
          <RDSCard.Thumbnail
            key={`${model.uid} + ${index}`}
            title={model.model_name}
            supportingText={getSupportingText(model)} //"2022.12.04 업로드"
            imageSrc={model.latest_transforms?.png?.url}
            switchText={t('Models.AR, 3D 뷰어 공개')}
            isSwitchOn={!!model.model_published}
            handleClick={() => {
              // Amplitude: 카드 선택해 모델로 이동 시 이벤트 전송
              amplitude.sendEvent(amplitudeEvents.allmodel.zero_allmodel_list_card_select);
              handleCardClick(model);
            }}
            handleSwitchClick={() => handleSwitchClick(model)}
          />
        );
      });
    } else {
      return (
        <div>
          <div>{`${t('Models.모델이 없습니다.')}`}</div>
          <div>{`${t('Models.glb 파일(50MB 이내)을 업로드해주세요.')}`}</div>
        </div>
      );
    }
  };

  const getFileSize = () => {
    return sizeLimit === 'inf' ? (
      <div>: glb</div>
    ) : window.locale === 'ko' ? (
      <div>: glb (최대 {sizeLimit})</div>
    ) : (
      <div>: glb (Max {sizeLimit})</div>
    );
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <RDSToast.AlertArea openedAlert={RDSAlert}></RDSToast.AlertArea>
      <RDSModal
        title={`${t('Detail.AR, 3D 뷰어 공개를 중단할 수 없는 모델입니다')}`}
        enableCloseButton={false}
        button1Label={t('Detail.카탈로그 확인하기')}
        button1Fn={() => {
          navigate('/dashboard/catalog');
        }}
        button2Label={t('Detail.닫기')}
        button2Fn={() => {
          setOpenModelOffDenyModal(false);
        }}
        supportingText={
          <>
            {`${t(
              'Detail.모델이 카탈로그에 포함되어서 AR, 3D 뷰어 공개 중단이 불가능합니다. 중단을 원하시면 카탈로그에서 먼저 모델을 제거해주세요.',
            )}`}
          </>
        }
        linkText={
          <div style={{ maxHeight: '130px', overflowY: 'scroll' }}>
            {publishedCatalogModels.length > 0 && (
              <div>
                <div> · {`${t('Detail.발행되어 있는 카탈로그')}`}</div>
                {publishedCatalogModels.map((model, index) => {
                  return (
                    <div key={model + index} style={{ marginLeft: '20px' }}>
                      - {model}
                    </div>
                  );
                })}
              </div>
            )}
            {savedCatalogModels.length > 0 && (
              <div>
                <div> · {`${t('Detail.저장되어 있는 카탈로그')}`}</div>
                {savedCatalogModels.map((model, index) => {
                  return (
                    <div key={model + index} style={{ marginLeft: '20px' }}>
                      - {model}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        }
        open={openModelOffDenyModal}
        onClose={() => {
          setOpenModelOffDenyModal(false);
        }}
      />
      <RDSToast.Overlay
        type="loadingSpinner"
        toastOverlayId="updating-spinner"
        openedToastOverlay={spinner}
        setOpenedToastOverlay={setSpinner}
      />
      <RDSModal
        title={getModalTitleText()}
        buttonType={'noBox'}
        enableCloseButton={false}
        button1Label={getModalButton()}
        button1Fn={() => {
          // Amplitude: 모델 공개/비공개 모달에서 확인 시 이벤트 전송
          amplitude.sendEvent(amplitudeEvents.allmodel.zero_allmodel_list_card_viewer_publish_modal_confirm);
          // Amplitude: 모델 공개/비공개 모달에서 다시 보지 않기 체크 되어있을 시 이벤트 전송
          if (switchModalCheckbox) {
            amplitude.sendEvent(amplitudeEvents.allmodel.zero_allmodel_list_card_viewer_publish_modal_noshow);
          }
          handleSwitch();
        }}
        button1Color={clickedModelSwitchState ? 'warning' : 'primary'}
        button2Label={clickedModelSwitchState ? t('MyPage.공개유지') : t('MyPage.취소')}
        button2Fn={() => setOpenSwitchModal(false)}
        supportingText={getModalSupportingText()}
        imageAtTop={false}
        imageSrc={getImageSrc()}
        checkboxState={switchModalCheckbox}
        checkboxHandleCheck={() => setSwitchModalCheckbox((prevState) => !prevState)}
        checkboxLabel={t('Models.다시 보지 않기')}
        open={openSwitchModal}
        onClose={() => setOpenSwitchModal(false)}
      />
      <OverViewCountModal
        isOverViewCountModalOpen={isOverViewCountModalOpen}
        setIsOverViewCountModalOpen={setIsOverViewCountModalOpen}
        closeHandler={() => inputRef.current?.click()}
      />
      <div className="Models container-fluid">
        <RDSCapsuleButton />
        <div className="Models__inner">
          <Row>
            <Col xl={1}></Col>
            <Col xl={7}>
              <div className="Models__inner__title">{`${t('Models.전체 모델 목록')}`}</div>
              <div className="Models__inner__text">
                {`${t('Models.편집을 원하는 모델을 선택해 디테일 페이지에서 상세 편집을 경험해보세요!')}`}
              </div>
            </Col>
            <Col xl={3}>
              <RDSNotificationTooltip
                id="models-upload_button-tooltip"
                serviceName="plicar-zero"
                position="left"
                wrapperStyle={{ width: '100%' }}
                enableClose
                content={
                  <>
                    <div>{`${t('Models.지원하는 파일 형식')}`}</div>
                    {getFileSize()}
                  </>
                }
              >
                <RDSIconTextButton
                  fullWidth
                  icon={<UploadIcon></UploadIcon>}
                  onClick={(event: any) => {
                    limitRefetch();
                    if (isOverViewCount) {
                      event.preventDefault();
                      setIsOverViewCountModalOpen(true);
                    } else {
                      inputRef.current?.click();
                      amplitude.sendEvent(amplitudeEvents.allmodel.zero_allmodel_list_fileupload);
                    }
                  }}
                  type="outline"
                >
                  {`${t('Models.3D 모델 업로드')}`}
                </RDSIconTextButton>
              </RDSNotificationTooltip>
              <RDSUpload.Input checkValidation={(file) => validationFile(file)}>
                {({ handleOnChange }) => (
                  <input
                    ref={inputRef}
                    id="RDSUpload-model-input"
                    type={'file'}
                    multiple
                    onChange={async (e) => {
                      setSpinner('updating-spinner');
                      const remainingModelCount = await checkRemainingModelCount();
                      setSpinner('');
                      const fileCount = e.target.files?.length || 0;
                      if (remainingModelCount - fileCount < 0) {
                        setRemainingUploadCount(remainingModelCount);
                        setIsOverUploadModalOpen(true);
                        return;
                      }
                      amplitude.sendEvent(amplitudeEvents.allmodel.zero_allmodel_list_fileupload_start, {
                        count_upload_files: e.target.files?.length ?? 0,
                      });
                      handleOnChange(e);
                    }}
                    onClick={async (event) => (inputRef.current!.value = '')}
                    style={{
                      display: 'none',
                    }}
                  ></input>
                )}
              </RDSUpload.Input>
            </Col>
            <Col xl={1}></Col>
          </Row>
          <Row className="Models__inner__wrapper">
            {totalPage >= 5 ? (
              <>
                <Col xl={1}></Col>
                <Col className="Models__inner__wrapper__pagination" xl={10}>
                  <RDSPagination.Number
                    minimumType
                    size="small"
                    pageSize={50}
                    total={totalModelCount}
                    current={internalCurrentPage}
                    handleChange={handleCurrentPageChange}
                  />
                </Col>
                <Col xl={1}></Col>
              </>
            ) : (
              ''
            )}
            <Col xl={1}></Col>
            <Col
              className={
                models && totalModelCount > 0 ? 'Models__inner__wrapper__models' : 'Models__inner__wrapper__nomodels'
              }
              xl={10}
            >
              {renderModelsComponent()}
            </Col>
            <Col xl={1}></Col>
          </Row>
          {totalModelCount > 0 && (
            <Row>
              <Col xl={1}></Col>
              <Col className="Models__inner__wrapper__pagination" xl={10}>
                <RDSPagination.Number
                  pageSize={50}
                  size="small"
                  total={totalModelCount}
                  current={internalCurrentPage}
                  handleChange={handleCurrentPageChange}
                  hideOnSinglePage
                />
              </Col>
              <Col xl={1}></Col>
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

export default Models;
