import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  RDSButton,
  RDSCard,
  RDSCarousel,
  RDSNotificationTooltip,
  RDSModal,
  RDSSpinner,
  RDSToast,
} from '@reconlabs/reconlabs-fe-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RDSUpload, { SetRDSUploadContext } from './RDSUpload';
import { useModelsList } from '../../../hooks/react-query/useThreeDModel';
import { validationFile } from '../../../utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThreeDModel, UpdateModelInfo } from '../../../types/modelTypes';
import { useUpdateModel } from '../../../hooks/react-query/useUpdateThreeDModel';
import { useTranslation } from 'react-i18next';
import amplitude from '../../../utils/amplitude';
import amplitudeEvents from '../../../constants/amplitudeEvents';
import switchOnImg from '../../../images/ViewerOnModalImage.png';
import switchOnImgEn from '../../../images/ViewerOnModalImageEn.png';
import switchOffImg from '../../../images/ViewerOffModalImage.png';
import switchOffImgEn from '../../../images/ViewerOffModalImageEn.png';
import { DashboardContext } from '../../context/DashboardContextProvider';
import { checkModelSizeLimit, checkRemainingModelCount } from '../../../utils/limit';
import OverViewCountModal from '../../global/OverViewCountModal';
import { useLimits } from '../../../hooks/react-query/useLimits';

const ModelFileUploadResult = () => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  // const
  const inputRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // context
  const setUploads = useContext(SetRDSUploadContext);
  const { setIsOverUploadModalOpen, setRemainingUploadCount } = useContext(DashboardContext);

  // api calls
  const {
    mutate: updateMutation,
    isUpdateSuccess,
    isLoading: isUpdateModelLoading,
  } = useUpdateModel({ range: [0, 49] });
  const { data: limitData, refetch: limitRefetch } = useLimits();

  // boolean
  const [clickedModelSwitchState, setClickedModalSwitchState] = useState<boolean>(false);
  const [switchModalCheckbox, setSwitchModalCheckbox] = useState<boolean>(false);
  const [openSwitchModal, setOpenSwitchModal] = useState<boolean>(false);
  const [isOverViewCountModalOpen, setIsOverViewCountModalOpen] = useState(false);
  const [isOverViewCount, setIsOverViewCount] = useState<boolean>(false);

  // spinner
  const [spinner, setSpinner] = useState<string>('');

  // internal variables
  const { models, refetch } = useModelsList({ range: [0, 49] });
  const [resultModels, setResultModels] = useState<ThreeDModel[]>([]);
  const [switchModel, setSwitchModel] = useState<ThreeDModel>();
  const [sizeLimit, setSizeLimit] = useState<string>('');
  /*----------------------------------------
                    Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    (async () => {
      const size = await checkModelSizeLimit();
      setSizeLimit(size);
    })();
  }, []);

  useEffect(() => {
    if (limitData?.result === 'success') {
      setIsOverViewCount(limitData?.isOverViewCount);
    }
  }, [limitData]);

  useEffect(() => {
    if (models && models.length > 0 && models[0].uid !== '-') {
      setModels();
      setUploads([]);
    }
  }, [models, models.length]);

  useEffect(() => {
    refetch();
  }, [location, location.search]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setModels();
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isUpdateModelLoading) {
      setSpinner('updating-spinner');
    } else {
      setSpinner('');
    }
  }, [isUpdateModelLoading]);
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const setModels = () => {
    const ids = location.search.split('?')[1].split('&');
    const result: ThreeDModel[] = [];
    ids.map((id) => {
      if (models.find((model) => model.uid === id)) {
        result.push(models.find((model) => model.uid === id)!);
      }
    });
    setResultModels(result);
  };

  const getDate = (item: any) => {
    let text: string = '';
    if (item.registered_date === item.modified_date) {
      text = item.registered_date.slice(0, 11) + t('Models.업로드');
    } else {
      text = item.modified_date.slice(0, 11) + t('Models.업데이트');
    }

    return text;
  };

  const getModalTitleText = () => {
    return clickedModelSwitchState ? t('Models.AR, 3D 뷰어 외부 공개 활성화 중단') : t('Models.AR, 3D 모델 뷰어 공개');
  };

  const getModalButton = () => {
    return clickedModelSwitchState ? t('Models.중단합니다') : t('Models.공개합니다');
  };

  const getModalSupportingText = () => {
    return clickedModelSwitchState ? (
      <>
        {`${t(
          'Models.외부 사이트에 공개 중인 AR, 3D 뷰어를 OFF 상태로 변경하면 에러 메시지가 노출되므로 해당 사이트에서 별도의 리스트 관리가 필요할 수 있습니다.',
        )}`}
        <br /> <br />
        {`${t('Models.AR, 3D 뷰어의 외부 채널 공개를 중단하시겠습니까?')}`}
      </>
    ) : (
      <>
        {`${t(
          'Models.뷰어 공개가 ON 상태일 때 외부 사이트에 AR, 3D 뷰어가 공개되어 방문자가 증가하는 만큼 트래픽이 발생할 수 있으며, 뷰어를 보는 수 만큼 ‘뷰 수’가 카운팅됩니다.',
        )}`}
        <br /> <br />
        {`${t('Models.AR, 3D 뷰어를 외부 채널에 공개할까요?')}`}
      </>
    );
  };

  const getImageSrc = () => {
    let result;

    if (clickedModelSwitchState) {
      // TODO: En 이미지 추가시 변경
      result = window.locale === 'ko' ? switchOffImg : switchOffImgEn;
    } else {
      result = window.locale === 'ko' ? switchOnImg : switchOnImgEn;
    }

    return result;
  };

  const getFileSize = () => {
    return sizeLimit === 'inf' ? (
      <div>: glb</div>
    ) : window.locale === 'ko' ? (
      <div>: glb (최대 {sizeLimit})</div>
    ) : (
      <div>: glb (Max {sizeLimit})</div>
    );
  };
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const buttonClicked = async (event: any) => {
    limitRefetch();
    if (isOverViewCount) {
      event.preventDefault();
      setIsOverViewCountModalOpen(true);
    } else {
      inputRef.current?.click();
    }
  };

  const handleSwitch = async (model?: ThreeDModel) => {
    const updateModel: UpdateModelInfo = model
      ? {
          uid: model.uid,
          model_memo: model.model_memo,
          model_sales_url: model.model_sales_url,
          model_name: model.model_name,
          model_published: model.model_published ? 0 : 1,
        }
      : {
          uid: switchModel?.uid ? switchModel.uid : '',
          model_memo: switchModel?.model_memo ? switchModel.model_memo : '',
          model_sales_url: switchModel?.model_sales_url ? switchModel.model_sales_url : '',
          model_name: switchModel?.model_name ? switchModel.model_name : '',
          model_published: switchModel?.model_published ? 0 : 1,
        };

    if (switchModalCheckbox) {
      localStorage.setItem('plicar-zero_closed_modal', 'model_list_switch_modal');
    }

    // Amplitude: 모델 공개 스위치 시 데이터 전송
    amplitude.sendEvent(amplitudeEvents.uploadcomplete.zero_uploaded_list_card_viewer_publish, {
      type: updateModel.model_published === 1 ? 'on' : 'off',
    });

    setOpenSwitchModal(false);
    updateMutation(updateModel);
  };

  const handleSwitchClick = async (model: ThreeDModel) => {
    setClickedModalSwitchState(!!model.model_published);

    if (localStorage.getItem('plicar-zero_closed_modal')) {
      handleSwitch(model);
    } else {
      setSwitchModel(model);
      setOpenSwitchModal(true);
    }
  };

  const handleCardClick = (model: ThreeDModel) => {
    navigate('/dashboard/model/detail?model_uid=' + model.uid);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <RDSModal
        title={getModalTitleText()}
        buttonType={'noBox'}
        enableCloseButton={false}
        button1Label={getModalButton()}
        button1Fn={() => handleSwitch()}
        button1Color={clickedModelSwitchState ? 'warning' : 'primary'}
        button2Label={clickedModelSwitchState ? t('MyPage.공개유지') : t('MyPage.취소')}
        button2Fn={() => setOpenSwitchModal(false)}
        supportingText={getModalSupportingText()}
        imageAtTop={false}
        imageSrc={getImageSrc()}
        checkboxState={switchModalCheckbox}
        checkboxHandleCheck={() => setSwitchModalCheckbox((prevState) => !prevState)}
        checkboxLabel={t('Models.다시 보지 않기')}
        open={openSwitchModal}
        onClose={() => setOpenSwitchModal(false)}
      />
      <OverViewCountModal
        isOverViewCountModalOpen={isOverViewCountModalOpen}
        setIsOverViewCountModalOpen={setIsOverViewCountModalOpen}
        closeHandler={() => inputRef.current?.click()}
      />
      <RDSToast.Overlay
        type="loadingSpinner"
        toastOverlayId="updating-spinner"
        openedToastOverlay={spinner}
        setOpenedToastOverlay={setSpinner}
      />
      <div className="Uploadresult container-fluid">
        <Row className="Uploadresult__model">
          <Col className="Uploadresult__model__text" xl={12}>
            {`${t('UploadResult.뷰어 생성이 완료되었습니다!')}`}
          </Col>
          <Col className="Uploadresult__model__card" xl={12}>
            {resultModels && resultModels.length === 0 ? (
              <>
                <RDSSpinner size={150} colorHex={'grey'} />
              </>
            ) : (
              <>
                <RDSNotificationTooltip
                  id="fileUploadResultTooltip"
                  serviceName="plicar-zero"
                  content={t('UploadResult.상세 페이지에서 모델 뷰어를 설정해보세요!')}
                  position="bottom"
                  customStyle={{ top: '100%' }}
                  wrapperStyle={{ width: '100%' }}
                >
                  <RDSCarousel
                    prevButtonFn={() =>
                      amplitude.sendEvent(amplitudeEvents.uploadcomplete.zero_uploaded_list_horizontalscroll, {
                        direction: 'left',
                      })
                    }
                    nextButtonFn={() => {
                      amplitude.sendEvent(amplitudeEvents.uploadcomplete.zero_uploaded_list_horizontalscroll, {
                        direction: 'right',
                      });
                    }}
                  >
                    {resultModels.map((item: ThreeDModel, index: number) => {
                      return (
                        <RDSCard.Thumbnail
                          key={index}
                          title={item.source_file_name}
                          supportingText={getDate(item)}
                          switchText={t('Models.AR, 3D 뷰어 공개')}
                          imageSrc={item.latest_transforms?.png?.url}
                          isSwitchOn={!!item.model_published}
                          handleClick={() => {
                            amplitude.sendEvent(amplitudeEvents.uploadcomplete.zero_uploaded_list_card_select);
                            handleCardClick(item);
                          }}
                          handleSwitchClick={() => handleSwitchClick(item)}
                        />
                      );
                    })}
                  </RDSCarousel>
                </RDSNotificationTooltip>
              </>
            )}
          </Col>
        </Row>
        <Row className="Uploadresult__upload">
          <Col className="Uploadresult__upload__wrapper" xl={2}>
            <RDSButton fullWidth type="outline" onClick={buttonClicked}>
              {`${t('UploadResult.추가 업로드하기')}`}
            </RDSButton>
            <RDSUpload.Input checkValidation={(file) => validationFile(file)}>
              {({ handleOnChange }) => (
                <input
                  ref={inputRef}
                  id="RDSUpload-result-input"
                  type={'file'}
                  multiple
                  onChange={async (e) => {
                    setSpinner('loading-spinner');
                    const remainingModelCount = await checkRemainingModelCount();
                    setSpinner('');
                    const fileCount = e.target.files?.length || 0;
                    if (remainingModelCount - fileCount < 0) {
                      setRemainingUploadCount(remainingModelCount);
                      setIsOverUploadModalOpen(true);
                      return;
                    }
                    amplitude.sendEvent(amplitudeEvents.uploadcomplete.zero_uploaded_list_fileupload_more, {
                      count_upload_files: e.target.files?.length ?? 0,
                    });
                    handleOnChange(e);
                  }}
                  style={{
                    display: 'none',
                  }}
                ></input>
              )}
            </RDSUpload.Input>
            <div className="Uploadresult__upload__wrapper__text">
              <div>{`${t('UploadResult.업로드 가능 포맷')}`}</div>
              {getFileSize()}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ModelFileUploadResult;
