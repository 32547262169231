import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useAuth from '../../hooks/utils/useAuth';
import ChannelService from '../../utils/ChannelService';

const PublicRoutesLayout = () => {
  /*----------------------------------------
                    Data
   ----------------------------------------*/
  const { checkUserWithToken } = useAuth();
  checkUserWithToken();

  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (ChannelService.isBooted) {
      ChannelService.shutdown();
    }
  }, []);

  return (
    <div style={{ height: '100%' }}>
      <Outlet />
    </div>
  );
};

export default PublicRoutesLayout;
