import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DEFAULT_LOGGEDIN_ROUTE } from '../../../constants/urls';
import { RDSModal, RDSToast } from '@reconlabs/reconlabs-fe-components';
import { verifyEmailAndSetToken } from '../../../utils/login';
import { useTranslation } from 'react-i18next';
const EmailVerify = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [toast, setToast] = useState<string>('loading');
  const [isInvalidNoticeModalOpen, setIsInvalidNoticeModalOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    (async function () {
      try {
        await verifyEmailAndSetToken(token as string);
        setToast('');
        navigate(DEFAULT_LOGGEDIN_ROUTE);
      } catch {
        setToast('');
        setIsInvalidNoticeModalOpen(true);
      }
    })();
  }, []);

  const handleClose = () => {
    setIsInvalidNoticeModalOpen(false);
    navigate('/login');
  };

  return (
    <div className="ResetPasswordPage_background">
      <RDSToast.Overlay
        type="loadingSpinner"
        toastOverlayId="loading"
        openedToastOverlay={toast}
        setOpenedToastOverlay={setToast}
      />
      <RDSModal
        open={isInvalidNoticeModalOpen}
        onClose={handleClose}
        title={`${t('Login.만료된 인증코드입니다. 인증을 다시 시도해주세요.')}`}
        supportingText={`
          ${t(
            'Login.인증 신청 후 시간이 오래 지났거나, 링크가 잘못된 경우 정상적으로 인증이 되지 않을 수 있습니다. 다시 한번 인증 시도를 부탁드립니다.',
          )}`}
        buttonType="solid"
        button1Label={t('Login.메인 페이지로 이동')}
        button1Fn={handleClose}
        enableCloseOnBackdropClick={false}
        enableBackdrop={false}
      ></RDSModal>
    </div>
  );
};

export default EmailVerify;
