// GNB (네비게이션 바) 항목들에 대한 클릭 이벤트명
const gnb = {
  zero_gnb_logo: 'zero_gnb_logo',
  zero_gnb_fileupload: 'zero_gnb_fileupload',
  zero_gnb_fileupload_start: 'zero_gnb_fileupload_start',
  zero_gnb_catalog: 'zero_gnb_catalog',
  zero_gnb_payment: 'zero_gnb_payment',
  zero_gnb_allmodel: 'zero_gnb_allmodel',
  zero_gnb_newbadge_allmodel: 'zero_gnb_newbadge_allmodel', // 알림 배지가 있을 때 클릭
  zero_gnb_traffic: 'zero_gnb_traffic',
  zero_gnb_introduce: 'zero_gnb_introduce',
  zero_gnb_create_model: 'zero_gnb_create_model',
  zero_gnb_helpcenter: 'zero_gnb_helpcenter',
  zero_gnb_mypage: 'zero_gnb_mypage',
  zero_gnb_logout: 'zero_gnb_logout', // 마이페이지가 아닌 위치에서 logout 클릭 시
  zero_mypage_logout: 'zero_mypage_logout', // 마이페이지에서 로그아웃 클릭 시
};

// 메인 페이지에 대한 이벤트명
const main = {
  zero_main_fileupload_btn: 'zero_main_fileupload_btn',
  zero_main_fileupload_progress: 'zero_main_fileupload_progress',
  zero_main_fileupload_dragdrop: 'zero_main_fileupload_dragdrop',
  zero_main_catalog: 'zero_main_catalog',
  zero_main_example_3dviewer: 'zero_main_example_3dviewer',
  zero_main_example_arviewer: 'zero_main_example_arviewer',
  zero_main_example_gif: 'zero_main_example_gif',
  zero_main_example_3dviewer_settings: 'zero_main_example_3dviewer_settings',
};

// 전체 모델 페이지 대한 이벤트명 (업로드 된 것이 없는 상태와 있는 상태로 나뉩니다)
const allmodel = {
  // 업로드 리스트 없는 상태 (nohistory)
  zero_allmodel_nohistory_fileupload: 'zero_allmodel_nohistory_fileupload',
  zero_allmodel_nohistory_fileupload_start: 'zero_allmodel_nohistory_fileupload_start', // count_upload_files: number
  zero_allmodel_nohistory_example_arviewer: 'zero_allmodel_nohistory_example_arviewer',
  zero_allmodel_nohistory_example_gif: 'zero_allmodel_nohistory_example_gif',
  zero_allmodel_nohistory_example_3dviewer: 'zero_allmodel_nohistory_example_3dviewer',
  // 업로드 리스트 있는 상태 (list)
  zero_allmodel_list_fileupload: 'zero_allmodel_list_fileupload',
  zero_allmodel_list_fileupload_start: 'zero_allmodel_list_fileupload_start', // count_upload_files: number
  zero_allmodel_list_card_select: 'zero_allmodel_list_card_select',
  // 뷰어 공개 Switch
  zero_allmodel_list_card_viewer_publish: 'zero_allmodel_list_card_viewer_publish', // type: "on" | "off"
  zero_allmodel_list_card_viewer_publish_modal_noshow: 'zero_allmodel_list_card_viewer_publish_modal_noshow', // 모달 확인 시 로깅
  zero_allmodel_list_card_viewer_publish_modal_confirm: 'zero_allmodel_list_card_viewer_publish_modal_confirm',
};

// 모델 상세 페이지에 대한 이벤트 명 TODO: noel - 이벤트 명 확정 시 키값도 변경해야함
const detail = {
  // 최상단 영역
  zero_detail_link_allmodel: 'zero_management_link_allmodel',
  zero_detail_viewer_publish: 'zero_management_viewer_publish', // type: "on" | "off"
  zero_detail_model_delete_confirm: 'zero_management_model_delete_confirm',
  zero_detail_model_delete_cancel: 'zero_management_model_delete_cancel',
  zero_detail_filename_edit: 'zero_management_filename_edit',
  zero_detail_filename_edit_confirm: 'zero_management_filename_edit_confirm',
  zero_utilization_detail_info_download_glb: 'zero_utilization_detail_info_download_glb',

  // 3D Viewer
  zero_detail_3dviewer_ratio: 'zero_management_3dviewer_ratio', // type: "square" | "rectangle"
  zero_detail_3dviewer_settings_lighting: 'zero_management_3dviewer_settings_lighting', // export 시 로깅 type : "default" | "studio" | "neutral" | "outdoor" | "indoor"
  zero_detail_3dviewer_settings_size: 'zero_management_3dviewer_settings_size', // export 시 로깅 type : "hide" | "mm" | "cm" | "inch"
  zero_detail_3dviewer_export_javascript: 'zero_management_3dviewer_export_javascript',
  zero_detail_3dviewer_guide: 'zero_management_3dviewer_guide',
  zero_detail_3dviewer_guide_more: 'zero_management_3dviewer_guide_more',
  zero_utilization_detail_3dviewer_capture_download: 'zero_utilization_detail_3dviewer_capture_download',
  zero_utilization_detail_3dviewer_export_javascript: 'zero_utilization_detail_3dviewer_export_javascript',
  zero_utilization_detail_3dviewer_export_iframe: 'zero_utilization_detail_3dviewer_export_iframe',
  zero_utilization_detail_3dviewer_export_viewerpage: 'zero_utilization_detail_3dviewer_export_viewerpage',

  // AR Viewer
  zero_detail_arviewer_export_qrcode: 'zero_management_arviewer_export_qrcode',
  zero_detail_arviewer_export_link: 'zero_management_arviewer_export_link',
  zero_detail_arviewer_arlinkbtn_guide: 'zero_management_arviewer_arlinkbtn_guide',
  zero_detail_arviewer_arlinkbtn_guide_more: 'zero_management_arviewer_arlinkbtn_guide_more',
  zero_detail_arviewer_arlinkbtn_btntype: 'zero_management_arviewer_arlinkbtn_btntype', // export 시 로깅 type: "btn1" | "btn2" | "btn3"
  zero_utilization_detail_arviewer_arlinkbtn_export_javascript:
    'zero_utilization_detail_arviewer_arlinkbtn_export_javascript',
  zero_utilization_detail_arviewer_arlinkbtn_export_hyperlink:
    'zero_utilization_detail_arviewer_arlinkbtn_export_hyperlink',
  zero_utilization_detail_arviewer_export_qrcode: 'zero_utilization_detail_arviewer_export_qrcode',
  zero_utilization_detail_arviewer_export_link: 'zero_utilization_detail_arviewer_export_link',

  // GIF
  zero_utilization_detail_gif_download: 'zero_utilization_detail_gif_download', // viewer_publish_states: "publish" | "unpublish"

  // Other Models
  zero_detail_otherlist_horizontalscroll: 'zero_management_otherlist_horizontalscroll', // direction : "left" | "right"
  zero_detail_otherlist_card_select: 'zero_management_otherlist_card_select',
  zero_detail_otherlist_card_viewer_publish: 'zero_management_otherlist_card_viewer_publish', // type: "on" | "off"

  // 공개 활성화 off 시
  zero_detail_viewer_unpublish: 'zero_management_viewer_unpublish',
  zero_detail_viewer_unpublish_cancel: 'zero_management_viewer_unpublish_cancel',
  zero_detail_viewer_unpublish_modal_noshow: 'zero_management_viewer_unpublish_modal_noshow', // type: "on" | "off"

  // 모델 삭제
  zero_utilization_detail_model_delete_confirm: 'zero_utilization_detail_model_delete_confirm',
  zero_utilization_detail_model_delete_cancel: 'zero_utilization_detail_model_delete_cancel',

  // 모델 정보
};

const signin = {
  zero_signin_auto: 'zero_signin_auto',
  zero_signin_remember: 'zero_signin_remember',
  zero_signin_email_success: 'zero_signin_email_success',
  zero_signin_email_failure: 'zero_signin_email_failure',
  zero_signin_language_switch: 'zero_signin_language_switch',
  zero_signin_policy: 'zero_signin_policy',
  구글: 'zero_google',
  zero_google_failure: 'zero_google_failure',
  네이버: 'zero_naver',
  zero_naver_failure: 'zero_naver_failure',
  카카오: 'zero_kakao',
  zero_kakao_failure: 'zero_kakao_failure',
  zero_signin_change_password: 'zero_signin_change_password',
  zero_signin_change_password_success: 'zero_signin_change_password_success',
  zero_signin_change_password_failure: 'zero_signin_change_password_failure',
};

const signup = {
  zero_signup_try: 'zero_signup_try',
  zero_signup_process1_next: 'zero_signup_process1_next',
  zero_signup_process2_next: 'zero_signup_process2_next',
  zero_signup_process_success: 'zero_signup_process_success',
  zero_signup_process_failure: 'zero_signup_process_failure',
  zero_signup_process_survey_tag: 'zero_signup_process_survey_tag',
  zero_signup_process_survey_etc: 'zero_signup_process_survey_etc',
  zero_signup_process_survey_skip: 'zero_signup_process_survey_skip',
};

const bottomsheet = {
  zero_allmodel_bottomsheet_close: 'zero_allmodel_bottomsheet_close', // after_completed | force_stop
  zero_allmodel_bottomsheet_close_modal_cancel: 'zero_allmodel_bottomsheet_close_modal_cancel', // 바텀시트 닫으려다 취소
  zero_allmodel_bottomsheet_layer: 'zero_allmodel_bottomsheet_layer', // 바텀시트 펼치고 접은 클릭 수 status: "fold" | "unfold"
  zero_allmodel_bottomsheet_list_success: 'zero_allmodel_bottomsheet_list_success',
  zero_allmodel_bottomsheet_list_error_network: 'zero_allmodel_bottomsheet_list_error_network',
  zero_allmodel_bottomsheet_list_error_network_retry: 'zero_allmodel_bottomsheet_list_error_network_retry',
  zero_allmodel_bottomsheet_list_error_network_retry_sucess:
    'zero_allmodel_bottomsheet_list_error_network_retry_sucess',
  zero_allmodel_bottomsheet_list_error_capacity: 'zero_allmodel_bottomsheet_list_error_capacity',
  zero_allmodel_bottomsheet_list_error_capacity_retry: 'zero_allmodel_bottomsheet_list_error_capacity_retry',
  zero_allmodel_bottomsheet_list_error_capacity_retry_sucess:
    'zero_allmodel_bottomsheet_list_error_capacity_retry_sucess',
  zero_allmodel_bottomsheet_list_error_wrong_format: 'zero_allmodel_bottomsheet_list_error_wrong_format',
  zero_allmodel_bottomsheet_list_error_exceed_plan: 'zero_allmodel_bottomsheet_list_error_exceed_plan',
  zero_allmodel_bottomsheet_list_error_exceed_number: 'zero_allmodel_bottomsheet_list_error_exceed_number',
  zero_allmodel_bottomsheet_list_uploading_delete: 'zero_allmodel_bottomsheet_list_uploading_delete', // 업로드 중인 파일 중단 모달 button: "stop" | "cancel"
  zero_allmodel_bottomsheet_list_waiting_delete: 'zero_allmodel_bottomsheet_list_waiting_delete', // 업로드 대기 중인 파일 취소 모달 button: "delete" | "cancel"
};

const uploadcomplete = {
  zero_uploaded_complete_link: 'zero_uploaded_complete_link',
  zero_uploaded_list_horizontalscroll: 'zero_uploaded_list_horizontalscroll', // direction: "left" | "right" TODO: 모바일에서도 로깅해야함.
  zero_uploaded_list_card_viewer_publish: 'zero_uploaded_list_card_viewer_publish', // type: "on" | "off"
  zero_uploaded_list_card_select: 'zero_uploaded_list_card_select',
  zero_uploaded_list_fileupload_more: 'zero_uploaded_list_fileupload_more', // count_upload_files: number
};

const mypage = {
  zero_mypage_withdrawal: 'zero_mypage_withdrawal', // 소셜 회원 중 회원 탈퇴 시도
  zero_mypage_withdrawal_email: 'zero_mypage_withdrawal_email', // 이메일 회원 중 회원 탈퇴 시도
  zero_mypage_email_pw_change_success: 'zero_mypage_email_pw_change_success',
  zero_mypage_email_pw_change_failure: 'zero_mypage_email_pw_change_failure',
  // 탈퇴 안내문 단계
  zero_mypage_withdrawal_guide_next: 'zero_mypage_withdrawal_guide_next',
  zero_mypage_withdrawal_guide_cancel: 'zero_mypage_withdrawal_guide_cancel',
  // 탈퇴 설문조사 단계
  zero_mypage_withdrawal_survey_checklist: 'zero_mypage_withdrawal_survey_checklist',
  zero_mypage_withdrawal_survey_interview_participation: 'zero_mypage_withdrawal_survey_interview_participation',
  zero_mypage_withdrawal_survey_cancel: 'zero_mypage_withdrawal_survey_cancel',
  zero_mypage_withdrawal_survey_next: 'zero_mypage_withdrawal_survey_next',
  zero_mypage_withdrawal_restoration: 'zero_mypage_withdrawal_restoration', // type: "restoration_email" | "restoration_kakao" | "restoration_naver" | "restoration_google"
  zero_mypage_withdrawal_success: 'zero_mypage_withdrawal_success',
};

const payment = {
  zero_payment_prewarning_free_modal_plans: 'zero_payment_prewarning_free_modal_plans',
  zero_payment_prewarning_free_modal_close: 'zero_payment_prewarning_free_modal_close',
  zero_payment_prewarning_basic_modal_plans: 'zero_payment_prewarning_basic_modal_plans',
  zero_payment_prewarning_basic_modal_close: 'zero_payment_prewarning_basic_modal_close',
  zero_payment_prewarning_plus_modal_plans: 'zero_payment_prewarning_plus_modal_plans',
  zero_payment_prewarning_plus_modal_close: 'zero_payment_prewarning_plus_modal_close',
  zero_payment_planover_free_modal_plans: 'zero_payment_planover_free_modal_plans',
  zero_payment_planover_free_modal_close: 'zero_payment_planover_free_modal_close',
  zero_payment_planover_basic_modal_helpcenter: 'zero_payment_planover_basic_modal_helpcenter',
  zero_payment_planover_plus_modal_helpcenter: 'zero_payment_planover_plus_modal_helpcenter',
  zero_payment_planover_basic_modal_close: 'zero_payment_planover_basic_modal_close',
  zero_payment_planover_plus_modal_close: 'zero_payment_planover_plus_modal_close',
  zero_payment_tabs_plans: 'zero_payment_tabs_plans',
  zero_payment_tabs_plans_detail: 'zero_payment_tabs_plans_detail',
  zero_payment_tabs_plans_basic_select: 'zero_payment_tabs_plans_basic_select',
  zero_payment_tabs_plans_plus_select: 'zero_payment_tabs_plans_plus_select',
  zero_payment_tabs_plans_basic_select_reserve_cancel: 'zero_payment_tabs_plans_basic_select_reserve_cancel',
  zero_payment_tabs_plans_basic_select_reserve: 'zero_payment_tabs_plans_basic_select_reserve',
  zero_payment_tabs_plans_basic_select_reserve_cancel_success:
    'zero_payment_tabs_plans_basic_select_reserve_cancel_success',
  zero_payment_tabs_plans_enterprise_contact: 'zero_payment_tabs_plans_enterprise_contact',
  zero_payment_tabs_plans_free_select: 'zero_payment_tabs_plans_free_select',
  zero_payment_tabs_plans_free_select_reserve: 'zero_payment_tabs_plans_free_select_reserve',
  zero_payment_tabs_plans_free_select_reserve_cancel: 'zero_payment_tabs_plans_free_select_reserve_cancel',
  zero_payment_tabs_plans_free_select_reserve_cancel_success:
    'zero_payment_tabs_plans_free_select_reserve_cancel_success',
  zero_payment_tabs_plans_basic_select_failed_modal_contact:
    'zero_payment_tabs_plans_basic_select_failed_modal_contact',
  zero_payment_tabs_plans_basic_select_failed_modal_close: 'zero_payment_tabs_plans_basic_select_failed_modal_close',
  zero_payment_tabs_plans_basic_select_success_history_modal_confirm:
    'zero_payment_tabs_plans_basic_select_success_history_modal_confirm',
  zero_payment_tabs_history: 'zero_payment_tabs_history',
  zero_payment_tabs_history_contact: 'zero_payment_tabs_history_contact',
  zero_payment_tabs_history_receipt: 'zero_payment_tabs_history_receipt',
  zero_payment_tabs_management: 'zero_payment_tabs_management',
  zero_payment_tabs_management_card_change: 'zero_payment_tabs_management_card_change',
  zero_payment_tabs_management_contact: 'zero_payment_tabs_management_contact',
};

const catalog = {
  zero_catalog_add: 'zero_catalog_add',
  zero_catalog_add_create: 'zero_catalog_add_create',
  zero_catalog_add_close: 'zero_catalog_add_close',
  zero_catalog_search: 'zero_catalog_search',
  zero_catalog_filter_states: 'zero_catalog_filter_states',
  zero_catalog_filter_sequence: 'zero_catalog_filter_sequence',
  zero_catalog_publish: 'zero_catalog_publish',
  zero_catalog_unpublish_modal: 'zero_catalog_unpublish_modal',
  zero_catalog_unpublish_modal_cancel: 'zero_catalog_unpublish_modal_cancel',
  zero_catalog_filter_pagination: 'zero_catalog_filter_pagination',
  zero_catalog_list_delete: 'zero_catalog_list_delete',
  zero_catalog_list_delete_modal: 'zero_catalog_list_delete_modal',
  zero_catalog_list_delete_modal_success: 'zero_catalog_list_delete_modal_success',
  zero_catalog_list_delete_modal_failure_publishing: 'zero_catalog_list_delete_modal_failure_publishing',
  zero_catalog_list_readonly: 'zero_catalog_list_readonly',
  zero_catalog_evaluation: 'zero_catalog_evaluation',
  zero_catalog_evaluation_survey: 'zero_catalog_evaluation_survey',
  // editor
  zero_catalog_editor_title: 'zero_catalog_editor_title',
  zero_catalog_editor_source: 'zero_catalog_editor_source',
  zero_catalog_editor_nohistory_import: 'zero_catalog_editor_nohistory_import',
  zero_catalog_editor_pageout_modal_temporary_storage: 'zero_catalog_editor_pageout_modal_temporary_storage',
  zero_catalog_editor_pageout_modal_nosave: 'zero_catalog_editor_pageout_modal_nosave',
  zero_catalog_editor_import_modal_close: 'zero_catalog_editor_import_modal_close',
  zero_catalog_editor_import_modal_execute: 'zero_catalog_editor_import_modal_execute',
  zero_catalog_editor_sequence: 'zero_catalog_editor_sequence',
  zero_catalog_editor_sequence_control: 'zero_catalog_editor_sequence_control',
  zero_catalog_editor_import: 'zero_catalog_editor_import',
  zero_catalog_editor_preview: 'zero_catalog_editor_preview',
  zero_catalog_editor_save: 'zero_catalog_editor_save',
  zero_catalog_editor_publish: 'zero_catalog_editor_publish',
  zero_catalog_editor_publish_modal_run: 'zero_catalog_editor_publish_modal_run',
  zero_catalog_editor_publish_modal_cancel: 'zero_catalog_editor_publish_modal_cancel',
  zero_catalog_editor_publish_modal_run_linkcopy: 'zero_catalog_editor_publish_modal_run_linkcopy',
  zero_catalog_editor_publish_modal_run_close: 'zero_catalog_editor_publish_modal_run_close',
  zero_catalog_editor_delete: 'zero_catalog_editor_delete',
  zero_catalog_editor_typing_title: 'zero_catalog_editor_typing_title',
  zero_catalog_editor_typing_body: 'zero_catalog_editor_typing_body',
  zero_catalog_editor_addmodel: 'zero_catalog_editor_addmodel',
  zero_catalog_editor_model_delete_modal_execute: 'zero_catalog_editor_model_delete_modal_execute',
  zero_catalog_editor_model_delete_modal_cancel: 'zero_catalog_editor_model_delete_modal_cancel',
  zero_catalog_editor_language: 'zero_catalog_editor_language',
  zero_catalog_editor_delete_all: 'zero_catalog_editor_delete_all',
  zero_catalog_editor_delete_selection: 'zero_catalog_editor_delete_selection',
  // preview
  zero_catalog_editor_preview_3dviewer_enlarge: 'zero_catalog_editor_preview_3dviewer_enlarge',
  zero_catalog_editor_preview_3dviewer_turn: 'zero_catalog_editor_preview_3dviewer_turn',
  zero_catalog_editor_preview_arviewer: 'zero_catalog_editor_preview_arviewer',
  zero_catalog_editor_preview_pagination: 'zero_catalog_editor_preview_pagination',
};

const overview = {
  zero_utilization_summary: 'zero_utilization_summary',
  zero_utilization_summary_3dviewer_export_javascript: 'zero_utilization_summary_3dviewer_export_javascript',
  zero_utilization_summary_3dviewer_export_iframe: 'zero_utilization_summary_3dviewer_export_iframe',
  zero_utilization_summary_3dviewer_export_viewerpage: 'zero_utilization_summary_3dviewer_export_viewerpage',
  zero_utilization_summary_3dviewer_capture_download: 'zero_utilization_summary_3dviewer_capture_download',
  zero_utilization_summary_info_download_glb: 'zero_utilization_summary_info_download_glb',
  zero_utilization_summary_arviewer_export_qrcode: 'zero_utilization_summary_arviewer_export_qrcode',
  zero_utilization_summary_arviewer_export_link: 'zero_utilization_summary_arviewer_export_link',
  zero_utilization_summary_arviewer_arlinkbtn_export_javascript:
    'zero_utilization_summary_arviewer_arlinkbtn_export_javascript',
  zero_utilization_summary_arviewer_arlinkbtn_export_hyperlink:
    'zero_utilization_summary_arviewer_arlinkbtn_export_hyperlink',
  zero_utilization_summary_gif_download: 'zero_utilization_summary_gif_download',
  zero_utilization_summary_model_delete_confirm: 'zero_utilization_summary_model_delete_confirm',
  zero_utilization_summary_model_delete_cancel: 'zero_utilization_summary_model_delete_cancel',
};

export default {
  gnb,
  main,
  allmodel,
  detail,
  signin,
  signup,
  bottomsheet,
  uploadcomplete,
  mypage,
  payment,
  catalog,
  overview,
};
