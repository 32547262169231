import { RDSModal } from '@reconlabs/reconlabs-fe-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/utils/useAuth';
import amplitudeEvents from '../../constants/amplitudeEvents';
import { TIER } from '../../constants/values';
import amplitude from '../../utils/amplitude';

const OverViewCountModal = ({
  isOverViewCountModalOpen,
  setIsOverViewCountModalOpen,
  closeHandler,
}: {
  isOverViewCountModalOpen: boolean;
  setIsOverViewCountModalOpen: (openState: boolean) => void;
  closeHandler?: Function;
}) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClose = () => {
    closeHandler && closeHandler();
    setIsOverViewCountModalOpen(false);
  };

  return (
    <RDSModal
      enableCloseButton={false}
      open={isOverViewCountModalOpen}
      onClose={onClose}
      title={`${t(`Payment.플랜 사용량 초과.${user.user_tier}`)}`}
      supportingText={
        <div>
          <div>{`${t(`Payment.플랜 사용량 초과 모달 문구.조회 수.${user.user_tier}.1`)}`}</div>
          <div>{`${t(`Payment.플랜 사용량 초과 모달 문구.조회 수.${user.user_tier}.2`)}`}</div>
        </div>
      }
      button1Label={user.user_tier === TIER.Free ? t('Payment.플랜 구독하기') : t('Payment.문의하기')}
      button1Fn={
        user.user_tier === TIER.Free
          ? () => {
              amplitude.sendEvent(amplitudeEvents.payment.zero_payment_planover_free_modal_plans);
              onClose();
              navigate('/dashboard/payment');
            }
          : () => {
              user.user_tier === TIER.Basic
                ? amplitude.sendEvent(amplitudeEvents.payment.zero_payment_planover_basic_modal_helpcenter)
                : amplitude.sendEvent(amplitudeEvents.payment.zero_payment_planover_plus_modal_helpcenter);
              setIsOverViewCountModalOpen(false);
              (window as any).ChannelIO('openChat');
            }
      }
      button2Label={t('global.닫기')}
      button2Fn={() => {
        amplitude.sendEvent(
          user.user_tier === TIER.Free
            ? amplitudeEvents.payment.zero_payment_planover_free_modal_close
            : user.user_tier === TIER.Basic
            ? amplitudeEvents.payment.zero_payment_planover_basic_modal_close
            : amplitudeEvents.payment.zero_payment_planover_basic_modal_close,
        );
        onClose();
      }}
    ></RDSModal>
  );
};

export default OverViewCountModal;
